import { Col, LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import { LanguageContentObj } from '@traba/types'
import { getEnglishContent } from '@traba/utils'
import { useOpsManualStepByApplicationItemId } from 'src/hooks/useOpsManualStep'
import { DetailRow } from '../DetailRow'

type OpsManualStepApplicationItemContentProps = {
  applicationItemId?: string
  workerAppTitle?: LanguageContentObj[]
  workerAppDescription?: LanguageContentObj[]
  opsConsoleTitle?: string
  opsConsoleDescription?: string
}

export function OpsManualStepApplicationItemContent({
  applicationItemId,
  workerAppTitle,
  workerAppDescription,
  opsConsoleTitle,
  opsConsoleDescription,
}: OpsManualStepApplicationItemContentProps) {
  const { opsManualStep, isLoading: isOpsManualStepLoading } =
    useOpsManualStepByApplicationItemId(applicationItemId || '')

  if (isOpsManualStepLoading) {
    return <LoadingSpinner />
  }

  const {
    parsedWorkerAppTitle: foundWorkerAppTitle,
    parsedWorkerAppDescription: foundWorkerAppDescription,
    opsConsoleTitle: foundOpsConsoleTitle,
    opsConsoleDescription: foundOpsConsoleDescription,
  } = opsManualStep || {}

  const opsAction = foundOpsConsoleTitle || opsConsoleTitle || '-'
  const opsNote = foundOpsConsoleDescription || opsConsoleDescription || '-'
  const title = foundWorkerAppTitle || workerAppTitle
  const subtitle = foundWorkerAppDescription || workerAppDescription

  return (
    <Col gap={theme.space.xs}>
      <DetailRow label="[OPS] Action" value={opsAction} />
      <DetailRow label="[OPS] Note" value={opsNote} />
      <DetailRow
        label="[WORKER APP] Title"
        value={getEnglishContent(title) || '-'}
      />
      <DetailRow
        label="[WORKER APP] Subtitle (optional)"
        value={getEnglishContent(subtitle) || '-'}
      />
    </Col>
  )
}
