import { Input, Row, Text, Toggle } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import {
  isShiftRequestParentInactive,
  shiftRequestSearchText,
} from '@traba/utils'
import { SetStateAction, Dispatch, useMemo, useState } from 'react'

import { ScheduleInfoCard } from 'src/components/Schedule/ScheduleInfoCard'

export const SchedulesTabContent = ({
  shiftRequestParents,
  isSchedulesEnabled = true,
  showInactiveSchedules,
  setShowInactiveSchedules,
}: {
  shiftRequestParents: ShiftRequestParentWithShiftRequest[]
  isSchedulesEnabled?: boolean
  showInactiveSchedules: boolean
  setShowInactiveSchedules: Dispatch<SetStateAction<boolean>>
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('')

  const filteredSchedules = useMemo(
    () =>
      shiftRequestParents
        ?.filter((srp) => {
          const lowerCaseFilter = searchFilter.toLowerCase()
          const concat = `${srp.title} ${srp.shiftRequests.map(shiftRequestSearchText).join(' ')}`
          return concat.toLowerCase().includes(lowerCaseFilter)
        })
        ?.filter(
          (srp) => showInactiveSchedules || !isShiftRequestParentInactive(srp),
        ),
    [shiftRequestParents, searchFilter, showInactiveSchedules],
  )

  return (
    <>
      <Row justifyBetween alignCenter mb={theme.space.xs}>
        <Text variant="h4">Schedules ({filteredSchedules?.length})</Text>
        <Input
          placeholder="Search schedules"
          leftIconName="search"
          name="scheduleSearch"
          type="text"
          defaultValue=""
          containerStyle={{ marginTop: theme.space.zero }}
          width={'65%'}
          value={searchFilter}
          onChange={(e) => {
            e.preventDefault()
            setSearchFilter(e.target.value)
          }}
          onClear={() => setSearchFilter('')}
        />
        <Toggle
          label="Show inactive schedules"
          buttonState={showInactiveSchedules}
          runOnChange={() => setShowInactiveSchedules((s) => !s)}
        />
      </Row>
      {!isSchedulesEnabled && (
        <Text variant="h5" mb={theme.space.xs} color={theme.colors.red}>
          This company cannot view schedules on the biz app, and any existing
          schedules will need to be migrated to the new system before the biz
          can see them. Refer to the onboarding guide for details:{' '}
          <a
            href="https://coda.io/d/Active-All-Projects_d8d1650wtGq/Ops-onboarding_suNW6hkX#_luxGfAzC"
            target="_blank"
            rel="noreferrer"
          >
            https://coda.io/d/Active-All-Projects_d8d1650wtGq/Ops-onboarding_suNW6hkX#_luxGfAzC
          </a>
          .
        </Text>
      )}
      {filteredSchedules?.map((srp, index) => (
        <ScheduleInfoCard
          shiftRequestParent={srp}
          key={`${srp.shiftRequestParentId}_${index}`}
        />
      ))}
    </>
  )
}
