import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import {
  WatchlistRequirementFilter,
  WatchlistSearchParams,
  WorkerDetailsForWatchlist,
  ShiftTag,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'

export const workersOnWatchlistQueryKey = 'workersOnWatchlist'

async function getWorkersOnWatchlist({
  excludedCompanyIds,
  regions,
  nextShiftStartTimeBefore,
  searchText,
  requirementFilters,
  excludeApprovedWorkers,
  showMarkedAsDone,
  limit,
  offset,
  shiftTags,
  pods,
}: WatchlistSearchParams) {
  try {
    const res = await trabaApi.post('/workers/watchlist-v2', {
      excludedCompanyIds,
      regions,
      nextShiftStartTimeBefore,
      searchText,
      requirementFilters,
      excludeApprovedWorkers,
      showMarkedAsDone,
      limit,
      offset,
      shiftTags,
      pods,
    })
    return res.data
  } catch (error: any) {
    const errorMessage = `useWorkers -> getWorkersOnWatchlist() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    captureSentryError(error)
    console.error(errorMessage)
  }
}

export function useWorkersOnWatchlist({
  excludedCompanyIds,
  regionIds,
  nextShiftStartTimeBefore,
  searchText,
  requirementFilters,
  excludeApprovedWorkers,
  showMarkedAsDone,
  page,
  pageSize,
  shiftTags,
  pods,
}: {
  excludedCompanyIds?: string[]
  regionIds?: string[]
  nextShiftStartTimeBefore?: Date
  searchText?: string
  requirementFilters: WatchlistRequirementFilter[]
  excludeApprovedWorkers?: boolean
  showMarkedAsDone?: boolean
  page: number
  pageSize: number
  shiftTags?: ShiftTag[]
  pods?: string[]
}) {
  const params: WatchlistSearchParams = {
    excludedCompanyIds,
    regions: regionIds,
    nextShiftStartTimeBefore,
    searchText,
    requirementFilters,
    excludeApprovedWorkers,
    showMarkedAsDone,
    limit: pageSize,
    offset: page * pageSize,
    shiftTags,
    pods,
  }

  const queryKey = ['workersOnWatchlist', params]
  const queryFn = () => getWorkersOnWatchlist(params)

  const {
    isLoading,
    isFetching,
    isError,
    data: workersOnWatchlist,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerDetailsForWatchlist[], Error>({
    queryKey,
    queryFn,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isFetching,
    isError,
    workersOnWatchlist,
    error,
    isFetched,
    refetch,
  }
}
