import { useStatsigClient } from '@statsig/react-bindings'
import { TabPanel, TabsContainer, Tab, Tabs } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { useState } from 'react'
import { useUserContext } from 'src/context/user/UserContext'
import { internalUserHasOpsLeadPrivileges } from 'src/utils/internalUserUtils'
import { MainLayout } from '../../components/layout/MainLayout/MainLayout'
import { IncentiveListTab } from './components/IncentiveListTab'
import CreateIncentive from './CreateIncentive'
import { GrantIncentivesTab } from './GrantIncentivesTab'

enum TabIndex {
  ALL_INCENTIVES,
  GRANT_INCENTIVES,
  CREATE_INCENTIVES,
}

export default function IncentivesMonitor() {
  const [currentTab, setCurrentTab] = useState<number>(TabIndex.ALL_INCENTIVES)
  const { state } = useUserContext()
  const { client } = useStatsigClient()

  const canCreateIncentives =
    internalUserHasOpsLeadPrivileges(state.userProfile?.internalUser) ||
    client.checkGate('create_incentives')

  return (
    <MainLayout title="Incentives Monitor">
      <Text variant="h4">Incentives Monitor</Text>

      <TabsContainer>
        <Tabs
          value={currentTab}
          onChange={(_, v) => setCurrentTab(v)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab value={TabIndex.ALL_INCENTIVES} label="All Incentives" />
          <Tab value={TabIndex.GRANT_INCENTIVES} label="Grant Incentives" />
          {canCreateIncentives && (
            <Tab value={TabIndex.CREATE_INCENTIVES} label="Create Incentives" />
          )}
        </Tabs>
      </TabsContainer>

      <TabPanel value={currentTab} index={TabIndex.ALL_INCENTIVES}>
        <IncentiveListTab />
      </TabPanel>

      <TabPanel value={currentTab} index={TabIndex.GRANT_INCENTIVES}>
        <GrantIncentivesTab />
      </TabPanel>

      <TabPanel value={currentTab} index={TabIndex.CREATE_INCENTIVES}>
        <CreateIncentive />
      </TabPanel>
    </MainLayout>
  )
}
