import { Tooltip } from '@mui/material'
import { theme } from '@traba/theme'
import { LocationPointTypeEnum } from '@traba/types'
import { differenceInMinutes } from 'date-fns'
import { EarningSummaryWorkerShift } from 'src/hooks/useEarningsSummary'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import {
  wardenExemptionsInfoMap,
  wardenHardRulesInfoMap,
} from 'src/types/warden'
import { formatDuration } from 'src/utils/dateUtils'
import {
  getChargedUnitsString,
  getMoneyString,
  getPayRateString,
} from 'src/utils/stringUtils'
import { DataTable, DataTableHeader, TableCell } from '../base/Table/DataTable'

type EarningsSummaryItemTableProps = {
  workerShiftId: string
  earningSummaryWorkerShift: EarningSummaryWorkerShift
}

export const EarningsSummaryItemTable = ({
  workerShiftId,
  earningSummaryWorkerShift,
}: EarningsSummaryItemTableProps) => {
  const shiftStartTime = new Date(earningSummaryWorkerShift.shift.startTime)
  const shiftEndTime = new Date(earningSummaryWorkerShift.shift.endTime)

  const clockInDateString =
    earningSummaryWorkerShift.workerShiftAdjustments[0]?.clockInTime ??
    earningSummaryWorkerShift.clockInTime
  const clockOutDateString =
    earningSummaryWorkerShift.workerShiftAdjustments[0]?.clockOutTime ??
    earningSummaryWorkerShift.clockOutTime
  const clockInTime = clockInDateString
    ? new Date(clockInDateString)
    : undefined
  const clockOutTime = clockOutDateString
    ? new Date(clockOutDateString)
    : undefined
  const { getShiftTime } = useTimezonedDates(
    earningSummaryWorkerShift.shift.timezone,
  )
  const headers: DataTableHeader[] = [
    {
      key: 'shiftTime',
      label: 'Shift Time',
    },
    {
      key: 'workerTime',
      label: 'Worker Time',
    },
    {
      key: 'totalHours',
      label: 'Total Hours',
    },
    {
      key: 'payRate',
      label: 'Pay Rate',
    },
    {
      key: 'netPay',
      label: 'Net Pay',
    },
    {
      key: 'wardenSource',
      label: 'Warden Source',
    },
    {
      key: 'hardRules',
      label: 'Hard Rules Violated',
    },
    {
      key: 'exemptionsApplied',
      label: 'Exemptions Applied',
    },
    {
      key: 'clockInDiff',
      label: 'Clock In Diff',
    },
    {
      key: 'clockOutDiff',
      label: 'Clock Out Diff',
    },
    {
      key: 'startDist',
      label: 'Start Dist',
    },
    {
      key: 'endDist',
      label: 'End Dist',
    },
  ]

  const cells: TableCell[] = [
    {
      renderFn: () => getShiftTime(shiftStartTime, shiftEndTime),
    },
    {
      renderFn: () =>
        clockInTime && clockOutTime
          ? getShiftTime(clockInTime, clockOutTime)
          : '--',
    },
    {
      renderFn: () => {
        const timeWorked =
          earningSummaryWorkerShift.workerShiftAdjustments[0]?.timeWorked ??
          earningSummaryWorkerShift.timeWorked
        return timeWorked
          ? getChargedUnitsString({
              payType: earningSummaryWorkerShift.shift.payType,
              timeWorkedInMinutes: timeWorked,
              unitsWorked:
                earningSummaryWorkerShift.workerShiftAdjustments[0]
                  ?.unitsWorked ?? earningSummaryWorkerShift.unitsWorked,
            })
          : '--'
      },
    },
    {
      renderFn: () => {
        const payRate =
          earningSummaryWorkerShift.workerShiftAdjustments[0]
            ?.overridePayRate ??
          earningSummaryWorkerShift.overridePayRate ??
          earningSummaryWorkerShift.shift.payRate
        return getPayRateString(
          earningSummaryWorkerShift.shift.payType,
          payRate,
        )
      },
    },
    {
      renderFn: () =>
        earningSummaryWorkerShift.netPayAmount
          ? getMoneyString({
              amount: earningSummaryWorkerShift.netPayAmount,
              currency: 'USD',
            })
          : '--',
    },
    {
      renderFn: () =>
        earningSummaryWorkerShift.wardenResult
          ? earningSummaryWorkerShift.wardenResult.source
          : '--',
    },
    {
      renderFn: () =>
        earningSummaryWorkerShift.wardenResult ? (
          <Tooltip
            title={
              !earningSummaryWorkerShift.wardenResult.hardRuleViolations
                .length ? (
                'No warden hard rules violated'
              ) : (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`Warden Hard Rules violated:\n ${earningSummaryWorkerShift.wardenResult.hardRuleViolations
                    .map(
                      (hardRule) =>
                        `- ${wardenHardRulesInfoMap[hardRule].title}`,
                    )
                    .join('\n')}`}
                </span>
              )
            }
          >
            <div>
              {earningSummaryWorkerShift.wardenResult.hardRuleViolations
                .length ? (
                <span style={{ color: theme.colors.Red70 }}>Yes</span>
              ) : (
                <span>No</span>
              )}
            </div>
          </Tooltip>
        ) : (
          '--'
        ),
    },
    {
      renderFn: () =>
        earningSummaryWorkerShift.wardenResult ? (
          <Tooltip
            title={
              !earningSummaryWorkerShift.wardenResult.exemptionsApplied
                .length ? (
                'No warden exemptions applied'
              ) : (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`Warden Exemptions applied:\n ${earningSummaryWorkerShift.wardenResult.exemptionsApplied
                    .map(
                      (exemption) =>
                        `- ${wardenExemptionsInfoMap[exemption].title}`,
                    )
                    .join('\n')}`}
                </span>
              )
            }
          >
            <div>
              {earningSummaryWorkerShift.wardenResult.exemptionsApplied
                .length ? (
                <span style={{ color: theme.colors.Red70 }}>Yes</span>
              ) : (
                <span>No</span>
              )}
            </div>
          </Tooltip>
        ) : (
          '--'
        ),
    },
    {
      renderFn: () =>
        clockInTime
          ? formatDuration(
              Math.abs(differenceInMinutes(clockInTime, shiftStartTime)),
            )
          : '--',
    },
    {
      renderFn: () =>
        clockOutTime
          ? formatDuration(
              Math.abs(differenceInMinutes(clockOutTime, shiftEndTime)),
            )
          : '--',
    },
    {
      renderFn: () => {
        const startDist = earningSummaryWorkerShift.locationPoints.find(
          (lp) => lp.type === LocationPointTypeEnum.START_SHIFT,
        )
        return startDist
          ? `${startDist.milesFromShiftLocation.toFixed(2)} mi`
          : '--'
      },
    },
    {
      renderFn: () => {
        const endDist = earningSummaryWorkerShift.locationPoints.find(
          (lp) => lp.type === LocationPointTypeEnum.END_SHIFT,
        )
        return endDist
          ? `${endDist.milesFromShiftLocation.toFixed(2)} mi`
          : '--'
      },
    },
  ]

  return (
    <DataTable
      headers={headers}
      rows={[
        {
          cells,
          key: workerShiftId,
        },
      ]}
    />
  )
}
