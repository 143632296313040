import { Col } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerApplicationWithApplicant } from '@traba/types'
import { Fragment } from 'react'
import Divider from 'src/components/base/Divider'
import { CollapsibleWorkerApplicationItemResults } from './CollapsibleWorkerApplicationItemResults'

export type WorkerApplicationResultsProps = {
  workerApplication: WorkerApplicationWithApplicant
  workerId?: string
  selectedWorkerApplicationItemId?: string | null
  analyticsSource?: string
}

export function WorkerApplicationResults({
  workerApplication,
  workerId,
  selectedWorkerApplicationItemId,
  analyticsSource = 'worker-application-results',
}: WorkerApplicationResultsProps) {
  const { workerApplicationItems } = workerApplication

  return (
    <Col gap={theme.space.xs}>
      {workerApplicationItems.map((workerApplicationItem, index) => (
        <Fragment key={workerApplicationItem.id}>
          {index > 0 && <Divider />}
          <CollapsibleWorkerApplicationItemResults
            key={workerApplicationItem.id}
            workerApplicationItem={workerApplicationItem}
            workerId={workerId}
            startOpen={
              workerApplicationItem.id === selectedWorkerApplicationItemId
            }
            analyticsSource={analyticsSource}
          />
        </Fragment>
      ))}
    </Col>
  )
}
