import { theme } from '@traba/theme'
import { CostCenterType } from '@traba/types'
import { useMemo } from 'react'
import {
  SvgIcon,
  Col,
  Row,
  Text,
  DotMenu,
  DotMenuProps,
  Badge,
  CardTile,
} from '../base-components'
import { BadgeVariant } from '../base-components/Badge/Badge.styles'

export type CostCenterProps = {
  costCenter: CostCenterType
  archive?: (costCenter: CostCenterType) => void
  edit?: (costCenter: CostCenterType) => void
  userCanEdit?: boolean
}

export function CostCenter(props: CostCenterProps): React.JSX.Element {
  const { costCenter, archive, edit, userCanEdit } = props

  const menuItems = useMemo(() => {
    const menuItems: DotMenuProps['menuItems'] = []

    if (!userCanEdit) {
      return []
    }

    if (edit) {
      menuItems.push({
        title: 'Edit cost center',
        iconName: 'edit',
        onClick: () => {
          edit(costCenter)
        },
        color: theme.colors.MidnightBlue,
      })
    }

    if (archive) {
      menuItems.push({
        title: 'Archive cost center',
        iconName: 'trash',
        onClick: () => {
          archive(costCenter)
        },
        color: theme.colors.Red60,
      })
    }

    return menuItems
  }, [archive, costCenter, edit, userCanEdit])

  const hasActions = useMemo(() => !!menuItems.length, [menuItems.length])

  return (
    <Row alignCenter gap={theme.space.xxs}>
      <CardTile size="56px">
        <SvgIcon name="dollar" />{' '}
      </CardTile>
      <Col pl={24} width={hasActions ? '90%' : '100%'} gap={theme.space.xxs}>
        <Row alignCenter gap={theme.space.xs}>
          <Text variant="h4">{costCenter.name}</Text>
          <Badge
            variant={
              costCenter.status === 'ACTIVE'
                ? BadgeVariant.SUCCESS
                : BadgeVariant.BUSINESS
            }
            title={costCenter.status}
          />
        </Row>
        {costCenter.locations.length > 0 && (
          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h6">Locations:</Text>
            <Text variant="body2">
              {costCenter.locations.map((loc) => loc.name).join(', ')}
            </Text>
          </Row>
        )}
      </Col>
      {hasActions && (
        <Col width="10%">
          <Row justifyEnd>
            <DotMenu
              type="costCenters"
              dotMenuKey={costCenter.id}
              menuItems={menuItems}
            />
          </Row>
        </Col>
      )}
    </Row>
  )
}
