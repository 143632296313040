import { IMenuItem } from '@traba/react-components'
import {
  InternalUser,
  InternalUserRole,
  JOB_STATUSES_FOR_WORKERS_ON_THE_WAY,
  PaymentStatus,
  RoleAttribute,
  SentinelNotification,
  ShiftNotificationSettingsForShift,
  ShiftRequestParent,
  WorkerShiftForOps,
  WorkerShiftTransit,
} from '@traba/types'
import { addMinutes, isAfter, subHours } from 'date-fns'
import { compact } from 'lodash'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { FieldMonitorFiltersType } from 'src/screens/FieldMonitorScreen/components/FieldMonitorFilters/FieldMonitorFilters'
import { JobStatus } from 'src/types'
import { calculateShiftReliabilityAverage } from 'src/utils/shiftUtils'
import { normalizeString } from 'src/utils/stringUtils'
import { getExpectedWorkerShifts } from 'src/utils/workerShiftUtils'

export const LOW_RELIABILITY_THRESHOLD = 0.8

export function isSentinelNotificationsMuted(
  shiftId: string,
  sentinelNotificationSettings:
    | Record<string, ShiftNotificationSettingsForShift>
    | undefined,
  internalUserId?: string,
) {
  return (
    sentinelNotificationSettings?.[shiftId]?.shiftSettings.shiftSilenced ||
    sentinelNotificationSettings?.[shiftId]?.userSpecificSettings[
      internalUserId ?? ''
    ]?.shiftSilenced
  )
}

export function isShiftRequestParent(
  s: OpsExtendedShift | ShiftRequestParent,
): s is ShiftRequestParent {
  const parent = s as ShiftRequestParent
  return parent.title !== undefined && parent.shiftRequestParentId !== undefined
}

export function getShiftTableRowProps({
  activeFilterCount,
  assignedTo,
  fieldMonitorFilters,
  handleSelectShiftId,
  internalUserId,
  roleAttributes,
  selectedShiftIds,
  sentinelNotificationSettings,
  sentinelNotifications,
  setSelectedShiftIds,
  shift,
  showSelect,
}: {
  shift: OpsExtendedShift
  setSelectedShiftIds:
    | React.Dispatch<React.SetStateAction<string[]>>
    | undefined
  handleSelectShiftId: (s: string) => void
  selectedShiftIds: string[] | undefined
  sentinelNotificationSettings:
    | Record<string, ShiftNotificationSettingsForShift>
    | undefined
  internalUserId: string | undefined
  sentinelNotifications:
    | {
        shiftId: string
        notifications: SentinelNotification[]
      }[]
    | undefined
  activeFilterCount: number | undefined
  fieldMonitorFilters: FieldMonitorFiltersType | undefined
  assignedTo: IMenuItem[] | undefined
  roleAttributes: RoleAttribute[] | undefined
  showSelect: boolean | undefined
}) {
  const workersMovingOrArrived = calculateWorkersMovingOrArrived(
    shift.workerShifts,
    shift.workerShiftTransit,
  )

  return {
    shift: { ...shift, workersMovingOrArrived },
    onSelectShift: setSelectedShiftIds ? handleSelectShiftId : undefined,
    isSelected: !!selectedShiftIds?.includes(shift.id),
    isSentinelNotificationsMuted: isSentinelNotificationsMuted(
      shift.id,
      sentinelNotificationSettings,
      internalUserId,
    ),
    sentinelNotifications: sentinelNotifications
      ?.filter((s) => s.shiftId === shift.id)
      .flatMap((s) => s.notifications)
      .filter(
        (notification) =>
          notification.sentinelNotificationToUser?.internalUserId &&
          notification.sentinelNotificationToUser.internalUserId ===
            internalUserId,
      ),
    activeFilterCount,
    fieldMonitorFilters,
    assignedTo,
    roleAttributes,
    showSelect,
  }
}

export function calculateWorkersMovingOrArrived(
  workerShifts?: WorkerShiftForOps[],
  workerShiftTransit?: WorkerShiftTransit[],
): number {
  const activeWorkerIdSet = workerShifts
    ?.filter((ws) => JOB_STATUSES_FOR_WORKERS_ON_THE_WAY.has(ws.jobStatus))
    .map((ws) => (ws.workerId ? ws.workerId : ws.worker.id))

  return (
    workerShiftTransit?.filter(
      (wst) =>
        activeWorkerIdSet?.includes(wst.workerId) &&
        ((typeof wst.latestRemainingDistanceMeters === 'number' &&
          typeof wst.initialRemainingDistanceMeters === 'number' &&
          wst.latestRemainingDistanceMeters <
            wst.initialRemainingDistanceMeters) ||
          wst.arrivedAt),
    ).length || 0
  )
}

function showShiftHelper({
  activeFilterCount,
  assignedToMarketOps,
  assignedToScaledOps,
  assignedToUser,
  fieldMonitorFilters,
  hasAssignments,
  warnClockIn,
  warnClockOut,
  warnConfirm,
  warnLowReliability,
  warnPaid,
  warnPaidBackupSlotsFilled,
  warnSlotsFilled,
  includesAssignedTo,
  includesFFMax,
  includesFirstName,
  includesLastName,
  useLegacyFieldMonitorFiltering,
}: {
  fieldMonitorFilters: FieldMonitorFiltersType | undefined
  activeFilterCount: number | undefined
  warnLowReliability: boolean
  warnConfirm: boolean
  warnClockIn: boolean
  warnClockOut: boolean
  warnPaid: boolean
  warnPaidBackupSlotsFilled: boolean
  warnSlotsFilled: boolean
  assignedToUser: boolean
  hasAssignments: boolean
  assignedToMarketOps: boolean
  assignedToScaledOps: boolean
  includesFirstName: boolean
  includesLastName: boolean
  includesAssignedTo: boolean
  includesFFMax: boolean
  useLegacyFieldMonitorFiltering: boolean
}): boolean {
  if (!fieldMonitorFilters) {
    return true
  }
  // If the filter is present and worker names do not match then hide row
  /*
   * Logic for showing filteredShifts. Most of this logic is handled in the backend, except for the low reliability and clock in/out filters.
   */
  // TODO(@cgalani25) Once we confirm that Backend Filtering are performing as expected, following logic would be removed.
  if (useLegacyFieldMonitorFiltering) {
    if (
      !includesFirstName ||
      !includesLastName ||
      !includesAssignedTo ||
      !includesFFMax
    ) {
      return false
    }
  }
  if (activeFilterCount && activeFilterCount > 0) {
    if (!warnLowReliability && fieldMonitorFilters.showLowReliabilityShifts) {
      return false
    }
    if (!warnConfirm && fieldMonitorFilters.showNotConfirmedFilter) {
      return false
    }
    if (!warnClockIn && fieldMonitorFilters.showLateClockInShiftsFilter) {
      return false
    }
    if (!warnClockOut && fieldMonitorFilters.showLateClockOutShiftsFilter) {
      return false
    }
    if (useLegacyFieldMonitorFiltering) {
      if (!warnPaid && fieldMonitorFilters.showNotPaidFilter) {
        return false
      }
      if (
        (!warnSlotsFilled || !warnPaidBackupSlotsFilled) &&
        fieldMonitorFilters.showSlotsNotFilledFilter
      ) {
        return false
      }
      if (!assignedToUser && fieldMonitorFilters.showAssignedShifts) {
        return false
      }

      // TODO(@cgalani25): Do NOT add any more logic here. @see https://linear.app/traba/issue/ENG-10522
      if (
        fieldMonitorFilters.showUnassignedShiftsMarketOps &&
        fieldMonitorFilters.showUnassignedShiftsScaleOps
      ) {
        // both are on, then we should filter out assigned ones so it shows all unassigned
        if (hasAssignments) {
          return false
        }
      } else {
        if (
          (fieldMonitorFilters.showUnassignedShiftsMarketOps &&
            assignedToMarketOps) ||
          (fieldMonitorFilters.showUnassignedShiftsScaleOps &&
            assignedToScaledOps)
        ) {
          return false
        }
      }
    }
  }
  return true
}

export function shouldShowShift({
  workerFirstName,
  workerLastName,
  shift,
  fieldMonitorFilters,
  assignedTo,
  internalUser,
  ffMax,
  activeFilterCount,
  useLegacyFieldMonitorFiltering,
}: {
  workerFirstName: string | undefined
  workerLastName: string | undefined
  fieldMonitorFilters: FieldMonitorFiltersType | undefined
  shift: OpsExtendedShift
  assignedTo: IMenuItem[] | undefined
  ffMax: IMenuItem[] | undefined
  internalUser: InternalUser | undefined
  activeFilterCount: number | undefined
  useLegacyFieldMonitorFiltering: boolean
}) {
  /* Parse worker shifts for required info */
  const expectedToWork = getExpectedWorkerShifts(shift.workerShifts)
  const confirmedWS = expectedToWork?.filter((ws) => ws.isShiftConfirmed)
  const clockedInWS = expectedToWork?.filter((ws) => ws.clockInTime)
  const clockedOutWS = expectedToWork?.filter((ws) => ws.clockOutTime)
  const workersWithStrikeCount = (expectedToWork ?? []).filter(
    (ws) => !!ws.worker.strikes?.length,
  ).length

  const reliabilityAverage = calculateShiftReliabilityAverage(
    shift.workerShifts,
  )

  /*
   *  Normalize worker names for filtering
   */
  const normalizedWorkerNames = compact(
    shift.workerShifts?.map((ws) => {
      if (ws.jobStatus !== JobStatus.Canceled) {
        return {
          normalizedFirstName: normalizeString(ws.worker?.firstName || ''),
          normalizedLastName: normalizeString(ws.worker?.lastName || ''),
        }
      }
      return undefined
    }),
  )

  const firstNameFilter = workerFirstName
    ? normalizeString(workerFirstName)
    : ''
  const lastNameFilter = workerLastName ? normalizeString(workerLastName) : ''

  const includesFirstName = firstNameFilter
    ? normalizedWorkerNames.some((workerName) =>
        workerName.normalizedFirstName.includes(firstNameFilter),
      )
    : true
  const includesLastName = lastNameFilter
    ? normalizedWorkerNames.some((workerName) =>
        workerName.normalizedLastName.includes(lastNameFilter),
      )
    : true

  // TODO move this to be a backend filter
  const assignedToSet = new Set(
    assignedTo?.map((item: IMenuItem) => item.value),
  )

  /** Check if there are shifts assigned the selected internal user Ids */
  const includesAssignedTo = !!(assignedToSet.size > 0
    ? shift.shiftAssignment?.assignees.some((assignee) =>
        assignedToSet.has(assignee.internalUserId),
      )
    : true)

  const includesFFMax =
    ffMax && ffMax.length > 0
      ? ffMax.some((item) => item.value === shift.forwardFillMax)
      : true

  //Check if this shift is assigned to logged in user
  const assignedToUser = !!shift.shiftAssignment?.assignees.find(
    (assignee) => assignee.internalUserId === internalUser?.id,
  )

  /*
  Check whether shift is paid
   */
  let workerShiftsPaid = 0
  let workerShiftsOwed = 0

  shift.workerShifts?.forEach((ws) => {
    const earningsSummary = ws.earningsSummary
    if (earningsSummary && earningsSummary.totalOwed > 0) {
      if (
        earningsSummary.paymentStatus === PaymentStatus.Paid ||
        earningsSummary.paymentStatus === PaymentStatus.ManualComplete
      ) {
        workerShiftsPaid++
      }
      workerShiftsOwed++
    }
  })
  /*
   * Logic for showing warnings
   */
  const curDate = new Date()
  const twoHoursBeforeShift = subHours(new Date(shift.startTime), 2)
  const thirtyMinsAfterShift = addMinutes(new Date(shift.endTime), 30)

  const warnLowReliability =
    reliabilityAverage !== null &&
    reliabilityAverage < LOW_RELIABILITY_THRESHOLD

  const warnConfirm = !!(
    isAfter(curDate, twoHoursBeforeShift) &&
    confirmedWS &&
    confirmedWS.length < shift.slotsRequested
  )

  const warnClockIn = !!(
    isAfter(curDate, new Date(shift.startTime)) &&
    clockedInWS &&
    clockedInWS.length < shift.slotsRequested
  )

  const warnClockOut = !!(
    isAfter(curDate, thirtyMinsAfterShift) &&
    clockedOutWS &&
    expectedToWork &&
    clockedOutWS.length < expectedToWork.length
  )

  const warnPaid = workerShiftsPaid < workerShiftsOwed

  const warnSlotsFilled: boolean =
    shift.slotsFilled < (shift.overbookSlotsRequested ?? shift.slotsRequested)

  const hasAssignments = !!shift.shiftAssignment?.assignees?.length

  const assignedToMarketOps = !!shift.shiftAssignment?.assignees.find(
    (assignee) => assignee.internalUser.role === InternalUserRole.MARKET_OPS,
  )

  const assignedToScaledOps = !!shift.shiftAssignment?.assignees.find(
    (assignee) => assignee.internalUser.role === InternalUserRole.SCALED_OPS,
  )

  const warnOverbooked =
    confirmedWS && confirmedWS.length > shift.slotsRequested

  const warnPaidBackupSlotsFilled = !!(
    shift.paidBackupSlotsRequested &&
    (shift.paidBackupSlotsFilled ?? 0) < shift.paidBackupSlotsRequested
  )
  /*
   * Logic for showing filteredShifts
   * FIXME: Do NOT add anymore logic here. We need to revamp. This will lead to more unituitive side-effects as the UI becomes more complex.
   * https://linear.app/traba/issue/ENG-10522
   */
  const showShift = showShiftHelper({
    activeFilterCount,
    assignedToMarketOps,
    assignedToScaledOps,
    assignedToUser,
    fieldMonitorFilters,
    hasAssignments,
    warnClockIn,
    warnClockOut,
    warnConfirm,
    warnLowReliability,
    warnPaid,
    warnPaidBackupSlotsFilled,
    warnSlotsFilled,
    includesAssignedTo,
    includesFFMax,
    includesFirstName,
    includesLastName,
    useLegacyFieldMonitorFiltering,
  })
  return {
    showShift,
    warnLowReliability,
    reliabilityAverage,
    confirmedWS,
    expectedToWork,
    warnConfirm,
    clockedInWS,
    hasAssignments,
    warnClockIn,
    clockedOutWS,
    warnSlotsFilled,
    workerShiftsOwed,
    workerShiftsPaid,
    warnOverbooked,
    warnPaid,
    warnClockOut,
    warnPaidBackupSlotsFilled,
    workersWithStrikeCount,
  }
}
