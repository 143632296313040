import {
  useMutation,
  useQuery,
  useQueries,
  useQueryClient,
} from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  WorkerShiftForOps as WorkerShift,
  ShiftTime,
  BulkEndShiftsResponse,
} from '@traba/types'
import { FIVE_MINUTES_IN_MS, ONE_MINUTE_IN_MS } from 'src/libs/constants'
import { PENDING_CLOCK_OUTS_QUERY_KEY } from './usePendingClockOuts'

const WORKER_SHIFT_QUERY_KEY = 'workerShift'
const WORKER_SHIFTS_QUERY_KEY = 'workerShifts'

async function getWorkerShift({
  workerId,
  shiftId,
  includeWorker,
  includeAccountStatus,
}: {
  workerId: string
  shiftId: string
  includeWorker?: boolean
  includeAccountStatus?: boolean
}): Promise<WorkerShift | undefined> {
  try {
    // Construct query parameters
    const queryParams = new URLSearchParams({
      adjustmentsOption: 'INCORPORATED',
      ...(includeWorker && { includeWorker: 'true' }),
      ...(includeAccountStatus && { includeAccountStatus: 'true' }),
    })

    // Construct full URL with query parameters
    const url = `workers/${workerId}/worker-shifts/${shiftId}?${queryParams.toString()}`

    const res = await trabaApi.get(url)
    return res.data
  } catch (error: any) {
    console.error(
      'useWorkerShifts -> getWorkerShift() ERROR',
      error.message ?? error,
    )
  }
}

export function useWorkerShift({
  workerId,
  shiftId,
  includeWorker,
  includeAccountStatus,
}: {
  workerId: string
  shiftId: string
  includeWorker?: boolean
  includeAccountStatus?: boolean
}) {
  const {
    isLoading,
    isError,
    data: workerShift,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerShift | undefined, Error>({
    queryKey: [
      WORKER_SHIFT_QUERY_KEY,
      workerId,
      shiftId,
      includeWorker,
      includeAccountStatus,
    ],
    queryFn: () =>
      getWorkerShift({
        workerId,
        shiftId,
        includeWorker,
        includeAccountStatus,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    workerShift,
    error,
    isFetched,
    refetch,
  }
}

async function getWorkerShifts(
  shiftId: string,
): Promise<WorkerShift[] | undefined> {
  try {
    const res = await trabaApi.get(
      `shifts/${shiftId}/worker-shifts?includeEarningsSummary=true&includeIfFirstWorkerShiftWithCompany=true`,
    )
    return res.data
  } catch (error: any) {
    console.error(
      'useWorkerShifts -> getWorkerShifts() ERROR',
      error.message ?? error,
    )
  }
}

export function useWorkerShifts(shiftId: string) {
  const {
    isLoading,
    isError,
    data: workerShifts,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerShift[] | undefined, Error>({
    queryKey: [WORKER_SHIFTS_QUERY_KEY, shiftId],
    queryFn: () => getWorkerShifts(shiftId),
    staleTime: ONE_MINUTE_IN_MS,
    enabled: !!shiftId,
  })

  return {
    isLoading,
    isError,
    workerShifts,
    error,
    isFetched,
    refetch,
  }
}

export function useWorkerShiftsById(shiftId: string, workerIds: string[]) {
  const queryResults = useQueries({
    queries: workerIds.map((workerId) => ({
      queryKey: [WORKER_SHIFT_QUERY_KEY, workerId, shiftId],
      queryFn: () =>
        getWorkerShift({
          workerId: workerId,
          shiftId: shiftId,
          includeWorker: true,
          includeAccountStatus: true,
        }),
    })),
  })

  return queryResults.map(
    ({ isLoading, isError, data, error, isFetched, refetch }) => ({
      isLoading,
      isError,
      data,
      error,
      isFetched,
      refetch,
    }),
  )
}

export interface BulkEndShiftsParams {
  workerShiftsToEnd: {
    workerId: string
    shiftId: string
    clockOutTime: Date
    sentinelNotificationToUserId?: string
  }[]
}

const bulkEndShifts = async ({
  workerShiftsToEnd,
}: BulkEndShiftsParams): Promise<BulkEndShiftsResponse> => {
  const response = await trabaApi.patch(`worker-shifts/end-shifts`, {
    workerShiftsToEnd,
  })
  return response.data
}

export interface UpdateShiftParams {
  workerId: string
  shiftId: string
  // params indicating what we want updated
  clockInTime?: Date
  clockOutTime?: Date
  breaks?: ShiftTime[]
  shiftInfo?: {
    payRate?: number
    minimumPaidTime?: number | null
  }
}

const updateShift = async ({
  workerId,
  shiftId,
  ...rest
}: UpdateShiftParams) => {
  const response = await trabaApi.patch(
    `/workers/${workerId}/worker-shifts/${shiftId}`,
    rest,
  )
  return response.data
}

export function useWorkerShiftMutations() {
  const { showError, showSuccess, handleError } = useAlert()
  const queryClient = useQueryClient()

  const bulkEndShiftsMutation = useMutation<
    BulkEndShiftsResponse,
    Error,
    BulkEndShiftsParams
  >({
    mutationFn: bulkEndShifts,
    onSuccess: ({ rejected }) => {
      queryClient.invalidateQueries({
        queryKey: [PENDING_CLOCK_OUTS_QUERY_KEY],
      })

      if (rejected.length > 0) {
        showError(
          'Some worker shifts could not be completed, please see "Reponses" for full list',
        )
      } else {
        showSuccess('All worker shifts have been completed successfully')
      }
    },
    onError: (error) => {
      handleError(
        error,
        'useWorkerShiftMutations -> bulkEndShifts',
        error.message,
        'Error bulk ending worker shifts',
      )
    },
  })

  const updateShiftMutation = useMutation<
    WorkerShift,
    Error,
    UpdateShiftParams,
    WorkerShift
  >({
    mutationFn: updateShift,
    onError: (error) => {
      handleError(
        error,
        'useWorkerShiftMutations -> updateShiftMutation',
        error.message,
        'Error updating worker shift',
      )
    },
  })

  return {
    updateShift: updateShiftMutation.mutateAsync,
    isUpdateShiftLoading: updateShiftMutation.isPending,
    bulkEndShifts: bulkEndShiftsMutation.mutateAsync,
    isBulkEndShiftsLoading: bulkEndShiftsMutation.isPending,
    bulkEndShiftResponseData: bulkEndShiftsMutation.data,
  }
}
