import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  AuditLog,
  Incentive,
  IncentiveRules,
  IncentiveStatus,
  IncentiveTypeIds,
  Money,
  ProgressLine,
  RuleLine,
  Shift,
  SourceType,
  TargetType,
  ValueType,
  WorkerIncentive,
  WorkerIncentiveReviewAction,
  WorkerIncentiveStatus,
} from '@traba/types'
import { captureSentryError, getQueryParams } from '@traba/utils'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { addDays, format, subMonths } from 'date-fns'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { convertPayRateToCents } from 'src/utils/moneyUtils'
import { trimParams } from '../utils/helperUtils'
import {
  DEFAULT_PAGE_SIZE,
  PaginationParams,
  basePaginationParams,
  useBasicPagination,
} from './usePagination'
import { makeSearchParameters } from './utils/searchUtils'

const BASE_HOURLY_INCENTIVE = {
  category: 'Existing Worker Incentive - Engagement',
  categoryId: 'EWI-ENG',
  type: 'shift_hourly_bonus',
  typeId: IncentiveTypeIds.shift_hrly,
  status: IncentiveStatus.Active,
  title: 'Hourly Shift Bonus',
  rules: [],
  target: TargetType.Worker,
  source: SourceType.Traba,
  companyId: 'TRABA',
  valueType: ValueType.HourlyRate,
}

const WORKER_INCENTIVE_AUDIT_LOG_QUERY_KEY = 'workerIncentiveAuditLogs'

const getBaseHourlyIncentive = (
  shift: Shift,
  bonusAmount: number,
): Omit<Incentive, 'incentiveId'> => {
  return {
    ...BASE_HOURLY_INCENTIVE,
    description: `Earn an extra ${bonusAmount}/hr bonus when you complete this shift.`,
    campaignId: `${format(new Date(shift.startTime), 'yyyymmdd')}_${
      shift.employerName
    }_${shift.shiftId}_${bonusAmount}hr`,
    shiftId: shift.shiftId,
    startTime: new Date(),
    endTime: addDays(new Date(shift.startTime), 7),
    internalMemo: `Hourly bonus of ${bonusAmount}/hr for shift ${shift.shiftId}`,
    regionIds: [shift.regionId],
    total: {
      amount: convertPayRateToCents(bonusAmount),
      currency: 'USD',
    },
  }
}

export enum IncentiveOrFields {
  id = 'id',
  shiftId = 'shiftId',
  ruleType = 'ruleType',
  title = 'title',
  description = 'description',
  companyName = 'companyName',
  companyId = 'companyId',
}

export type IncentivesSelectFields = {
  [key: string]: string[]
}

export type IncentivesSearchParams = {
  id?: string
  regionIds?: string[]
  statuses?: IncentiveStatus[]
  typeIds?: IncentiveTypeIds[]
  ruleTypes?: IncentiveRules[]
  valueTypes?: ValueType[]
  companyId?: string
  endTimeBefore?: Date
  orFields?: {
    id?: string
    shiftId?: string
    companyId?: string
    title?: string
    description?: string
    ruleType?: string
  }
}

export type WorkerIncentiveResyncAuditLog = Omit<AuditLog, 'properties'> & {
  properties: {
    incentiveId: string
    workerIncentiveId: string
    updatedStatus: WorkerIncentiveStatus
    previousStatus?: WorkerIncentiveStatus
    reason: string
    changedShifts: string[]
    progress: ProgressLine[]
    action: WorkerIncentiveReviewAction
  }
}

export interface GetPendingWorkerIncentivesProps {
  skip?: number
  limit?: number
  before?: string
  after?: string
  workerIds?: string[]
  workerIncentiveId?: string
  incentiveId?: string
  regionIds?: string[]
  shiftIds?: string[]
  orderBy?: 'asc' | 'desc'
}

export interface GetWorkerIncenitveAuditLogsProps {
  before?: string
  after?: string
  workerId?: string
  workerIncentiveId?: string
  enabled: boolean
}

export interface ReviewPendingIncentiveProps {
  workerId: string
  workerIncentiveId: string
  reversalReason?: string
  action: WorkerIncentiveReviewAction
}

export type IncentivesSearchQuery = {
  withCount?: boolean
  parameters: IncentivesSearchParams
  select?: IncentivesSelectFields
}

export type IncentiveFromSearch = Omit<Incentive, 'rules'> & {
  rules: {
    incentiveId: string
    ruleLine: RuleLine
  }[]
}

export async function runSearchIncentives(
  parameters: IncentivesSearchParams,
  paginationParams: PaginationParams,
  activeOrFields?: IncentiveOrFields[],
  fullTextSearchParam?: string,
  select?: IncentivesSelectFields,
  config?: AxiosRequestConfig,
): Promise<{ incentives: IncentiveFromSearch[]; count: number } | undefined> {
  try {
    const payload: IncentivesSearchQuery = {
      parameters: makeSearchParameters(
        parameters,
        activeOrFields,
        fullTextSearchParam,
      ),
      select,
      withCount: true,
    }
    const { limit, offset, sortBy, sortOrder } = paginationParams
    const URL = `incentives/search?startAt=${offset}&limit=${limit}${
      sortBy ? `&orderBy=${String(sortBy)}` : ''
    }${sortOrder ? `&sortOrder=${String(sortOrder)}` : ''}`
    const res = await trabaApi.post(URL, payload, config)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

const SEARCH_INCENTIVES_QUERY_KEY = 'incentivesSearchCacheKey'

export function useSearchIncentives({
  textSearchValue,
  params,
  paginationParams,
  activeOrFields,
  fullTextSearchParam,
  select,
  config,
}: {
  textSearchValue?: string
  params: IncentivesSearchParams
  paginationParams: PaginationParams
  activeOrFields?: IncentiveOrFields[]
  fullTextSearchParam?: string
  select?: IncentivesSelectFields
  config?: AxiosRequestConfig
}) {
  const { currentPage, goToNextPage, goToPreviousPage, setCurrentPage } =
    useBasicPagination()

  const offset = currentPage * (paginationParams.limit || DEFAULT_PAGE_SIZE)
  const pagination = {
    ...basePaginationParams,
    ...paginationParams,
    offset,
  }

  const {
    data: searchResults,
    error,
    isLoading,
    refetch,
  } = useQuery(
    [
      SEARCH_INCENTIVES_QUERY_KEY,
      textSearchValue,
      params.regionIds,
      params.statuses,
      params.ruleTypes,
      params.valueTypes,
      params.typeIds,
      params.companyId,
      params.endTimeBefore,
      pagination,
      currentPage,
    ],
    () =>
      runSearchIncentives(
        trimParams(params),
        pagination,
        activeOrFields,
        fullTextSearchParam,
        select,
        config,
      ),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  )

  return {
    incentives: searchResults?.incentives || [],
    totalFound: searchResults?.count || 0,
    isLoading,
    error,
    refetch,
    currentPage,
    setCurrentPage,
    goToNextPage,
    goToPreviousPage,
  }
}

const getIncentiveById = async (
  incentiveId: string,
): Promise<Incentive | undefined> => {
  try {
    const response = await trabaApi.get(`incentives/${incentiveId}`)
    return response.data.incentive
  } catch (error) {
    captureSentryError(error)
  }
}

const getWorkerIncentives = async (
  workerId: string,
  status?: WorkerIncentiveStatus,
) => {
  try {
    const url = `worker-incentives/ops/${workerId}${
      status ? `?status=${status}` : ''
    }`
    const response = await trabaApi.get(url)
    return response.data
  } catch (error) {
    captureSentryError(error)
  }
}

const getWorkerIncentivesForIncentive = async (
  incentiveId: string,
  statuses?: WorkerIncentiveStatus[],
) => {
  try {
    const statusesQuery = statuses?.length
      ? statuses.map((s) => `statuses[]=${s}`).join('&')
      : []
    const url = `worker-incentives/query/${incentiveId}?${statusesQuery}`
    const response = await trabaApi.get(url)
    return response.data
  } catch (error) {
    captureSentryError(error)
  }
}

export function useIncentives() {
  const [bonusAmount, setBonusAmount] = useState(0)
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(false)
  const [isCreatingIncentive, setIsCreatingIncentive] = useState(false)
  const { showError, showSuccess } = useAlert()

  const handleIncentiveCreationForShift = async (shift: Shift) => {
    try {
      const newIncentive = getBaseHourlyIncentive(shift, bonusAmount)
      setIsLoadingIncentives(true)
      await trabaApi.post(`incentives`, newIncentive)
      showSuccess('Incentive saved successfully', '')
    } catch (error: unknown) {
      showError(`There was an error creating incentive`, 'Error')
      captureSentryError(error)
    } finally {
      setIsLoadingIncentives(false)
    }
  }

  const useIncentiveById = (incentiveId: string) => {
    return useQuery(
      ['incentive', incentiveId],
      () => getIncentiveById(incentiveId),
      {
        staleTime: 60 * 1000,
        enabled: !!incentiveId,
      },
    )
  }

  async function createNewIncentive(incentive: Omit<Incentive, 'incentiveId'>) {
    try {
      setIsCreatingIncentive(true)
      await trabaApi.post('incentives', incentive)
      showSuccess('Incentive created successfully', '')
    } catch (error) {
      showError('There was an error creating an incentive', 'Error')
      captureSentryError(error)
    } finally {
      setIsCreatingIncentive(false)
    }
  }

  async function getIncentivesForShift(shiftId: string) {
    try {
      setIsLoadingIncentives(true)
      const url = `incentives/query?shiftId=${encodeURIComponent(
        shiftId,
      )}&status=${IncentiveStatus.Active}`
      const response = await trabaApi.get(url)
      return response.data
    } catch (error) {
      showError('There was an error fetching a shift incentive', 'Error')
      captureSentryError(error)
    } finally {
      setIsLoadingIncentives(false)
    }
  }

  async function resyncWorkerIncentive(
    workerId: string,
    incentiveId: string,
    shiftId?: string,
  ) {
    try {
      const response = await trabaApi.patch(`incentives/${workerId}/resync`, {
        from: subMonths(new Date(), 6).toISOString(),
        incentiveIds: [incentiveId],
        shiftId,
      })
      return response.data
    } catch (error) {
      showError('There was an error running the resync', 'Error')
      captureSentryError(error)
    }
  }

  async function reverseWorkerIncentive(
    workerId: string,
    workerIncentiveId: string,
    reversalReason: string,
  ) {
    try {
      const response = await trabaApi.patch(
        `worker-incentives/${workerIncentiveId}/reverse`,
        {
          workerId,
          reversalReason,
        },
      )
      return response.data
    } catch (error) {
      showError('There was an error reversing the worker incentive', 'Error')
      captureSentryError(error)
    }
  }

  async function getActiveIncentivesForWorkerId(workerId: string) {
    try {
      setIsLoadingIncentives(true)
      const url = `incentives/active/${workerId}`
      const response = await trabaApi.get(url)
      return response.data
    } catch (error) {
      showError(
        `There was an error fetching active incentives for workerId ${workerId}`,
        'Error',
      )
      captureSentryError(error)
    } finally {
      setIsLoadingIncentives(false)
    }
  }

  async function updateIncentiveById(
    incentiveId: string,
    updates: Partial<Incentive>,
  ) {
    try {
      setIsLoadingIncentives(true)
      const url = `incentives/${incentiveId}`
      const response = await trabaApi.patch(url, updates)
      return response.data
    } catch (error) {
      showError('There was an error updating an incentive', 'Error')
      captureSentryError(error)
    } finally {
      setIsLoadingIncentives(false)
    }
  }

  const useGetWorkerIncentivesForWorkerId = (workerId: string) => {
    return useQuery(
      ['workerIncentives', workerId],
      () => getWorkerIncentives(workerId),
      {
        staleTime: 60 * 1000,
        enabled: !!workerId,
      },
    )
  }

  const useGetWorkerIncentivesForIncentiveId = (incentiveId: string) => {
    return useQuery(
      ['workerIncentives', incentiveId],
      () => getWorkerIncentivesForIncentive(incentiveId),
      {
        staleTime: 60 * 1000,
        enabled: !!incentiveId,
      },
    )
  }

  return {
    useIncentiveById,
    useGetWorkerIncentivesForIncentiveId,
    useGetWorkerIncentivesForWorkerId,
    bonusAmount,
    setBonusAmount,
    handleIncentiveCreationForShift,
    createNewIncentive,
    getIncentivesForShift,
    getActiveIncentivesForWorkerId,
    isLoadingIncentives,
    isCreatingIncentive,
    updateIncentiveById,
    getWorkerIncentives,
    resyncWorkerIncentive,
    reverseWorkerIncentive,
    getWorkerIncentivesForIncentive,
  }
}

async function reviewWorkerIncentive(props: ReviewPendingIncentiveProps) {
  const { workerId, workerIncentiveId, reversalReason, action } = props

  try {
    const response = await trabaApi.patch(
      `worker-incentives/${workerIncentiveId}/review`,
      {
        update: {
          workerId,
          reversalReason,
        },
        action,
      },
    )
    return response.data
  } catch (error) {
    captureSentryError(error, { data: { workerIncentiveId } })
    console.error(error)
    throw error
  }
}

async function getPendingIncentives(props: GetPendingWorkerIncentivesProps) {
  try {
    const url = `worker-incentives/pending`
    const response = await trabaApi.post(url, props)
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export function usePendingWorkerIncentives(
  props: GetPendingWorkerIncentivesProps,
) {
  const {
    before,
    after,
    workerIds,
    workerIncentiveId,
    incentiveId,
    regionIds,
    shiftIds,
    orderBy,
  } = props

  const {
    isLoading,
    isError,
    data: pendingIncentives,
    error,
    isFetched,
    isFetching,
    refetch,
  } = useQuery<WorkerIncentive[] | undefined, Error>(
    [
      `pendingIncentives`,
      {
        before,
        after,
        workerIds,
        workerIncentiveId,
        incentiveId,
        regionIds,
        shiftIds,
        orderBy,
      },
    ],
    () => getPendingIncentives(props),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const reviewWorkerIncentiveMutation = useMutation<
    any,
    AxiosError,
    ReviewPendingIncentiveProps
  >(reviewWorkerIncentive)

  return {
    isLoading,
    isError,
    pendingIncentives,
    error,
    isFetched,
    isFetching,
    refetch,
    reviewWorkerIncentive: reviewWorkerIncentiveMutation.mutate,
  }
}

async function bulkDismissWorkerIncentives(workerIncentiveIds: string[]) {
  try {
    const response = await trabaApi.patch('worker-incentives/dismiss/bulk', {
      workerIncentiveIds,
    })
    return response.data
  } catch (error) {
    captureSentryError(error, { data: { workerIncentiveIds } })
    console.error(error)
    throw error
  }
}

export function useBulkDismissWorkerIncentivesMutation() {
  const bulkDismissMutation = useMutation<
    PromiseSettledResult<AxiosResponse>[],
    AxiosError,
    string[]
  >(bulkDismissWorkerIncentives)

  return bulkDismissMutation.mutate
}

async function getAuditLogs(props: GetWorkerIncenitveAuditLogsProps) {
  const { workerId, workerIncentiveId, before, after } = props
  try {
    const queryString = getQueryParams([
      ['workerId', workerId],
      ['afterCreationDate', after],
      ['beforeCreationDate', before],
    ])
    const url = `worker-incentives/${workerIncentiveId}/audit-logs${queryString}`
    const response = await trabaApi.get(url)
    return response.data
  } catch (error) {
    captureSentryError(error, { data: { workerIncentiveId } })
    console.error(error)
    throw error
  }
}

export function useWorkerIncentiveAuditLogs(
  props: GetWorkerIncenitveAuditLogsProps,
) {
  const { workerId, workerIncentiveId, before, after, enabled } = props

  const {
    isLoading,
    isError,
    data: auditLogs,
    error,
    isFetched,
    isFetching,
    refetch,
  } = useQuery<WorkerIncentiveResyncAuditLog[] | undefined, Error>(
    [
      WORKER_INCENTIVE_AUDIT_LOG_QUERY_KEY,
      workerIncentiveId,
      workerId,
      before,
      after,
    ],
    () => getAuditLogs(props),
    {
      staleTime: FIVE_MINUTES_IN_MS,
      enabled,
    },
  )

  return {
    isLoading,
    isError,
    auditLogs,
    error,
    isFetched,
    isFetching,
    refetch,
  }
}

export function useGrantWorkerIncentive() {
  const { showSuccess, showError } = useAlert()

  const grantIncentiveMutation = useMutation<
    void,
    AxiosError,
    {
      incentiveId: string
      workerIds: string[]
      shiftId: string
      earnedAt?: Date | null
      total?: Money
    }
  >(async ({ incentiveId, workerIds, earnedAt, shiftId, total }) => {
    try {
      await trabaApi.post(`worker-incentives`, {
        incentiveId,
        workerIds,
        earnedAt,
        shiftId,
        total,
        status: WorkerIncentiveStatus.Complete,
      })
      showSuccess(`Incentive granted to ${workerIds.length} workers`, 'Success')
    } catch (error) {
      const message =
        error && typeof error === 'object' && 'message' in error
          ? String(error.message)
          : JSON.stringify(error)
      showError(message, 'Failed to Grant Incentive')
      captureSentryError(error, { data: { incentiveId, workerIds } })
      throw error
    }
  })

  return {
    grantWorkerIncentive: grantIncentiveMutation.mutateAsync,
    reset: grantIncentiveMutation.reset,
    isLoading: grantIncentiveMutation.isLoading,
    isSuccess: grantIncentiveMutation.isSuccess,
  }
}
