import { Badge, BadgeVariant } from '@traba/react-components'
import {
  WorkerShiftSignupNotification,
  WorkerShiftSignupNotificationStatus,
} from '@traba/types'
import { EligibilityCriteria } from '@traba/types'
import { useState } from 'react'
import NotificationStatusBadge from 'src/components/base/Badge/NotificationStatusBadge'

export type NotificationsTableEligibilityProps = {
  notification: WorkerShiftSignupNotification
}

export default function NotificationsTableEligibility({
  notification,
}: NotificationsTableEligibilityProps) {
  const [showMore, setShowMore] = useState(false)
  const eligibilityCriteriaArray = notification.eligibilityCriteria
    ? Object.keys(notification.eligibilityCriteria)
        .map((key) => ({
          key,
          value:
            notification.eligibilityCriteria[key as keyof EligibilityCriteria],
        }))
        .map((c) =>
          !c.value ? `🚫 ${c.key.replace(/([A-Z])/g, ' $1')}` : null,
        )
        .filter((c) => !!c)
    : []

  return (
    <>
      {notification.status &&
        (notification.status ===
        WorkerShiftSignupNotificationStatus.WorkerIneligible ? (
          <>
            {eligibilityCriteriaArray
              .slice(0, 1)
              .map(
                (c) =>
                  c && (
                    <Badge
                      title={c}
                      variant={BadgeVariant.DANGER}
                      key={`${notification.workerId}_${notification.shiftId}_${c}`}
                    />
                  ),
              )}
            {!showMore && eligibilityCriteriaArray.length > 1 && (
              <Badge
                onClick={() => setShowMore(true)}
                title={'. . .'}
                variant={BadgeVariant.DANGER}
                style={{ cursor: 'pointer', marginLeft: 5 }}
              />
            )}
            {showMore &&
              eligibilityCriteriaArray.length > 1 &&
              eligibilityCriteriaArray
                .slice(1)
                .map(
                  (c) =>
                    c && (
                      <Badge
                        title={c}
                        variant={BadgeVariant.DANGER}
                        style={{ marginLeft: 5 }}
                      />
                    ),
                )}
          </>
        ) : (
          <NotificationStatusBadge notificationStatus={notification.status} />
        ))}
    </>
  )
}
