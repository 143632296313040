import { Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { format } from 'date-fns'
import { MRT_Row } from 'material-react-table'
import { CopyTextIcon } from 'src/components/base'
import { IncentiveFromSearch } from 'src/hooks/useIncentives'
import { getMoneyString } from 'src/utils/stringUtils'

export function IncentiveDetailRow({
  incentive,
}: {
  incentive: MRT_Row<IncentiveFromSearch>
}) {
  const {
    original: {
      title,
      description,
      internalMemo,
      id,
      totalAmount,
      totalCurrency,
      valueType,
      createdAt,
      updatedAt,
      campaignId,
    },
  } = incentive

  return (
    <Row gap={theme.space.sm}>
      <Row flexCol gap={theme.space.sm} style={{ width: '30%' }}>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            TYPE
          </Text>
          <Text>{title}</Text>
        </Row>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            INCENTIVE ID
          </Text>
          <Row>
            <Text
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '9ch',
                whiteSpace: 'nowrap',
              }}
            >
              {id}
            </Text>
            <CopyTextIcon textToCopy={id} />
          </Row>
        </Row>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            CREATED AT
          </Text>
          <Text>
            {createdAt
              ? format(new Date(createdAt), 'MMM dd, yyyy h:mm a')
              : '-'}
          </Text>
        </Row>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            UPDATED AT
          </Text>
          <Text>
            {updatedAt
              ? format(new Date(updatedAt), 'MMM dd, yyyy h:mm a')
              : '-'}
          </Text>
        </Row>
      </Row>

      <Row flexCol gap={theme.space.sm} style={{ width: '20%' }}>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            AMOUNT
          </Text>
          <Text>
            {totalAmount && totalCurrency
              ? getMoneyString({
                  amount: totalAmount,
                  currency: totalCurrency,
                })
              : '-'}
          </Text>
        </Row>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            CURRENCY
          </Text>
          <Text>{totalCurrency || '-'}</Text>
        </Row>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            VALUE TYPE
          </Text>
          <Text>{valueType}</Text>
        </Row>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            TOTAL SPENT
          </Text>
          <Text>{'-'}</Text>
        </Row>
      </Row>

      <Row flexCol gap={theme.space.sm} style={{ width: '40%' }}>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            INTERNAL MEMO
          </Text>
          <Text>{internalMemo || '-'}</Text>
        </Row>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            DESCRIPTION
          </Text>
          <Text>{description || '-'}</Text>
        </Row>
        <Row flexCol>
          <Text variant="caption" color={theme.colors.Grey60}>
            CAMPAIGN ID
          </Text>
          <Text>{campaignId || '-'}</Text>
        </Row>
      </Row>
    </Row>
  )
}
