import { useStatsigClient } from '@statsig/react-bindings'
import {
  Button,
  ButtonVariant,
  DataTable,
  DatePicker,
  IMenuItem,
  Input,
  Link,
  LoadingSpinner,
  SearchSelect,
  TableRow,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  EmploymentType,
  DynamicConfigs,
  getStrikeReasonLabel,
  OperatorConfig,
  Strike,
  StrikeReason,
} from '@traba/types'
import { subWeeks } from 'date-fns'
import { useMemo, useState } from 'react'
import { Col, CopyTextIcon, Row, Icon } from 'src/components/base'
import { CheckboxThemed } from 'src/components/base/CheckboxThemed/CheckboxThemed'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import Pagination from 'src/components/base/Pagination/Pagination'
import { DataTableHeader } from 'src/components/base/Table/DataTable'
import { useCompanies } from 'src/hooks/useCompanies'
import { useBasicPagination } from 'src/hooks/usePagination'
import { useRegions } from 'src/hooks/useRegions'
import { useSearchWorkersWithStrikes } from 'src/hooks/useSearchStrikes'
import { useStrikeMutation } from 'src/hooks/useWorkerStrikes'
import { getOperatorConversationUrl } from 'src/utils/operatorUtils'

const DEFAULT_PAGE_SIZE = 200

const renderStrikeProperty = ({
  strikes,
  property,
}: {
  strikes: Strike[]
  property: keyof Strike
}) => {
  return strikes.map((strike) => {
    let displayValue = strike[property]
    if (displayValue instanceof Date) {
      displayValue = strike.createdAt.toLocaleDateString()
    }
    if (property === 'reason') {
      displayValue = getStrikeReasonLabel(displayValue as StrikeReason)
    }
    return (
      <Row key={`${strike.id}-${property}`} alignCenter mt={theme.space.xxs}>
        <Text>{`${displayValue}`}</Text>
      </Row>
    )
  })
}

export const WorkerStrikesTable = ({
  employmentType,
}: {
  employmentType: EmploymentType
}) => {
  const searchParams = new URLSearchParams(window.location.search)
  const { client: statsigClient } = useStatsigClient()
  const { value } = statsigClient.getDynamicConfig(
    DynamicConfigs.OPERATOR_AI_CONFIG,
  )
  const operatorId = (value as OperatorConfig)?.operatorMissesConfig?.operatorId
  const [strikeReasons, setStrikeReasons] = useState<IMenuItem<string>[]>([])
  const [workerId, setWorkerId] = useState<string | undefined>(
    searchParams.get('workerId') ?? undefined,
  )
  const [shiftId, setShiftId] = useState<string | undefined>(undefined)
  const [strikeCount, setStrikeCount] = useState<number | undefined>(undefined)
  const { currentPage, goToNextPage, goToPreviousPage } = useBasicPagination()
  const { companies = [] } = useCompanies({
    isApproved: true,
  })
  const [createdAfter, setCreatedAfter] = useState<Date | undefined>(
    subWeeks(new Date(), 1),
  )
  const [selectedStrikeIds, setSelectedStrikeIds] = useState<string[]>([])
  const [selectedRegionItems, setSelectedRegionItems] = useState<IMenuItem[]>(
    [],
  )
  const { regions = [], isLoading: isLoadingRegions } = useRegions()

  const regionOptions: IMenuItem[] = useMemo(
    () =>
      regions.map((region) => ({
        label: region.displayName,
        value: region.regionId,
      })),
    [regions],
  )

  const companyOptions: IMenuItem[] = companies.map((company) => {
    return {
      label: company.employerName,
      value: company.id,
      secondaryLabel: company.id,
    }
  })
  const [selectedCompanyItem, setSelectedCompanyItem] = useState<
    IMenuItem | undefined
  >()

  const { workersWithStrikes, isFetching } = useSearchWorkersWithStrikes({
    page: currentPage,
    companyId: selectedCompanyItem?.value,
    createdAfter,
    strikeReasons: strikeReasons.map((reason) => reason.value as StrikeReason),
    shiftId,
    strikeCount,
    regionId:
      selectedRegionItems.length > 0 ? selectedRegionItems[0].value : undefined,
    workerId: workerId,
    employmentType,
  })
  const {
    invalidateStrikes,
    isInvalidating,
    scheduleMissesCalls,
    isSchedulingMissesCalls,
  } = useStrikeMutation()
  const groupedStatusOptions = Object.values(StrikeReason).map((value) => ({
    value,
    label: getStrikeReasonLabel(value),
  }))

  const tableHeaders: DataTableHeader[] = [
    {
      key: 'workerName',
      label: 'Worker name',
    },
    {
      key: 'workerId',
      label: 'Worker ID',
    },
    {
      key: 'phoneNumber',
      label: 'Phone',
    },
    {
      key: 'infractions',
      label: 'Infractions',
    },
    {
      key: 'strikes',
      label: 'Strikes',
    },
    {
      key: 'eventDate',
      label: 'Event Date',
    },
    {
      key: 'shiftId',
      label: 'Shift ID',
    },
    {
      key: 'points',
      label: 'Points',
    },
    {
      key: 'eventType',
      label: 'Event Type',
    },
    {
      key: 'operator',
      label: 'Operator',
    },
    {
      key: 'action',
      label: 'Actions',
    },
  ]

  const tableRows: TableRow[] = useMemo(() => {
    return (
      workersWithStrikes?.map((workersWithStrikes) => ({
        key: workersWithStrikes.id,
        cells: [
          {
            key: `${workersWithStrikes.id}-name`,
            content: `${workersWithStrikes.firstName} ${workersWithStrikes.lastName}`,
            renderFn: () => {
              const fullName = `${workersWithStrikes.firstName} ${workersWithStrikes.lastName}`
              const truncatedName =
                fullName.length > 24 ? fullName.slice(0, 21) + '...' : fullName
              return (
                <Link to={`/workers/${workersWithStrikes.id}`} target="_blank">
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {truncatedName}
                  </Button>
                </Link>
              )
            },
          },
          {
            key: `${workersWithStrikes.id}-worker-id`,
            content: workersWithStrikes.id,
            renderFn: () => {
              return (
                <Row>
                  <Text>{workersWithStrikes.id.slice(0, 8)}...</Text>
                  <CopyTextIcon textToCopy={workersWithStrikes.id} />
                </Row>
              )
            },
          },
          {
            key: `${workersWithStrikes.id}-phone-number`,
            content: workersWithStrikes.phoneNumber,
            renderFn: () => {
              return (
                <Row>
                  <Text>{workersWithStrikes.phoneNumber.slice(0, 4)}...</Text>
                  <CopyTextIcon textToCopy={workersWithStrikes.phoneNumber} />
                </Row>
              )
            },
          },
          {
            key: `${workersWithStrikes.id}-infractions`,
            content: workersWithStrikes.strikes.length,
            renderFn: () => {
              return <Text>{workersWithStrikes.strikes.length}</Text>
            },
          },
          {
            key: `${workersWithStrikes.id}-currentStrikePoints`,
            content: workersWithStrikes.currentStrikePoints,
            renderFn: () => {
              return <Text>{workersWithStrikes.currentStrikePoints}</Text>
            },
          },
          {
            key: `${workersWithStrikes.id}-event-date`,
            content: workersWithStrikes.strikes.map(
              (strike) => strike.createdAt,
            ),
            renderFn: () => {
              return renderStrikeProperty({
                strikes: workersWithStrikes.strikes,
                property: 'createdAt',
              })
            },
          },
          {
            key: `${workersWithStrikes.id}-shift-id`,
            content: workersWithStrikes.strikes.map((strike) => strike.shiftId),
            renderFn: () => {
              return workersWithStrikes.strikes.map((strike) => {
                return (
                  <Row
                    mt={theme.space.xxs}
                    alignCenter
                    key={`${workersWithStrikes.id}-shift-id-${strike.shiftId}`}
                  >
                    <Link
                      to={`/field-monitor/${strike.shiftId}`}
                      target="_blank"
                    >
                      {strike.shiftId?.slice(0, 8)}...
                    </Link>
                    <CopyTextIcon textToCopy={strike.shiftId} />
                  </Row>
                )
              })
            },
          },
          {
            key: `${workersWithStrikes.id}-event-points`,
            content: workersWithStrikes.strikes.map(
              (strike) => strike.strikePoints,
            ),
            renderFn: () => {
              return renderStrikeProperty({
                strikes: workersWithStrikes.strikes,
                property: 'strikePoints',
              })
            },
          },
          {
            key: `${workersWithStrikes.id}-event-type`,
            content: workersWithStrikes.strikes.map((strike) => strike.reason),
            renderFn: () => {
              return renderStrikeProperty({
                strikes: workersWithStrikes.strikes,
                property: 'reason',
              })
            },
          },
          {
            key: `${workersWithStrikes.id}-operator`,
            content: workersWithStrikes.operatorConversationId || '',
            renderFn: () => {
              const operatorUrl = getOperatorConversationUrl(
                operatorId,
                workersWithStrikes.operatorConversationId,
              )
              if (!operatorUrl) {
                return <Text>-</Text>
              }

              return (
                <Row mt={theme.space.xxs} alignCenter>
                  <Link
                    to={operatorUrl}
                    target="_blank"
                    title="View Operator Call Transcript"
                  >
                    <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                      <Row alignCenter>
                        <Icon name="phone" size={16} />
                        <Text ml={theme.space.xxs}>View Call</Text>
                      </Row>
                    </Button>
                  </Link>
                </Row>
              )
            },
          },
          {
            key: `${workersWithStrikes.id}-actions`,
            content: workersWithStrikes.strikes.map(
              (strike) => strike.invalidatedBy,
            ),
            renderFn: () => {
              return workersWithStrikes.strikes.map((strike) => {
                return (
                  <Row
                    mt={theme.space.xxs}
                    key={`${workersWithStrikes.id}-actions-${strike.shiftId}`}
                  >
                    <CheckboxThemed
                      key={`${strike.id}-checkbox`}
                      style={{ alignSelf: 'center' }}
                      selected={selectedStrikeIds.includes(strike.id)}
                      onClick={() => {
                        setSelectedStrikeIds((prev) => {
                          if (prev.includes(strike.id)) {
                            return prev.filter((id) => id !== strike.id)
                          }
                          return [...prev, strike.id]
                        })
                      }}
                    />
                  </Row>
                )
              })
            },
          },
        ],
      })) || []
    )
  }, [workersWithStrikes, selectedStrikeIds, operatorId])

  // Add this new function to check if multiple strikes from the same worker are selected
  const hasMultipleStrikesFromSameWorker = useMemo(() => {
    if (selectedStrikeIds.length === 0) {
      return false
    }

    // Create a map to track worker IDs and count of their selected strikes
    const workerStrikeCounts: Record<string, number> = {}

    workersWithStrikes?.forEach((worker) => {
      worker.strikes.forEach((strike) => {
        if (selectedStrikeIds.includes(strike.id)) {
          workerStrikeCounts[worker.id] =
            (workerStrikeCounts[worker.id] || 0) + 1
        }
      })
    })

    // Check if any worker has more than one strike selected
    return Object.values(workerStrikeCounts).some((count) => count > 1)
  }, [selectedStrikeIds, workersWithStrikes])

  const hasDisallowedStrikeReasons = useMemo(() => {
    if (selectedStrikeIds.length === 0) {
      return false
    }

    const disallowedReasons = [
      StrikeReason.TARDY,
      StrikeReason.EARLY_LEAVE,
      StrikeReason.REJECTION,
    ]

    return workersWithStrikes?.some((worker) =>
      worker.strikes.some(
        (strike) =>
          selectedStrikeIds.includes(strike.id) &&
          disallowedReasons.includes(strike.reason),
      ),
    )
  }, [selectedStrikeIds, workersWithStrikes])

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
      }}
    >
      <Row gap={theme.space.xxs} mb={theme.space.xs} alignCenter justifyBetween>
        <Row gap={theme.space.xxs}>
          <Input
            containerStyle={{ margin: '0' }}
            full
            type="text"
            leftIconName="search"
            placeholder="Worker ID"
            value={workerId}
            onChange={(e) => setWorkerId(e.target.value)}
            onClear={() => setWorkerId('')}
          />
          <Input
            containerStyle={{ margin: '0' }}
            full
            type="text"
            leftIconName="search"
            placeholder="Shift ID"
            value={shiftId}
            onChange={(e) => setShiftId(e.target.value)}
            onClear={() => setShiftId('')}
          />
          <NumberInput
            label="Strike Count"
            value={strikeCount}
            setValue={setStrikeCount}
            step={1}
            min={0}
            max={100}
          />
          <DatePicker
            showTimeFieldInPopover={true}
            setDate={(until) => {
              setCreatedAfter(until ?? undefined)
            }}
            isClearable={true}
            inlineLabel={true}
            label="Created After"
            date={createdAfter}
          />
        </Row>
        <Row
          gap={theme.space.xxs}
          style={{
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Pagination
            dataSize={workersWithStrikes.length}
            onPageLeft={goToPreviousPage}
            onPageRight={goToNextPage}
            page={currentPage}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </Row>
      </Row>
      <Row
        gap={theme.space.xxs}
        pb={theme.space.sm}
        style={{
          justifyContent: 'start',
        }}
        alignCenter
      >
        <SearchSelect
          multiple
          options={groupedStatusOptions}
          selectedItems={strikeReasons}
          handleSelectMultiple={setStrikeReasons}
          label={`Category`}
          style={{ width: '20%' }}
        />
        <SearchSelect
          multiple
          options={regionOptions}
          selectedItems={selectedRegionItems}
          handleSelectMultiple={setSelectedRegionItems}
          label={`Region`}
          style={{ width: '20%' }}
          shouldAlsoSearchSecondaryLabel={true}
          showClearButton={true}
          isLoading={isLoadingRegions}
        />
        <SearchSelect
          options={companyOptions}
          selectItem={selectedCompanyItem}
          handleSelect={setSelectedCompanyItem}
          label={`Company`}
          style={{ width: '20%' }}
          shouldAlsoSearchSecondaryLabel={true}
          showClearButton={true}
        />
      </Row>
      <Row mb={theme.space.sm} justifyBetween>
        <Text variant="h5">Search results</Text>
        <Row alignCenter gap={theme.space.xxs}>
          <Text variant="body2" mr={theme.space.xxs}>
            {selectedStrikeIds.length} strikes selected
          </Text>
          <Button
            variant={ButtonVariant.BRANDNEW}
            loading={isInvalidating}
            disabled={selectedStrikeIds.length === 0}
            onClick={async () => {
              if (selectedStrikeIds.length > 0) {
                await invalidateStrikes({
                  strikeIds: selectedStrikeIds,
                  invalidationReason: 'Forgiven',
                })
              }
            }}
          >
            Forgive events
          </Button>
          <Button
            variant={ButtonVariant.BRANDNEW}
            loading={isSchedulingMissesCalls}
            disabled={
              selectedStrikeIds.length === 0 ||
              hasMultipleStrikesFromSameWorker ||
              hasDisallowedStrikeReasons
            }
            onClick={async () => {
              if (selectedStrikeIds.length > 0) {
                await scheduleMissesCalls({
                  strikeIds: selectedStrikeIds,
                })
              }
            }}
          >
            Operator Call
          </Button>
          <Button
            loading={isInvalidating || isSchedulingMissesCalls}
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              if (selectedStrikeIds.length > 0) {
                setSelectedStrikeIds([])
              } else {
                setSelectedStrikeIds(
                  workersWithStrikes.flatMap((worker) =>
                    worker.strikes.map((strike) => strike.id),
                  ),
                )
              }
            }}
          >
            {selectedStrikeIds.length > 0 ? 'Unselect all' : 'Select all'}
          </Button>
        </Row>
      </Row>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <DataTable
          headers={tableHeaders}
          rows={tableRows}
          initialSortByColumnIndex={0}
        />
      )}
    </Col>
  )
}
