import { Switch } from '@mui/material'
import { Text, SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ChangeEvent, useCallback } from 'react'
import { Button, Col, Icon, Input, Row } from 'src/components/base'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { ButtonVariant } from 'src/components/base/Button/types'
import { StateSearchSelect } from 'src/components/base/SearchSelect/StateSearchSelect'
import { IMenuItem } from 'src/components/base/Select/Select'

export enum HiddenFilterTypes {
  PAYMENT_STATUS_OPTIONS = 'PAYMENT_STATUS_OPTIONS',
  WARDEN_FLAG_ONLY = 'WARDEN_FLAG_ONLY',
  WORKER_EDITED_ONLY = 'WORKER_EDITED_ONLY',
  COMPANY_OPTIONS = 'COMPANY_OPTIONS',
  INCENTIVE_OPTIONS = 'INCENTIVE_OPTIONS',
}

export interface FilterProps {
  shiftId: string
  setShiftId: (shiftId: string) => void
  workerId: string
  setWorkerId: (workerId: string) => void
  dateRange: [Date | null, Date | null]
  setDateRange: (dateRange: [Date | null, Date | null]) => void
  companyIds: IMenuItem[]
  setCompanyIds: (companyIds: IMenuItem[]) => void
  regionIds: string[]
  setRegionIds: (regionIds: string[]) => void
  excludeCompanies: boolean
  setExcludeCompanies: (excludeCompanies: boolean) => void
  paymentStatuses: IMenuItem[]
  setPaymentStatuses: (paymentStatuses: IMenuItem[]) => void
  tagOptions: IMenuItem[]
  selectedTags: IMenuItem[]
  setTags: (tagMenuItems: IMenuItem[]) => void
  wardenHalted: boolean
  setWardenHalted: (wardenHalted: boolean) => void
  workerEditedOnly: boolean
  setWorkerEditedOnly: (workerEditedOnly: boolean) => void
  includeZeroPay: boolean
  setIncludeZeroPay: (includeZeroPay: boolean) => void
  incentiveId: string
  setIncentiveId: (incentiveId: string) => void
  workerIncentiveId: string
  setWorkerIncentiveId: (workerIncentiveId: string) => void
  companyOptions: IMenuItem[]
  regionsOptions: IMenuItem[]
  paymentStatusOptions: IMenuItem[]
  podsOptions: IMenuItem[]
  selectedPods: IMenuItem[]
  setPods: (podMenuItems: IMenuItem[]) => void
}

type FiltersSectionProps = {
  isLoading: boolean
  onClickSearch: () => void
  hiddenFilters?: HiddenFilterTypes[]
  filterProps: FilterProps
}

export function FiltersSection(props: FiltersSectionProps) {
  const { onClickSearch, isLoading, hiddenFilters, filterProps } = props

  const {
    shiftId,
    setShiftId,
    workerId,
    setWorkerId,
    dateRange,
    setDateRange,
    companyIds,
    setCompanyIds,
    regionIds,
    setRegionIds,
    excludeCompanies,
    setExcludeCompanies,
    paymentStatuses,
    setPaymentStatuses,
    tagOptions,
    selectedTags,
    setTags,
    wardenHalted,
    setWardenHalted,
    workerEditedOnly,
    setWorkerEditedOnly,
    incentiveId,
    setIncentiveId,
    workerIncentiveId,
    setWorkerIncentiveId,
    companyOptions,
    regionsOptions,
    paymentStatusOptions,
    podsOptions,
    selectedPods,
    setPods,
    includeZeroPay,
    setIncludeZeroPay,
  } = filterProps

  const toggleExcludedCompanies = useCallback(() => {
    setExcludeCompanies(!excludeCompanies)
  }, [setExcludeCompanies, excludeCompanies])

  const toggleWardenHalted = useCallback(() => {
    setWardenHalted(!wardenHalted)
  }, [setWardenHalted, wardenHalted])

  const toggleWorkerEditedOnly = useCallback(() => {
    setWorkerEditedOnly(!workerEditedOnly)
  }, [setWorkerEditedOnly, workerEditedOnly])

  const toggleIncludeZeroPay = useCallback(() => {
    setIncludeZeroPay(!includeZeroPay)
  }, [setIncludeZeroPay, includeZeroPay])

  const handleSetWorkerId = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setWorkerId(ev.target.value)
    },
    [setWorkerId],
  )

  const handleSetShiftId = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setShiftId(ev.target.value)
    },
    [setShiftId],
  )
  const handleSetIncentiveId = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setIncentiveId(ev.target.value)
    },
    [setIncentiveId],
  )
  const handleSetWorkerIncentiveId = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setWorkerIncentiveId(ev.target.value)
    },
    [setWorkerIncentiveId],
  )
  return (
    <>
      <Row mt={theme.space.sm} alignCenter justifyBetween>
        <Col mr={theme.space.xs}>
          <DateRangePicker
            label="Date range"
            inlineLabel={true}
            dateRange={dateRange}
            setDateRange={setDateRange}
            granularity="day"
          />
        </Col>
        {/* don't show tags filter on Incentives tab */}
        {hiddenFilters?.includes(HiddenFilterTypes.INCENTIVE_OPTIONS) && (
          <>
            <Col mr={theme.space.xxs}>
              <SearchSelect
                options={tagOptions}
                selectedItems={selectedTags}
                handleSelectMultiple={setTags}
                label="Tags"
                multiple
                showClearButton
                selectedOnTop
                onlyShowLabel
              />
            </Col>
            <Col mr={theme.space.xxs}>
              <SearchSelect
                options={podsOptions}
                selectedItems={selectedPods}
                handleSelectMultiple={setPods}
                label="Pods"
                multiple
                showClearButton
                selectedOnTop
                onlyShowLabel
              />
            </Col>
          </>
        )}
        {!hiddenFilters?.includes(HiddenFilterTypes.COMPANY_OPTIONS) && (
          <>
            <Col mr={theme.space.xxs}>
              <SearchSelect
                options={companyOptions}
                selectedItems={companyIds}
                handleSelectMultiple={setCompanyIds}
                label="Businesses"
                maxValueLength={80}
                multiple
                showClearButton
                selectedOnTop
              />
            </Col>
            <Row alignCenter mr={theme.space.xs}>
              <Switch
                inputProps={{ 'aria-label': 'controlled' }}
                checked={excludeCompanies}
                onClick={toggleExcludedCompanies}
              />
              <Text variant="caption">Exclude Companies</Text>
            </Row>
          </>
        )}
        <Col mr={theme.space.xs} style={{ maxWidth: 600 }}>
          <StateSearchSelect
            multiple
            options={regionsOptions}
            handleSelectMultiple={setRegionIds}
            selectedItems={regionIds}
            label="Region"
            showClearButton
          />
        </Col>
        {!hiddenFilters?.includes(HiddenFilterTypes.WARDEN_FLAG_ONLY) && (
          <Row alignCenter mr={theme.space.xs}>
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={wardenHalted}
              onClick={toggleWardenHalted}
            />
            <Text variant="caption">Warden Flagged Only</Text>
          </Row>
        )}
        {!hiddenFilters?.includes(HiddenFilterTypes.INCENTIVE_OPTIONS) && (
          <>
            <Col mr={theme.space.xs}>
              <Input
                full
                label="Incentive Id"
                value={incentiveId}
                onChange={handleSetIncentiveId}
              />
            </Col>
            <Col mr={theme.space.xs}>
              <Input
                full
                label="Worker Incentive Id"
                value={workerIncentiveId}
                onChange={handleSetWorkerIncentiveId}
              />
            </Col>
          </>
        )}
      </Row>
      <Row mt={theme.space.xs} alignCenter>
        <Col mr={theme.space.xs}>
          <Input
            full
            label="Shift ID"
            value={shiftId}
            onChange={handleSetShiftId}
          />
        </Col>
        <Col mr={theme.space.xs}>
          <Input
            full
            label="Worker ID"
            value={workerId}
            onChange={handleSetWorkerId}
          />
        </Col>
        {!hiddenFilters?.includes(HiddenFilterTypes.PAYMENT_STATUS_OPTIONS) && (
          <Col mr={theme.space.xs}>
            <SearchSelect
              multiple
              options={paymentStatusOptions}
              selectedItems={paymentStatuses}
              handleSelectMultiple={setPaymentStatuses}
              label="Payment Status"
              onlyShowLabel
            />
          </Col>
        )}
        <Col>
          <Button
            full
            leftIcon={<Icon name="search" />}
            onClick={onClickSearch}
            variant={ButtonVariant.FILLED}
            loading={isLoading}
          >
            Search
          </Button>
        </Col>
      </Row>
      {!hiddenFilters?.includes(HiddenFilterTypes.WORKER_EDITED_ONLY) && (
        <Row>
          <Row alignCenter>
            <Switch
              size="medium"
              inputProps={{ 'aria-label': 'controlled' }}
              checked={workerEditedOnly}
              onClick={toggleWorkerEditedOnly}
            />
            <Text variant="caption">Worker Edited Only</Text>
            <Switch
              size="medium"
              inputProps={{ 'aria-label': 'controlled' }}
              checked={includeZeroPay}
              onClick={toggleIncludeZeroPay}
            />
            <Text variant="caption">Include Zero Pay</Text>
          </Row>
        </Row>
      )}
    </>
  )
}
