import { useAlert } from '@traba/context'
import {
  SelectDropdown,
  Text,
  Col,
  Row,
  Button,
  Input,
  ButtonVariant,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  EmploymentType,
  Language,
  VettingConfig,
  VettingPromptContext,
  RequiredAttributeLevel,
} from '@traba/types'
import { useCallback, useState } from 'react'
import Checkbox from 'src/components/base/Checkbox'
import { InlineBanner } from 'src/components/InlineBanner/InlineBanner'
import { useAttributes } from 'src/hooks/useAttributes'
import {
  AI_CONSENT,
  DEFAULT_EXIT_MESSAGES,
  getLanguage,
  getPhoneNumber,
  INITIAL_VETTING_CONTEXT_FORM_VALUES,
  VettingConfigInitialValues,
} from 'src/utils/vettingCampaignUtils'
import { RequiredAttributeLevelsSections } from '../RequiredAttributeLevelsSections/RequiredAttributeLevelsSections'

export type VettingContextFormProps = {
  onSubmit?: (data: VettingConfig) => void
  onSave?: (data: VettingConfig) => void
  initialValues?: VettingConfigInitialValues
  loading?: boolean
  includeSMSVetting?: boolean
}

export function VettingContextForm({
  onSubmit,
  onSave,
  initialValues = INITIAL_VETTING_CONTEXT_FORM_VALUES,
  loading,
  includeSMSVetting = true,
}: VettingContextFormProps) {
  const [formData, setFormData] = useState<VettingPromptContext>({
    locationDetails: initialValues.vettingLogistics?.locationDetails || '',
    scheduleDetails: initialValues.vettingLogistics?.scheduleDetails || '',
    payRatePlainText: initialValues.vettingLogistics?.payRatePlainText || '',
    jobTitle: initialValues.vettingLogistics?.jobTitle || '',
    roleDetails: initialValues.vettingLogistics?.roleDetails || '',
    otherQuestions: initialValues.vettingLogistics?.otherQuestions || [],
    language: initialValues.vettingLogistics?.language || Language.EN,
    employmentType:
      initialValues.vettingLogistics?.employmentType ||
      EmploymentType.CONTRACTOR_1099,
  })
  const [collapsed, setCollapsed] = useState(true)
  const { attributes: allAttributes } = useAttributes()
  const [requiredAttributeLevels, setRequiredAttributeLevels] = useState<
    RequiredAttributeLevel[]
  >(initialValues.requiredAttributeLevels ?? [])
  const [customUnqualifiedExitMessage, setCustomUnqualifiedExitMessage] =
    useState(() => {
      if (initialValues.customUnqualifiedExitMessage) {
        return initialValues.customUnqualifiedExitMessage
      }
      return DEFAULT_EXIT_MESSAGES.UNQUALIFIED[
        getLanguage(initialValues.vettingLogistics?.language || Language.EN)
      ]
    })
  const [customQualifiedExitMessage, setCustomQualifiedExitMessage] = useState(
    () => {
      if (initialValues.customQualifiedExitMessage) {
        return initialValues.customQualifiedExitMessage
      }
      const phoneNumber = getPhoneNumber(
        initialValues.vettingLogistics?.employmentType ||
          EmploymentType.CONTRACTOR_1099,
      )
      return DEFAULT_EXIT_MESSAGES.QUALIFIED[
        getLanguage(initialValues.vettingLogistics?.language || Language.EN)
      ](phoneNumber)
    },
  )
  const [initialMessage, setInitialMessage] = useState(
    (initialValues.initialMessage || '')
      .replace(AI_CONSENT.INITIAL_MESSAGE[Language.EN], '')
      .trim(),
  )
  const [newQuestion, setNewQuestion] = useState('')

  const handleInputChange = useCallback(
    (field: keyof VettingPromptContext, value: string) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }))

      // Update qualified exit message when employment type changes
      if (field === 'employmentType') {
        const phoneNumber = getPhoneNumber(value as EmploymentType)
        setCustomQualifiedExitMessage(
          DEFAULT_EXIT_MESSAGES.QUALIFIED[getLanguage(formData.language)](
            phoneNumber,
          ),
        )
      }

      // Update messages when language changes
      if (field === 'language') {
        const phoneNumber = getPhoneNumber(
          formData.employmentType ?? EmploymentType.CONTRACTOR_1099,
        )
        const language = getLanguage(value)
        setCustomQualifiedExitMessage(
          DEFAULT_EXIT_MESSAGES.QUALIFIED[language](phoneNumber),
        )
        setCustomUnqualifiedExitMessage(
          DEFAULT_EXIT_MESSAGES.UNQUALIFIED[language],
        )
      }
    },
    [formData.language, formData.employmentType],
  )

  const handleAddQuestion = () => {
    if (newQuestion.trim()) {
      setFormData((prev) => ({
        ...prev,
        otherQuestions: [...(prev.otherQuestions || []), newQuestion.trim()],
      }))
      setNewQuestion('')
    }
  }

  const handleRemoveQuestion = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      otherQuestions: (prev.otherQuestions || []).filter((_, i) => i !== index),
    }))
  }

  const validateForm = () => {
    const errors: Record<string, string> = {}
    if (!formData.jobTitle.trim()) {
      errors.jobTitle = 'Job Title is required'
    }
    if (!formData.payRatePlainText.trim()) {
      errors.payRate = 'Pay Rate is required'
    }
    if (!formData.locationDetails.trim()) {
      errors.locationDetails = 'Location Details are required'
    }
    if (!formData.scheduleDetails.trim()) {
      errors.scheduleDetails = 'Schedule Details are required'
    }
    if (!initialMessage.trim()) {
      errors.initialMessage = 'Initial Message is required'
    }
    if (!customQualifiedExitMessage.trim()) {
      errors.customQualifiedExitMessage = 'Qualified Exit Message is required'
    }
    return Object.keys(errors).length === 0
  }

  const getProcessedFormData = useCallback((): VettingConfig => {
    const language = getLanguage(formData.language)
    const generatedPromptDetails = [
      `Job Title: ${formData.jobTitle}`,
      `Pay Rate: ${formData.payRatePlainText}`,
      `Location: ${formData.locationDetails}`,
      `Schedule: ${formData.scheduleDetails}`,
      formData.roleDetails && `Role Details: ${formData.roleDetails}`,
      AI_CONSENT.PROMPT_DETAILS,
    ]
      .filter(Boolean)
      .join('\n')

    return {
      vettingLogistics: formData,
      questions: [],
      promptDetails: generatedPromptDetails,
      customUnqualifiedExitMessage,
      customQualifiedExitMessage,
      initialMessage: `${initialMessage}${AI_CONSENT.INITIAL_MESSAGE[language]}`,
      requiredAttributeLevels,
    }
  }, [
    formData,
    customUnqualifiedExitMessage,
    customQualifiedExitMessage,
    initialMessage,
    requiredAttributeLevels,
  ])

  const { showError, showSuccess } = useAlert()

  const handleSubmit = async () => {
    if (!validateForm() || !onSubmit) {
      return
    }
    try {
      onSubmit(getProcessedFormData())
      showSuccess('Successfully submitted vetting context')
    } catch (error) {
      console.error('Error submitting form:', error)
      showError(JSON.stringify(error), 'Error Submitting Vetting Context')
    }
  }

  const handleSave = async () => {
    if (!validateForm() || !onSave) {
      return
    }
    try {
      onSave(getProcessedFormData())
      showSuccess('Successfully saved/updated vetting context')
    } catch (error) {
      console.error('Error saving form:', error)
      showError(JSON.stringify(error), 'Error Saving Vetting Context')
    }
  }

  return (
    <Col style={{ width: '100%' }} mb={theme.space.sm} gap={theme.space.sm}>
      <Col gap={theme.space.xxs}>
        <Text variant="h6">Employment Type:</Text>
        <SelectDropdown
          value={formData.employmentType}
          handleSelect={(value) => handleInputChange('employmentType', value)}
          menuItems={[
            {
              value: EmploymentType.CONTRACTOR_1099,
              label: '1099 Contractor',
            },
            {
              value: EmploymentType.W2,
              label: 'W2',
            },
          ]}
        />
        {formData.employmentType === EmploymentType.W2 && (
          <InlineBanner
            severity="info"
            text={
              'Note: If W2 is selected, the AI will follow a W2 vetting script and introduce itself as "Labor That Works" instead of "Traba".'
            }
          />
        )}
      </Col>
      <Col gap={theme.space.xxs}>
        <Text variant="h6">Phone Vetting:</Text>
        <Input
          label="Job Title"
          value={formData.jobTitle}
          onChange={(e) => handleInputChange('jobTitle', e.target.value)}
          full
        />
        <Input
          label="Pay Rate"
          value={formData.payRatePlainText}
          onChange={(e) =>
            handleInputChange('payRatePlainText', e.target.value)
          }
          placeholder="e.g. $17/hr"
          full
        />
        <Input
          label="Location Details"
          value={formData.locationDetails}
          onChange={(e) => handleInputChange('locationDetails', e.target.value)}
          type="textarea"
          rows={3}
          full
        />
        <Input
          label="Schedule Details"
          value={formData.scheduleDetails}
          onChange={(e) => handleInputChange('scheduleDetails', e.target.value)}
          type="textarea"
          rows={3}
          full
        />
        <Input
          label="Role Details (optional)"
          value={formData.roleDetails}
          onChange={(e) => handleInputChange('roleDetails', e.target.value)}
          type="textarea"
          rows={5}
          full
        />
      </Col>
      <Col gap={theme.space.xxs}>
        <Row alignCenter>
          <Checkbox
            checked={formData.language === Language.ES}
            onChange={(e) =>
              handleInputChange(
                'language',
                e.target.checked ? Language.ES : Language.EN,
              )
            }
            label="Make this a Spanish language call"
          />
        </Row>
        <InlineBanner
          severity="info"
          text={
            'Note: If selected, all AI calls will be conducted in Spanish with no option to switch languages during the call.'
          }
        />
      </Col>
      <Col gap={theme.space.xxs}>
        <Text variant="h6">Additional Phone Questions (optional)</Text>
        <Row alignCenter gap={theme.space.xs}>
          <Input
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            placeholder="Enter an additional question"
            full
            containerStyle={{ marginTop: 0 }}
          />
          <Button
            variant={ButtonVariant.FILLED}
            onClick={handleAddQuestion}
            disabled={!newQuestion.trim()}
          >
            Add
          </Button>
        </Row>
        {formData?.otherQuestions?.map((question, index) => (
          <Row key={index} alignCenter justifyBetween>
            <Text variant="body1">{question}</Text>
            <Button
              variant={ButtonVariant.TEXT}
              onClick={() => handleRemoveQuestion(index)}
            >
              Remove
            </Button>
          </Row>
        ))}
      </Col>
      {includeSMSVetting && (
        <Col gap={theme.space.xs}>
          <Text variant="h6">SMS Vetting:</Text>
          <Input
            label="Initial Message"
            value={initialMessage}
            onChange={(e) => setInitialMessage(e.target.value)}
            type="textarea"
            rows={3}
            full
          />
          <Text variant="caption" color="gray">
            Preview:{' '}
            {`${initialMessage}${AI_CONSENT.INITIAL_MESSAGE[getLanguage(formData.language)]}`}
          </Text>
          <Input
            label="Custom Qualified Exit Message"
            value={customQualifiedExitMessage}
            onChange={(e) => setCustomQualifiedExitMessage(e.target.value)}
            type="textarea"
            rows={3}
            full
          />
          <Input
            label="Custom Unqualified Exit Message (optional)"
            value={customUnqualifiedExitMessage}
            onChange={(e) => setCustomUnqualifiedExitMessage(e.target.value)}
            type="textarea"
            rows={3}
            full
          />
        </Col>
      )}
      <Col gap={theme.space.xs}>
        <Row
          gap={theme.space.xs}
          mt={theme.space.sm}
          mb={theme.space.xs}
          alignCenter
        >
          <Text variant="h5">
            Required Attribute Levels ({requiredAttributeLevels.length})
          </Text>
          <Button
            style={{
              color: collapsed ? theme.colors.Violet : undefined,
            }}
            variant={ButtonVariant.TEXT}
            onClick={() => {
              setCollapsed((prev) => !prev)
            }}
          >
            {collapsed ? '+ Expand' : '- Collapse'}
          </Button>
        </Row>
        {!collapsed && (
          <RequiredAttributeLevelsSections
            attributes={allAttributes}
            setRequiredAttributeLevels={setRequiredAttributeLevels}
            requiredAttributeLevels={requiredAttributeLevels}
            showDeselect={true}
          />
        )}
      </Col>
      <Row justifyEnd gap={theme.space.xs}>
        {!!onSave && (
          <Button
            variant={ButtonVariant.FILLED}
            onClick={handleSave}
            disabled={!validateForm()}
            loading={loading}
          >
            Save
          </Button>
        )}
        {!!onSubmit && (
          <Button
            variant={ButtonVariant.FILLED}
            onClick={handleSubmit}
            disabled={!validateForm()}
            loading={loading}
          >
            Deploy Scout
          </Button>
        )}
      </Row>
    </Col>
  )
}
