import { theme } from '@traba/theme'
import { Shift, ShiftRequest, ShiftRequestParent } from '@traba/types'
import { useMemo } from 'react'
import { Button } from 'src/components/base'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { useModal } from '../../../../components/base/Modal/Modal'
import AcceptShiftModal from '../../../../modals/AcceptShiftModal/AcceptShiftModal'
import { AddWorkersToShiftsOrSchedule } from './AddWorkersToShiftsOrSchedule'

export const AddWorkersToShift = ({
  shift,
  workers,
  shiftRequestParent,
  openModal,
  selectShiftIcon,
  selectScheduleIcon,
  shiftRequests,
}: {
  shift: Shift
  workers: PopulatedWorker[]
  openModal: () => void
  selectShiftIcon: React.ReactNode
  selectScheduleIcon: React.ReactNode
  shiftRequests: ShiftRequest[]
  shiftRequestParent: ShiftRequestParent | undefined
}) => {
  const acceptShiftModal = useModal()

  const parseSelectedWorkers = () => {
    return workers.map((w) => w.id || w.workerId)
  }

  const multipleShiftsModalContent = useMemo(() => {
    if (!shiftRequestParent) {
      return (
        <Button onClick={openModal} ml={theme.space.xxs}>
          Add workers to multiple shifts
        </Button>
      )
    }

    return (
      <AddWorkersToShiftsOrSchedule
        shift={shift}
        workers={workers}
        shiftRequestParentId={shiftRequestParent.shiftRequestParentId}
        shiftRequests={shiftRequests}
        selectShiftIcon={selectShiftIcon}
        selectScheduleIcon={selectScheduleIcon}
        openModal={openModal}
      />
    )
  }, [
    workers,
    shiftRequestParent,
    shiftRequests,
    openModal,
    selectShiftIcon,
    selectScheduleIcon,
    shift,
  ])

  return (
    <>
      <Button
        onClick={() => acceptShiftModal.open()}
        ml={theme.space.xxs}
        disabled={workers.length === 0}
      >
        {workers.length === 0
          ? 'Select workers'
          : `Add ${workers.length} workers`}
      </Button>

      {workers.length > 0 && (
        <AcceptShiftModal
          {...acceptShiftModal}
          workerIds={parseSelectedWorkers()}
          shift={shift}
        />
      )}
      {multipleShiftsModalContent}
    </>
  )
}
