import { LoadingSpinner } from '@traba/react-components'

import { useState } from 'react'

import { SchedulesTabContent } from 'src/components/Schedule/SchedulesTabContent'
import { useSchedulesFeatureFlag } from 'src/hooks/useSchedulesFeatureFlag'
import { useShiftRequestParents } from 'src/hooks/useShiftRequestParents'

interface Props {
  companyId: string
}

export const CompanySchedulesTab = ({ companyId }: Props) => {
  const { isSchedulesEnabled } = useSchedulesFeatureFlag(companyId)
  const [showInactiveSchedules, setShowInactiveSchedules] = useState(false)
  const { isLoading, shiftRequestParents } = useShiftRequestParents({
    companyId,
  })

  if (isLoading || !shiftRequestParents) {
    return <LoadingSpinner />
  }

  return (
    <SchedulesTabContent
      shiftRequestParents={shiftRequestParents}
      isSchedulesEnabled={isSchedulesEnabled}
      showInactiveSchedules={showInactiveSchedules}
      setShowInactiveSchedules={setShowInactiveSchedules}
    />
  )
}
