import {
  BreakType,
  ClientShift,
  EmploymentType,
  LocationResponse,
  ONGOING_JOB_STATUSES,
  ScheduledBreak,
  Shift,
  ShiftTime,
  WorkerShift,
} from '@traba/types'
import {
  differenceInSeconds,
  intervalToDuration,
  startOfMinute,
} from 'date-fns'

import { addMinutes, differenceInMinutes } from 'date-fns'
import { getLocationNameOrTruncatedAddress } from './stringUtils'

// TODO: Query the server for some of these numbers instead of calculating it on the client.
/**
 * Returns sum of break times. If a break time is in between minutes, we fix to start of minute
 * (e.g., if break start time is 10:45:49 AM, it is treated as 10:45:00 AM)
 * @param {WorkerShiftResponseDto} activeShift Active Shift object
 * @param {Date} endDate Date object
 * @returns {number} time worked in minutes
 */
function calculateBreaksInSeconds(breaks: ShiftTime[]) {
  const breakTimes =
    breaks?.map((shiftBreak) => {
      const { startTime, endTime } = shiftBreak
      if (!endTime || !startTime) {
        return 0
      }
      return differenceInSeconds(
        startOfMinute(new Date(endTime)),
        startOfMinute(new Date(startTime)),
      )
    }) || []

  return breakTimes?.reduce((acc, curr) => {
    return acc + curr
  }, 0)
}

export const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

export function calculateBreaksInMinutes(
  workerShift: WorkerShift,
  enableWorkerEditTime?: boolean,
) {
  return Math.floor(calculateBreakTimes(workerShift, enableWorkerEditTime) / 60)
}

function calculateScheduledBreaksInSeconds(
  scheduledBreaks: ScheduledBreak[],
): number {
  return calculatedScheduledBreaksInMinutes(scheduledBreaks) * 60
}

export function calculatedScheduledBreaksInMinutes(
  scheduledBreaks: ScheduledBreak[],
): number {
  return scheduledBreaks?.reduce((acc, curr) => {
    return acc + curr.breakLength * curr.count
  }, 0)
}

export function calculateBreakTimes(
  workerShift: WorkerShift,
  enableWorkerEditTime?: boolean,
) {
  const { shiftInfo } = workerShift
  const breaks = getBreaksForWorkerShift(workerShift, enableWorkerEditTime)
  const { breakType, scheduledBreaks } = shiftInfo

  if (breakType === BreakType.PAID) {
    return 0
  }

  /* If breaks[] has any values, we consider this an override to the
  scheduledBreaks and return the total break time from the array. */
  const breaksInSeconds = calculateBreaksInSeconds(breaks)
  if (breaks.length > 0) {
    return breaksInSeconds
  }

  if (breakType === BreakType.AUTO_UNPAID) {
    const scheduledBreaksInSeconds =
      calculateScheduledBreaksInSeconds(scheduledBreaks)
    return Math.max(scheduledBreaksInSeconds, breaksInSeconds)
  }

  return breaksInSeconds
}

export function calculateClockedInTimeInSeconds(activeShift: WorkerShift) {
  const { clockInTime, clockOutTime } = activeShift
  if (!clockInTime || !clockOutTime) {
    return 0
  }
  const shiftTime = differenceInSeconds(
    startOfMinute(clockOutTime),
    startOfMinute(clockInTime),
  )
  return shiftTime
}

/**
 * Returns time worked on a shift in minutes, subtracting break times. If shift time is
 * in between minutes, we fix to start of minute (e.g., if clock in time is 7:45:49 AM, it is treated as 7:45:00 AM)
 * @param {WorkerShiftResponseDto} activeShift Active Shift object
 * @param {Date} endDate Date object
 * @returns {number} time worked in minutes
 */
export function calculateTimeWorkedInMinutes(
  activeShift: WorkerShift,
  enableWorkerEditTime?: boolean,
): number {
  const shiftTime = calculateClockedInTimeInSeconds(activeShift)
  const totalBreakTime = calculateBreakTimes(activeShift, enableWorkerEditTime)
  if (totalBreakTime) {
    // Math.max added to avoid negative worked times
    return Math.max(Math.floor((shiftTime - totalBreakTime) / 60), 0)
  }
  return Math.floor(shiftTime / 60)
}

// Get Estimated Shift Details
export function getTotalScheduledBreakTime(
  scheduledBreaks: ScheduledBreak[] = [],
) {
  return (
    scheduledBreaks.reduce((acc: number, currentBreak: ScheduledBreak) => {
      return acc + currentBreak.count * currentBreak.breakLength
    }, 0) || 0
  )
}

export function getScheduledBreaksTimeString(
  scheduledBreaks: ScheduledBreak[],
) {
  return `${calculatedScheduledBreaksInMinutes(scheduledBreaks)}m`
}

export function getTotalBreaksTimeString({
  workerShift,
  displayHours,
  enableWorkerEditTime,
}: {
  workerShift: WorkerShift
  displayHours: boolean
  enableWorkerEditTime?: boolean
}) {
  if (!displayHours) {
    const totalBreaksMinutes = calculateBreaksInMinutes(
      workerShift,
      enableWorkerEditTime,
    )
    return `${totalBreaksMinutes ? `${totalBreaksMinutes}m ` : ''}`
  }
  const totalBreaksTime = intervalToDuration({
    start: 0,
    end: calculateBreakTimes(workerShift, enableWorkerEditTime) * 1000,
  })

  return convertDurationToTimeString(totalBreaksTime)
}

/**
 * Determine if we should use breaksBeforeWorkerEdit or breaks field in workerShift
 * @param workerShift The WorkerShift object
 * @param enableWorkerEditTime Flag indicating whether worker edit time is enabled
 * @returns true if we should use breaksBeforeWorkerEdit, false otherwise
 */
export function shouldUseBreaksBeforeWorkerEdit(
  workerShift: WorkerShift,
  enableWorkerEditTime?: boolean,
) {
  return (
    enableWorkerEditTime &&
    workerShift.shiftInfo.breakType === BreakType.MANUAL_UNPAID &&
    ONGOING_JOB_STATUSES.includes(workerShift.jobStatus) &&
    // if breaks are already populated, we don't want to override them (backward compatibility)
    !workerShift.breaks
  )
}

/**
 * Determines which breaks array to use for the given WorkerShift object.
 *
 * @param workerShift The WorkerShift object
 * @param enableWorkerEditTime hotsetting flag
 * @returns The appropriate breaks array (breaksBeforeWorkerEdit or breaks)
 */
export function getBreaksForWorkerShift(
  workerShift: WorkerShift,
  enableWorkerEditTime?: boolean,
) {
  return (
    (shouldUseBreaksBeforeWorkerEdit(workerShift, enableWorkerEditTime)
      ? workerShift.breaksBeforeWorkerEdit
      : workerShift.breaks) || []
  )
}

export function convertDurationToTimeString(duration: Duration) {
  return `${duration.years ? `${duration.years}y ` : ''}${
    duration.months ? `${duration.months}mo ` : ''
  }${duration.days ? `${duration.days}d ` : ''}${
    duration.hours ? `${duration.hours}h ` : ''
  }${duration.minutes ? `${duration.minutes}m` : ''}`
}

export function shiftIsClientShift(
  shift: Shift | ClientShift,
): shift is ClientShift {
  const anyShift = shift as any
  return (
    anyShift.hideAnnouncements !== undefined ||
    anyShift.hideInvitations !== undefined ||
    anyShift.editInfinite !== undefined
  )
}

/**
 * Gets the time when the shift opens in case of invite first
 */
export function getTimeUntilShiftOpen(creationTime: Date, shiftStart: Date) {
  const SIX_HOURS_IN_MINUTES = 60 * 6
  const minutesUntilShiftStart = differenceInMinutes(shiftStart, creationTime)

  if (minutesUntilShiftStart > SIX_HOURS_IN_MINUTES) {
    return addMinutes(creationTime, minutesUntilShiftStart / 4)
  } else {
    return addMinutes(creationTime, 5)
  }
}

export function sortLocationsByName(
  locations: Pick<LocationResponse, 'name' | 'address'>[] = [],
) {
  return locations.sort((loc1, loc2) => {
    const locationName1 = getLocationNameOrTruncatedAddress(loc1)
    const locationName2 = getLocationNameOrTruncatedAddress(loc2)

    return locationName1.localeCompare(locationName2)
  })
}

export function isObject(v: unknown): v is object {
  return !!v && typeof v === 'object'
}

export function isEmploymentTypesOnlyW2(
  employmentTypes: EmploymentType[] | undefined,
): boolean {
  return (
    !!employmentTypes &&
    employmentTypes.length === 1 &&
    employmentTypes[0] === EmploymentType.W2
  )
}
