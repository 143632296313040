import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Col,
  InlineBanner,
  Input,
  LinkText,
  Row,
  SearchSelect,
  Table,
  Td,
  Text,
  Tr,
} from '@traba/react-components'
import { theme, Z_INDEXES } from '@traba/theme'
import {
  WorkerTimecard,
  WorkerTimecardOpsDecisionReason,
  WorkerTimecardStatus,
} from '@traba/types'
import { formatDuration } from '@traba/utils'
import { useMemo, useState } from 'react'
import { Modal } from 'src/components/base/Modal/Modal'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { WorkerShiftHistoryItem } from 'src/components/WorkerShiftHistoryItem'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { finalizeW2WorkerTimecard } from 'src/hooks/useW2WorkerTimecard'
import { W2WorkerBreakDisputeRow } from './W2WorkerBreakDisputeRow'
import { W2WorkerClockInDisputeRow } from './W2WorkerClockInDisputeRow'
import { W2WorkerClockOutDisputeRow } from './W2WorkerClockOutDisputeRow'
import { W2WorkerTotalDisputeRow } from './W2WorkerTotalDisputeRow'

interface W2WorkerHourDisputeModalProps {
  isOpen: boolean
  onClose: () => void
  onFinalize: () => void
  workerTimecard: WorkerTimecard
}

const REASON_OPTIONS = [
  {
    label: 'Business as source of truth',
    value: WorkerTimecardOpsDecisionReason.BUSINESS_AS_SOURCE_OF_TRUTH,
  },
  {
    label:
      'Sufficient evidence collected to confirm dispute/adjustment (business provided)',
    value: WorkerTimecardOpsDecisionReason.ENOUGH_EVIDENCE_BUSINESS,
  },
  {
    label:
      'Sufficient evidence collected to confirm dispute/adjustment (worker provided)',
    value: WorkerTimecardOpsDecisionReason.ENOUGH_EVIDENCE_WORKER,
  },
  {
    label:
      'Sufficient evidence collected to confirm dispute/adjustment (Ops provided)',
    value: WorkerTimecardOpsDecisionReason.ENOUGH_EVIDENCE_OPS,
  },
  {
    label: 'Other',
    value: WorkerTimecardOpsDecisionReason.OTHER,
  },
]

export const W2WorkerHourDisputeModal = (
  props: W2WorkerHourDisputeModalProps,
) => {
  const { isOpen, onClose, workerTimecard, onFinalize } = props
  const {
    worker,
    bizClockOutTime,
    bizClockInTime,
    bizTotalBreakDurationSeconds,
    workerClockOutTime,
    workerClockInTime,
    workerTotalBreakDurationSeconds,
    opsClockInTime,
    opsClockOutTime,
    opsTotalBreakDurationSeconds,
    workerShift,
    status,
    shiftId,
    workerId,
  } = workerTimecard
  const [selectedReason, setSelectedReason] = useState<
    WorkerTimecardOpsDecisionReason | undefined
  >(undefined)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [otherReason, setOtherReason] = useState<string | undefined>(undefined)
  const [finalBreakDuration, setFinalBreakDuration] = useState<
    number | undefined
  >(
    Number(
      opsTotalBreakDurationSeconds ??
        workerTotalBreakDurationSeconds ??
        bizTotalBreakDurationSeconds,
    ),
  )
  const [finalClockInTime, setFinalClockInTime] = useState<Date>(
    opsClockInTime ?? workerClockInTime ?? bizClockInTime,
  )
  const [finalClockOutTime, setFinalClockOutTime] = useState<Date>(
    opsClockOutTime ?? workerClockOutTime ?? bizClockOutTime,
  )
  const { showError, showSuccess } = useAlert()
  const canContinue = useMemo(() => {
    if (selectedReason === WorkerTimecardOpsDecisionReason.OTHER) {
      return !!otherReason
    }
    return !!selectedReason
  }, [selectedReason, otherReason])

  const tz = useTimezonedDates(workerShift.timeZone)
  const calculatedTotalWorkedHoursString = (
    clockIn?: Date,
    clockOut?: Date,
    breakDuration?: number,
  ) => {
    if (!clockIn || !clockOut) {
      return ' - '
    }
    const totalMinutesWorked =
      (clockOut.getTime() - clockIn.getTime()) / (1000 * 60) -
      (breakDuration ?? 0) / 60

    return formatDuration(totalMinutesWorked)
  }

  const bizTotalWorked = calculatedTotalWorkedHoursString(
    bizClockInTime,
    bizClockOutTime,
    bizTotalBreakDurationSeconds,
  )
  const workerTotalWorked = calculatedTotalWorkedHoursString(
    workerClockInTime,
    workerClockOutTime,
    workerTotalBreakDurationSeconds,
  )

  const finalTotalWorked = useMemo(() => {
    return calculatedTotalWorkedHoursString(
      finalClockInTime,
      finalClockOutTime,
      finalBreakDuration,
    )
  }, [finalClockInTime, finalClockOutTime, finalBreakDuration])

  const isFinalized = status === WorkerTimecardStatus.FINALIZED
  const isPendingWorker = status === WorkerTimecardStatus.PENDING_WORKER

  return (
    <Modal
      handleClose={onClose}
      isOpen={isOpen}
      size={MODAL_SIZE.MEDIUM}
      title={`Review timecard for ${worker.firstName} ${worker.lastName}`}
      style={{
        zIndex: Z_INDEXES.MODAL,
      }}
    >
      <Row justifyBetween mb={theme.space.sm} mx={theme.space.sm}>
        <Col>
          <WorkerShiftHistoryItem
            header="CREATED AT"
            value={`${tz.getDate(workerTimecard.createdAt, false)}, 
              ${tz.getTime(workerTimecard.createdAt)}`}
          />
          <WorkerShiftHistoryItem
            header="SHIFT ROLE"
            value={
              <LinkText
                variant="link"
                onClick={() => {
                  window.open(`/field-monitor/${shiftId}`)
                }}
              >
                {workerShift.roleName}
              </LinkText>
            }
          />
        </Col>
        <Col>
          <WorkerShiftHistoryItem
            header="WORKER NAME"
            value={
              <LinkText
                variant="link"
                onClick={() => window.open(`/worker/${workerId}`)}
              >
                {`${worker.firstName} ${worker.lastName}`}
              </LinkText>
            }
          />
          <WorkerShiftHistoryItem header="REGION ID" value={worker.regionId} />
        </Col>
        <Col>
          <WorkerShiftHistoryItem
            header="COMPANY NAME"
            value={workerShift.employerName}
          />
          <WorkerShiftHistoryItem
            header="PAY RATE"
            value={`$${workerShift.payRate}/hr`}
          />
        </Col>
      </Row>
      <Row
        flexCol
        mb={theme.space.sm}
        style={{
          backgroundColor: theme.colors.Grey10,
          borderRadius: theme.space.xxs,
          padding: theme.space.xs,
        }}
      >
        <Text variant="h5">Summary</Text>
        <Text variant="body2">
          A summary of business times and worker disputed times. You need to
          make a final decision on the working hours for this shift and provide
          a reason.
        </Text>
        <Table headers={['', 'Business times', 'Worker times', 'Final times']}>
          <Tr>
            <Td>
              <Text variant="caption">CLOCK IN</Text>
            </Td>
            <W2WorkerClockInDisputeRow
              finalTime={finalClockInTime}
              setFinalTime={setFinalClockInTime}
              timecard={workerTimecard}
            />
          </Tr>
          <Tr>
            <Td>
              <Text variant="caption">CLOCK OUT</Text>
            </Td>
            <W2WorkerClockOutDisputeRow
              finalTime={finalClockOutTime}
              setFinalTime={setFinalClockOutTime}
              timecard={workerTimecard}
            />
          </Tr>
          <Tr>
            <Td>
              <Text variant="caption">BREAK</Text>
            </Td>
            <W2WorkerBreakDisputeRow
              timecard={workerTimecard}
              finalBreakDuration={finalBreakDuration}
              setFinalBreakDuration={setFinalBreakDuration}
            />
          </Tr>
          <Tr>
            <Td>
              <Text variant="caption">TOTAL WORKED HOURS</Text>
            </Td>
            <W2WorkerTotalDisputeRow
              finalRecord={finalTotalWorked}
              businessRecord={bizTotalWorked}
              workerRecord={workerTotalWorked}
              hasChanged={bizTotalWorked !== workerTotalWorked}
            />
          </Tr>
        </Table>
      </Row>
      {!(isPendingWorker || isFinalized) && (
        <>
          <Text variant="h5" mb={theme.space.sm}>
            Select reason for resolution
          </Text>
          <SearchSelect
            onlyShowLabel
            options={REASON_OPTIONS}
            selectItem={REASON_OPTIONS.find(
              (reason) => reason.value === selectedReason,
            )}
            handleSelect={(reason) => {
              setSelectedReason(
                reason?.value as WorkerTimecardOpsDecisionReason,
              )
            }}
            label="Reason"
          />
          {selectedReason === WorkerTimecardOpsDecisionReason.OTHER && (
            <Input
              label="Other reason (Required)"
              value={otherReason}
              onChange={(e) => {
                setOtherReason(e.target.value)
              }}
              containerStyle={{ width: 250, marginRight: theme.space.xs }}
              placeholder="Enter a reason"
            />
          )}
        </>
      )}

      {isFinalized && (
        <InlineBanner
          severity="warning"
          text="This timecard has been finalized. You won't be able to make any changes to it."
          style={{ marginBottom: theme.space.xxs }}
        />
      )}
      {isPendingWorker && (
        <InlineBanner
          severity="warning"
          text="This timecard is pending worker action. You won't be able to make any changes to it before that."
          style={{ marginBottom: theme.space.xxs }}
        />
      )}
      <Row
        fullWidth
        style={{
          marginTop: theme.space.med,
          justifyContent: 'space-between',
        }}
      >
        <Button variant={ButtonVariant.OUTLINED} onClick={onClose}>
          Go back
        </Button>
        <Button
          loading={isSubmitting}
          onClick={async () => {
            try {
              setIsSubmitting(true)
              if (!selectedReason) {
                showError('Please select a reason')
                return
              }
              await finalizeW2WorkerTimecard({
                timecardId: workerTimecard.id,
                totalBreakDurationSeconds: finalBreakDuration ?? 0,
                clockInTime: finalClockInTime,
                clockOutTime: finalClockOutTime,
                reason: selectedReason,
                otherReason: otherReason,
              })
              showSuccess('Timecard finalized successfully')
              onFinalize()
            } catch (error) {
              console.error(error)
              showError('Failed to finalize timecard, please try again')
            } finally {
              setIsSubmitting(false)
            }
          }}
          disabled={!canContinue}
        >
          Submit Final Decision
        </Button>
      </Row>
    </Modal>
  )
}
