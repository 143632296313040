import { BrandColors, otherColors } from '@traba/theme'
import styled, { css } from 'styled-components'

type ColorVariant = {
  color: string
  backgroundColor: string
  borderColor?: string
}

export enum BadgeVariant {
  INFO = 'info',
  BRAND = 'brand',
  DISABLED = 'disabled',
  SUCCESS = 'success',
  WARNING = 'warning',
  ADDITIONAL = 'additional',
  BUSINESS = 'business',
  DANGER = 'danger',
  OPAQUE_RED = 'opaqueRed',
  CERTIFICATION = 'certification',
  BLUE_CERTIFICATION = 'blueCertification',
  YELLOW = 'yellow',
  DARK_ORANGE = 'darkOrange',
  DARK_RED = 'darkRed',
  PINK = 'pink',
  CLEAR = 'clear',
  GREY = 'grey',
  TRANSPARENT = 'transparent',
  ORANGE = 'orange',
  BLUE = 'blue',
  OUTLINE_VIOLET = 'outlineViolet',
  OUTLINE_GREEN = 'outlineGreen',
  OUTLINE_RED = 'outlineRed',
  OUTLINE_YELLOW = 'outlineYellow',
}

export const COLOR_VARIANTS: Record<string, ColorVariant> = {
  [BadgeVariant.INFO]: {
    color: otherColors.Blue80,
    backgroundColor: otherColors.Blue10,
  },
  [BadgeVariant.BRAND]: {
    color: BrandColors.Violet,
    backgroundColor: otherColors.Violet10,
  },
  [BadgeVariant.DISABLED]: {
    color: otherColors.Grey60,
    backgroundColor: otherColors.Grey10,
  },
  [BadgeVariant.SUCCESS]: {
    color: otherColors.Green80,
    backgroundColor: otherColors.Green10,
  },
  [BadgeVariant.WARNING]: {
    color: otherColors.Yellow70,
    backgroundColor: otherColors.Yellow10,
  },
  [BadgeVariant.ADDITIONAL]: {
    color: otherColors.Violet70,
    backgroundColor: otherColors.Violet10,
  },
  [BadgeVariant.BUSINESS]: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  [BadgeVariant.DANGER]: {
    color: otherColors.Red60,
    backgroundColor: otherColors.Red10,
  },
  [BadgeVariant.OPAQUE_RED]: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Red40,
  },
  [BadgeVariant.CERTIFICATION]: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  [BadgeVariant.BLUE_CERTIFICATION]: {
    color: otherColors.Blue80,
    backgroundColor: otherColors.Blue10,
  },
  [BadgeVariant.YELLOW]: {
    color: otherColors.Yellow80,
    backgroundColor: otherColors.Yellow10,
  },
  [BadgeVariant.DARK_ORANGE]: {
    color: otherColors.Peach70,
    backgroundColor: otherColors.Peach10,
  },
  [BadgeVariant.DARK_RED]: {
    color: otherColors.Red70,
    backgroundColor: otherColors.Red10,
  },
  [BadgeVariant.PINK]: {
    color: otherColors.Red80,
    backgroundColor: '#FFC0CB',
  },
  [BadgeVariant.CLEAR]: {
    color: otherColors.Grey50,
    backgroundColor: 'transparent',
    borderColor: otherColors.Grey30,
  },
  [BadgeVariant.GREY]: {
    color: otherColors.Grey50,
    backgroundColor: otherColors.Grey10,
  },
  [BadgeVariant.TRANSPARENT]: {
    color: otherColors.Grey50,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  [BadgeVariant.ORANGE]: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Orange60,
  },
  [BadgeVariant.BLUE]: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Blue50,
  },
  [BadgeVariant.OUTLINE_VIOLET]: {
    color: BrandColors.Violet,
    backgroundColor: 'transparent',
    borderColor: BrandColors.Violet,
  },
  [BadgeVariant.OUTLINE_GREEN]: {
    color: otherColors.Green80,
    backgroundColor: 'transparent',
    borderColor: otherColors.Green80,
  },
  [BadgeVariant.OUTLINE_RED]: {
    color: otherColors.Red80,
    backgroundColor: 'transparent',
    borderColor: otherColors.Red80,
  },
  [BadgeVariant.OUTLINE_YELLOW]: {
    color: otherColors.Yellow70,
    backgroundColor: 'transparent',
    borderColor: otherColors.Yellow70,
  },
}

// Legacy type for backward compatibility
// export type BadgeVariant = keyof typeof COLOR_VARIANTS

type BadgeContainerProps = {
  round?: boolean
  variant: BadgeVariant
  hoverBorderColor?: string
  hoverTextColor?: string
  sm?: boolean
}

export const BadgeContainer = styled.div<BadgeContainerProps>`
  border-radius: 4px;
  padding: 6px 12px;
  background-color: ${({ variant }) => COLOR_VARIANTS[variant].backgroundColor};
  color: ${({ variant }) => COLOR_VARIANTS[variant].color};
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ variant }) => COLOR_VARIANTS[variant].borderColor || 'transparent'};

  &:hover {
    border-color: ${(props) => props.hoverBorderColor || 'transparent'};
    color: ${(props) =>
      props.hoverTextColor || COLOR_VARIANTS[props.variant].color};
  }

  ${({ round }) =>
    round &&
    css`
      border-radius: 100%;
      padding: 0;
      width: 24px;
      height: 24px;
    `};

  ${({ sm }) =>
    sm &&
    css`
      margin-right: 4px;
      margin-bottom: 4px;
      font-size: 8px;
      padding: 4px 6px;
    `};
`
