import { BUSINESS_CANCELLATION_FAQ_LINK } from '@traba/consts'
import { Anchor, Card, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CancellationBusinessChargeType } from '@traba/types'
import PaidCancellationSummary from './PaidCancellationSummary'

export default function CancellationCosts(props: {
  numberWorkersToAppease: number
  numberOfWorkersToChargeBusinessFor: number
  baseWorkerPay: number
  baseBusinessCharge: number
  baseBusinessHoursCharged: number
  totalBusinessCharge?: number
  totalWorkerPay?: number
  cancellationBusinessTimeWindow?: number
  cancellationBusinessChargeType: CancellationBusinessChargeType
  cancellationChargeSummary?: string
  shouldWaiveCancellationFees: boolean
  isPaidCancellation: boolean
  employerName: string
}) {
  const {
    cancellationBusinessTimeWindow,
    shouldWaiveCancellationFees,
    isPaidCancellation,
    employerName,
  } = props

  return (
    <Card
      style={{
        backgroundColor: isPaidCancellation
          ? theme.colors.Red10
          : theme.colors.Green10,
        border: 'none',
        padding: '40px 56px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text
          variant="h6"
          style={{
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          {isPaidCancellation ? `Paid cancellation` : `Free cancellation`}
        </Text>
        <Anchor
          href={BUSINESS_CANCELLATION_FAQ_LINK}
          style={{
            color: isPaidCancellation ? '#F20D59' : '#1AB273',
            marginBottom: '24px',
          }}
        >
          View our policy
        </Anchor>
      </div>

      <Text
        variant="body2"
        style={{
          color: theme.colors.Grey60,
        }}
      >
        {isPaidCancellation && shouldWaiveCancellationFees
          ? `Late cancellation fees for ${employerName} are waived. A charge for the expected fee will still show on their invoice, but it will be automatically appeased with a negative line item. Any worker will still be paid for cancellation within 18 hours.`
          : isPaidCancellation
            ? `Shifts cancelled for ${employerName} within ${cancellationBusinessTimeWindow} hours of start will incur fees, similarly any shift canceled within 18 hours will result in a payment to scheduled workers.`
            : `This cancellation will be free under our current cancellation
          policy.`}
      </Text>

      {isPaidCancellation && <PaidCancellationSummary {...props} />}
    </Card>
  )
}
