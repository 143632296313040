import { useAlert } from '@traba/context'
import { useDeleteEligibilityConnectionMutation } from '@traba/hooks'
import { SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useEffect, useState } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Button, CopyTextIcon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { useModal } from 'src/components/base/Modal/Modal'
import { DataTable, TableRow } from 'src/components/base/Table/DataTable'
import { useWorkerEligibilityConnections } from 'src/hooks/useConnections'
import { truncateString } from 'src/utils/stringUtils'
import { WorkerEligibilityConnectionModal } from './WorkerEligibilityConnectionModal'

interface WorkerConnectionsProps {
  workerId: string
}

export default function WorkerEligibilityConnections({
  workerId,
}: WorkerConnectionsProps) {
  const { showError } = useAlert()
  const { connections, isLoading, isError } =
    useWorkerEligibilityConnections(workerId)
  const { deleteEligibilityConnections } =
    useDeleteEligibilityConnectionMutation()
  const createWorkerConnectionModal = useModal()
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('')
  const [showSelect, setShowSelect] = useState<boolean>(true)

  useEffect(() => {
    if (isError) {
      showError(
        'There was an error fetching the eligibility connections',
        'Error fetching eligibility connections',
      )
    }
  }, [isError, showError])

  if (isLoading) {
    return <CircularProgress size="medium" />
  }

  const tableRows: TableRow[] =
    connections?.map((company) => {
      const { companyId, employerName } = company

      return {
        key: `eligibility_connection_${companyId}`,
        cells: [
          {
            renderFn: () => (
              <Row justifyBetween>
                <Link to={`/companies/${companyId}`} target="_blank">
                  <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                    {employerName}
                  </Button>
                </Link>
              </Row>
            ),
            sortKey: employerName,
          },
          {
            renderFn: () => (
              <>
                {truncateString(companyId, 6)}
                <CopyTextIcon textToCopy={companyId} />
              </>
            ),
            sortKey: companyId,
          },
          {
            renderFn: () => (
              <Row>
                <SvgIcon
                  name={'edit'}
                  onClick={() => {
                    setSelectedCompanyId(companyId)
                    setShowSelect(false)
                    createWorkerConnectionModal.open()
                  }}
                  style={{ cursor: 'pointer' }}
                />
                <SvgIcon
                  name={'cancel'}
                  color={theme.colors.red}
                  onClick={() => {
                    deleteEligibilityConnections({ companyId, workerId })
                  }}
                />
              </Row>
            ),
          },
        ],
      }
    }) ?? []

  return (
    <>
      <Row pb={theme.space.xs} fullWidth justifyEnd>
        <Button
          style={{ padding: 0 }}
          leftIcon={<Plus />}
          reverse
          variant={ButtonVariant.TEXT}
          onClick={createWorkerConnectionModal.open}
        >
          Create Connection
        </Button>
      </Row>
      <DataTable
        initialSortByColumnIndex={0} // sort by employerName column
        rows={tableRows}
        headers={[
          {
            key: 'employerName',
            label: 'Company Name',
            sortable: true,
          },
          {
            key: 'companyId',
            label: 'Company ID',
            sortable: true,
          },
          {
            key: 'modifyEligibilityConnection',
            label: 'Modify Eligibility',
          },
        ]}
      />
      <WorkerEligibilityConnectionModal
        workerId={workerId}
        isOpen={createWorkerConnectionModal.isOpen}
        handleClose={createWorkerConnectionModal.handleClose}
        showSelect={showSelect}
        setShowSelect={setShowSelect}
        setSelectedCompanyId={setSelectedCompanyId}
        selectedCompanyId={selectedCompanyId}
      />
    </>
  )
}
