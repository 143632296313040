import { theme } from '@traba/theme'
import { InputStatus } from '@traba/types'
import React from 'react'
import styled, { css } from 'styled-components'

import { RequiredProps } from '../Required'
import { SvgIcon } from '../SvgIcon'
import { InputProps } from './Input'

export const InputContainer = styled.div`
  margin-top: ${({ theme }) => theme.space.sm}px;
  width: ${(props) => props.style?.width};
`

const inputStyling = css<InputProps>`
  /* Basic Styles */
  border: 1px solid #e2e6e9;
  border-radius: 8px;
  padding: 12px 16px;
  line-height: 22px;
  outline: none;
  font-weight: 300;
  transition: border 0.3s;
  font-size: 14px;

  /* Full Width */
  ${({ full }) => (full ? 'width: 100%;' : '')}

  /* Transitions */
  &.valid,
  :-webkit-autofill,
  :-webkit-autofill::first-line,
  :-webkit-autofill:active {
    border: 1px solid #e2e6e9;
    font-family: Poppins;
    font-weight: 300;
  }

  // Focused Input
  :focus,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: 1px solid #8000ff;
  }

  // Label for focused input
  :focus ~ label,
  :-webkit-autofill:hover ~ label,
  :-webkit-autofill:focus ~ label {
    top: -8px;
    font-size: 12px;
    color: #8000ff;
    font-weight: 500;
  }

  // Label for valid input
  &.valid ~ label,
  :-webkit-autofill ~ label,
  :-webkit-autofill:active ~ label {
    top: -8px;
    font-size: 12px;
    font-weight: 500;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  ::placeholder {
    color: #7a8a99;
  }

  color: ${(props) => {
    switch (props.inputStatus) {
      case InputStatus.error:
        return props.theme?.error.border
      default:
        return props.theme.colors.MidnightBlue
    }
  }};

  border-color: ${(props) => {
    switch (props.inputStatus) {
      case InputStatus.error:
        return props.theme?.error.border
    }
  }};

  ${({ placeholder }) =>
    placeholder
      ? css`
          ~ label {
            top: -8px;
            font-size: 12px;
            font-weight: 500;
          }
        `
      : ''}

  ${({ disabled }) =>
    disabled
      ? css`
          background: ${({ theme }) => theme.colors.Grey10};
          color: ${({ theme }) => theme.colors.Grey50};
          border-color: ${({ theme }) => theme.colors.Grey20};
        `
      : ''}
`

export const Label = styled.label<RequiredProps>`
  top: 14px;
  left: 12px;
  position: absolute;
  background-color: white;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
  font-size: 14px;
  font-weight: 300;

  color: ${(props) => {
    switch (props.inputStatus) {
      case InputStatus.error:
        return props.theme?.error.border
      default:
        return '#7A8A99'
    }
  }};
`
export const Input = styled.input<InputProps>`
  ${inputStyling}
`

export const MultiLineInput = styled.textarea<InputProps>`
  ${inputStyling}
`

export const InputErrorIcon = styled(SvgIcon).attrs({
  name: 'alert',
  color: theme.error.border,
  size: 18,
})``

export const InformationIcon = styled.div`
  color: '#E5E5E5';
  padding-right: 8px;
  top: 1px;
`

export const InputErrorMessage = styled.div`
  color: ${({ theme }) => theme?.error.border};
  font-size: 12px;

  margin-top: ${({ theme }) => theme.space.xxs}px;
  display: flex;
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 12px;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme?.space.xxxs}px;
`
export const RightIconWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: ${({ theme }) => theme?.space.xxs}px;
  cursor: pointer;
`
export const LeftIconWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: ${({ theme }) => theme?.space.xxs}px;
`

export const LanguagePrefixWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: ${({ theme }) => theme?.space.xxs}px;
`

type StyledInputErrorProps = {
  message?: string
  style?: React.CSSProperties
}

export const StyledInputError: React.FC<StyledInputErrorProps> = ({
  message,
  style,
}) => {
  if (!message) {
    return null
  }

  return (
    <InputErrorMessage style={style}>
      <InputErrorIcon />
      {message}
    </InputErrorMessage>
  )
}
