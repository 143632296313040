import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  EmploymentType,
  PaginatedResponse,
  StrikeReason,
  WorkerWithStrikes,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'

async function getWorkersWithStrikes({
  page,
  companyId,
  firstName,
  lastName,
  createdAfter,
  strikeReasons,
  shiftId,
  strikeCount,
  regionId,
  workerId,
  employmentType,
}: {
  page: number
  companyId?: string
  firstName?: string
  lastName?: string
  createdAfter?: Date
  strikeReasons?: StrikeReason[]
  shiftId?: string
  strikeCount?: number
  regionId?: string
  workerId?: string
  employmentType: EmploymentType
}) {
  try {
    const payload = {
      page,
      companyId,
      firstName,
      lastName,
      createdAfter,
      strikeReasons,
      shiftId,
      strikeCount,
      regionId,
      workerId,
      employmentType,
    }
    const response = await trabaApi.post(`/workers/strikes/search`, payload)
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export const useSearchWorkersWithStrikes = ({
  page,
  companyId,
  firstName,
  lastName,
  createdAfter,
  strikeReasons,
  shiftId,
  strikeCount,
  regionId,
  workerId,
  employmentType,
}: {
  page: number
  companyId?: string
  firstName?: string
  lastName?: string
  createdAfter?: Date
  strikeReasons: StrikeReason[]
  shiftId?: string
  strikeCount?: number
  regionId?: string
  workerId?: string
  employmentType: EmploymentType
}) => {
  const { showError } = useAlert()
  const { data, isFetching, isError, error } = useQuery<
    PaginatedResponse<WorkerWithStrikes>,
    AxiosError
  >({
    queryKey: [
      'workers-with-strikes',
      employmentType,
      page,
      companyId,
      workerId,
      firstName,
      lastName,
      createdAfter,
      ...strikeReasons,
      shiftId,
      strikeCount,
      regionId,
    ],
    queryFn: () =>
      // takes in base 0 page number but endpoint expects 1 based page number
      getWorkersWithStrikes({
        page: page + 1,
        companyId,
        firstName,
        lastName,
        createdAfter,
        strikeReasons,
        shiftId,
        strikeCount,
        regionId,
        workerId,
        employmentType,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const workersWithStrikes = data?.items || []

  if (isError) {
    showError(error.message, 'Failed to load workers with strikes')
  }

  return {
    isFetching,
    isError,
    workersWithStrikes,
    error,
  }
}
