import { Text } from '@traba/react-components'

export function GhostProfileLinkedApplicationItemResults({
  hasWorkerCreatedAccount,
}: {
  hasWorkerCreatedAccount: boolean
}) {
  return (
    <Text variant="body1">
      This worker has{hasWorkerCreatedAccount ? ' ' : ' NOT '} created an
      account.
    </Text>
  )
}
