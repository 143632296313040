import { Row, SearchSelect, Text, Toggle } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AccountStatus, IMenuItem, IncentiveStatus, Worker } from '@traba/types'
import { format } from 'date-fns'
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_PaginationState,
} from 'material-react-table'
import { useMemo, useState } from 'react'
import {
  IncentiveFromSearch,
  useSearchIncentives,
} from 'src/hooks/useIncentives'
import { SortOrder } from 'src/hooks/usePagination'
import { GrantIncentiveForm } from './components/GrantIncentiveForm'
import { IncentiveDetailRow } from './components/IncentiveDetailRow'
import { RegionCell } from './components/RegionCell'
import { incentiveCategoryOptions } from './utils'

const DEFAULT_PAGE_SIZE = 25

const INCENTIVE_CATEGORY_OPTIONS: IMenuItem[] = incentiveCategoryOptions.map(
  (option) => ({
    value: option.key,
    label: option.label,
  }),
)

const HEADER_CELL_STYLES = {
  backgroundColor: theme.colors.Grey10,
  color: theme.colors.Grey60,
  '& *': {
    fontWeight: 'bolder',
  },
}

const TABLE_COLUMNS: MRT_ColumnDef<IncentiveFromSearch>[] = [
  {
    accessorKey: 'title',
    header: 'Title',
  },
  {
    accessorKey: 'region',
    header: 'Region',
    Cell: ({ row }) => {
      return <RegionCell value={row.original.regionIds} />
    },
  },
  {
    accessorKey: 'startTime',
    header: 'Start Time',
    Cell: ({ row }) => {
      return <Text>{format(row.original.startTime, 'MM/dd/yyyy hh:mm a')}</Text>
    },
  },
  {
    accessorKey: 'endTime',
    header: 'End Time',
    Cell: ({ row }) => {
      return <Text>{format(row.original.endTime, 'MM/dd/yyyy hh:mm a')}</Text>
    },
  },
]

const SELECT_FIELDS = {
  incentive: [
    'id',
    'createdAt',
    'updatedAt',
    'title',
    'description',
    'internalMemo',
    'categoryId',
    'typeId',
    'valueType',
    'startTime',
    'endTime',
    'totalAmount',
    'totalCurrency',
    'regionIds',
  ],
}

export function GrantIncentivesTab({
  worker,
}: {
  worker?: Worker & AccountStatus
}) {
  const [activeOnly, setActiveOnly] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState<
    IMenuItem | undefined
  >()
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  })
  const [selectedIncentive, setSelectedIncentive] = useState<
    IncentiveFromSearch | undefined
  >()

  // TODO(ziyuanmw): need to update the search endpoint to support categoryIds
  const typeId = useMemo(() => {
    return incentiveCategoryOptions.find(
      (option) => option.key === selectedCategory?.value,
    )?.typeId
  }, [selectedCategory])

  const {
    incentives = [],
    totalFound,
    setCurrentPage,
  } = useSearchIncentives({
    params: {
      statuses: activeOnly
        ? [IncentiveStatus.Active]
        : [
            IncentiveStatus.Active,
            IncentiveStatus.Inactive,
            IncentiveStatus.Expired,
          ],
      typeIds: typeId ? [typeId] : undefined,
    },
    paginationParams: {
      sortBy: 'endTime',
      sortOrder: SortOrder.desc,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
    },
    select: SELECT_FIELDS,
  })

  const handlePaginationChange = (
    updaterOrValue:
      | MRT_PaginationState
      | ((old: MRT_PaginationState) => MRT_PaginationState),
  ) => {
    const newPagination =
      typeof updaterOrValue === 'function'
        ? updaterOrValue(pagination)
        : updaterOrValue
    setPagination(newPagination)
    setCurrentPage(newPagination.pageIndex)
  }

  return (
    <Row fullWidth gap={theme.space.sm}>
      <Row flexCol style={{ width: '50%' }}>
        <Row
          alignCenter
          mt={theme.space.xms}
          mb={theme.space.sm}
          fullWidth
          gap={theme.space.xs}
        >
          <SearchSelect
            options={INCENTIVE_CATEGORY_OPTIONS}
            selectItem={selectedCategory}
            handleSelect={setSelectedCategory}
            label={'Incentive Type'}
            placeholder="All Types"
            onlyShowLabel
            showClearButton
            width={200}
            style={{ flexGrow: 1, maxWidth: 500 }}
            selectStyle={{ width: '100%' }}
          />
          <Toggle
            label="Only Active"
            buttonState={activeOnly}
            runOnChange={() => setActiveOnly(!activeOnly)}
          />
        </Row>

        <MaterialReactTable
          columns={TABLE_COLUMNS}
          data={incentives}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination
          enableSorting={false}
          enableBottomToolbar
          enableTopToolbar={false}
          enableExpanding
          enableExpandAll={false}
          positionExpandColumn="last"
          state={{ pagination }}
          onPaginationChange={handlePaginationChange}
          manualPagination
          rowCount={totalFound}
          displayColumnDefOptions={{
            'mrt-row-expand': {
              muiTableHeadCellProps: {
                sx: {
                  ...HEADER_CELL_STYLES,
                  '& .Mui-TableHeadCell-Content': {
                    display: 'none',
                  },
                },
              },
              size: 20,
            },
          }}
          renderDetailPanel={({ row }) => (
            <IncentiveDetailRow incentive={row} />
          )}
          muiTableProps={{
            sx: {
              tableLayout: 'fixed',
            },
          }}
          muiTableHeadCellProps={{
            sx: HEADER_CELL_STYLES,
          }}
          muiTableBodyCellProps={{
            sx: {
              fontWeight: '300',
            },
          }}
          muiTableContainerProps={{
            sx: {
              height: '70vh',
            },
          }}
          getRowId={(row) => row.incentiveId}
          muiTableBodyRowProps={({ row }) => {
            const isSelected = row.original.id === selectedIncentive?.id
            return {
              sx: {
                ...(isSelected && {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                }),
              },
              onClick: () => {
                setSelectedIncentive(row.original)
              },
            }
          }}
        />
      </Row>
      {selectedIncentive && (
        <Row mb={theme.space.sm} style={{ width: '50%' }}>
          <GrantIncentiveForm incentive={selectedIncentive} worker={worker} />
        </Row>
      )}
    </Row>
  )
}
