import { AccountStatus } from './account-status'
import { ChargeWithApproval } from './charges'
import { BreakType } from './companies'
import { Incentive } from './incentive'
import { Address } from './locations'
import { Money } from './money'
import { Transaction } from './payments'
import { LocationPoint, RulesFlag } from './rules'
import {
  CancellationSource,
  CancellationType,
  ShiftInfo,
  ShiftPayType,
} from './shifts'
import { WorkerIncentive } from './worker-incentive'
import { ShiftTime, WorkerShiftAdjustment } from './worker-shift-adjustments'
import { Strike } from './worker-strikes'
import { Worker } from './workers'

export enum JobStatus {
  ToDo = 'TO_DO',
  InProgress = 'IN_PROGRESS',
  OnBreak = 'ON_BREAK',
  Complete = 'COMPLETE',
  Canceled = 'CANCELED',
  NoShow = 'NO_SHOW',
  Abandoned = 'ABANDONED',
  Rejected = 'REJECTED',
  Appeased = 'APPEASED',
}

export enum WorkerEditStatus {
  NOT_EDITED = 'NOT_EDITED', // Worker has not confirmed the times or ops or biz clock out workers
  NOT_FLAGGED = 'NOT_FLAGGED', // Passed superwarden worker edit rule
  FLAGGED = 'FLAGGED', // Flagged by superwarden worker edit rule
  APPROVED = 'APPROVED', // Edited time is approved by ops
  REJECTED = 'REJECTED', // Edited time is rejected by ops
}

// TODO: Split into base WorkerShift and WorkerShiftForBiz + WorkerShiftForOps that extend base
export type WorkerShift = {
  id: string
  shiftInfo: ShiftInfo
  rating?: number
  workedShiftId?: string | undefined
  workerId: string
  workerEditStatus?: WorkerEditStatus
  shiftId: string
  jobStatus: JobStatus
  isComplete?: boolean
  clockInTime?: Date | null
  clockOutTime?: Date | null
  clockOutTimeBeforeWorkerEdit?: Date | null
  breaks?: ShiftTime[] | null
  breaksBeforeWorkerEdit?: ShiftTime[] | null
  grossPay?: Money
  totalTrustAndSafetyFee?: Money
  netPay?: Money
  netPayWithIncentives?: Money
  timeWorked?: number
  codeConfirmed?: boolean
  clockOutCodeConfirmed?: boolean
  startedLate?: boolean
  endedLate?: boolean
  transactions?: Transaction[]
  instantPayFee?: Money
  totalPaidTime?: number
  cancellationSource?: CancellationSource
  cancellationReason?: string
  cancellationType?: CancellationType
  cancellationPercentCharge?: number
  canceledAt?: Date
  canceledLate?: boolean
  needsReview?: boolean
  minimumPaidTimeSucceeded?: boolean
  waitlistId?: string
  createdAt?: Date
  isShiftConfirmed?: boolean
  isBackfill?: boolean
  workerETA?: Date
  reliability?: number
  confirmedAt?: Date
  workerSiteArrivalTime?: Date
  onTimeStatus?: WorkerOnTimeStatus
  isFraudulent?: boolean
  earningsSummary?: EarningsSummary
  rulesFlag?: RulesFlag
  tripId?: string
  netPayAmount?: number //PG only field
  // TODO: fix this type
  pendingAdjustment?: WorkerShift
  unitsWorked?: number
  isFirstTimeWorkerShiftWithCompany?: boolean
  rejectionReason?: string
  latestAdjustmentAt?: Date
  address?: Address
  locationName?: string
}

export type WorkerShiftWithWorkerDetails = WorkerShift & {
  worker: Worker
  accountStatus?: AccountStatus
  strikes?: Strike[]
}

export type WorkerShiftWithCharges = WorkerShift & {
  charges: ChargeWithApproval[]
  isApproved: boolean // Consider a workerShift approved if any charge associated is approved
  isInvoiced: boolean // Consider a workerShift invoiced if it is attached to at least one invoice line item
  hasPendingEdits?: boolean
}

export type WorkerWithWorkerShiftAndCharges = Worker & {
  workerShifts: WorkerShiftWithCharges[]
}

export type WorkerShiftWithWorkerAndCharges = WorkerShiftWithCharges & {
  worker: Worker
}

export type WorkerShiftForOps = WorkerShiftWithWorkerDetails & {
  clockOutSource: string
  clockOutContext: string
  forgiveIncidents?: boolean
  endShiftFlags?: Flags
  shiftRequestId: string
}

export type EarningsSummaryPrisma = {
  workerId: string
  shiftId: string
  workerShiftId: string
  createdAt: Date
  updatedAt: Date
  totalPaid: number
  totalOwed: number
  totalPaidForShift: number
  totalPaidForIncentives: number
  totalOwedForShift: number
  totalOwedForIncentives: number
  paymentStatus: PaymentStatus
  currency: string
  allPayoutsStarted: boolean | null
  paymentStatusReason: string | null
}

export type EarningsSummary = {
  totalPaid: number
  totalOwed: number
  totalPaidForShift: number
  totalPaidForIncentives: number
  totalOwedForShift: number
  totalOwedForIncentives: number
  paymentStatus: PaymentStatus
  currency: string
  allPayoutsStarted?: boolean
  paymentStatusReason?: string
}

export enum PaymentStatus {
  NeedsReview = 'NEEDS_REVIEW',
  Underpaid = 'UNDERPAID',
  Overpaid = 'OVERPAID',
  Paid = 'PAID',
  ManualComplete = 'MANUAL_COMPLETE', //Marked as complete even if pay does not match
  PayoutFailed = 'PAYOUT_FAILED',
  PayoutDisabled = 'PAYOUT_DISABLED', // Payout will not be attempted due to state of Stripe account
}

export type WorkerEarning = WorkerShift & {
  incentives: Incentive[]
  workerIncentives: WorkerIncentive[]
  adjustments?: WorkerShiftAdjustment[]
}

const JobStatusOrder = [
  JobStatus.ToDo,
  JobStatus.InProgress,
  JobStatus.OnBreak,
  JobStatus.Complete,
  JobStatus.Abandoned,
  JobStatus.Rejected,
  JobStatus.Appeased,
  JobStatus.NoShow,
  JobStatus.Canceled,
]

export const JOB_STATUSES_FOR_WORKERS_ON_THE_WAY = new Set([
  JobStatus.ToDo,
  JobStatus.InProgress,
  JobStatus.OnBreak,
  JobStatus.Complete,
])

export const ONGOING_JOB_STATUSES = [JobStatus.InProgress, JobStatus.OnBreak]

export enum WorkerOnTimeStatus {
  OnTime = 'ON_TIME',
  Late = 'LATE',
  InsufficientData = 'INSUFFICIENT_DATA',
}

export const sortByJobStatus = (a: JobStatus, b: JobStatus) => {
  return JobStatusOrder.indexOf(a) - JobStatusOrder.indexOf(b)
}

/**
 * Model PendingAdjustment
 */
export type PendingAdjustment = {
  id: string
  createdAt: string
  updatedAt: string
  adjustmentReason: string
  source: string
  clockInTime: string | null
  clockOutTime: string | null
  breaks: Array<ShiftTime> | null
  shiftId: string
  workerShiftId: string
  unitsWorked: number | null
}

export type PendingClockOutsResponse = {
  workerShift: Pick<
    WorkerShiftForOps,
    | 'id'
    | 'workerId'
    | 'shiftId'
    | 'clockInTime'
    | 'clockOutTime'
    | 'clockOutTimeBeforeWorkerEdit'
    | 'breaks'
    | 'breaksBeforeWorkerEdit'
    | 'timeWorked'
    | 'jobStatus'
    | 'shiftInfo'
    | 'netPayAmount'
    | 'unitsWorked'
    | 'isBackfill'
  > & {
    locationPoints: LocationPoint[]
  }
  worker: Pick<
    Worker,
    'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
  >
}

type BulkResponseRejectedObj = {
  message: string
  workerId: string
  status: number
}

export interface BulkEndShiftsResponse {
  rejected: BulkResponseRejectedObj[]
  fulfilled: WorkerShiftForOps[]
}

export type InstantPayFlags = {
  needsReview?: boolean
  overMaxDailyLimit?: boolean
  overSinglePayoutLimit?: boolean
  noBreaksTaken?: boolean
  breaksLengthTooShort?: boolean
  workerReportedEndTime?: boolean
}

export type Flags = {
  instantPayFlags?: InstantPayFlags
}

export type WorkerShiftAsTimesheetRow = {
  workerName: string
  shiftName: string
  clockInDate: string | undefined
  clockInTime: string | undefined
  clockOutDate: string | undefined
  clockOutTime: string | undefined
  jobStatus: JobStatus | string
  breakTime: number
  [key: `breakIn_${number}`]: string
  [key: `breakOut_${number}`]: string
  payRate: number
  numberOfUnits: number | null
  workerId: string
  shiftId: string
  companyId: string
  minimumPaidTime: number | null
  breakType: BreakType | string
  unitsWorked: number | null
  payType: ShiftPayType | string | null
  timezone: string
  startTime: Date
  endTime: Date
  slotsRequested: number | null
  breaks: ShiftTime[]
  accountStatus: Pick<AccountStatus, 'payment'>
}

export type RecalculatedWorkedTime = {
  shiftInfo: ShiftInfo
  totalPaidTime: number | undefined
  totalPaidUnits: number | undefined
  timeWorked: number
  unitsWorked: number | undefined
  grossPay: Money
  netPay: Money
  totalTrustAndSafetyFee: Money
  calculatedMarkup: number
}
