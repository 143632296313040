import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  AcceptShiftWaiver,
  CancellationSource,
  GenericAcceptShiftBypasses,
} from '@traba/types'
import { Worker } from '@traba/types'
import { useState } from 'react'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { ConfirmationStatuses, WorkerOnShift } from '../types'
import { useEligibilitiesForWorkers } from './useEligibilitiesForWorkers'

type useAddWorkersModalActionsProps = {
  selectedShifts: Record<string, string[]>
  checkedWorkers: Worker[]
  workerShiftsToCancel: WorkerOnShift[]
  setIsLoading: (isLoading: boolean) => void
  bypasses?: GenericAcceptShiftBypasses
  waivers?: AcceptShiftWaiver[]
  analyticsSource?: string
}

export const useAddWorkersModalActions = ({
  selectedShifts,
  checkedWorkers,
  workerShiftsToCancel,
  setIsLoading,
  bypasses,
  waivers,
  analyticsSource = 'add-workers-modal-actions',
}: useAddWorkersModalActionsProps) => {
  const { trackAnalytics } = useAnalytics()

  const [workerShiftsWithStatus, setWorkerShiftsWithStatus] = useState<
    ConfirmationStatuses[]
  >([])
  const [toCancelWithStatus, setToCancelWithStatus] = useState<
    ConfirmationStatuses[]
  >([])
  const { showError } = useAlert()

  const { workersAlreadyOnShifts } = useEligibilitiesForWorkers(
    selectedShifts,
    checkedWorkers.map((w) => w.id || w.workerId),
  )

  const getStatus = (worker: Worker, shiftId: string, status: string) => ({
    workerId: worker.id || worker.workerId,
    name: `${worker.firstName} ${worker.lastName}`,
    shiftId,
    status: status as ConfirmationStatuses['status'],
  })

  /**
   * Prepare the list of workers to accept and cancel to use in the last step
   */
  const prepareShiftsForConfirmation = () => {
    const newWorkersStatuses: ConfirmationStatuses[] = []
    const newWorkerShiftsToCancel: ConfirmationStatuses[] = []

    /** Build the list to accept */
    Object.values(selectedShifts)
      .flat()
      .forEach((shiftId) =>
        checkedWorkers.forEach((worker) => {
          newWorkersStatuses.push(getStatus(worker, shiftId, 'pending'))
        }),
      )
    setWorkerShiftsWithStatus(newWorkersStatuses)

    /** Build the list to cancel */
    workerShiftsToCancel.forEach((ws) => {
      const worker = checkedWorkers.find((w) => w.id === ws.workerId)
      if (worker) {
        newWorkerShiftsToCancel.push(getStatus(worker, ws.shiftId, 'pending'))
      }
    })
    setToCancelWithStatus(newWorkerShiftsToCancel)
  }

  const getLoadingStatus = (list: ConfirmationStatuses[]) =>
    list.map((ws) => ({
      ...ws,
      status: 'loading' as ConfirmationStatuses['status'],
    }))

  /**
   * Adding workers to shifts
   */
  const handleAcceptShift = async (workerIds: string[], shiftId: string) => {
    /** Filter out workerIds that are already on the shift */
    const workerIdsToAdd = workerIds.filter(
      (workerId) =>
        !workersAlreadyOnShifts.find(
          (ws) => ws.workerId === workerId && ws.shiftId === shiftId,
        ),
    )

    if (!workerIdsToAdd.length) {
      return console.log('Skipping, no workers to add')
    }

    setIsLoading(true)
    const loadingState = getLoadingStatus(workerShiftsWithStatus)
    setWorkerShiftsWithStatus(loadingState)

    try {
      const res = await trabaApi.post(
        `/worker-shifts/${shiftId}/accept-shift`,
        {
          workerIds: workerIdsToAdd,
          bypasses,
          waivers,
        },
      )

      const errorWorkers = res.data.filter((d: any) => !!d.error)
      setWorkerShiftsWithStatus((prev: ConfirmationStatuses[]) =>
        prev.map((ws) => {
          const errorFound = errorWorkers.find(
            (errorWorker: ConfirmationStatuses) =>
              errorWorker.workerId === ws.workerId && shiftId === ws.shiftId,
          )
          if (errorFound) {
            console.log(errorFound, 'ERROR adding workers to shift', ws.shiftId)
            return {
              ...ws,
              status: 'error',
              message: errorFound.message,
            }
          }
          if (workerIds.includes(ws.workerId) && shiftId === ws.shiftId) {
            return {
              ...ws,
              status: 'success',
            }
          }
          return ws
        }),
      )
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
      console.log(e, 'ERROR adding workers to shifts')
      showError('Error adding some workers to shifts', e.message)
    }
  }

  async function handleAddWorkers() {
    const shiftIds = Object.values(selectedShifts).flat()
    const workerIds = checkedWorkers.map(
      (worker) => worker.id || worker.workerId,
    )

    trackAnalytics('Add workers to shifts', {
      selectedShiftIds: shiftIds,
      workerIds: workerIds,
      source: analyticsSource,
    })

    await Promise.all(
      shiftIds.map((shiftId) => handleAcceptShift(workerIds, shiftId)),
    )
  }

  /**
   * Cancelling workers shifts
   */
  const handleCancelShift = async (
    workerId: string,
    shiftId: string,
    paidBackup: boolean,
  ) => {
    setIsLoading(true)
    const loadingState = getLoadingStatus(toCancelWithStatus)
    setToCancelWithStatus(loadingState)

    try {
      if (!paidBackup) {
        await trabaApi.patch(
          `/workers/${workerId}/worker-shifts/${shiftId}/cancel-shift`,
          {
            cancellationSource: CancellationSource.Ops,
            cancellationReason: 'worker had a conflicting shift',
            bypassCancellationSourceCheck: true,
          },
        )
      } else {
        await trabaApi.patch(`/paid-backup/cancel`, {
          workerId,
          shiftId,
          cancellationReason: 'worker converted from paid backup',
        })
      }

      const workerShiftIndex = toCancelWithStatus.findIndex(
        (ws) => ws.workerId === workerId && ws.shiftId === shiftId,
      )
      setToCancelWithStatus((prev) => {
        const updatedStatuses = [...prev]
        updatedStatuses[workerShiftIndex].status = 'success'
        return updatedStatuses
      })

      setIsLoading(false)
    } catch (e: any) {
      const workerShiftIndex = toCancelWithStatus.findIndex(
        (ws) => ws.workerId === workerId && ws.shiftId === shiftId,
      )
      setToCancelWithStatus((prev) => {
        const updatedStatuses = [...prev]
        updatedStatuses[workerShiftIndex].status = 'error'
        updatedStatuses[workerShiftIndex].message = e.message
        return updatedStatuses
      })

      setIsLoading(false)
      console.log(e, 'ERROR cancelling shifts')
      showError('Error cancelling some worker  shifts', e.message)
    }
  }

  async function handleCancellations() {
    await Promise.all(
      workerShiftsToCancel.map(async (ws) => {
        const { workerId, shiftId, paidBackup } = ws
        await handleCancelShift(workerId, shiftId, paidBackup)
      }),
    )
  }

  return {
    prepareShiftsForConfirmation,
    handleAddWorkers,
    handleCancellations,
    workerShiftsWithStatus,
    toCancelWithStatus,
    setToCancelWithStatus,
    setWorkerShiftsWithStatus,
  }
}
