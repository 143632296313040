import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { Strike } from '@traba/types'
import { captureSentryError } from '@traba/utils'

async function getWorkerStrikes(
  workerId: string | undefined,
  includeInvalidated: boolean,
): Promise<{ strikes: Strike[] } | undefined> {
  if (!workerId) {
    return undefined
  }
  try {
    const response = await trabaApi.get(
      `/workers/strikes/${workerId}?includeInvalidated=${includeInvalidated}`,
    )
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error('useWorkerStrikes -> getWorkerStrikes() ERROR', error)
  }
}

export function useWorkerStrikes(
  workerId?: string,
  includeInvalidated = false,
) {
  const {
    isLoading,
    isError,
    data: strikes,
    error,
    isFetched,
    refetch,
  } = useQuery<{ strikes: Strike[] } | undefined, Error>({
    queryKey: ['worker-strikes', workerId],
    queryFn: () => getWorkerStrikes(workerId, includeInvalidated),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!workerId,
  })

  return {
    isLoading,
    isError,
    strikes: strikes?.strikes,
    error,
    isFetched,
    refetch,
  }
}

async function postInvalidateStrike({
  strikeId,
  invalidationReason,
}: {
  strikeId: string
  invalidationReason?: string
}) {
  try {
    const payload = {
      strikeId,
      invalidationReason,
    }
    await trabaApi.patch(`/workers/strikes/invalidate`, payload)
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

async function patchConfirmStrike({
  strikeId,
  proven,
}: {
  strikeId: string
  proven?: boolean
}) {
  try {
    const payload = {
      strikeId,
      proven,
    }
    await trabaApi.patch(`/workers/strikes/confirm`, payload)
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

async function postMissesCallStrike({ strikeId }: { strikeId: string }) {
  try {
    const payload = {
      strikeId,
    }
    await trabaApi.post(`/workers/strikes/misses-call`, payload)
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export const useStrikeMutation = () => {
  const { showError, showSuccess } = useAlert()
  const queryClient = useQueryClient()

  const { mutateAsync: invalidateStrikes, isPending: isInvalidating } =
    useMutation({
      mutationFn: async ({
        strikeIds,
        invalidationReason,
      }: {
        strikeIds: string[]
        invalidationReason?: string
      }) =>
        await Promise.all(
          strikeIds.map((strikeId) =>
            postInvalidateStrike({ strikeId, invalidationReason }),
          ),
        ),
      onError: (error) => {
        showError(error.message, 'Failed to forgive strikes')
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['worker-strikes'],
        })
        queryClient.invalidateQueries({
          queryKey: ['workers-with-strikes'],
        })
        showSuccess('Strikes have been forgiven')
      },
    })

  const {
    mutateAsync: scheduleMissesCalls,
    isPending: isSchedulingMissesCalls,
  } = useMutation({
    mutationFn: async ({ strikeIds }: { strikeIds: string[] }) =>
      await Promise.all(
        strikeIds.map((strikeId) => postMissesCallStrike({ strikeId })),
      ),
    onError: (error) => {
      showError(error.message, 'Failed to schedule operator calls')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['worker-strikes'],
      })
      queryClient.invalidateQueries({
        queryKey: ['workers-with-strikes'],
      })
      showSuccess('Scheduled operator calls')
    },
  })

  const { mutateAsync: confirmStrike } = useMutation({
    mutationFn: async ({
      strikeId,
      proven,
    }: {
      strikeId: string
      proven?: boolean
    }) => await patchConfirmStrike({ strikeId, proven }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['worker-strikes'],
      })
      queryClient.invalidateQueries({
        queryKey: ['workers-with-strikes'],
      })
    },
    onError: (error) => {
      showError(error.message, 'Failed to forgive strike')
    },
  })

  return {
    invalidateStrikes,
    confirmStrike,
    isInvalidating,
    scheduleMissesCalls,
    isSchedulingMissesCalls,
  }
}
