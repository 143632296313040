import { Text, Row, Input, Col, Checkbox } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  IMenuItem,
  InvoiceStatus,
  ReportColumn,
  ReportColumnKey,
  ReportConfiguration,
  ReportGroupBy,
} from '@traba/types'
import { useFormik } from 'formik'
import { startCase, toLower } from 'lodash'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { Icon, Button } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import RadioGroup from 'src/components/base/RadioGroup'
import InvoiceStatusBadge from 'src/screens/BillingScreen/components/InvoiceStatusBadge'
import { DetailRow } from './DetailRow'
import { ReportColumnList } from './ReportColumnList'

export interface TableConfigurationDetailsProps {
  configuration: ReportConfiguration
  tableIndex?: number
  isEditing: boolean
  editDisabled?: boolean
  onEdit?: () => void
  onSave: (
    updatedConfiguration: ReportConfiguration,
    options?: {
      saveReportConfig: boolean
      dirty: boolean
    },
  ) => void
  onDelete?: () => void
  singleTable?: boolean
  isDraftInvoice?: boolean
  handleClose?: () => void
  showSuccess?: () => void
}

const containerStyle: React.CSSProperties = {
  borderRadius: theme.space.xxs,
  border: `2px solid ${theme.colors.Grey20}`,
  padding: theme.space.sm,
}

const removeButtonStyle: React.CSSProperties = {
  width: '200px',
  marginRight: theme.space.sm,
  padding: 0,
}

const removeTextStyle: React.CSSProperties = {
  marginLeft: theme.space.xxs,
  color: theme.colors.red,
}

const editButtonStyle: React.CSSProperties = {
  width: '200px',
}

const iconStyle: React.CSSProperties = {
  marginRight: theme.space.xxs,
}

export function TableConfigurationDetails(
  props: TableConfigurationDetailsProps,
) {
  const {
    configuration,
    tableIndex,
    editDisabled,
    isEditing,
    onEdit,
    onSave,
    onDelete,
    handleClose,
    singleTable,
    isDraftInvoice,
  } = props
  const { displayName, columns, groupBy, showTotals } = configuration
  const formattedGroupBy = useMemo(() => startCase(toLower(groupBy)), [groupBy])

  const [showColumnErrors, setShowColumnErrors] = useState(false)
  const [saveReportConfig, setSaveReportConfig] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayName,
      groupBy,
      columns: columns ?? [],
      showTotals,
    },
    onSubmit: (values) => {
      if (values.columns.some(({ columnKey }) => columnKey === undefined)) {
        setShowColumnErrors(true)
        return
      }
      onSave(values, { saveReportConfig, dirty: formik.dirty })
    },
  })

  const EditDetailsButton = () => (
    <Button
      variant={ButtonVariant.OUTLINED}
      onClick={() => {
        onEdit?.()
      }}
      slim
      style={editButtonStyle}
      disabled={editDisabled}
    >
      <Icon name="edit" style={iconStyle} />
      Edit Details
    </Button>
  )

  const SaveChangesButton = (props: { label: string }) => (
    <Button
      variant={ButtonVariant.FILLED}
      slim
      style={editButtonStyle}
      type="submit"
    >
      {props.label}
    </Button>
  )

  const formattedColumns =
    useMemo(
      () =>
        columns
          ?.map(
            ({ displayName, columnKey }) =>
              displayName ?? startCase(toLower(columnKey)),
          )
          .join(', '),
      [columns],
    ) ?? ''

  const handleDisplayNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      const updatedColumns = [...formik.values.columns]
      updatedColumns[index] = {
        ...updatedColumns[index],
        displayName: e.target.value,
      }
      formik.setFieldValue('columns', updatedColumns)
    },
    [formik],
  )

  const handleColumnSelect = useCallback(
    (value: IMenuItem | undefined, index: number) => {
      const updatedColumns = [...formik.values.columns]
      updatedColumns[index] = {
        ...updatedColumns[index],
        columnKey: value?.value as ReportColumnKey,
      }
      formik.setFieldValue('columns', updatedColumns)
    },
    [formik],
  )

  const handleAddColumn = useCallback(() => {
    formik.setFieldValue('columns', [...formik.values.columns, {}])
  }, [formik])

  const handleDeleteColumn = useCallback(
    (index: number) => {
      const updatedColumns = [...formik.values.columns]
      updatedColumns.splice(index, 1)
      formik.setFieldValue('columns', updatedColumns)
    },
    [formik],
  )

  const handleColumnReorder = useCallback(
    (reordered: ReportColumn[]) => {
      formik.setFieldValue('columns', reordered)
    },
    [formik],
  )

  const handleSaveReportConfig = () => {
    setSaveReportConfig(!saveReportConfig)
  }

  const reportConfiguration = (
    <>
      <Row
        alignCenter
        mb={theme.space.xxs}
        p={theme.space.xs}
        style={{
          backgroundColor: theme.colors.Grey10,
          borderRadius: theme.space.xxs,
        }}
      >
        <Checkbox
          checked={saveReportConfig}
          disabled={!formik.dirty}
          onChange={() => handleSaveReportConfig()}
          label="Save these changes as the new custom report default settings for this company."
        />
      </Row>
      {isDraftInvoice && (
        <Row
          alignCenter
          p={theme.space.xs}
          style={{
            backgroundColor: theme.colors.Orange10,
            borderRadius: theme.space.xxs,
          }}
          mt={-theme.space.xs}
        >
          <Icon
            name="info"
            type="svg"
            size={theme.space.sm}
            color={theme.colors.Yellow80}
          />
          <Text
            variant="body1"
            color={theme.colors.Yellow80}
            style={{ paddingLeft: theme.space.xxs }}
          >
            Please note this is a draft report and may be subject to invoice
            changes in the future.
          </Text>
        </Row>
      )}
      <Row alignCenter justifyBetween>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={handleClose}
          style={{ width: '120px' }}
          type="button"
        >
          Cancel
        </Button>
        <SaveChangesButton label={'Download Report'} />
      </Row>
    </>
  )

  const tableIdx = tableIndex ? `${tableIndex + 1} ` : ''

  return (
    <form
      style={singleTable ? { padding: theme.space.sm } : containerStyle}
      onSubmit={formik.handleSubmit}
    >
      {singleTable ? (
        <Row alignCenter justifyStart>
          <Text
            variant="h4"
            style={{
              paddingLeft: 0,
              paddingRight: theme.space.xs,
            }}
          >
            {'Generate Custom Report'}
          </Text>
          {isDraftInvoice && (
            <InvoiceStatusBadge status={InvoiceStatus.DRAFT} />
          )}
        </Row>
      ) : (
        <>
          <Row justifyBetween alignCenter mb={theme.space.xs}>
            {isEditing ? (
              <Input
                label={'Table Name'}
                name={'displayName'}
                value={formik.values.displayName}
                onChange={formik.handleChange}
                width={'50%'}
                containerStyle={{
                  marginTop: 0,
                }}
              />
            ) : (
              <Text variant="h5">
                {displayName ?? `Table ${tableIdx}Details`}
              </Text>
            )}
            <Row>
              <Button
                variant={ButtonVariant.REDOUTLINED}
                onClick={() => onDelete && onDelete()}
                slim
                style={removeButtonStyle}
                type={'button'}
                iconPadding="0"
                leftIcon={
                  <Icon
                    name="trash"
                    type="svg"
                    size={theme.space.xs}
                    color={theme.colors.red}
                  />
                }
              >
                <Text variant="h6" style={removeTextStyle}>
                  Remove Table
                </Text>
              </Button>
              {isEditing ? (
                <SaveChangesButton label={'Save Changes'} />
              ) : (
                <EditDetailsButton />
              )}
            </Row>
          </Row>
          <Divider />
        </>
      )}
      <Row flexCol gap={theme.space.sm} mt={theme.space.xs}>
        <DetailRow
          label="Group By"
          value={formattedGroupBy}
          editing={isEditing}
          showLabelOnEdit
        >
          <RadioGroup
            options={[
              { label: 'Workers', value: ReportGroupBy.WORKER },
              { label: 'Worker Shift', value: ReportGroupBy.WORKER_SHIFT },
              {
                label: 'Workers and Worker Shift',
                value: ReportGroupBy.WORKER_AND_WORKER_SHIFT,
              },
              {
                label: 'Cost Center',
                value: ReportGroupBy.COST_CENTER,
              },
              {
                label: 'Cost Centers and Worker',
                value: ReportGroupBy.COST_CENTER_AND_WORKER,
              },
              {
                label: 'Location',
                value: ReportGroupBy.LOCATION,
              },
              {
                label: 'Location and Worker',
                value: ReportGroupBy.LOCATION_AND_WORKER,
              },
              {
                label: 'Role',
                value: ReportGroupBy.ROLE,
              },
              {
                label: 'Role and Worker',
                value: ReportGroupBy.ROLE_AND_WORKER,
              },
            ]}
            value={formik.values.groupBy}
            onChange={(e) => formik.setFieldValue('groupBy', e.target.value)}
            style={{
              color: theme.colors.Grey60,
              flexDirection: 'row',
              marginLeft: -theme.space.med,
            }}
            fontWeight={300}
            fontSize={14}
            fontColor={theme.colors.Blue100}
          />
        </DetailRow>
        {isEditing && <Divider />}
        <DetailRow
          label="Column Totals"
          value={
            showTotals === false
              ? 'Do not show column totals'
              : 'Below table(s)'
          }
          editing={isEditing}
          showLabelOnEdit
        >
          <RadioGroup
            options={[
              { label: 'Do not show column totals', value: 'false' },
              { label: 'Below table(s)', value: 'true' },
            ]}
            value={formik.values.showTotals?.toString()}
            onChange={(e) =>
              formik.setFieldValue('showTotals', e.target.value === 'true')
            }
            style={{
              color: theme.colors.Grey60,
              flexDirection: 'row',
              marginLeft: -theme.space.xxxl,
            }}
            fontWeight={300}
            fontSize={14}
            fontColor={theme.colors.Blue100}
          />
        </DetailRow>
        {isEditing && (
          <>
            <Divider />
            <Row>
              <Text variant="h6" style={{ width: '200px' }}>
                Report Columns
              </Text>
              <Row alignCenter justifyEnd style={{ flex: 1 }}>
                <Icon
                  name="info"
                  type="svg"
                  size={theme.space.sm}
                  color={theme.colors.Grey60}
                  style={{ marginRight: theme.space.xxs }}
                />
                <Text variant="body3" color={theme.colors.Grey60}>
                  The pre-selected columns are based on this company's default
                  settings.
                </Text>
              </Row>
            </Row>
          </>
        )}
        <DetailRow
          label="Report Columns"
          value={formattedColumns}
          editing={isEditing}
        >
          <Col width={'100%'} gap={theme.space.xs}>
            <ReportColumnList
              columns={formik.values.columns}
              showColumnErrors={showColumnErrors}
              handleColumnSelect={handleColumnSelect}
              handleDisplayNameChange={handleDisplayNameChange}
              handleAddColumn={handleAddColumn}
              handleDeleteColumn={handleDeleteColumn}
              handleColumnReorder={handleColumnReorder}
            />
          </Col>
        </DetailRow>
        {singleTable && isEditing && reportConfiguration}
      </Row>
    </form>
  )
}
