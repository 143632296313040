import { ShiftRequest } from '@traba/types'
import { isSameDay } from 'date-fns'
import { DateObject } from 'react-multi-date-picker'
import { getScheduleOccurrences } from './scheduleUtils'

export function scheduleOccurrenceMapDaysProvider(
  shiftRequest: ShiftRequest,
): ({ date }: { date: DateObject }) => { disabled: boolean } {
  const occurrences = getScheduleOccurrences(shiftRequest.schedules)
  return ({ date }: { date: DateObject }) => {
    const isScheduleOccurrence = occurrences.some((occurrence) =>
      isSameDay(date.toDate(), new Date(occurrence)),
    )
    return {
      disabled: !isScheduleOccurrence,
    }
  }
}
