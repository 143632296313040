import { Button, Modal, MODAL_SIZE, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  TerminationClaim,
  useWorkerW2Termination,
} from 'src/hooks/useW2WorkerTermination'

interface TerminationField {
  label: string
  getValue: (claim: TerminationClaim) => string | JSX.Element
  customStyle?: React.CSSProperties
}

const terminationFields: TerminationField[] = [
  {
    label: 'Reason',
    getValue: (claim) => claim.reason,
  },
  {
    label: 'Initiation',
    getValue: (claim) => claim.initiation,
  },
  {
    label: 'Date',
    getValue: (claim) => claim.date.toLocaleDateString(),
  },
  {
    label: 'Uploads',
    getValue: (claim) =>
      claim.uploads.length > 0
        ? claim.uploads.map((upload) => upload.title).join(', ')
        : 'None',
  },
  {
    label: 'Explanation',
    getValue: (claim) => claim.explanation,
  },
  {
    label: 'Email Recipient',
    getValue: (claim) => claim.emailRecipient,
  },
  {
    label: 'Email Body',
    getValue: (claim) => claim.emailBody,
    customStyle: { whiteSpace: 'pre-wrap' },
  },
]

export const TerminationSummaryModal = ({
  handleClose,
  isOpen,
  terminationClaim,
}: {
  handleClose: () => void
  isOpen: boolean
  terminationClaim: TerminationClaim | undefined
}) => {
  const { resendEmail, isResending } = useWorkerW2Termination(
    terminationClaim?.workerId,
  )
  const workerId = terminationClaim?.workerId
  return (
    <Modal
      handleClose={handleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.LARGE}
      title={'Termination Report'}
    >
      {terminationClaim && (
        <Row flexCol gap={theme.space.sm}>
          {terminationFields.map((field) => (
            <Row key={field.label} alignCenter gap={theme.space.sm}>
              <Text variant="h6">{field.label}</Text>
              <Text style={field.customStyle}>
                {field.getValue(terminationClaim)}
              </Text>
            </Row>
          ))}
          <Button
            disabled={terminationClaim?.workerId === undefined}
            onClick={() => workerId && resendEmail(workerId)}
            loading={isResending}
          >
            Resend Email
          </Button>
        </Row>
      )}
    </Modal>
  )
}
