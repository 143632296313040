import { Link, Row, Text, TextWithTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import { getStrikeReasonLabel, Strike, StrikeReason } from '@traba/types'
import { Worker } from '@traba/types'

interface WorkerStrikeWithTooltipProps {
  strikes?: Strike[]
  worker: Worker
}

export const WorkerStrikeWithTooltip = ({
  strikes,
  worker,
}: WorkerStrikeWithTooltipProps) => {
  if (!strikes || strikes.length === 0) {
    return <Text>0</Text>
  }

  const groupedStrikes = strikes.reduce(
    (acc, strike) => {
      if (acc[strike.reason]) {
        acc[strike.reason] += 1
      } else {
        acc[strike.reason] = 1
      }
      return acc
    },
    {} as Record<StrikeReason, number>,
  )

  const totalStrikes = strikes.reduce(
    (acc, strike) => acc + strike.strikePoints,
    0,
  )

  return (
    <TextWithTooltip
      tooltipContent={
        <>
          <Text variant="body2">
            Worker has following strikes in the past week:
          </Text>
          {Object.entries(groupedStrikes).map(([reason, count]) => {
            return (
              <Row key={`${reason}-${count}`} alignCenter justifyBetween>
                <Text variant="h7">
                  {getStrikeReasonLabel(reason as StrikeReason)}:
                </Text>
                <Text variant="h7" color={theme.colors.Red70}>
                  {`x ${count}`}
                </Text>
              </Row>
            )
          })}
        </>
      }
    >
      <Link to={`/action-center/w2/worker-strikes?workerId=${worker.uid}`}>
        <Text color={theme.colors.Red70}>{totalStrikes}</Text>
      </Link>
    </TextWithTooltip>
  )
}
