import { Badge, BadgeVariant } from '@traba/react-components'
import { WorkerApplicationItemStatus } from '@traba/types'
import { MouseEventHandler } from 'react'

export type WorkerApplicationItemStatusBadgeProps = {
  itemStatus: WorkerApplicationItemStatus
  style?: React.CSSProperties
  onClick?: MouseEventHandler<HTMLDivElement>
}

const APPLICATION_ITEM_STATUS_TO_BADGE_VARIANT: Record<
  WorkerApplicationItemStatus,
  BadgeVariant
> = {
  [WorkerApplicationItemStatus.COMPLETED]: BadgeVariant.SUCCESS,
  [WorkerApplicationItemStatus.IN_PROGRESS]: BadgeVariant.YELLOW,
  [WorkerApplicationItemStatus.NOT_STARTED]: BadgeVariant.DISABLED,
  [WorkerApplicationItemStatus.REVIEW_REQUIRED]: BadgeVariant.DANGER,
  [WorkerApplicationItemStatus.SYSTEM_PROCESSING]: BadgeVariant.INFO,
  [WorkerApplicationItemStatus.CANCELED]: BadgeVariant.INFO,
}

const CUSTOM_STATUS_TO_TEXT: Record<WorkerApplicationItemStatus, string> = {
  [WorkerApplicationItemStatus.NOT_STARTED]: 'To Do',
  [WorkerApplicationItemStatus.IN_PROGRESS]: 'In Progress',
  [WorkerApplicationItemStatus.SYSTEM_PROCESSING]: 'Processing',
  [WorkerApplicationItemStatus.REVIEW_REQUIRED]: 'Needs Action',
  [WorkerApplicationItemStatus.COMPLETED]: 'Completed',
  [WorkerApplicationItemStatus.CANCELED]: 'Canceled',
}

export default function WorkerApplicationItemStatusBadge({
  itemStatus,
  style,
  onClick,
}: WorkerApplicationItemStatusBadgeProps) {
  const badgeVariant = APPLICATION_ITEM_STATUS_TO_BADGE_VARIANT[itemStatus]

  return (
    <Badge
      style={style}
      title={CUSTOM_STATUS_TO_TEXT[itemStatus]}
      variant={badgeVariant}
      onClick={onClick}
    />
  )
}
