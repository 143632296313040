import { PaidBackupStatus } from './paid-backup'

export const SENTINEL_NOTIFICATION_TOASTS_LOCAL_STORAGE_KEY =
  'enableSentinelNotificationToasts'

export enum ShiftConfirmationSource {
  PushNotification = 'PushNotification',
  InApp = 'InApp',
  Ops = 'Ops',
}

export enum ShiftNotificationsStatus {
  ACTIVE = 'ACTIVE',
  SILENCED = 'SILENCED',
  URGENT_ONLY = 'URGENT_ONLY',
}

export enum NotificationStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  SEND_FAILED = 'SEND_FAILED',
  READ = 'READ',
  DISMISSED = 'DISMISSED',
  ACTIONED = 'ACTIONED',
}

export enum NotificationPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  URGENT = 'URGENT',
}

type WorkerShiftBaseInfo = {
  workerId: string
  firstName: string
  lastName: string
  phoneNumber: string
  isShiftConfirmed: boolean
  confirmationSource: ShiftConfirmationSource | null
  confirmedAt: Date | null
  jobStatus: string
  tripId: string | null
  clockInTime: Date | null
  clockOutTime: Date | null
  // metrics: string // TODO expand this type
}

type WorkerShiftBaseInfoWithETA = WorkerShiftBaseInfo & {
  workerETA: Date
}

type WorkerShiftBaseInfoWithWarden = WorkerShiftBaseInfo & {
  rulesSummary: any // TODO
}

type ShiftBaseInfo = {
  shiftId: string
  companyId: string
  startTime: Date
  endTime: Date
  slotsRequested: number
  slotsFilled: number
  overbookSlotsRequested: number | null
  minimumAcceptedTier: string | null
  reliabilityThreshold: number | null
  shiftDetailsDeeplink: string | null
  roleName: string // from Role
  employerName: string // from Company
  shortLocation: string // from Location
}

type PaidBackupBaseInfo = {
  id: string
  shiftId: string
  status: PaidBackupStatus
}

export type WorkerBaseInfo = {
  workerId: string
  firstName: string
  lastName: string
  phoneNumber: string
}

type PaidBackupAndWorkerInfo = {
  paidBackup: PaidBackupBaseInfo
  worker: WorkerBaseInfo
}

/**
 * Individual data combinations for action types
 */

/** High priority trigger based events */ // TODO: do these need context of the whole shift to be useful?
export type WorkerLateCancelActionsData = ShiftBaseInfo & {
  shiftInviteStats: string
  backfillAllowedOnShift: boolean
  workerShifts: WorkerShiftBaseInfo[]
}

export type WorkerAcceptedBackfillCallNoSignupActionsData = ShiftBaseInfo & {
  whenWorkerAccepted?: Date
  workerShifts: WorkerShiftBaseInfo[]
}

/** Cycle based events */
export type WorkersPaymentStoppedActionsData = ShiftBaseInfo & {
  wardenScore: string
  earningsSummary: string
  workerShifts: WorkerShiftBaseInfoWithWarden[]
}

export type WorkersNotConfirmedActionsData = ShiftBaseInfo & {
  lastRobocallSent: Date
  workerShifts: WorkerShiftBaseInfoWithETA[]
}

export type WorkerRunningLateActionsData = ShiftBaseInfo & {
  workerShifts: WorkerShiftBaseInfoWithETA[]
}

export type WorkerLeftJobSiteActionsData = ShiftBaseInfo & {
  workerShifts: WorkerShiftBaseInfo[]
}

export type WorkerNotLeftActionsData = ShiftBaseInfo & {
  workerShifts: WorkerShiftBaseInfoWithETA[]
}

export type WorkersNotClockedInActionsData = ShiftBaseInfo & {
  workerShifts: WorkerShiftBaseInfoWithETA[]
}

export type WorkersNotClockedOutActionsData = ShiftBaseInfo & {
  workerShifts: WorkerShiftBaseInfo[]
}

export type ShiftNotFilledActionsData = ShiftBaseInfo & {
  workerShifts: WorkerShiftBaseInfo[]
}

export type PaidBackupCancellationPendingData = {
  paidBackups: PaidBackupAndWorkerInfo[]
}

export type SentinelEventActionData =
  | WorkerLateCancelActionsData
  | WorkerAcceptedBackfillCallNoSignupActionsData
  | WorkersPaymentStoppedActionsData
  | WorkersNotConfirmedActionsData
  | WorkerRunningLateActionsData
  | WorkerLeftJobSiteActionsData
  | WorkerNotLeftActionsData
  | WorkersNotClockedInActionsData
  | WorkersNotClockedOutActionsData
  | ShiftNotFilledActionsData
  | PaidBackupCancellationPendingData

export enum SentinelTriggerBasedEventType {
  WORKER_LATE_CANCELED = 'WORKER_LATE_CANCELED',
  WORKER_LEFT_JOBSITE = 'WORKER_LEFT_JOBSITE',
  WORKER_ACCEPTED_BACKFILL_CALL = 'WORKER_ACCEPTED_BACKFILL_CALL',
  WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP = 'WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP',
  WORKER_ON_SHIFT_CALLED = 'WORKER_ON_SHIFT_CALLED',
  WORKER_ON_SHIFT_MESSAGED = 'WORKER_ON_SHIFT_MESSAGED',
  CONTACTED_WORKER_ON_WAITLIST_CALLED = 'CONTACTED_WORKER_ON_WAITLIST_CALLED',
  CONTACTED_WORKER_ON_WAITLIST_MESSAGED = 'CONTACTED_WORKER_ON_WAITLIST_MESSAGED',
  WORKER_CONFIRMED_OVER_SLOTS_REQUESTED = 'WORKER_CONFIRMED_OVER_SLOTS_REQUESTED',
  PAID_BACKUP_ON_SHIFT_CALLED = 'PAID_BACKUP_ON_SHIFT_CALLED',
  PAID_BACKUP_ON_SHIFT_MESSAGED = 'PAID_BACKUP_ON_SHIFT_MESSAGED',
}

export enum SentinelCheckpointBasedEventType {
  WORKERS_PAYMENT_STOPPED = 'WORKERS_PAYMENT_STOPPED',
  WORKERS_NOT_CONFIRMED = 'WORKERS_NOT_CONFIRMED',
  WORKERS_LOCATION_STATUS = 'WORKERS_LOCATION_STATUS',
  WORKERS_TRANSIT_STATUS = 'WORKERS_TRANSIT_STATUS',
  WORKERS_NOT_CLOCKED_IN = 'WORKERS_NOT_CLOCKED_IN',
  WORKERS_NOT_CLOCKED_OUT = 'WORKERS_NOT_CLOCKED_OUT',
  SHIFT_NOT_FILLED = 'SHIFT_NOT_FILLED',
  PAID_BACKUP_CANCELLATION_PENDING = 'PAID_BACKUP_CANCELLATION_PENDING',
}

export enum SentinelToastSettings {
  WORKER_LATE_CANCELED = 1 << 0,
  WORKER_LEFT_JOBSITE = 1 << 1,
  WORKER_ACCEPTED_BACKFILL_CALL = 1 << 2,
  WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP = 1 << 3,
  WORKERS_PAYMENT_STOPPED = 1 << 4,
  WORKERS_NOT_CONFIRMED = 1 << 5,
  WORKERS_LOCATION_STATUS = 1 << 6,
  WORKERS_NOT_CLOCKED_IN = 1 << 7,
  WORKERS_NOT_CLOCKED_OUT = 1 << 8,
  SHIFT_NOT_FILLED = 1 << 9,
  WORKERS_TRANSIT_STATUS = 1 << 10,
  WORKER_CONFIRMED_OVER_SLOTS_REQUESTED = 1 << 11,
  PAID_BACKUP_CANCELLATION_PENDING = 1 << 12,
  PAID_BACKUP_ON_SHIFT_CALLED = 1 << 13,
  PAID_BACKUP_ON_SHIFT_MESSAGED = 1 << 14,
}

export type SentinelNotificationEventType =
  | SentinelTriggerBasedEventType
  | SentinelCheckpointBasedEventType

export enum SentinelEventActionType {
  // Communication actions
  BUGLE_SMS = 'BUGLE_SMS',
  BUGLE_PUSH = 'BUGLE_PUSH',
  DIRECT_SMS = 'DIRECT_SMS',
  DIRECT_PUSH = 'DIRECT_PUSH',
  BACKFILL_CALLS = 'BACKFILL_CALLS',
  TRIGGER_ROBOCALLS_TO_WORKER = 'TRIGGER_ROBOCALLS_TO_WORKER',
  REQUEST_ETA = 'REQUEST_ETA',
  // Worker shift actions
  ADD_WORKER_TO_SHIFT = 'ADD_WORKER_TO_SHIFT',
  CANCEL_WORKERS = 'CANCEL_WORKERS',
  CLOCK_IN_WORKERS = 'CLOCK_IN_WORKERS',
  CLOCK_OUT_WORKERS = 'CLOCK_OUT_WORKERS',
  ABANDON_WORKERS = 'ABANDON_WORKERS',
  NO_SHOW_WORKERS = 'NO_SHOW_WORKERS',
  // Paid backup actions
  CONVERT_PAID_BACKUP_TO_WORKER_SHIFT = 'CONVERT_PAID_BACKUP_TO_WORKER_SHIFT',
  PAY_AND_DISMISS_PAID_BACKUP = 'PAY_AND_DISMISS_PAID_BACKUP',
  CANCEL_PAID_BACKUP = 'CANCEL_PAID_BACKUP',
  // Shift actions
  EDIT_SHIFT = 'EDIT_SHIFT',
}

export enum SentinelEventCustomActionType {
  OPENPHONE_LINK = 'OPENPHONE_LINK',
}

/**
 * Presets can be used to define a set of actions that should be taken for a given event type.
 */
export enum SentinelEventActionPreset {
  TO_WAITLISTS_ON_SHIFT = 'TO_WAITLISTS_ON_SHIFT',
  TO_WAITLISTS_AND_AVAILABLE_NOW = 'TO_WAITLISTS_AND_AVAILABLE_NOW',
  TO_BACKFILL_LIST = 'TO_BACKFILL_LIST',
  WAVY_NOTIFICATION_GUYS = 'WAVY_NOTIFICATION_GUYS',
  TO_PHONE_NUMBERS_LIST = 'TO_PHONE_NUMBERS_LIST',
}

export type SentinelEventAction = {
  actionType: SentinelEventActionType
  presets?: SentinelEventActionPreset[]
  properties?: Record<string, any>
}

export type SentinelActionTaken = {
  id: string
  createdAt: string
  updatedAt: string
  actionTakenAt: string
  actionType: SentinelEventActionType
  properties: any
  sentinelNotificationToUserId: string
}

export type SentinelNotification = {
  id: string
  relatedShiftId?: string
  eventType: SentinelNotificationEventType
  checkpointType?: SentinelCheckpointBasedEventType
  priority: NotificationPriority
  createdAt: Date
  message: string
  data: SentinelNotificationData
  actionsSuggested: SentinelEventAction[]
  sentinelNotificationToUser: {
    id: string
    internalUserId?: string
    internalUserEmail?: string
    internalUserRank?: string
    status: NotificationStatus
    readAt?: Date
    actionsTaken?: SentinelEventAction[]
  }
}

export type SentinelNotificationData = {
  shift: {
    shiftId: string
    timezone: string
    startTime: string
    endTime: string
    slotsFilled: string
    slotsRequested: string
    roleName: string
    employerName: string
    companyId: string
  }
  worker?: {
    firstName: string
    lastName: string
    workerId: string
    phoneNumber: string
  }
  relevantWorkerShifts?: {
    workerId: string
  }[]
  relevantPaidBackups?: {
    workerId: string
    shiftId: string
    status: PaidBackupStatus
    workerFirstName: string
    workerLastName: string
    workerPhoneNumber: string
  }[]
}

export type SlimSentinelNotification = Pick<
  SentinelNotification,
  'id' | 'sentinelNotificationToUser'
>

export type SentinelNotificationForShiftsResponse = {
  allShiftSettings: Record<string, ShiftNotificationSettingsForShift>
  shifts: {
    shiftId: string
    notifications: SentinelNotification[]
    actionsTaken: SentinelActionTaken[]
  }[]
}

export type ShiftNotificationsSettings = {
  notificationSettings: NotificationSettings
}

export type NotificationSettings = {
  shiftSilenced: boolean
  workerLateCanceled: ShiftNotificationsStatus
  workerAcceptedBackfillCall: ShiftNotificationsStatus
  workerAcceptedBackfillCallNoSignup: ShiftNotificationsStatus
  workersPaymentStopped: ShiftNotificationsStatus
  workersNotConfirmed: ShiftNotificationsStatus
  workerRunningLate: ShiftNotificationsStatus
  workerLeftJobSite: ShiftNotificationsStatus
  workerNotLeft: ShiftNotificationsStatus
  clockInNotifications: ShiftNotificationsStatus
  clockOutNotifications: ShiftNotificationsStatus
  shiftNotFilled: ShiftNotificationsStatus
  shiftNotPaid: ShiftNotificationsStatus
  workerConfirmedOverSlotsRequested: ShiftNotificationsStatus
  workerLocationNotAvailable: ShiftNotificationsStatus
  workersLocationStatus: ShiftNotificationsStatus
  workersTransitStatus: ShiftNotificationsStatus
  workerOnShiftCalled: ShiftNotificationsStatus
  workerOnShiftMessaged: ShiftNotificationsStatus
  contactedWorkerOnWaitlistCalled: ShiftNotificationsStatus
  contactedWorkerOnWaitlistMessaged: ShiftNotificationsStatus
  paidBackupCancellationPending: ShiftNotificationsStatus
  paidBackupOnShiftCalled: ShiftNotificationsStatus
  paidBackupOnShiftMessaged: ShiftNotificationsStatus
}

export type UpdateSentinelNotification = {
  id: string
  notificationId: string
  status?: NotificationStatus
  actions?: SentinelEventAction[]
}

export type UpdateNotificationSettings = {
  settingsLevel: NotificationSettingsLevel
  shiftId: string
  internalUserId: string
  notificationSettings: Partial<NotificationSettings>
}

export type ShiftNotificationSettingsForShift = {
  shiftId: string
  shiftSettings: NotificationSettings
  userSpecificSettings: Record<string, NotificationSettings>
}

export type ShiftNotificationsWithSettings = {
  notifications: SentinelNotification[]
  shiftSettings: NotificationSettings
  userSpecificSettings: NotificationSettings
}

export enum NotificationSettingsLevel {
  SHIFT = 'SHIFT',
  INTERNAL_USER = 'INTERNAL_USER',
}
