import { LinearProgress } from '@mui/material'
import { useUserContext } from '@traba/context'
import {
  Button,
  ButtonVariant,
  IMenuItem,
  Row,
  SearchSelect,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useMemo, useState } from 'react'
import { useEligibilityGroups } from 'src/hooks/useEligibilityGroups'
import { useRoles } from 'src/hooks/useRoles'
import { useTags } from 'src/hooks/useTags'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

export function EligibilityGroupSection({
  companyId,
  workers,
}: {
  companyId: string
  workers: PopulatedWorker[]
}) {
  const {
    state: { userProfile },
  } = useUserContext()

  const { roles } = useRoles({ companyId })

  const { tags = [] } = useTags({
    associations: roles?.map((role) => ({
      roleId: role.roleId,
    })),
  })

  const { assignWorkersToEligibilityGroup, isPendingAssign } =
    useEligibilityGroups()

  const tagSelectOptions = useMemo(() => {
    return tags.map((tag) => ({
      label: tag.name,
      value: tag.name,
    }))
  }, [tags])

  const [selectedEligibilityGroup, setSelectedEligibilityGroup] = useState<
    string | undefined
  >(undefined)

  const [progress, setProgress] = useState<{
    completed: number
    total: number
  }>({ completed: 0, total: workers.length })

  const handleAssignWorkersToEligibilityGroup = () => {
    if (!selectedEligibilityGroup) {
      return
    }

    const totalWorkers = workers.length
    setProgress({ completed: 0, total: totalWorkers })

    assignWorkersToEligibilityGroup({
      companyId,
      workerIds: workers.map((worker) => worker.id as string),
      tagName: selectedEligibilityGroup,
      initiatedBy: userProfile?.email ?? 'unknown',
      onProgress: (args) => {
        setProgress({
          completed: args.completed,
          total: args.completed + args.remaining,
        })
      },
    })
  }

  return (
    <>
      <Row fullWidth gap={theme.space.xxs}>
        <SearchSelect
          options={tagSelectOptions}
          selectItem={
            selectedEligibilityGroup
              ? {
                  label: selectedEligibilityGroup,
                  value: selectedEligibilityGroup,
                }
              : undefined
          }
          handleSelect={(value: IMenuItem | undefined) =>
            setSelectedEligibilityGroup(value?.value)
          }
          label="Eligibility Group"
          onlyShowLabel
          width={'100%'}
        />
        <Button
          style={{ width: '200px' }}
          reverse
          variant={ButtonVariant.OUTLINED}
          disabled={!selectedEligibilityGroup || isPendingAssign}
          loading={isPendingAssign}
          onClick={handleAssignWorkersToEligibilityGroup}
        >
          Assign Workers to Group
        </Button>
      </Row>

      {isPendingAssign && (
        <div style={{ marginTop: theme.space.xxs, width: '100%' }}>
          <Text variant="h6" style={{ marginBottom: theme.space.xxxs }}>
            {`${progress.completed}/${progress.total} workers assigned`}
          </Text>
          <LinearProgress
            variant="determinate"
            value={Math.min(
              100,
              Math.floor(
                (Number(progress.completed) / Number(progress.total)) * 100,
              ) || 0,
            )}
            style={{ height: 10 }}
          />
        </div>
      )}
    </>
  )
}
