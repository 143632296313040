import { ListItemButton } from '@mui/material'
import {
  Button,
  Card,
  Col,
  LoadingSpinner,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useCallback } from 'react'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { useWorkerResumeGenerateSummary } from 'src/hooks/useWorkerResumeUpload'
import { WorkerResumeDto } from 'src/hooks/useWorkerUploadedResumes'
import { openInNewTab } from 'src/utils/helperUtils'
import { DetailRow } from '../../../ApplicationItemContent/DetailRow'
interface ResumeLinkWithSummaryCardProps {
  resume: WorkerResumeDto & { index: number }
  showGenerateResumeButton?: boolean
  source?: string
}

const getResumeFileName = (resume: WorkerResumeDto) => {
  const month = resume.createdAt.getMonth() + 1
  const day = resume.createdAt.getDate()
  const year = resume.createdAt.getFullYear()
  const hours = resume.createdAt.getHours()
  const minutes = resume.createdAt.getMinutes()
  return `Resume_${month}/${day}/${year}_${hours}:${minutes}.pdf`
}

export function ResumeLinkWithSummaryCard({
  resume,
  showGenerateResumeButton,
  source,
}: ResumeLinkWithSummaryCardProps) {
  const { generateSummary, isLoading: isGeneratingSummary } =
    useWorkerResumeGenerateSummary(resume.workerId)
  const { trackAnalytics } = useAnalytics()
  const onGenerateSummary = useCallback(
    (resumeId: string) => () => {
      trackAnalytics('Ops Clicked Generate Resume Summary', {
        resumeId,
        workerId: resume.workerId,
        source,
      })
      generateSummary(resumeId)
    },
    [generateSummary, trackAnalytics, resume.workerId, source],
  )
  return (
    <Card style={{ padding: theme.space.xs }}>
      <Col gap={theme.space.xxs}>
        <DetailRow
          label="Resume link"
          customResultsComponent={
            <ListItemButton
              onClick={() => {
                openInNewTab(resume.downloadUrl)
              }}
              sx={{ justifyContent: 'space-between', padding: 0 }}
            >
              <Text variant="link">{getResumeFileName(resume)}</Text>
            </ListItemButton>
          }
        />

        {isGeneratingSummary ? (
          <LoadingSpinner />
        ) : (
          resume.aiSummary && (
            <DetailRow label="AI Summary" value={resume.aiSummary} />
          )
        )}
        {showGenerateResumeButton &&
          !isGeneratingSummary &&
          !resume.aiSummary && (
            <DetailRow
              label="AI Summary"
              customResultsComponent={
                <div>
                  <Button slim onClick={onGenerateSummary(resume.resumeId)}>
                    Generate summary
                  </Button>
                </div>
              }
            />
          )}
      </Col>
    </Card>
  )
}
