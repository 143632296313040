import { Col, LoadingSpinner, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { getEnglishContent, getSpanishContent } from '@traba/utils'
import { useOpsManualStepByApplicationItemId } from 'src/hooks/useOpsManualStep'
import { HyperlinkLocalizedText } from '../../HyperlinkLocalizedText'
import { StyledAnswerText } from '../../styles'

interface OpsManualStepApplicationItemResultsProps {
  applicationItemId?: string
}

export function OpsManualStepApplicationItemResults({
  applicationItemId,
}: OpsManualStepApplicationItemResultsProps) {
  const { opsManualStep, isLoading } = useOpsManualStepByApplicationItemId(
    applicationItemId || '',
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!opsManualStep) {
    return <StyledAnswerText>No data found</StyledAnswerText>
  }

  const {
    parsedWorkerAppTitle,
    parsedWorkerAppDescription,
    opsConsoleTitle,
    opsConsoleDescription,
  } = opsManualStep

  return (
    <Col gap={theme.space.med}>
      <Col gap={theme.space.xxs}>
        <Text variant="body3">OPS ACTION</Text>
        <Col gap={theme.space.xxxs}>
          <HyperlinkLocalizedText
            variant="body1"
            text={opsConsoleTitle || '-'}
          />
        </Col>
      </Col>

      <Col gap={theme.space.xxs}>
        <Text variant="body3">OPS NOTE</Text>
        <Col gap={theme.space.xxxs}>
          <HyperlinkLocalizedText
            variant="body1"
            text={opsConsoleDescription || '-'}
          />
        </Col>
      </Col>

      <Col gap={theme.space.xxs}>
        <Text variant="body3">WORKER APP TITLE</Text>
        <Col gap={theme.space.xxxs}>
          <HyperlinkLocalizedText
            variant="body1"
            text={getEnglishContent(parsedWorkerAppTitle) || '-'}
          />
          <HyperlinkLocalizedText
            variant="body2"
            textColor={theme.colors.Grey60}
            text={getSpanishContent(parsedWorkerAppTitle) || '-'}
          />
        </Col>
      </Col>

      {parsedWorkerAppDescription && (
        <Col gap={theme.space.xxs}>
          <Text variant="body3">WORKER APP SUBTITLE</Text>
          <Col gap={theme.space.xxxs}>
            <HyperlinkLocalizedText
              variant="body1"
              text={getEnglishContent(parsedWorkerAppDescription) || '-'}
            />
            <HyperlinkLocalizedText
              variant="body2"
              textColor={theme.colors.Grey60}
              text={getSpanishContent(parsedWorkerAppDescription) || '-'}
            />
          </Col>
        </Col>
      )}
    </Col>
  )
}
