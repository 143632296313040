import { Col, LoadingSpinner, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useWorkerVettingSession } from 'src/hooks/useWorkerVetting'
import WorkerVettingSessionMessageViewer from 'src/screens/WorkerVettingScreen/components/WorkerVettingSessionMessageViewer'

interface WorkerVettingCallApplicationItemResultsProps {
  applicationItemSourceId: string
}

export function WorkerVettingCallApplicationItemResults({
  applicationItemSourceId,
}: WorkerVettingCallApplicationItemResultsProps) {
  const { session: workerVettingSession, isLoading: isLoadingVettingSession } =
    useWorkerVettingSession(applicationItemSourceId)

  if (isLoadingVettingSession) {
    return <LoadingSpinner />
  }

  return (
    <Col style={{ height: '100%' }}>
      <Row style={{ flex: 1 }}>
        {workerVettingSession ? (
          <WorkerVettingSessionMessageViewer
            vettingSession={workerVettingSession}
            isDrawer={true}
            containerStyle={{
              marginLeft: 0,
              paddingLeft: 0,
            }}
          />
        ) : (
          <Row ml={theme.space.med} mt={theme.space.med}>
            <Text variant="body1">No session found</Text>
          </Row>
        )}
      </Row>
    </Col>
  )
}
