import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { ShiftRequest } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

const SHIFT_REQUEST_QUERY_KEY = 'shift-request'
async function getShiftRequestById(
  shiftRequestId: string,
): Promise<ShiftRequest | undefined> {
  try {
    const res = await trabaApi.get(`shift-requests/${shiftRequestId}`)
    return res.data
  } catch (error: any) {
    const errorMessage = `useShiftRequests -> getShiftRequestById() ERROR ${
      error.message ?? JSON.stringify(error)
    }`
    console.error(errorMessage)
    captureSentryError(error)
  }
}

export function useShiftRequestById(shiftRequestId: string) {
  const {
    isLoading,
    isError,
    data: shiftRequest,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftRequest | undefined, Error>({
    queryKey: [SHIFT_REQUEST_QUERY_KEY, shiftRequestId],
    queryFn: () => getShiftRequestById(shiftRequestId),
    enabled: !!shiftRequestId,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    shiftRequest,
    error,
    isFetched,
    refetch,
  }
}
