import { Col, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ApplicationItem, ApplicationRecordStatus } from '@traba/types'
import { BaseApplicationAnalyticsParams } from 'src/screens/Applications/hooks/useApplicationsAnalytics'
import { ViewAndEditExistingApplicationItemsList } from 'src/screens/Applications/ViewAndEditExistingApplicationItemsList'
import { DisableApplicationToggleButton } from 'src/screens/ShiftDetailsScreen/components/ShiftApplicantsTable/DisableApplicationToggleButton'
import { ShiftPostingInputContainer } from '../../ShiftPostingInputContainer'

type EditExistingApplicationsSectionProps = {
  applicationId: string
  applicationItems: ApplicationItem[]
  roleName: string
  shiftRequestId: string
  companyId?: string
  roleId?: string
  applicationRecordStatus: ApplicationRecordStatus
  analyticsSource?: string
  analyticsParams?: BaseApplicationAnalyticsParams
}

export function EditExistingApplicationsSection({
  applicationId,
  applicationItems,
  roleName,
  shiftRequestId,
  companyId,
  roleId,
  applicationRecordStatus,
  analyticsSource = 'edit-existing-applications-section',
  analyticsParams,
}: EditExistingApplicationsSectionProps) {
  return (
    <ShiftPostingInputContainer
      title="Applications (optional)"
      style={{ background: theme.colors.Grey10, borderRadius: '10px' }}
    >
      <Col fullWidth gap={theme.space.sm}>
        <Col
          fullWidth
          p={theme.space.sm}
          style={{
            borderRadius: theme.space.xxs,
            border: `1px solid ${theme.colors.Grey20}`,
            backgroundColor: theme.colors.White,
          }}
          gap={theme.space.xs}
        >
          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h4">{roleName}</Text>
            <DisableApplicationToggleButton
              shiftRequestId={shiftRequestId}
              analyticsSource={analyticsSource}
              analyticsParams={analyticsParams}
            />
          </Row>

          {applicationRecordStatus === ApplicationRecordStatus.ACTIVE && (
            <ViewAndEditExistingApplicationItemsList
              applicationId={applicationId}
              applicationItems={applicationItems}
              analyticsSource={analyticsSource}
              analyticsParams={{ ...analyticsParams, shiftRequestId }}
              companyId={companyId}
              roleId={roleId}
              shiftRequestId={shiftRequestId}
              isEditable
            />
          )}
        </Col>
      </Col>
    </ShiftPostingInputContainer>
  )
}
