import {
  LanguageContentObj,
  QualificationQuestionAnswerCreateParams,
  QualificationQuestionAnswerUpdateParams,
} from './qualification-question-answer'
import { WorkerApplicationItemStatus } from './worker-applications'
import { VettingConfig } from './worker-vetting'

export enum ApplicationEntityType {
  SHIFT_REQUEST = 'SHIFT_REQUEST',
  ROLE = 'ROLE',
}

export type ApplicationEntity = {
  id: string
  applicationEntityType: ApplicationEntityType
  shiftRequestId?: string
  roleId?: string
}

export enum ApplicationItemType {
  AI_VETTING_CALL = 'AI_VETTING_CALL',
  DRUG_SCREENING = 'DRUG_SCREENING',
  RESUME_UPLOAD = 'RESUME_UPLOAD',
  OPS_MANUAL_STEP = 'OPS_MANUAL_STEP',
  CUSTOM_QUESTION = 'CUSTOM_QUESTION',
  GHOST_PROFILE_LINKED = 'GHOST_PROFILE_LINKED', // When a Ghost profile becomes linked to an actual worker.
  VETTING_CAMPAIGN_SPREADSHEET = 'VETTING_CAMPAIGN_SPREADSHEET',
}

export const APPLICATION_ITEM_TYPE_TO_TITLE: Record<
  ApplicationItemType,
  string
> = {
  [ApplicationItemType.AI_VETTING_CALL]: 'AI Vetting Call',
  [ApplicationItemType.DRUG_SCREENING]: 'Drug Screening',
  [ApplicationItemType.RESUME_UPLOAD]: 'Resume Upload',
  [ApplicationItemType.OPS_MANUAL_STEP]: 'Ops Manual Step',
  [ApplicationItemType.CUSTOM_QUESTION]: 'Screening Question',
  [ApplicationItemType.GHOST_PROFILE_LINKED]: 'Account Creation',
  [ApplicationItemType.VETTING_CAMPAIGN_SPREADSHEET]: 'Spreadsheet Entry',
}

export const ENABLED_APPLICATION_ITEM_TYPES = [
  ApplicationItemType.AI_VETTING_CALL,
  ApplicationItemType.RESUME_UPLOAD,
  ApplicationItemType.CUSTOM_QUESTION,
  ApplicationItemType.OPS_MANUAL_STEP,
]

// we will not allow ops to change the status of these application items
export const APPLICATION_ITEM_TYPES_THAT_CANNOT_BE_ACTIONED_ON = [
  ApplicationItemType.GHOST_PROFILE_LINKED,
]

// we will not allow ops to change the status of cancelled items (items that are tied to archived application items)
export const APPLICATION_STATUSES_THAT_CANNOT_BE_ACTIONED_ON = [
  WorkerApplicationItemStatus.CANCELED,
]

// these application items can be used multiple times in one the application
export const ALLOW_MULTIPLE_STEPS_OF_APPLICATION_ITEM_TYPES = [
  ApplicationItemType.OPS_MANUAL_STEP,
  ApplicationItemType.CUSTOM_QUESTION,
]

export enum ApplicationRecordStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISABLED = 'DISABLED',
}

export enum ApplicationItemRecordStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type ApplicationItem = {
  id: string
  applicationItemType: ApplicationItemType
  sourceId?: string
  applicationId: string
  recordStatus: ApplicationItemRecordStatus
  sectionDisplayOrderIndex?: number
  sectionIndex?: number
}

export type Application = {
  id: string
  recordStatus: ApplicationRecordStatus
  applicationEntities: ApplicationEntity[]
  applicationItems: ApplicationItem[]
}

export type EmptyItemContent = {}

export type ShiftInformationForVettingConfigApplicationItem = {
  shiftRoleId: string
  shiftRequestParentTitle?: string
  shiftStartTime?: string
  shiftRoleName: string
}

export type ApplicationItemAICallItemContent = {
  vettingConfig: VettingConfig
  shiftInformationForVettingConfig: ShiftInformationForVettingConfigApplicationItem
}

export type ApplicationItemAICallRequest = {
  applicationItemType: ApplicationItemType.AI_VETTING_CALL
  itemContent: ApplicationItemAICallItemContent
}

export type ApplicationItemAIResumeUploadRequest = {
  applicationItemType: ApplicationItemType.RESUME_UPLOAD
  itemContent: EmptyItemContent
}

export type ApplicationItemCustomQuestionItemContent = {
  createQualifierQuestionRequestDto?: QualificationQuestionAnswerCreateParams
  updateQualifierQuestionRequestDto?: QualificationQuestionAnswerUpdateParams
}

export type ApplicationItemCustomQuestionRequest = {
  applicationItemType: ApplicationItemType.CUSTOM_QUESTION
  itemContent: ApplicationItemCustomQuestionItemContent
}

export type OpsManualStepCreateParams = {
  workerAppTitle: LanguageContentObj[]
  workerAppDescription?: LanguageContentObj[]
  workerAppCtaText?: LanguageContentObj[]
  opsConsoleTitle: string
  opsConsoleDescription: string
}

export type OpsManualStepUpdateParams = {
  workerAppTitle?: LanguageContentObj[]
  workerAppDescription?: LanguageContentObj[]
  workerAppCtaText?: LanguageContentObj[]
  opsConsoleTitle?: string
  opsConsoleDescription?: string
  opsInputNotes?: string
  applicationItemId?: string
}

export type ApplicationItemOpsManualStepItemContent = {
  createOpsManualActionRequestDto?: OpsManualStepCreateParams
  updateOpsManualActionRequestDto?: OpsManualStepUpdateParams
}

export type ApplicationItemOpsManualStepRequest = {
  applicationItemType: ApplicationItemType.OPS_MANUAL_STEP
  itemContent: ApplicationItemOpsManualStepItemContent
}

export type ApplicationItemRequestType =
  | ApplicationItemAICallRequest
  | ApplicationItemAIResumeUploadRequest
  | ApplicationItemCustomQuestionRequest
  | ApplicationItemOpsManualStepRequest

export type ApplicationItemContent =
  | EmptyItemContent
  | ApplicationItemAICallItemContent
  | ApplicationItemCustomQuestionItemContent
  | ApplicationItemOpsManualStepItemContent

// TODO(gail...): revisit separation of create & update types for saving app items
export type UpsertApplicationItemRequestType = ApplicationItemRequestType & {
  applicationItemId?: string
  applicationItemSourceId?: string
  itemContent: ApplicationItemContent
  // TODO(gail): use these on create application when we support SECTIONING
  sectionDisplayOrderIndex?: number
  sectionIndex?: number
}

export type ApplicationForShiftRequestRequest = {
  applicationItems: ApplicationItemRequestType[]
}

export type AdditionalInformationForItemCreateOrUpdate = {
  shiftInformationForVettingConfig?: ShiftInformationForVettingConfigApplicationItem
}
