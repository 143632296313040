import { SvgIconProps } from '@traba/types'

export default function Copy({
  size,
  color = '#7A8A99',
  strokeWidth = '1.5',
}: SvgIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.42631 12.2682C7.06441 10.7254 7.06441 9.11974 7.42631 7.57691C7.92742 5.44059 9.59548 3.77254 11.7318 3.27142C13.2746 2.90953 14.8803 2.90953 16.4231 3.27142C18.5594 3.77254 20.2275 5.44059 20.7286 7.57692C21.0905 9.11974 21.0905 10.7254 20.7286 12.2682C20.2275 14.4045 18.5594 16.0726 16.4231 16.5737C14.8803 16.9356 13.2746 16.9356 11.7318 16.5737M7.42631 12.2682C7.92742 14.4045 9.59548 16.0726 11.7318 16.5737M7.42631 12.2682C7.19844 11.2968 7.11405 10.3004 7.17313 9.31057C7.08958 9.32762 7.00622 9.3459 6.92307 9.36541C5.09194 9.79493 3.66217 11.2247 3.23265 13.0558C2.92245 14.3782 2.92245 15.7545 3.23265 17.0769C3.66217 18.9081 5.09194 20.3378 6.92307 20.7674C8.24549 21.0775 9.62175 21.0775 10.9442 20.7674C12.7753 20.3378 14.2051 18.9081 14.6346 17.0769C14.6541 16.9938 14.6724 16.9104 14.6894 16.8269C13.6996 16.886 12.7032 16.8016 11.7318 16.5737"
        stroke={color}
        stroke-width={strokeWidth}
      />
    </svg>
  )
}
