import { Row, Text, TextVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  APPLICATION_ITEM_TYPE_TO_TITLE,
  ApplicationItemType,
} from '@traba/types'

const DEFAULT_TEXT = 'Application Step'

interface ApplicationItemNumberAndTitleProps {
  stepNumber: number
  applicationItemType?: ApplicationItemType
  customApplicationItemText?: string
  titleVariant?: TextVariant
}

export function ApplicationItemNumberAndTitle({
  applicationItemType,
  stepNumber,
  titleVariant,
  customApplicationItemText,
}: ApplicationItemNumberAndTitleProps) {
  const applicationItemText = customApplicationItemText
    ? customApplicationItemText
    : applicationItemType
      ? APPLICATION_ITEM_TYPE_TO_TITLE[applicationItemType] || DEFAULT_TEXT
      : DEFAULT_TEXT

  return (
    <Row gap={theme.space.xsmed}>
      <Row
        alignCenter
        justifyCenter
        style={{
          backgroundColor: theme.colors.brand,
          borderRadius: '100%',
          width: '24px',
          height: '24px',
        }}
      >
        <Text variant="h6" color={theme.colors.White} p={theme.space.sm}>
          {stepNumber}
        </Text>
      </Row>
      <Text variant={titleVariant} p={theme.space.sm}>
        {applicationItemText}
      </Text>
    </Row>
  )
}
