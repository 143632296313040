import { ListItem, ListItemText } from '@mui/material'
import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Col,
  LoadingSpinner,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { CopyTextIcon } from 'src/components/base'
import { getZealAccountLink, useZeal } from 'src/hooks/useZeal'
import { useZealI9 } from 'src/hooks/useZealI9'

interface W2WorkerDetailsProps {
  workerId: string
}

export const W2WorkerDetails = (props: W2WorkerDetailsProps) => {
  const { workerId } = props
  const { isZealLoading, zealEmployeeData } = useZeal({
    workerId,
    enabled: true,
  })

  const { zealI9Data, refetchZealI9, isZealI9Fetching } = useZealI9({
    workerId,
    enabled: false,
  })

  const [zealLink, setZealLink] = useState<string | undefined>(undefined)
  const [zealI9Link, setZealI9Link] = useState<string | undefined>(undefined)
  const [isGeneratingAccountLink, setIsGeneratingAccountLink] = useState(false)

  const { showError } = useAlert()

  if (isZealLoading) {
    return <LoadingSpinner />
  }

  return (
    <ListItem disableGutters>
      <ListItemText
        style={{
          paddingLeft: theme.space.xs,
        }}
      >
        <Text variant="h5" mb={theme.space.xs}>
          W2 Worker Management
        </Text>
        <Row alignCenter>
          <Text variant="h6" my={theme.space.xs} mr={theme.space.xxs}>
            Zeal onboarding complete:
          </Text>
          <Text>{zealEmployeeData?.onboarded ? 'YES' : 'No'}</Text>
        </Row>
        <Row alignCenter>
          <Text variant="h6" my={theme.space.xs} mr={theme.space.xxs}>
            Employment status:
          </Text>
          <Text>{zealEmployeeData?.employment_status}</Text>
        </Row>
        <Row alignCenter>
          <Text variant="h6" my={theme.space.xs} mr={theme.space.xxs}>
            Start date:
          </Text>
          <Text>
            {zealEmployeeData?.start_date
              ? zealEmployeeData.start_date.toString()
              : 'Missing start date'}
          </Text>
        </Row>
        <Col gap={theme.space.xs}>
          {zealLink ? (
            <Row>
              <Text variant="body1">Copy generated link</Text>
              <CopyTextIcon textToCopy={zealLink} />
            </Row>
          ) : (
            <Button
              full
              loading={isGeneratingAccountLink}
              variant={ButtonVariant.OUTLINED}
              onClick={async () => {
                try {
                  setIsGeneratingAccountLink(true)
                  const link = await getZealAccountLink(workerId)
                  setZealLink(link)
                } catch (error: any) {
                  showError(error?.message ?? 'Error generating link')
                } finally {
                  setIsGeneratingAccountLink(false)
                }
              }}
            >
              {isGeneratingAccountLink
                ? 'Generating link...'
                : 'Generate link to Zeal for worker'}
            </Button>
          )}

          {zealI9Link ? (
            <Row>
              <Text variant="body1">Copy generated link</Text>
              <CopyTextIcon textToCopy={zealI9Link} />
            </Row>
          ) : (
            <Button
              full
              loading={isZealI9Fetching}
              variant={ButtonVariant.OUTLINED}
              onClick={async () => {
                try {
                  await refetchZealI9()
                  setZealI9Link(zealI9Data?.i9Link)
                } catch (error: any) {
                  showError(error?.message ?? 'Error generating link')
                }
              }}
            >
              {isZealI9Fetching
                ? 'Generating link...'
                : 'Generate link to Zeal i9'}
            </Button>
          )}
        </Col>
      </ListItemText>
    </ListItem>
  )
}
