import {
  Row,
  Text,
  Button,
  DatePicker,
  ButtonVariant,
  Badge,
  BadgeVariant,
  Input,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { AccountStatus, EmploymentType, ValueType, Worker } from '@traba/types'
import { DateTime } from 'luxon'
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { NumberInput } from 'src/components/base/Input/NumberInput'
import {
  IncentiveFromSearch,
  useGrantWorkerIncentive,
} from 'src/hooks/useIncentives'
import { SearchWorkers } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { convertPayRateToCents } from 'src/utils/moneyUtils'

type GrantIncentiveFormInputs = {
  workers: PopulatedWorker[]
  earnedOn: Date | null
  shiftId: string
  amount?: number
}

function getInitialForm(
  worker?: Worker & AccountStatus,
): GrantIncentiveFormInputs {
  return {
    workers: worker ? [{ ...worker, accountStatus: worker }] : [],
    earnedOn: new Date(),
    shiftId: '',
    amount: undefined,
  }
}

function SelectedWorkerList({ workers }: { workers: PopulatedWorker[] }) {
  return (
    <Row flexCol p={theme.space.sm} gap={theme.space.xxs}>
      <Text variant="h5" color={theme.colors.Black}>
        {`Workers (${workers.length})`}
      </Text>
      <ul
        style={{
          fontSize: '11px',
          color: theme.colors.Grey50,
          marginLeft: theme.space.xs,
        }}
      >
        {workers.map((worker) => (
          <li key={worker.id}>
            <Text variant="body1" color={theme.colors.Black}>
              {`${worker.firstName} ${worker.lastName}`}
            </Text>
          </li>
        ))}
      </ul>
    </Row>
  )
}

function GrantIncentiveReview({
  workers,
  earnedOn,
}: {
  workers: PopulatedWorker[]
  earnedOn: Date
}) {
  const earnedOnDT = useMemo(() => DateTime.fromJSDate(earnedOn), [earnedOn])

  return (
    <>
      <SelectedWorkerList workers={workers} />
      <Row flexCol p={theme.space.sm} gap={theme.space.xxs}>
        <Text variant="h5" color={theme.colors.Black}>
          Earned On
        </Text>
        <Text variant="body1" color={theme.colors.Black}>
          {earnedOnDT.toFormat('MMMM dd, yyyy')}
        </Text>
        <Text variant="body1" color={theme.colors.Black}>
          {earnedOnDT.toFormat('hh:mm a ZZZZ')}
        </Text>
      </Row>
    </>
  )
}

function GrantIncentiveInputs({
  form,
  valueType,
  hideWorkerSearch,
  handleInputChange,
}: {
  form: GrantIncentiveFormInputs
  valueType: ValueType
  hideWorkerSearch?: boolean
  handleInputChange: (
    key: keyof GrantIncentiveFormInputs,
    value: string | PopulatedWorker[] | Date | number | null | undefined,
  ) => void
}) {
  const setWorkers = (workersOrUpdater: SetStateAction<PopulatedWorker[]>) => {
    if (typeof workersOrUpdater === 'function') {
      handleInputChange('workers', workersOrUpdater(form.workers))
    } else {
      handleInputChange('workers', workersOrUpdater)
    }
  }

  return (
    <>
      {hideWorkerSearch ? (
        <SelectedWorkerList workers={form.workers} />
      ) : (
        <Row flexCol p={theme.space.sm} gap={theme.space.xxs}>
          <Text variant="h5" color={theme.colors.Black}>
            Worker UID
          </Text>
          <SearchWorkers
            checkedWorkers={form.workers}
            setCheckedWorkers={setWorkers}
            disableSideBar
            hideInstructionText
            hideApprovedOnlyToggle
            openInline
            top={'0'}
          />
        </Row>
      )}
      <Row flexCol p={theme.space.sm} gap={theme.space.xxs}>
        <Text variant="h5" color={theme.colors.Black}>
          Earned On
        </Text>
        <DatePicker
          aria-label="Earned On"
          showTimeFieldInPopover={true}
          setDate={(date) => handleInputChange('earnedOn', date)}
          isClearable={true}
          date={form.earnedOn}
          containerStyle={{ flexGrow: 1, maxWidth: 300 }}
          style={{ width: '100%' }}
        />
      </Row>
      <Row flexCol p={theme.space.sm} gap={theme.space.xxs}>
        <Text variant="h5" color={theme.colors.Black}>
          Shift ID
        </Text>
        <Input
          id="shiftId"
          value={form.shiftId}
          onChange={(e) => handleInputChange('shiftId', e.target.value)}
          autoComplete="off"
          containerStyle={{ marginTop: 0, flexGrow: 1, maxWidth: 300 }}
        />
      </Row>
      {valueType === ValueType.Voucher && (
        <Row flexCol p={theme.space.sm} gap={theme.space.xxs}>
          <Text variant="h5" color={theme.colors.Black}>
            Amount
          </Text>
          <NumberInput
            value={form.amount}
            setValue={(value?: number) => {
              handleInputChange('amount', value)
            }}
            width="300px"
            required
            isMoney
          />
        </Row>
      )}
    </>
  )
}

export function GrantIncentiveForm({
  incentive,
  worker,
}: {
  incentive: IncentiveFromSearch
  worker?: Worker & AccountStatus
}) {
  const [form, setForm] = useState<GrantIncentiveFormInputs>(
    getInitialForm(worker),
  )
  const [inReview, setInReview] = useState<boolean>(false)
  const { grantWorkerIncentive, isSuccess, reset } = useGrantWorkerIncentive()

  useEffect(() => {
    if (inReview && isSuccess) {
      setInReview(false)
      setForm(getInitialForm(worker))
      reset()
    }
  }, [inReview, isSuccess, reset, worker])

  useEffect(() => {
    setInReview(false)
    setForm(getInitialForm(worker))
  }, [incentive, worker])

  const handleFormSubmit = useCallback(() => {
    if (inReview) {
      grantWorkerIncentive({
        incentiveId: incentive.id as string,
        workerIds: form.workers.map((worker) => worker.id as string),
        earnedAt: form.earnedOn,
        shiftId: form.shiftId,
        total: form.amount
          ? {
              amount: convertPayRateToCents(form.amount),
              currency: 'USD',
            }
          : undefined,
      })
    } else {
      setInReview(true)
    }
  }, [inReview, incentive, grantWorkerIncentive, form])

  const totalSpend = useMemo(() => {
    if (!form.workers.length) {
      return 0
    }

    const amountInCents = form.amount
      ? convertPayRateToCents(form.amount)
      : (incentive.totalAmount ?? 0)

    return (amountInCents * form.workers.length) / 100
  }, [form.workers.length, form.amount, incentive.totalAmount])

  const hasOvertimeImpact = useMemo(() => {
    return form.workers.some((worker) =>
      worker.accountStatus.activeEmploymentTypes?.includes(EmploymentType.W2),
    )
  }, [form.workers])

  const handleInputChange = (
    key: keyof GrantIncentiveFormInputs,
    value: string | PopulatedWorker[] | Date | number | null | undefined,
  ) => {
    setForm((prevForm) => ({ ...prevForm, [key]: value }))
  }

  return (
    <Row
      flexCol
      fullWidth
      style={{
        border: `1px solid ${theme.colors.Grey20}`,
        borderRadius: theme.space.sm,
      }}
    >
      <Row
        flexCol
        gap={theme.space.xs}
        p={theme.space.sm}
        style={{
          borderBottom: `1px solid ${theme.colors.Grey20}`,
        }}
      >
        <Text variant="h4" color={theme.colors.Black}>
          {incentive.title}
        </Text>
        <Text color={theme.colors.Black}>{incentive.description}</Text>
      </Row>

      {inReview ? (
        <GrantIncentiveReview
          workers={form.workers}
          earnedOn={form.earnedOn ?? new Date()}
        />
      ) : (
        <GrantIncentiveInputs
          form={form}
          valueType={incentive.valueType}
          handleInputChange={handleInputChange}
          hideWorkerSearch={worker !== undefined}
        />
      )}

      <Row
        justifyBetween
        alignCenter
        fullWidth
        p={theme.space.sm}
        style={{
          borderTop: `1px solid ${theme.colors.Grey20}`,
          marginTop: 'auto',
        }}
      >
        <Row flexCol>
          <Row alignCenter gap={theme.space.xxs}>
            <Text variant="h4" color={theme.colors.Black}>
              {`$${totalSpend}`}
            </Text>
            {hasOvertimeImpact && (
              <Badge title="OT IMPACT" variant={BadgeVariant.YELLOW} />
            )}
          </Row>
          <Text variant="body1" color={theme.colors.Black}>
            Total spend for this grant
          </Text>
        </Row>
        <Row gap={theme.space.xxs}>
          {inReview && (
            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={() => setInReview(false)}
            >
              Back
            </Button>
          )}
          <Button onClick={handleFormSubmit}>
            {inReview ? 'Grant' : 'Review'}
          </Button>
        </Row>
      </Row>
    </Row>
  )
}
