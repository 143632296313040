import { theme } from '@traba/theme'
import {
  ScheduleInvitationStatus,
  ScheduleShiftRequestWorkerInvitation,
} from '@traba/types'
import { useMemo, useState } from 'react'
import Avatar from '../base-components/Avatar/Avatar'
import { ConfirmationDialog } from '../base-components/Dialog/ConfirmationDialog'
import { DotMenu } from '../base-components/DotMenu/DotMenu.ui'
import { Input } from '../base-components/Input/Input'
import Row from '../base-components/Row'
import { DataTable } from '../base-components/Table/DataTable'
import { Text } from '../base-components/Text'
import { LinkText } from '../base-components/WorkerLinkText'
import { ScheduleInvitationBadge } from '../invitations/ScheduleInvitationBadge'

interface Props {
  invitations: ScheduleShiftRequestWorkerInvitation[]
  roleName: string
  onClickWorker: (workerId: string) => void
  rescindScheduleInvitation: ({
    shiftRequestId,
    workerId,
  }: {
    shiftRequestId: string
    workerId: string
  }) => Promise<void>
}

export const InvitationsTable = (props: Props) => {
  const { invitations, roleName, onClickWorker, rescindScheduleInvitation } =
    props
  const workersTableHeaders = [
    { label: 'Worker name', key: 'name', sortable: true },
    { label: 'Invitation status', key: 'status' },
    { label: 'Actions', key: 'actions' },
  ]
  const [searchFilter, setSearchFilter] = useState('')
  const [invitationToRescind, setInvitationToRescind] = useState<
    ScheduleShiftRequestWorkerInvitation | undefined
  >()

  const invitationTableRows = useMemo(() => {
    const filteredInvitations = invitations?.filter(({ worker }) => {
      const name = worker.firstName + ' ' + worker.lastName
      return name.toLowerCase().includes(searchFilter.toLowerCase())
    })

    return filteredInvitations?.map((invitation) => {
      const { worker, id, status, workerId, failedEligibilityChecks } =
        invitation
      return {
        key: id,
        cells: [
          {
            key: 'name',
            renderFn: () => (
              <Row alignCenter py={theme.space.xs}>
                <Avatar
                  src={worker.photoUrl}
                  name={`${worker.firstName} ${worker.lastName}`}
                  size={40}
                />{' '}
                <LinkText
                  variant="h6"
                  style={{
                    marginLeft: theme.space.xs,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  onClick={() => onClickWorker(workerId)}
                >
                  {`${worker.firstName} ${worker.lastName}`}
                </LinkText>
              </Row>
            ),
          },
          {
            key: 'status',
            renderFn: () => (
              <ScheduleInvitationBadge
                status={status}
                id={id}
                failedEligibilityChecks={failedEligibilityChecks}
              />
            ),
          },

          {
            key: 'actions',
            renderFn: () =>
              status === ScheduleInvitationStatus.PENDING ||
              status === ScheduleInvitationStatus.SENT ? (
                <DotMenu
                  style={{ height: 36 }}
                  type="worker-shift-request-invitation"
                  dotMenuKey={'worker-shift-request-invitation'}
                  menuItems={[
                    {
                      title: 'Rescind Invitation',
                      onClick: () => setInvitationToRescind(invitation),
                    },
                  ]}
                />
              ) : null,
          },
        ],
      }
    })
  }, [invitations, searchFilter])

  return (
    <>
      <Text variant="h5" mt={theme.space.sm}>
        {roleName}
      </Text>
      <Row alignCenter mb={theme.space.xxs} justifyBetween>
        <Row alignEnd gap={theme.space.xxs}>
          <Input
            placeholder="Search workers by name..."
            leftIconName="search"
            name="workerSearch"
            type="text"
            style={{ margin: 0 }}
            defaultValue=""
            width="300px"
            value={searchFilter}
            onChange={(e) => {
              e.preventDefault()
              setSearchFilter(e.target.value)
            }}
            onClear={() => setSearchFilter('')}
          />
        </Row>
      </Row>
      <DataTable
        headers={workersTableHeaders}
        rows={invitationTableRows ?? []}
      />
      <ConfirmationDialog
        open={!!invitationToRescind}
        onConfirm={async () => {
          if (invitationToRescind) {
            await rescindScheduleInvitation({
              shiftRequestId: invitationToRescind.shiftRequestId,
              workerId: invitationToRescind.workerId,
            })
            setInvitationToRescind(undefined)
          }
        }}
        onClose={() => setInvitationToRescind(undefined)}
        onConfirmCTA="Rescind"
        title="Rescind Invitation?"
        confirmationText={`Are you sure you want to rescind invitation for ${invitationToRescind?.worker.firstName} ${invitationToRescind?.worker.lastName}?`}
      />
    </>
  )
}
