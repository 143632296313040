import { useAlert } from '@traba/context'
import { TextVariant, Text } from '@traba/react-components'
import { Fragment, useCallback, useEffect } from 'react'

import { openInNewTab } from 'src/utils/helperUtils'

type LinkSection = {
  before: string
  linkHref?: string
  textOfLink?: string
  after?: string
}

type Props = {
  text: string
  style?: React.CSSProperties
  variant?: TextVariant
  linkColor?: string
  textColor?: string
  onClickLink?: () => void
}

// Validate that the text passed in is formatted correctly with opening & closing "a" tags
// with all opening "a" tags having an href inside
// Examples of text that will be valid:
// - no links: 'Text before link'
// - single link: 'Text before link <a href="https://www.mylink.com">text inside link</a> text after link'
// - multiple links: 'Text before link <a href="https://www.mylink.com">text inside first link</a> text after first link. More text before the next link <a href="https://www.mylink.com">text inside second link</a> with text after the second link'
// Examples of text that will be invalid:
// - no closing "a" tag: 'Text before link <a href="https://www.mylink.com">text inside link'
// - no opening "a" tag: 'text inside link</a> text after link'
// - no href inside "a" tag: 'Text before link <a>text inside link</a> text after link'
export function isValidLinkFormat(text: string): {
  isValid: boolean
  reason?: string
} {
  // Check for incomplete tags (opening tag without closing bracket)
  if (/<a(?![^<]*>)/.test(text)) {
    return { isValid: false, reason: 'Incomplete opening tag' }
  }

  // Check for incomplete href attributes
  if (/<a[^>]*href=(?![^>]*")/.test(text)) {
    return { isValid: false, reason: 'Incomplete href attribute' }
  }

  // Comprehensive pattern to match properly formatted links
  const linkPattern = /<a href="[^"]+">([^<]+)<\/a>/g

  // Extract all link matches
  const matches = text.match(linkPattern) || []

  // Count opening and closing tags to ensure they match the found links
  const openingTags = (text.match(/<a[^>]*>/g) || []).length
  const closingTags = (text.match(/<\/a>/g) || []).length

  // If there are no tags, the text is valid (just plain text)
  if (openingTags === 0 && closingTags === 0) {
    return { isValid: true }
  }

  // Validate that all URLs have proper protocols to prevent security issues
  const hrefPattern = /href="([^"]+)"/g
  let hrefMatch
  let allUrlsValid = true
  while ((hrefMatch = hrefPattern.exec(text)) !== null) {
    const url = hrefMatch[1]
    // Only allow http, https, mailto, and tel protocols
    if (!url.match(/^(https?:|mailto:|tel:)/)) {
      allUrlsValid = false
      break
    }
  }

  if (!allUrlsValid) {
    return {
      isValid: false,
      reason:
        'Invalid URL protocol, please only use http, https, mailto, or tel',
    }
  }

  // Otherwise, ensure all tags are properly matched and formed
  const isValid =
    matches.length === openingTags && matches.length === closingTags
  return { isValid, reason: isValid ? undefined : 'Invalid tag count' }
}

function getLinkSections(text: string): LinkSection[] {
  if (!isValidLinkFormat(text)) {
    // if the text is not valid, return an array with the text as the only section
    return [{ before: text }]
  }

  const linkSections: LinkSection[] = []
  let restOfText = text

  while (restOfText.indexOf('<a') !== -1) {
    const linkStart = restOfText.indexOf('<a')
    const linkEnd = restOfText.indexOf('</a>')
    const linkSection = restOfText.slice(linkStart, linkEnd + 4)
    const textAfterLink = restOfText.slice(linkEnd + 4)
    const isLastLink = textAfterLink.indexOf('<a') === -1
    linkSections.push({
      before: restOfText.slice(0, linkStart),
      linkHref: linkSection.match(/href="([^"]+)"/)?.[1],
      textOfLink: linkSection.match(/>([^<]+)</)?.[1],
      after: isLastLink ? textAfterLink : undefined,
    })
    restOfText = textAfterLink
  }

  return linkSections
}

export function HyperlinkLocalizedText({
  text,
  style,
  variant = 'body2',
  linkColor,
  textColor,
  onClickLink,
}: Props) {
  const { handleError } = useAlert()

  const onClick = useCallback(
    (linkHref?: string) => () => {
      if (onClickLink) {
        onClickLink()
      } else {
        if (linkHref) {
          openInNewTab(linkHref)
        }
      }
    },
    [onClickLink],
  )

  const { isValid, reason } = isValidLinkFormat(text)

  useEffect(() => {
    if (!isValid) {
      handleError(
        new Error(
          `${reason || 'Invalid link format for HyperlinkLocalizedText'}. Please edit and fix the link`,
        ),
        'HyperlinkLocalizedText -> isValidLinkFormat',
        `${reason || 'Invalid link format for HyperlinkLocalizedText'}. Please edit and fix the link, it will be shown as a link when fixed.`,
      )
    }
  }, [isValid])

  if (!isValid || text.indexOf('<a') === -1) {
    // if the text is not valid or has no links, just return the text as a single text component
    return (
      <Text variant={variant} color={textColor} style={style}>
        {text}
      </Text>
    )
  }

  const linkSections = getLinkSections(text)

  return (
    <Text variant={variant} color={textColor} style={style}>
      {linkSections.map((section, index) => (
        <Fragment key={index}>
          {section.before}
          {section.linkHref && section.textOfLink && (
            <span
              onClick={onClick(section.linkHref)}
              style={{
                color: linkColor,
                textDecorationLine: 'underline',
                cursor: 'pointer',
                fontWeight: 600,
              }}
            >
              {section.textOfLink}
            </span>
          )}
          {section.after}
        </Fragment>
      ))}
    </Text>
  )
}
