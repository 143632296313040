import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
  IMenuItem,
  Input,
  LoadingSpinner,
  SearchSelect,
  Text,
  useModal,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { Role, RoleAttribute, CreateVettingCampaignRequest } from '@traba/types'
import { ChangeEvent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Badge, Button, Col, Icon, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useAttributes } from 'src/hooks/useAttributes'
import { useCompanies } from 'src/hooks/useCompanies'
import { useRegions } from 'src/hooks/useRegions'
import { useRoles } from 'src/hooks/useRoles'

import {
  useVettingCampaignMutations,
  useVettingCampaignQuestions,
} from 'src/hooks/useVettingCampaigns'
import { AddCustomVettingQuestionModal } from 'src/modals/AddCustomVettingQuestionModal/AddCustomVettingQuestionModal'
import { RequiredAttributeLevelsModal } from 'src/modals/RequiredAttributeLevelsModal/RequiredAttributeLevelsModal'
import {
  ShiftPostingInputContainer,
  ShiftPostingInputContainerSection,
} from 'src/screens/PostShiftScreen/components/ShiftPostingInputContainer'
import { sortQuestionKeys } from 'src/utils/vettingCampaignUtils'

const REQUIRED_QUESTIONS: string[] = [
  'first_name',
  'last_name',
  'phone_number',
  'called',
  'caller',
]

export const CreateVettingCampaignScreen = () => {
  const navigate = useNavigate()

  // Form state
  const [campaignName, setCampaignName] = useState<string>('')
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    string | undefined
  >()
  const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>()
  const [selectedRegionIds, setSelectedRegionIds] = useState<IMenuItem[]>([])

  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([
    ...REQUIRED_QUESTIONS,
  ])
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([])
  const [customQuestions, setCustomQuestions] = useState<string[]>([])

  const { regions = [] } = useRegions()
  const { companies = [], isLoading: isLoadingCompanies } = useCompanies({
    identifiersOnly: true,
    isApproved: true,
  })
  const { roles = [], isLoading: isLoadingRoles } = useRoles({
    companyId: selectedCompanyId,
  })
  const { generalQuestions } = useVettingCampaignQuestions()
  const { attributes = [] } = useAttributes()
  const {
    createVettingCampaign,
    isCampaignCreationPending: isCreatingCampaign,
  } = useVettingCampaignMutations()
  const attributesMap = new Map(attributes.map((attr) => [attr.type, attr]))

  const {
    isOpen: isRoleAttrModalOpen,
    open: openRoleAttrModal,
    handleClose: handleCloseRoleAttrModal,
  } = useModal()

  const {
    isOpen: isCustomVettingQuestionModalOpen,
    open: openCustomVettingQuestionModal,
    handleClose: handleCloseCustomVettingQuestionModal,
  } = useModal()

  const companiesOptions: IMenuItem[] = companies
    .filter((company) => company.employerName)
    .map((company) => ({
      label: company.employerName,
      value: company.id,
    }))

  const rolesOptions: IMenuItem[] = roles.map((role) => ({
    label: role.roleName,
    value: role.roleId,
  }))

  const regionsOptions: IMenuItem[] = regions.map((region) => ({
    label: region.displayName,
    value: region.regionId,
  }))

  const selectedCompanyMenuItem = useMemo(
    () =>
      companiesOptions.find((company) => company.value === selectedCompanyId),
    [companiesOptions, selectedCompanyId],
  )

  const selectedRoleMenuItem = useMemo(
    () => rolesOptions.find((role) => role.value === selectedRoleId),
    [rolesOptions, selectedRoleId],
  )

  const handleQuestionRemoval = (id: string) => {
    if (REQUIRED_QUESTIONS.includes(id)) {
      return
    }
    setSelectedQuestions((prev) => prev.filter((item) => item !== id))
    setCustomQuestions((prev) => prev.filter((item) => item !== id))
    setSelectedAttributes((prev) => prev.filter((item) => item !== id))
  }

  const questionColumns: GridColDef[] = [
    {
      field: 'attribute',
      headerName: 'Associated Attribute',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Badge
          title={`${params.value}`}
          variant={
            (generalQuestions ?? []).map((q) => q.key).includes(params.row.id)
              ? 'success'
              : params.value === 'custom'
                ? 'business'
                : undefined
          }
        />
      ),
    },
    {
      field: 'question',
      headerName: 'Question',
      flex: 2,
      minWidth: 300,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      renderCell: (params) => {
        const isGeneralQuestion = (generalQuestions ?? [])
          .map((q) => q.key)
          .includes(params.row.id)
        if (isGeneralQuestion) {
          return null
        }

        return (
          <Button
            variant={ButtonVariant.TEXT}
            onClick={(e) => {
              e.stopPropagation()
              handleQuestionRemoval(params.row.id)
            }}
          >
            <Icon name="trash" />
          </Button>
        )
      },
    },
  ]

  const attributeRows = useMemo(
    () =>
      selectedAttributes
        .map((attr) => attributesMap.get(attr))
        .filter((attr): attr is RoleAttribute => attr !== undefined)
        ?.map((attr) => ({
          id: attr.type,
          attribute: attr.displayName,
          question: attr.displayName,
        })) ?? [],
    [selectedAttributes, attributesMap],
  )

  const generalQuestionRows = useMemo(() => {
    if (!generalQuestions) {
      return []
    }
    return generalQuestions.map((q) => ({
      id: q.key,
      attribute: 'general',
      question: q.displayName,
    }))
  }, [generalQuestions])

  const customQuestionRows = customQuestions.map((q) => ({
    id: q,
    attribute: 'custom',
    question: q,
  }))

  const rows = useMemo(
    () => [...generalQuestionRows, ...attributeRows, ...customQuestionRows],
    [generalQuestionRows, attributeRows, customQuestionRows],
  )

  const handleCampaignCreation = async () => {
    const createCampaignRequest: CreateVettingCampaignRequest = {
      title: campaignName,
      companyIds: selectedCompanyId ? [selectedCompanyId] : [],
      roleIds: selectedRoleId ? [selectedRoleId] : [],
      regionIds: selectedRegionIds.map((region) => region.value),
      questionKeys: sortQuestionKeys(
        selectedQuestions,
        customQuestions,
        generalQuestions,
      ),
      customQuestions: customQuestions.filter((q) =>
        selectedQuestions.includes(q),
      ),
    }
    await createVettingCampaign(createCampaignRequest)
    navigate('/vetting')
  }

  const getAttributesFromRoles = (
    roleIds: IMenuItem[],
    roles: Role[],
  ): string[] => {
    return [
      ...new Set(
        roleIds.flatMap((roleId) => {
          const selectedRole = roles.find(
            (role) => role.roleId === roleId.value,
          )
          return (
            selectedRole?.requiredAttributeLevels?.map(
              (ral) => ral.attribute.type,
            ) ??
            selectedRole?.requiredAttributes?.map((ra) => ra.type) ??
            []
          )
        }),
      ),
    ]
  }

  if (isLoadingCompanies || isCreatingCampaign) {
    return (
      <MainLayout>
        <LoadingSpinner />
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <Button variant={ButtonVariant.TEXT} onClick={() => navigate(-1)}>
        <Icon name="leftArrow" style={{ paddingRight: theme.space.xxs }} />
        Back to All Vetting Campaigns
      </Button>
      <Divider />
      <Row alignCenter mt={theme.space.xs} mb={theme.space.sm}>
        <Text variant="h3">Create New Vetting Campaign</Text>
      </Row>
      <Col>
        <ShiftPostingInputContainer title="Campaign Details">
          <Col>
            <ShiftPostingInputContainerSection
              input={
                <Row alignCenter justifyStart fullWidth>
                  <Input
                    label="Campaign Name"
                    value={campaignName}
                    onChange={(
                      ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                    ) => setCampaignName(ev.target.value)}
                    width="100%"
                    containerStyle={{ marginTop: 0 }}
                    style={{ height: '40px' }}
                    required={true}
                  />
                </Row>
              }
              label={''}
            />
            <ShiftPostingInputContainerSection
              input={
                <Row alignCenter justifyStart pt={theme.space.sm} fullWidth>
                  <SearchSelect
                    options={companiesOptions}
                    selectItem={selectedCompanyMenuItem}
                    onlyShowLabel
                    handleSelect={(company: IMenuItem | undefined) => {
                      setSelectedCompanyId(company?.value)
                      setSelectedRoleId(undefined)
                    }}
                    label={`Company *`}
                    width="100%"
                  />
                </Row>
              }
              label={''}
            />
            <ShiftPostingInputContainerSection
              input={
                <Row alignCenter justifyStart pt={theme.space.sm} fullWidth>
                  <SearchSelect
                    options={rolesOptions}
                    selectItem={selectedRoleMenuItem}
                    handleSelect={(role: IMenuItem | undefined) => {
                      setSelectedRoleId(role?.value)
                      const newAttributes = role
                        ? getAttributesFromRoles([role], roles)
                        : []
                      setSelectedAttributes((prev) => [
                        ...new Set([...prev, ...newAttributes]),
                      ])
                      setSelectedQuestions((prev) => [
                        ...new Set([...prev, ...newAttributes]),
                      ])
                    }}
                    label={`Role *`}
                    width="100%"
                    disabled={isLoadingRoles}
                    isLoading={isLoadingRoles}
                  />
                </Row>
              }
              label={''}
            />
            <ShiftPostingInputContainerSection
              input={
                <Row alignCenter justifyStart pt={theme.space.sm} fullWidth>
                  <SearchSelect
                    multiple
                    options={regionsOptions}
                    selectedItems={selectedRegionIds}
                    handleSelectMultiple={(regionIds: IMenuItem[]) => {
                      setSelectedRegionIds(regionIds)
                    }}
                    label={`Region(s)`}
                    width="100%"
                  />
                </Row>
              }
              label={''}
            />
          </Col>
        </ShiftPostingInputContainer>
        <ShiftPostingInputContainer
          title="Select Vetting Questions to Include in Campaign"
          titleActions={
            <Row gap={theme.space.xs}>
              <Button
                leftIcon={<Plus />}
                reverse
                variant={ButtonVariant.TEXT}
                style={{ padding: 0, color: theme.colors.Violet }}
                onClick={() => openCustomVettingQuestionModal()}
              >
                Add Custom
              </Button>
              <Button
                leftIcon={<Plus />}
                reverse
                variant={ButtonVariant.TEXT}
                style={{ padding: 0, color: theme.colors.Violet }}
                onClick={() => openRoleAttrModal()}
              >
                Add Attributes
              </Button>
            </Row>
          }
        >
          <AddCustomVettingQuestionModal
            isOpen={isCustomVettingQuestionModalOpen}
            onClose={handleCloseCustomVettingQuestionModal}
            onSubmit={(cq: string) => {
              setCustomQuestions((prev) => [...prev, cq])
              setSelectedQuestions((prev) => [...prev, cq])
            }}
          />
          <RequiredAttributeLevelsModal
            attributes={attributes}
            isOpen={isRoleAttrModalOpen}
            handleClose={handleCloseRoleAttrModal}
            handleConfirm={handleCloseRoleAttrModal}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={setSelectedAttributes}
            setSelectedQuestions={setSelectedQuestions}
            title="Select Attributes for Vetting Campaign"
            allowBooleanSelectionOnly={true}
            showDeselect={true}
          />
          <DataGrid
            rows={rows}
            columns={questionColumns}
            autoHeight
            hideFooterPagination
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            sx={{
              '.MuiDataGrid-columnHeaders': {
                fontWeight: 600,
                color: theme.colors.MidnightBlue,
              },
              '.MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .required-row .MuiCheckbox-root': {
                color: theme.colors.Grey,
                '&.Mui-checked': {
                  color: theme.colors.Grey,
                },
              },
            }}
            checkboxSelection
            isRowSelectable={(params) =>
              !REQUIRED_QUESTIONS.includes(params.row.id)
            }
            getRowClassName={(params) =>
              REQUIRED_QUESTIONS.includes(params.row.id) ? 'required-row' : ''
            }
            rowSelectionModel={selectedQuestions}
            onRowSelectionModelChange={(newSelection) => {
              const updatedSelection = [
                ...new Set([
                  ...REQUIRED_QUESTIONS,
                  ...newSelection.map((id) => id.toString()),
                ]),
              ]
              setSelectedQuestions(updatedSelection)
            }}
          />
        </ShiftPostingInputContainer>
      </Col>
      <Row justifyBetween>
        <Button
          variant={ButtonVariant.OUTLINED}
          style={{ width: '100px' }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          style={{ width: '250px' }}
          onClick={() => {
            handleCampaignCreation()
          }}
          disabled={
            !campaignName.trim() || !selectedRoleId || !selectedCompanyId
          }
        >
          Create Campaign
        </Button>
      </Row>
    </MainLayout>
  )
}
