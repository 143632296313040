import { IconButton, Tooltip } from '@mui/material'
import { useAlert } from '@traba/context'
import { Badge, BadgeVariant, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { TagAssociation } from '@traba/types'
import { keyBy } from 'lodash'
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { Trash } from 'src/assets/svg/icons/Trash'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { useRoles } from 'src/hooks/useRoles'
import { useTags } from 'src/hooks/useTags'
import { ExpandableList } from './ExpandableList'

export function CompanyEligibilityGroupTable({
  companyId,
  onDelete,
}: {
  companyId: string
  onDelete: (tagName: string) => Promise<void>
}) {
  const { showSuccess, showError } = useAlert()
  const { roles = [] } = useRoles({ companyId })
  const { tags = [], deleteTags } = useTags({
    associations: roles.map((role) => ({
      roleId: role.roleId,
    })),
    onDeleteSuccess: () => {
      showSuccess(`Successfully deleted eligibility group`)
    },
    onDeleteError: (error) => {
      showError(error.message, 'Failed to delete eligibility group', 10000)
    },
  })

  const [tagToDelete, setTagToDelete] = useState<{
    id: string
    name: string
  } | null>(null)

  const rolesById = useMemo(() => {
    return keyBy(roles, 'roleId')
  }, [roles])

  const roleNamesByTagId = useMemo(() => {
    return tags.reduce<Record<string, string[]>>((acc, tag) => {
      acc[tag.id] = tag.associations
        .filter(
          (a): a is TagAssociation & { roleId: string } =>
            a.roleId !== undefined,
        )
        .map(({ roleId }) => rolesById[roleId]?.roleName)
      return acc
    }, {})
  }, [tags, rolesById])

  const handleDeleteTag = () => {
    if (tagToDelete) {
      onDelete(tagToDelete.name).then(() => {
        deleteTags({ tagIds: [tagToDelete.id] })
        setTagToDelete(null)
      })
    }
  }

  const columns = useMemo<MRT_ColumnDef<(typeof tags)[0]>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Group',
        size: 100,
        Cell: ({ row }) => {
          const tagId = row.original.id
          const tagName = row.original.name
          return (
            <Badge variant={BadgeVariant.BRAND} key={tagId} title={tagName} />
          )
        },
      },
      {
        accessorKey: 'id',
        header: 'Roles',
        Cell: ({ row }) => {
          const tagId = row.original.id
          const roleNames = roleNamesByTagId[tagId]

          return (
            <ExpandableList title={roleNames.join(', ')}>
              {roleNames.map((roleName, index) => (
                <Badge key={`${tagId}-${index}`} title={roleName} />
              ))}
            </ExpandableList>
          )
        },
      },
      {
        accessorKey: 'id',
        header: 'Actions',
        enableSorting: false,
        size: 50,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const tagId = row.original.id
          const tagName = row.original.name

          return (
            <Tooltip title={`Delete ${tagName} group`}>
              <IconButton
                onClick={() => setTagToDelete({ id: tagId, name: tagName })}
                size="small"
              >
                <Trash size={18} color={theme.colors.red} />
              </IconButton>
            </Tooltip>
          )
        },
      },
    ],
    [roleNamesByTagId],
  )

  return (
    <>
      {tags.length > 0 && (
        <Row fullWidth mb={theme.space.sm}>
          <MaterialReactTable
            columns={columns}
            data={tags}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableSorting={false}
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: theme.colors.Grey10,
                paddingTop: '10px',
                '& *': {
                  fontWeight: 600,
                  fontSize: '10px',
                  letterSpacing: '0.1em',
                  textTransform: 'uppercase',
                  color: theme.colors.Grey60,
                  height: '18px',
                },
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                color: theme.colors.Grey60,
              },
            }}
            muiTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
          />
        </Row>
      )}

      <ConfirmationDialog
        open={!!tagToDelete}
        onConfirm={handleDeleteTag}
        onClose={() => setTagToDelete(null)}
        title="Delete Eligibility Group?"
        confirmButtonText="Delete"
        content={
          tagToDelete
            ? `Are you sure you want to delete the "${tagToDelete.name}" eligibility group?`
            : ''
        }
      />
    </>
  )
}
