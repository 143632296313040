import { useLocations } from '@traba/hooks'
import {
  Button,
  ButtonVariant,
  Card,
  Row,
  ScheduleDays,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'
import {
  formatScheduleDateRange,
  getLocationNameOrTruncatedAddress,
  isRecurringShiftRequest,
  handleNavigateWithNewTab,
  isShiftRequestParentInactive,
} from '@traba/utils'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge } from '../base'
import { ScheduleListItem } from '../Schedule/ScheduleListItem'

interface Props {
  shiftRequestParent: ShiftRequestParentWithShiftRequest | undefined
}

export const ScheduleInfoCard = (props: Props) => {
  const { shiftRequestParent } = props
  const navigate = useNavigate()
  const { locations } = useLocations(shiftRequestParent?.companyId)

  const recurringShiftRequests = useMemo(
    () =>
      (
        shiftRequestParent?.shiftRequests.filter((sr) =>
          sr.schedules.some((s) => s.isRecurringSchedule),
        ) ?? []
      ).sort((a, b) => {
        return (
          a.schedules[0].startTime.getTime() -
          b.schedules[0].startTime.getTime()
        )
      }),
    [shiftRequestParent],
  )
  const firstRecurringShiftRequest = useMemo(
    () => recurringShiftRequests.find((_sr) => true),
    [recurringShiftRequests],
  )

  if (!shiftRequestParent || !firstRecurringShiftRequest) {
    return null
  }
  const roleNames = shiftRequestParent.shiftRequests
    .filter(isRecurringShiftRequest)
    .map((sr) => `${sr.slotsRequested} ${sr.shiftRole}`)

  const locationId = shiftRequestParent.shiftRequests.length
    ? shiftRequestParent.shiftRequests[0].locationId
    : ''
  const location = locations?.find(
    (location) => location.locationId === locationId,
  )
  const locationName = location
    ? getLocationNameOrTruncatedAddress(location)
    : ''

  return (
    <Card
      style={{
        backgroundColor: theme.colors.Grey10,
        marginBottom: theme.space.xs,
      }}
    >
      <Row alignCenter justifyBetween>
        <Row alignCenter gap={theme.space.xxs}>
          <Text variant="h6">{shiftRequestParent.title}</Text>
          <Badge title="schedule" variant="brand" />
          {isShiftRequestParentInactive(shiftRequestParent) && (
            <Badge title="INACTIVE" variant="warning" />
          )}
        </Row>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={(e) => {
            const url = `/schedule/${shiftRequestParent.shiftRequestParentId}`
            handleNavigateWithNewTab(url, e, navigate)
          }}
        >
          Go to full schedule
        </Button>
      </Row>
      <Row alignCenter>
        <ScheduleListItem
          label="SCHEDULE RANGE"
          style={{ width: '25%' }}
          content={formatScheduleDateRange(recurringShiftRequests)}
        />
        <ScheduleListItem
          label="ROLES FOR EACH SHIFT"
          style={{ width: '25%' }}
          content={roleNames.join(', ')}
        />
        <ScheduleListItem
          label="LOCATION"
          style={{ width: '25%' }}
          content={locationName}
        />
        <ScheduleListItem
          label="WEEKLY PATTERN"
          style={{ width: '25%' }}
          content={
            <Row wrap>
              {firstRecurringShiftRequest.schedules.map((schedule, index) => (
                <ScheduleDays
                  repeatOn={schedule.recurringSchedule?.repeatOn || []}
                  key={index}
                />
              ))}
            </Row>
          }
        />
      </Row>
    </Card>
  )
}
