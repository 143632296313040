import { InputWithLanguagePrefix, Col, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { LanguageContentMap, SupportedLanguage } from '@traba/types'
import { ChangeEvent, useCallback } from 'react'

interface LanguageContentSectionProps {
  sectionTitle?: string
  languageContentMap: LanguageContentMap
  onChange?: (language: string, content: string) => void
}

export function LanguageContentInputSection({
  sectionTitle,
  languageContentMap,
  onChange,
}: LanguageContentSectionProps) {
  const onChangeLanguageContent = useCallback(
    (language: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onChange) {
          onChange(language, e.target.value)
        }
      },
    [onChange],
  )

  return (
    <Col gap={theme.space.xxs} fullWidth>
      {sectionTitle && <Text variant="body3">{sectionTitle}</Text>}
      {Object.keys(languageContentMap).map((language) => (
        <InputWithLanguagePrefix
          key={language}
          language={language as SupportedLanguage}
          value={languageContentMap[language as SupportedLanguage]}
          onChange={onChangeLanguageContent(language)}
        />
      ))}
    </Col>
  )
}
