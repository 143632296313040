import {
  Col,
  DotMenu,
  Row,
  SvgIcon,
  Text,
  useModal,
  Badge,
  BadgeVariant,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ShiftInvitation,
  ShiftInvitationStatus,
  WorkerApplicationItem,
  WorkerApplicationItemStatus,
  WorkerApplicationStatus,
  WorkerApplicationWithApplicant,
  WorkerApplicationWithWorkerInfo,
  doesWorkerAccountExistForWorkerApplication,
} from '@traba/types'
import {
  MRT_Table as MaterialReactTableNoToolbar,
  MRT_ColumnDef,
  MRT_Row,
  useMaterialReactTable,
} from 'material-react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AccountStatusBadge } from 'src/components/base'
import { WorkerApplicationStatusBadge } from 'src/components/base/Badge/WorkerApplicationStatusBadge'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useGetOverriddenWorkerApplications } from 'src/hooks/useWorkerApplications'
import { useApplicationsAnalytics } from 'src/screens/Applications/hooks/useApplicationsAnalytics'
import { WorkerApplicationResultsDrawer } from 'src/screens/Applications/WorkerApplicationItemResults/WorkerApplicationResultsDrawer'
import { formatPhoneNumber, toPercentString } from 'src/utils/stringUtils'
import { PhoneLinkAndCopyButton } from '../PhoneLinkAndCopyButton'
import { TruncatedIdWithCopyButton } from '../TruncatedIdWithCopyButton'
import { WorkerNameWithLink } from '../WorkerNameWithLink'
import { AddWorkerToShiftsModalContainer } from './AddWorkerToShiftsModalContainer'
import { ApplicationItemsSectionContainer } from './ApplicationItemsSectionContainer'
import { DisableApplicationToggleButton } from './DisableApplicationToggleButton'
import { InviteToSingleShiftOrScheduleModal } from './InviteToSingleShiftOrScheduleModal'
import { ShiftInvitationStatusOrInviteButton } from './ShiftInvitationStatusOrInviteButton'
import { ViewApplicationDrawer } from './ViewApplicationDrawer'

interface ShiftApplicantsTableProps {
  applications: WorkerApplicationWithApplicant[]
  timezone?: string
  shiftInvitations: ShiftInvitation[]
  workerShiftWorkerIdsSet?: Set<string>
  onRescindInvitation?: (shiftInvitation: ShiftInvitation) => void
  isLoading?: boolean
  shift?: OpsExtendedShift
  analyticsSource?: string
  roleId?: string
  companyId?: string
}

const CELL_COMMON_STYLES = {
  padding: '4px',
  fontSize: '12px',
  fontWeight: '300',
  border: '0px',
  paddingTop: '8px',
  paddingBottom: '8px',
  borderBottom: `1px solid ${theme.colors.Grey20}`,
}

const HEADER_CELL_STYLES = {
  ...CELL_COMMON_STYLES,
  color: theme.colors.MidnightBlue,
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  '& *': {
    fontWeight: 'bolder',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    fontSize: '10px',
  },
  paddingTop: '0px',
  paddingBottom: '0px',
  borderBottom: 'none',
}

const HEADER_ROW_STYLES = {
  paddingRight: '12px',
  paddingLeft: '12px',
  paddingTop: '6px',
  paddingBottom: '6px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  backgroundColor: theme.colors.Grey20,
  boxShadow: 'none',
}

export function ShiftApplicantsTable({
  applications,
  timezone,
  shiftInvitations,
  workerShiftWorkerIdsSet,
  onRescindInvitation,
  isLoading,
  shift,
  analyticsSource = 'shift-applicants-table',
  roleId,
  companyId,
}: ShiftApplicantsTableProps) {
  const overriddenWorkerApplications = useGetOverriddenWorkerApplications()
  const applicationId = applications[0]?.applicationId
  const commonAnalyticsParams = useMemo(
    () => ({
      shiftId: shift?.shiftId,
      shiftRequestId: shift?.shiftRequestId,
      companyId: shift?.companyId || companyId,
      roleId: shift?.roleId || roleId,
      source: analyticsSource,
      applicationId,
    }),
    [
      shift?.shiftId,
      shift?.shiftRequestId,
      shift?.companyId,
      companyId,
      shift?.roleId,
      roleId,
      analyticsSource,
      applicationId,
    ],
  )

  const data = useMemo(() => {
    return [...applications].map((a) => {
      const overriddenWorkerApplication = overriddenWorkerApplications[a.id]
      return overriddenWorkerApplication
        ? { ...a, ...overriddenWorkerApplication }
        : a
    })
  }, [applications, overriddenWorkerApplications])
  const tz = useTimezonedDates(timezone)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [workerForInviteModal, setWorkerForInviteModal] = useState<
    { id: string; firstName: string; lastName: string } | undefined
  >(undefined)
  const {
    trackInviteModalOpened,
    trackInviteModalClosed,
    trackResendInviteClicked,
    trackAddToShiftModalOpened,
    trackAddToShiftModalClosed,
    trackRescindInvitation,
    trackViewApplicationDetails,
    trackViewWorkerApplicationItemDetails,
    trackExpandWorkerApplication,
    trackWorkerApplicationResultsDrawerClosed,
  } = useApplicationsAnalytics()

  const onInviteModalOpen = useCallback(
    ({
      worker,
      workerApplicationId,
      isInviteAgain = false,
    }: {
      worker: { id: string; firstName: string; lastName: string }
      workerApplicationId: string
      isInviteAgain?: boolean
    }) =>
      () => {
        setWorkerForInviteModal(worker)
        setShowInviteModal(true)

        if (isInviteAgain) {
          trackResendInviteClicked({
            ...commonAnalyticsParams,
            workerApplicationId,
            workerId: worker.id,
          })
        } else {
          trackInviteModalOpened({
            ...commonAnalyticsParams,
            workerApplicationId,
            workerId: worker.id,
          })
        }
      },
    [commonAnalyticsParams],
  )
  const onInviteModalClose = useCallback(() => {
    setWorkerForInviteModal(undefined)
    setShowInviteModal(false)
    trackInviteModalClosed({
      ...commonAnalyticsParams,
      workerId: workerForInviteModal?.id,
    })
  }, [commonAnalyticsParams, workerForInviteModal])

  const handleRescindInvitation = useCallback(
    (shiftInvitation?: ShiftInvitation) => () => {
      if (shiftInvitation && onRescindInvitation) {
        onRescindInvitation(shiftInvitation)
        trackRescindInvitation({
          ...commonAnalyticsParams,
          workerId: shiftInvitation.workerId,
        })
      }
    },
    [onRescindInvitation, commonAnalyticsParams],
  )

  const [selectedWorkerApplicationItemId, setSelectedWorkerApplicationItemId] =
    useState<string | null>(null)
  const [isApplicationDetailsDrawerOpen, setIsApplicationDetailsDrawerOpen] =
    useState(false)
  const [
    workerApplicationForResultsDrawer,
    setWorkerApplicationForResultsDrawer,
  ] = useState<WorkerApplicationWithApplicant | undefined>(undefined)

  const onOpenApplicationDetailsDrawer = useCallback(() => {
    setSelectedWorkerApplicationItemId(null)
    setWorkerApplicationForResultsDrawer(undefined)
    setIsApplicationDetailsDrawerOpen(true)
    trackViewApplicationDetails({
      ...commonAnalyticsParams,
    })
  }, [commonAnalyticsParams])

  const onCloseApplicationDetailsDrawer = useCallback(() => {
    setIsApplicationDetailsDrawerOpen(false)
    trackViewApplicationDetails({
      ...commonAnalyticsParams,
    })
  }, [commonAnalyticsParams])

  const onCloseWorkerApplicationResultsDrawer = useCallback(() => {
    setWorkerApplicationForResultsDrawer(undefined)
    setSelectedWorkerApplicationItemId(null)
    trackWorkerApplicationResultsDrawerClosed({
      ...commonAnalyticsParams,
    })
  }, [commonAnalyticsParams])

  const onSelectWorkerApplicationItem = useCallback(
    (workerApplication: WorkerApplicationWithApplicant) =>
      (workerApplicationItemId: string | null) => {
        setSelectedWorkerApplicationItemId(workerApplicationItemId)
        setIsApplicationDetailsDrawerOpen(false)
        setWorkerApplicationForResultsDrawer(workerApplication)

        const workerApplicationItem =
          workerApplication.workerApplicationItems.find(
            (item) => item.id === workerApplicationItemId,
          )

        trackViewWorkerApplicationItemDetails({
          ...commonAnalyticsParams,
          workerApplicationItemId: workerApplicationItemId || undefined,
          workerId: doesWorkerAccountExistForWorkerApplication(
            workerApplication,
          )
            ? workerApplication.workerInfo.id
            : workerApplication.ghostProfileInfo.id,
          workerApplicationId: workerApplication.id,
          applicationItemId: workerApplicationItem?.applicationItem.id,
          applicationItemType:
            workerApplicationItem?.applicationItem.applicationItemType,
        })
      },
    [commonAnalyticsParams],
  )

  const addWorkerToShiftsModal = useModal()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const handleAddToShifts = useCallback(
    (workerId: string) => () => {
      setSelectedRows([workerId])
      addWorkerToShiftsModal.open()
      trackAddToShiftModalOpened({
        ...commonAnalyticsParams,
        workerId,
      })
    },
    [addWorkerToShiftsModal, commonAnalyticsParams],
  )

  const handleAddToShiftsModalClose = useCallback(() => {
    addWorkerToShiftsModal.handleClose()
    trackAddToShiftModalClosed({
      ...commonAnalyticsParams,
    })
  }, [addWorkerToShiftsModal, commonAnalyticsParams])

  const columns = useMemo<
    MRT_ColumnDef<WorkerApplicationWithApplicant>[]
  >(() => {
    // Define base columns that are always included
    const baseColumns: MRT_ColumnDef<WorkerApplicationWithApplicant>[] = [
      {
        id: 'workerName',
        header: 'WORKER NAME',
        accessorFn: (row) => row,
        size: 100,
        grow: 2,
        Cell: ({ cell }) => {
          const worker = cell.getValue<WorkerApplicationWithApplicant>()
          if (doesWorkerAccountExistForWorkerApplication(worker)) {
            return (
              <WorkerNameWithLink
                workerId={worker.workerInfo.id}
                workerFullName={`${worker.workerInfo.firstName} ${worker.workerInfo.lastName}`.trim()}
              />
            )
          }
          const ghostProfileInfo = worker.ghostProfileInfo
          return (
            <Text>
              {`${ghostProfileInfo.firstName} ${ghostProfileInfo.lastName}`.trim()}
            </Text>
          )
        },
      },
      {
        id: 'accountStatus',
        header: 'STATUS',
        accessorFn: (row) => row,
        size: 80,

        Cell: ({ cell }) => {
          const worker = cell.getValue<WorkerApplicationWithApplicant>()
          if (doesWorkerAccountExistForWorkerApplication(worker)) {
            return worker.workerInfo.accountApprovalStatus ? (
              <AccountStatusBadge
                accountStatus={worker.workerInfo.accountApprovalStatus}
                workerId={worker.workerInfo.id}
              />
            ) : null
          }

          return <Badge variant={BadgeVariant.INFO} title="👻 Ghost" />
        },
      },
      {
        id: 'workerId',
        header: 'WORKER ID',
        accessorFn: (row) => row,
        size: 64,
        Cell: ({ cell }) => {
          const worker = cell.getValue<WorkerApplicationWithApplicant>()
          const id = doesWorkerAccountExistForWorkerApplication(worker)
            ? worker.workerInfo.id
            : worker.ghostProfileInfo.id
          return <TruncatedIdWithCopyButton id={id} />
        },
      },
      {
        id: 'phoneNumber',
        header: 'PHONE #',
        accessorFn: (row) => row,
        size: 140,
        Cell: ({ cell }) => {
          const worker = cell.getValue<WorkerApplicationWithApplicant>()
          const phoneNumber = doesWorkerAccountExistForWorkerApplication(worker)
            ? worker.workerInfo.phoneNumber
            : worker.ghostProfileInfo.phoneNumber
          return (
            <PhoneLinkAndCopyButton
              phoneNumber={formatPhoneNumber(phoneNumber, true)}
            />
          )
        },
      },
      {
        id: 'lastUpdated',
        header: 'UPDATED AT',
        accessorFn: (row) => row.updatedAt,
        size: 72,
        grow: 1,
        Cell: ({ cell }) => {
          const date = cell.getValue<string>()
          const formattedDate = date ? `${tz.getDateTime(new Date(date))}` : '-'
          return <Text variant="body1">{formattedDate}</Text>
        },
      },
      {
        id: 'tier',
        header: 'TIER',
        accessorFn: (row) =>
          doesWorkerAccountExistForWorkerApplication(row)
            ? row.workerInfo.tierLevel
            : '-',
        size: 50,
      },
      {
        id: 'reliability',
        header: 'RELIABILITY',
        accessorFn: (row) =>
          doesWorkerAccountExistForWorkerApplication(row)
            ? `${toPercentString(row.workerInfo.reliabilityScore || 0)}%`
            : '-',
        size: 52,
      },
      {
        id: 'shiftsCompleted',
        header: '# SHIFTS',
        accessorFn: (row) =>
          doesWorkerAccountExistForWorkerApplication(row)
            ? (row.workerInfo.completedShifts ?? 0)
            : '-',
        size: 50,
      },
      {
        id: 'steps',
        header: 'STEPS',
        accessorFn: (row) => row.workerApplicationItems,
        size: 60,
        Cell: ({ cell }) => {
          const items = cell.getValue<WorkerApplicationItem[]>()
          const completed = items.filter(
            (item) => item.status === WorkerApplicationItemStatus.COMPLETED,
          )
          return (
            <Row alignCenter gap={theme.space.xxxs}>
              <Text variant="body1">
                {completed.length} / {items.length}
              </Text>
              {completed.length === items.length && (
                <SvgIcon
                  name="filledCheck"
                  size={12}
                  color={theme.colors.Green70}
                />
              )}
            </Row>
          )
        },
      },
      {
        id: 'applicationStatus',
        header: 'APPLICATION',
        accessorFn: (row) => row.status,
        size: 72,
        Cell: ({ cell }) => (
          <WorkerApplicationStatusBadge
            applicationStatus={cell.getValue<WorkerApplicationStatus>()}
          />
        ),
      },
    ]

    // Conditional columns based on whether shift exists
    const columnsWithShift: MRT_ColumnDef<WorkerApplicationWithApplicant>[] = [
      ...baseColumns,
    ]

    // Only add inviteStatus and actions columns if shift is defined
    if (shift) {
      columnsWithShift.push({
        id: 'inviteStatus',
        header: 'INVITE STATUS',
        accessorFn: (row) => row,
        size: 64,
        Cell: ({ cell }) => {
          const row = cell.getValue<WorkerApplicationWithWorkerInfo>()
          const worker = row.workerInfo
          return (
            <ShiftInvitationStatusOrInviteButton
              disabled={isLoading}
              isOnShift={workerShiftWorkerIdsSet?.has(worker.id)}
              worker={worker}
              shiftId={shift.shiftId}
              onClickInvite={onInviteModalOpen({
                worker,
                workerApplicationId: row.id,
              })}
            />
          )
        },
      })

      // Add actions column only when shift exists
      columnsWithShift.push({
        id: 'actions',
        header: '',
        enableSorting: false,
        accessorFn: (row) => row,
        size: 36,
        Cell: ({ cell }) => {
          const row = cell.getValue<WorkerApplicationWithWorkerInfo>()
          if (!doesWorkerAccountExistForWorkerApplication(row)) {
            return null
          }
          const worker = row.workerInfo
          const shiftInvitation = shiftInvitations.find(
            (invitation) => invitation.workerId === worker.id,
          )
          const isOnShift = workerShiftWorkerIdsSet?.has(worker.id)
          return (
            <DotMenu
              type="worker-application-actions"
              dotMenuKey={worker.id}
              disabled={isOnShift}
              style={{ marginRight: '12px' }}
              menuItems={[
                {
                  hidden: isOnShift,
                  title: 'Add to shifts',
                  onClick: handleAddToShifts(worker.id),
                  color: theme.colors.brand,
                  iconName: 'plus',
                },
                {
                  hidden:
                    !shiftInvitation ||
                    shiftInvitation.status === ShiftInvitationStatus.Rescinded,
                  title: 'Rescind invite',
                  onClick: handleRescindInvitation(shiftInvitation),
                  color: theme.colors.Red60,
                  iconName: 'block',
                },
                {
                  hidden:
                    shiftInvitation?.status !== ShiftInvitationStatus.Rescinded,
                  title: 'Invite again',
                  onClick: onInviteModalOpen({
                    worker,
                    workerApplicationId: row.id,
                    isInviteAgain: true,
                  }),
                  color: theme.colors.brand,
                  iconName: 'rotateLeft',
                },
              ]}
            />
          )
        },
      })
    }

    return columnsWithShift
  }, [
    isLoading,
    shiftInvitations,
    workerShiftWorkerIdsSet,
    shift,
    tz,
    handleAddToShifts,
    handleRescindInvitation,
    onInviteModalOpen,
  ])

  const table = useMaterialReactTable({
    columns,
    data,
    // enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableColumnFilters: true,
    enablePagination: false,
    enableSorting: true,
    enableColumnResizing: false,
    muiTableContainerProps: {
      sx: {
        overflow: 'scroll',
        minWidth: '900px',
      },
    },
    displayColumnDefOptions: {
      'mrt-row-numbers': {
        enableResizing: false, //allow the row numbers column to be resized
        size: 72,
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
      },
    },
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        '& .MuiCollapse-root': {
          width: '100%',
        },
      },
    },
    muiTableProps: {
      sx: {
        border: '0px',
        overflow: 'scroll',
      },
    },
    muiTableHeadRowProps: {
      sx: HEADER_ROW_STYLES,
    },
    muiTableHeadCellProps: {
      sx: HEADER_CELL_STYLES,
    },
    muiExpandAllButtonProps: {
      sx: {
        '& .MuiSvgIcon-root': {
          fontSize: '20px',
        },
      },
    },
    muiExpandButtonProps: {
      sx: {
        marginLeft: '12px',
      },
    },
    muiTableBodyCellProps: ({ row }) => ({
      sx: {
        ...CELL_COMMON_STYLES,
        background: row.getIsExpanded() ? theme.colors.Grey10 : 'transparent',
      },
    }),
    layoutMode: 'grid',
    muiDetailPanelProps: {
      sx: {
        width: '100%',
        borderLeft: `8px solid ${theme.colors.Violet30}`,
        padding: '0px',
        paddingLeft: '16px',
      },
    },
    renderDetailPanel: ({ row }) => {
      const isExpanded = row.getIsExpanded()
      useEffect(() => {
        if (isExpanded) {
          trackExpandWorkerApplication({
            ...commonAnalyticsParams,
            workerApplicationId: row.original.id,
            workerId: doesWorkerAccountExistForWorkerApplication(row.original)
              ? row.original.workerInfo.id
              : row.original.ghostProfileInfo.id,
          })
        }
      }, [trackExpandWorkerApplication, isExpanded])

      return (
        <ApplicationItemsSectionContainer
          applicationItemRow={row as MRT_Row<WorkerApplicationWithApplicant>}
          setSelectedWorkerApplicationItemId={onSelectWorkerApplicationItem(
            row.original,
          )}
        />
      )
    },
  })

  return (
    <>
      <Col gap={theme.space.sm}>
        <Row alignCenter justifyBetween>
          <Row alignCenter>
            <Text mr={theme.space.xxs} variant="h4">
              {shift ? 'Schedule Applications' : 'Role Applications'}
            </Text>
            {!!shift && (
              <DisableApplicationToggleButton
                shiftRequestId={shift?.shiftRequestId}
                roleId={roleId}
                analyticsSource={analyticsSource}
                analyticsParams={{
                  shiftRequestId: shift?.shiftRequestId,
                  roleId: shift?.roleId || roleId,
                  companyId: shift?.companyId || companyId,
                }}
              />
            )}
          </Row>

          <ViewApplicationDrawer
            isOpen={isApplicationDetailsDrawerOpen}
            onOpenDrawer={onOpenApplicationDetailsDrawer}
            onCloseDrawer={onCloseApplicationDetailsDrawer}
            shiftRequestId={shift?.shiftRequestId}
            roleId={roleId}
            companyId={shift?.companyId || companyId}
            analyticsSource={`${analyticsSource}-view-application-drawer`}
          />
        </Row>
        <MaterialReactTableNoToolbar table={table} />
      </Col>

      {addWorkerToShiftsModal.isOpen && shift && (
        <AddWorkerToShiftsModalContainer
          handleClose={handleAddToShiftsModalClose}
          isOpen={addWorkerToShiftsModal.isOpen}
          shift={shift}
          workerId={selectedRows[0]}
          analyticsSource={`${analyticsSource}-add-worker-to-shifts-modal`}
        />
      )}

      {workerForInviteModal && showInviteModal && shift && (
        <InviteToSingleShiftOrScheduleModal
          isOpen={showInviteModal}
          onClose={onInviteModalClose}
          shiftId={shift.shiftId}
          shiftRequestId={shift.shiftRequestId}
          shiftRequestParentId={shift.shiftRequestParentId}
          companyId={shift.companyId || companyId || ''}
          worker={workerForInviteModal}
          analyticsSource={`${analyticsSource}-invite-to-single-shift-or-schedule-modal`}
        />
      )}

      {workerApplicationForResultsDrawer && (
        <WorkerApplicationResultsDrawer
          isOpen={!!workerApplicationForResultsDrawer}
          onClose={onCloseWorkerApplicationResultsDrawer}
          workerApplication={workerApplicationForResultsDrawer}
          selectedWorkerApplicationItemId={selectedWorkerApplicationItemId}
          analyticsSource={`${analyticsSource}-worker-application-results-drawer`}
        />
      )}
    </>
  )
}
