import { Button, Col, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ApplicationItemAIResumeUploadRequest,
  ApplicationItemType,
} from '@traba/types'
import { ApplicationItemCreationAdditionalProps } from '../../types'
import { HelperInfoForApplicationItem } from '../HelperInfoForApplicationItem'

type CreateAndEditResumeUploadApplicationItemProps = {
  applicationItemId?: string
  onSave: (
    upsertApplicationItemRequest: ApplicationItemAIResumeUploadRequest & {
      applicationItemId?: string
    },
  ) => void
} & ApplicationItemCreationAdditionalProps

export function CreateAndEditResumeUploadApplicationItem({
  applicationItemId,
  onSave,
}: CreateAndEditResumeUploadApplicationItemProps) {
  const handleSave = () => {
    onSave({
      applicationItemType: ApplicationItemType.RESUME_UPLOAD,
      itemContent: {},
      applicationItemId,
    })
  }

  return (
    <Col gap={theme.space.med}>
      <HelperInfoForApplicationItem
        applicationItemType={ApplicationItemType.RESUME_UPLOAD}
      />
      <Row justifyEnd>
        <Button onClick={handleSave}>Save</Button>
      </Row>
    </Col>
  )
}
