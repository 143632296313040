import {
  Col,
  InfoRow,
  Row,
  SelectDropdown,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  APPLICATION_ITEM_TYPE_TO_TITLE,
  ApplicationItemType,
  UpsertApplicationItemRequestType,
} from '@traba/types'
import { useCallback, useState } from 'react'
import Divider from 'src/components/base/Divider'
import { ApplicationItemNumberAndTitle } from './ApplicationItemNumberAndTitle'
import { CreateAndEditApplicationItemContent } from './CreateAndEditApplicationItemContent/CreateAndEditApplicationItemContent'
import { useApplicationsAnalytics } from './hooks/useApplicationsAnalytics'
import { RemoveStepButton } from './RemoveStepButton'
import { StyledApplicationStepContainer } from './styles'
import { ApplicationItemCreationAdditionalProps } from './types'

type CreateNewApplicationItemProps = {
  applicationItemId?: string
  applicationItemType?: ApplicationItemType
  applicationItemSourceId?: string
  onSaveStep: (
    upsertApplicationItemRequest: UpsertApplicationItemRequestType,
  ) => void
  onRemoveStep?: () => void
  isLoading?: boolean
  stepIndex: number
  selectableApplicationItemTypes: ApplicationItemType[]
  analyticsSource?: string
} & ApplicationItemCreationAdditionalProps

export function CreateNewApplicationItem({
  applicationItemId,
  applicationItemType,
  applicationItemSourceId,
  onSaveStep,
  onRemoveStep,
  isLoading,
  stepIndex,
  selectableApplicationItemTypes,
  analyticsSource = 'create-new-application-item',
  ...additionalPropsForCreateAndEditApplicationItemContent
}: CreateNewApplicationItemProps) {
  const [selectedApplicationItemType, setSelectedApplicationItemType] =
    useState<ApplicationItemType | undefined>(applicationItemType)

  const { trackApplicationItemTypeSelectedForNewItem } =
    useApplicationsAnalytics()

  const handleSelectApplicationItemType = useCallback(
    (value: ApplicationItemType) => {
      setSelectedApplicationItemType(value)
      const { shiftRequest, role } =
        additionalPropsForCreateAndEditApplicationItemContent
      trackApplicationItemTypeSelectedForNewItem({
        applicationItemType: value,
        shiftRequestId: shiftRequest?.shiftRequestId,
        roleId: role?.roleId,
        companyId: role?.companyId,
        source: analyticsSource,
      })
    },
    [
      trackApplicationItemTypeSelectedForNewItem,
      additionalPropsForCreateAndEditApplicationItemContent.shiftRequest,
      additionalPropsForCreateAndEditApplicationItemContent.role,
    ],
  )

  return (
    <StyledApplicationStepContainer>
      <Row fullWidth justifyBetween alignCenter>
        <ApplicationItemNumberAndTitle
          stepNumber={stepIndex}
          applicationItemType={applicationItemType}
          titleVariant="h5"
          customApplicationItemText={
            applicationItemType ? undefined : 'New Application Step'
          }
        />

        {onRemoveStep && (
          <RemoveStepButton onRemoveStep={onRemoveStep} isLoading={isLoading} />
        )}
      </Row>

      <Col gap={theme.space.xxs}>
        <Text variant="body3">Select application step</Text>
        <SelectDropdown
          placeholder="Select application step"
          value={selectedApplicationItemType}
          handleSelect={handleSelectApplicationItemType}
          menuItems={selectableApplicationItemTypes.map((type) => ({
            value: type,
            label: APPLICATION_ITEM_TYPE_TO_TITLE[type],
          }))}
          disabled={!!applicationItemId}
        />
      </Col>

      {selectedApplicationItemType && (
        <>
          <Divider />

          <CreateAndEditApplicationItemContent
            applicationItemId={applicationItemId}
            applicationItemType={selectedApplicationItemType}
            applicationItemSourceId={applicationItemSourceId}
            onSave={onSaveStep}
            {...additionalPropsForCreateAndEditApplicationItemContent}
          />
        </>
      )}

      <InfoRow
        text="This item will NOT be added to the application until you save the application step"
        iconSize={18}
      />
    </StyledApplicationStepContainer>
  )
}
