import { Divider } from '@mui/material'
import { theme } from '@traba/theme'
import { WorkerOnSchedule } from '@traba/types'
import { anyToDate, formatShiftDateWithTimezone } from '@traba/utils'
import { useMemo, useState } from 'react'
import Avatar from '../base-components/Avatar/Avatar'
import { Badge } from '../base-components/Badge/Badge'
import { BadgeVariant } from '../base-components/Badge/Badge.styles'
import { Button, ButtonVariant } from '../base-components/Button/Button'
import { Col } from '../base-components/Col'
import { DotMenu } from '../base-components/DotMenu/DotMenu.ui'
import { Input } from '../base-components/Input/Input'
import Row from '../base-components/Row'
import { SvgIcon } from '../base-components/SvgIcon'
import { DataTable, TableRow } from '../base-components/Table/DataTable'
import { Text } from '../base-components/Text'
import { LinkText } from '../base-components/WorkerLinkText'

interface Props {
  scheduledWorkers: WorkerOnSchedule[]
  onClickWorker: (workerId: string) => void
  isFromOpsConsole: boolean
  onRemoveWorkers: (selectedWorkerIds: string[], shiftRequestId: string) => void
  onClickMessageWorker?: (workerId: string) => void
  isChatWidgetOpen?: boolean
  selectedWorkerId?: string
  isMobileViewOrReactNative?: boolean
}

const WorkerBadge = (worker: WorkerOnSchedule) => {
  return (
    <Text>
      {worker.workerShifts.every((ws) => ws.isCanceled) ? (
        <Badge variant={BadgeVariant.DARK_RED} title="CANCELED" />
      ) : (
        <Badge variant={BadgeVariant.INFO} title="SCHEDULED" />
      )}
    </Text>
  )
}

const NextShift = (worker: WorkerOnSchedule) => {
  return (
    <Text>
      {worker.nextShift && worker.workerShifts.length > 0
        ? formatShiftDateWithTimezone(
            anyToDate(worker.nextShift),
            worker.workerShifts[0].timezone,
          )
        : '-'}
    </Text>
  )
}

export const WorkersManagementTable = (props: Props) => {
  const {
    scheduledWorkers,
    onClickWorker,
    onRemoveWorkers,
    onClickMessageWorker,
    isChatWidgetOpen,
    selectedWorkerId,
    isMobileViewOrReactNative,
  } = props

  const workersTableHeaders = [
    { label: 'Worker name', key: 'name', sortable: true },
    { label: 'Status', key: 'status' },
    { label: 'Next shift', key: 'next-shift' },
    { label: 'Role', key: 'role', sortable: true },
    { label: 'Actions', key: 'actions' },
  ]

  const [selectedRows, setSelectedRows] = useState<TableRow[]>([])
  const [searchFilter, setSearchFilter] = useState('')

  const workersToDisplay = useMemo(() => {
    return scheduledWorkers
      ?.filter((worker) => {
        const name = worker.firstName + ' ' + worker.lastName
        return name.toLowerCase().includes(searchFilter.toLowerCase())
      })
      ?.filter((worker) => !!worker.nextShift)
  }, [scheduledWorkers, searchFilter])

  const workerTableRows = useMemo(() => {
    return workersToDisplay?.map((worker) => ({
      key: worker.workerId,
      cells: [
        {
          key: 'name',
          renderFn: () => (
            <Row alignCenter>
              <Avatar
                src={worker.photoUrl}
                name={`${worker.firstName} ${worker.lastName}`}
                size={40}
              />{' '}
              <LinkText
                variant="h6"
                style={{
                  marginLeft: theme.space.xs,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                onClick={() => onClickWorker(worker.workerId)}
              >
                {`${worker.firstName} ${worker.lastName}`}
              </LinkText>
            </Row>
          ),
        },
        {
          key: 'status',
          renderFn: () => <WorkerBadge {...worker} />,
        },
        {
          key: 'next-shift',
          renderFn: () => <NextShift {...worker} />,
        },
        {
          key: 'role',
          renderFn: () => <Text>{worker.longTermRole}</Text>,
        },
        {
          key: 'actions',
          renderFn: () => (
            <Row alignCenter gap={theme.space.xxs}>
              {onClickMessageWorker && (
                <Button
                  style={{ width: 48, height: 38 }}
                  onClick={() => {
                    onClickMessageWorker(worker.workerId)
                  }}
                  reverse
                  variant={
                    isChatWidgetOpen && selectedWorkerId === worker.workerId
                      ? ButtonVariant.FILLED
                      : ButtonVariant.OUTLINED
                  }
                >
                  <SvgIcon name="message" size={16} />
                </Button>
              )}
              <Button
                style={{ width: 48, height: 38 }}
                onClick={() => {
                  if (worker.longTermShiftRequestId) {
                    onRemoveWorkers(
                      [worker.workerId],
                      worker.longTermShiftRequestId,
                    )
                  }
                }}
                reverse
                variant={ButtonVariant.OUTLINED}
              >
                <SvgIcon name="reject" size={16} color={theme.colors.Grey60} />
              </Button>
            </Row>
          ),
        },
      ],
    }))
  }, [workersToDisplay])

  const menuItems = [
    {
      title: 'Remove workers',
      onClick: () => {
        if (scheduledWorkers[0].longTermShiftRequestId) {
          onRemoveWorkers(
            selectedRows.map((sr) => sr.key),
            scheduledWorkers[0].longTermShiftRequestId,
          )
        }
      },
    },
  ]

  return (
    <>
      <Text variant="h5" mt={theme.space.sm}>
        {scheduledWorkers?.[0].longTermRole}
      </Text>
      <Row alignCenter mb={theme.space.xxs} justifyBetween>
        <Row alignEnd gap={theme.space.xxs}>
          <Input
            placeholder="Search workers by name..."
            leftIconName="search"
            name="workerSearch"
            type="text"
            style={{ margin: 0 }}
            defaultValue=""
            width="300px"
            value={searchFilter}
            onChange={(e) => {
              e.preventDefault()
              setSearchFilter(e.target.value)
            }}
            onClear={() => setSearchFilter('')}
          />
        </Row>
        {!isMobileViewOrReactNative && (
          <DotMenu
            type="worker-management"
            dotMenuKey={'worker-management'}
            menuItems={menuItems}
            disabled={selectedRows.length === 0}
          />
        )}
      </Row>
      {isMobileViewOrReactNative ? (
        workersToDisplay.map((worker) => {
          const name = `${worker.firstName} ${worker.lastName}`
          return (
            <div key={worker.workerId}>
              <Row justifyBetween pb={theme.space.xs} pt={theme.space.xs}>
                <Row>
                  <Avatar src={worker.photoUrl} name={name} size={56} />
                  <Col>
                    <Row gap={theme.space.xxs}>
                      <div style={{ marginLeft: theme.space.xxs }}>
                        <LinkText
                          variant="h6"
                          onClick={() => onClickWorker(worker.workerId)}
                        >
                          {name}
                        </LinkText>
                      </div>
                      <WorkerBadge {...worker} />
                    </Row>
                    <Row alignCenter gap={theme.space.xxs} ml={theme.space.xxs}>
                      <Text>Next shift: </Text>
                      <NextShift {...worker} />
                    </Row>
                  </Col>
                </Row>

                <DotMenu
                  type="chat-menu"
                  menuItems={menuItems}
                  style={{
                    marginLeft: theme.space.xs,
                    height: 40,
                    width: 40,
                    padding: 0,
                  }}
                />
              </Row>
              <Divider />
            </div>
          )
        })
      ) : (
        <DataTable
          headers={workersTableHeaders}
          rows={workerTableRows ?? []}
          allowSelect
          selectedRows={selectedRows}
          onSelectRows={(selectedRows: TableRow[]) => {
            setSelectedRows(selectedRows)
          }}
        />
      )}
    </>
  )
}
