import { CircularProgress, Tooltip } from '@mui/material'
import { GridRowParams, MuiEvent } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid'
import {
  GridColDef,
  GridColTypeDef,
} from '@mui/x-data-grid/models/colDef/gridColDef'
import { Anchor, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { CompanyResponse, ShiftTag } from '@traba/types'
import { Invoice, InvoiceStatus } from '@traba/types'
import { Region } from '@traba/types'
import { useEffect, useMemo } from 'react'
import { Col, CopyTextIcon, Row } from 'src/components/base'
import Pagination from 'src/components/base/Pagination/Pagination'
import { useCompanies } from 'src/hooks/useCompanies'
import { useInvoices } from 'src/hooks/useInvoices'
import { useBasicPagination } from 'src/hooks/usePagination'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { getInvoiceStripeUrl } from 'src/utils/billingUtils'
import { getMoneyString } from 'src/utils/stringUtils'
import InvoiceStatusBadge from './InvoiceStatusBadge'

const DEFAULT_PAGE_SIZE = 15

type InvoicesResultsProps = {
  statuses: InvoiceStatus[]
  regions: Region[]
  companies: CompanyResponse[]
  tags: ShiftTag[]
  pods: string[]
  excludeCompanies: boolean
  showStatus?: boolean
  dueDateMax?: Date
}

function Loading() {
  return <CircularProgress size={20} />
}

const BaseRenderCell = ({
  value,
  title,
  style,
}: {
  value: React.ReactElement | string
  title?: string
  style?: React.CSSProperties
}) => (
  <Row fullWidth>
    <Tooltip title={title ?? value}>
      <div>
        <Text style={style}>{value}</Text>
      </div>
    </Tooltip>
  </Row>
)

export default function InvoicesResults(props: InvoicesResultsProps) {
  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    currentLocalOffset,
    setCurrentPage,
  } = useBasicPagination(DEFAULT_PAGE_SIZE)

  // Reset page when query changes
  useEffect(() => {
    setCurrentPage(0)
  }, [props.companies, props.regions, setCurrentPage])

  const { invoices, isFetching, invoiceCount } = useInvoices(
    {
      statuses: props.statuses,
      regions: props.regions,
      companies: props.companies,
      dueDateMax: props.dueDateMax,
      shiftTags: props.tags,
      excludeCompanies: props.excludeCompanies,
      pods: props.pods,
    },
    {
      startAt: currentLocalOffset,
      limit: DEFAULT_PAGE_SIZE,
    },
  )

  const { companies } = useCompanies({ isApproved: true })

  const companyIdToNames = useMemo(
    () =>
      companies
        ? Object.fromEntries(
            companies.map((company) => [company.id, company.employerName]),
          )
        : {},
    [companies],
  )

  const { getDateTime, getDate } = useTimezonedDates()

  const hideUnusedDraftColumns =
    props.statuses.length === 1 && props.statuses[0] === InvoiceStatus.DRAFT

  const COL_DEF_OPTIONS: GridColTypeDef = {
    sortable: false,
    flex: 1,
  }

  const invoiceTableColumns: GridColDef[] = [
    ...(!hideUnusedDraftColumns
      ? [
          {
            ...COL_DEF_OPTIONS,
            field: 'stripeInvoiceNumber',
            headerName: 'Invoice Number',
            renderCell: (params: any) => (
              <BaseRenderCell value={params.value} />
            ),
            minWidth: 130,
          },
        ]
      : []),
    {
      ...COL_DEF_OPTIONS,
      field: 'id',
      headerName: 'Invoice ID',
      renderCell: (params) => (
        <BaseRenderCell
          value={
            <Row gap={5}>
              <CopyTextIcon textToCopy={params.value} />
              {params.value}
            </Row>
          }
          title={params.value}
        />
      ),
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'companyId',
      headerName: 'Company',
      renderCell: (params) => (
        <BaseRenderCell
          value={
            <Anchor href={`/companies/${params.value}`} variant="link">
              {companyIdToNames[params.value]}
            </Anchor>
          }
          title={params.value}
        />
      ),
      minWidth: 200,
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'stripeId',
      headerName: 'Stripe ID',
      renderCell: (params) => (
        <BaseRenderCell
          value={
            <Row gap={5}>
              <CopyTextIcon textToCopy={params.value} />
              <Anchor href={getInvoiceStripeUrl(params.value)} variant="link">
                {params.value}
              </Anchor>
            </Row>
          }
          title={params.value}
        />
      ),
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'dateRange',
      headerName: 'Date Range',
      renderCell: (params) => {
        const result = `
          ${params.value.start ? getDate(params.value.start) : ''} - ${
            params.value.end ? getDate(params.value.end) : ''
          }`
        return <BaseRenderCell value={result} />
      },
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'group',
      headerName: 'Group',
      renderCell: (params) => (
        <BaseRenderCell
          value={params.value.name ?? '-'}
          title={params.value.id ?? '-'}
        />
      ),
    },
    ...(!hideUnusedDraftColumns
      ? [
          {
            ...COL_DEF_OPTIONS,
            field: 'dueDate',
            headerName: 'Due Date',
            renderCell: (params: any) => (
              <BaseRenderCell
                value={params.value ? getDate(params.value) : '-'}
              />
            ),
          },
        ]
      : []),
    {
      ...COL_DEF_OPTIONS,
      field: 'amount',
      headerName: 'Amount',
      renderCell: (params) => (
        <BaseRenderCell
          value={getMoneyString(params.value)}
          style={{ fontWeight: ' bold' }}
        />
      ),
      flex: 0.5,
    },
    ...(props.showStatus
      ? [
          {
            ...COL_DEF_OPTIONS,
            field: 'status',
            headerName: 'Status',
            renderCell: (params: any) => (
              <BaseRenderCell
                value={<InvoiceStatusBadge status={params.value} />}
              />
            ),
            flex: 0.5,
          },
        ]
      : []),
    {
      ...COL_DEF_OPTIONS,
      field: 'regions',
      headerName: 'Regions',
      renderCell: (params) => <BaseRenderCell value={params.value} />,
    },
    {
      ...COL_DEF_OPTIONS,
      field: 'createdAt',
      headerName: 'Created At',
      renderCell: (params) => (
        <BaseRenderCell value={getDateTime(params.value)} />
      ),
    },
  ]

  const makeInvoiceRow = (invoice: Invoice) => {
    return {
      id: invoice.invoiceId,
      stripeId: invoice.stripeInvoiceId,
      stripeInvoiceNumber: invoice.stripeInvoiceNumber,
      companyId: invoice.companyId,
      status: invoice.status,
      createdAt: invoice.createdAt,
      regions: invoice.regionIds?.join(', '),
      dateRange: {
        start: invoice.startDate,
        end: invoice.endDate,
      },
      group: {
        id: invoice.invoiceGroupId,
        name: invoice.invoiceGroupName,
      },
      dueDate: invoice.dueDate,
      amount: invoice.totalCharge,
    }
  }

  const rows = invoices ? invoices?.map(makeInvoiceRow) : []

  // Disable row click to invoice page if the click is on a link
  const handleRowClick = (row: GridRowParams<any>, event: MuiEvent<any>) => {
    if ((event.target as HTMLElement).tagName !== 'A') {
      row.row.id && window.open(`/billing/invoices/${row.row.id}`, '_blank')
    }
  }

  const invoiceTable = (
    <DataGrid
      rows={rows}
      columns={invoiceTableColumns}
      loading={isFetching}
      onRowClick={handleRowClick}
      autoHeight
      hideFooterPagination
      hideFooter
      disableColumnFilter
      disableRowSelectionOnClick
      sx={{
        borderRadius: 1,
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
          borderRight: '1px solid #f0f0f0',
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
      }}
    />
  )

  return (
    <Col>
      <Row justifyEnd>
        <Pagination
          page={currentPage}
          pageSize={DEFAULT_PAGE_SIZE}
          onPageLeft={goToPreviousPage}
          onPageRight={goToNextPage}
          dataSize={invoices ? invoices.length : 0}
          disabled={isFetching}
          totalFound={invoiceCount}
        />
      </Row>
      <Row mt={theme.space.xs} alignCenter justifyCenter>
        {isFetching ? <Loading /> : invoiceTable}
      </Row>
    </Col>
  )
}
