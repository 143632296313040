import { useMutation, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { FileType, useFileUploader } from '@traba/hooks'
import {
  WORKER_RESUMES_QUERY_KEY,
  WorkerResumeDto,
} from './useWorkerUploadedResumes'

async function generateResumeAiSummary(
  resumeId: string,
): Promise<WorkerResumeDto> {
  const response = await trabaApi.post(
    `/worker-resumes/${resumeId}/generate-summary`,
  )
  return response.data
}

async function uploadResume({
  workerId,
  downloadUrl,
}: {
  workerId: string
  downloadUrl: string
}): Promise<WorkerResumeDto> {
  const response = await trabaApi.post('/worker-resumes', {
    workerId,
    downloadUrl,
  })
  return response.data
}

export function useWorkerResumeUpload(workerId: string) {
  const { handleUpload } = useFileUploader()
  const { showError, showSuccess } = useAlert()
  const queryClient = useQueryClient()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (file: File) => {
      const url = await handleUpload({
        fileType: FileType.WORKER_RESUME,
        media: file,
        userId: workerId,
      })

      if (!url) {
        throw new Error('Failed to upload file')
      }

      return uploadResume({ workerId, downloadUrl: url })
    },
    onSuccess: () => {
      showSuccess('Resume uploaded successfully')
      queryClient.invalidateQueries({
        queryKey: [WORKER_RESUMES_QUERY_KEY, workerId],
      })
    },
    onError: () => showError('Failed to save resume'),
  })

  return {
    uploadResume: mutateAsync,
    isPending,
  }
}

export function useWorkerResumeGenerateSummary(workerId: string) {
  const { showError, showSuccess } = useAlert()
  const queryClient = useQueryClient()

  const generateAiSummaryMutation = useMutation({
    mutationFn: generateResumeAiSummary,
    onSuccess: () => {
      showSuccess('Resume ai summary generated successfully')
      queryClient.invalidateQueries({
        queryKey: [WORKER_RESUMES_QUERY_KEY, workerId],
      })
    },
    onError: () => showError('Failed to generate resume ai summary'),
  })

  return {
    generateSummary: generateAiSummaryMutation.mutate,
    isLoading: generateAiSummaryMutation.isPending,
  }
}
