import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  ActionStatus,
  OpsActionType,
  PaginatedResponse,
  WorkerWithActionItems,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'

async function getOpsOnboardingActionItems({
  page,
  actionTypes,
  actionStatuses,
  regionIds,
  firstName,
  lastName,
  employerName,
}: {
  page: unknown
  actionTypes: OpsActionType[]
  actionStatuses: ActionStatus[]
  regionIds: string[]
  firstName: string
  lastName: string
  employerName: string
}) {
  try {
    const payload = {
      page,
      ...(actionTypes.length > 0 && { actionTypes }),
      ...(actionStatuses.length > 0 && { actionStatuses }),
      ...(regionIds.length > 0 && { regionIds }),
      ...(firstName !== '' && { firstName }),
      ...(lastName !== '' && { lastName }),
      ...(employerName !== '' && { employerName }),
    }
    const response = await trabaApi.post(
      `/ops-required-actions/search`,
      payload,
    )
    return response.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

async function triggerActionItem(workerId: string, actionType: OpsActionType) {
  try {
    await trabaApi.patch(`/ops-required-actions/`, {
      workerId,
      actionType,
    })
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

async function clearActionItem(actionItemId: string) {
  try {
    await trabaApi.patch(`/ops-required-actions/clear`, {
      actionItemId,
    })
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export const useSearchOpsOnboardingActionItems = ({
  actionTypes,
  actionStatuses,
  regionIds,
  firstName,
  lastName,
  employerName,
  page,
}: {
  actionTypes: OpsActionType[]
  actionStatuses: ActionStatus[]
  regionIds: string[]
  firstName: string
  lastName: string
  employerName: string
  page: number
}) => {
  const { showError } = useAlert()
  const queryClient = useQueryClient()
  const { data, isFetching, isError, error } = useQuery<
    PaginatedResponse<WorkerWithActionItems>,
    AxiosError
  >({
    queryKey: [
      'ops-onboarding-action-items',
      actionTypes,
      actionStatuses,
      regionIds,
      firstName,
      lastName,
      employerName,
      page,
    ],
    queryFn: () =>
      // takes in base 0 page number but endpoint expects 1 based page number
      getOpsOnboardingActionItems({
        page: page + 1,
        actionTypes,
        actionStatuses,
        regionIds,
        firstName,
        lastName,
        employerName,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const { mutateAsync: actionItemMutation, isPending: isMutationPending } =
    useMutation<
      void,
      AxiosError,
      {
        workerId: string
        actionType: OpsActionType
      }
    >({
      mutationFn: async ({ workerId, actionType }) =>
        triggerActionItem(workerId, actionType),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['ops-onboarding-action-items'],
        })
      },
      onError: (error) => {
        showError(error.message, 'Failed to action on worker')
      },
    })

  const {
    mutateAsync: clearActionItemMutation,
    isPending: isClearActionItemPending,
  } = useMutation<
    void,
    AxiosError,
    {
      actionItemId: string
    }
  >({
    mutationFn: async ({ actionItemId }) => clearActionItem(actionItemId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ops-onboarding-action-items'],
      })
    },
    onError: (error) => {
      showError(error.message, 'Failed to clear action item')
    },
  })

  const opsOnboardingActionItems = data?.items || []

  if (isError) {
    showError(error.message, 'Failed to load ops onboarding actions')
  }

  return {
    isFetching,
    isError,
    opsOnboardingActionItems: opsOnboardingActionItems,
    actionItemMutation,
    isClearActionItemPending,
    clearActionItemMutation,
    isMutationPending,
    error,
  }
}
