import { theme } from '@traba/theme'
import Row from '../Row'
import FlagSpain from '../svgs/FlagSpain'
import FlagUSA from '../svgs/FlagUSA'
import { Text } from '../Text'
import { InputProps, Input } from './Input'
import { LanguagePrefixWrapper } from './Input.styles'

export type InputLanguagePrefixLanguageType = 'en' | 'es'

type InputLanguagePrefixProps = {
  language: InputLanguagePrefixLanguageType
}

type InputWithLanguagePrefixProps = InputLanguagePrefixProps & InputProps

const LANGUAGE_TO_FLAG_SVG_MAP = {
  en: FlagUSA,
  es: FlagSpain,
}

export function InputLanguagePrefix({ language }: InputLanguagePrefixProps) {
  const FlagComponent = LANGUAGE_TO_FLAG_SVG_MAP[language]

  return (
    <LanguagePrefixWrapper>
      <Row gap={theme.space.xxs} alignCenter>
        <FlagComponent />
        <Text variant="body2">{language.toUpperCase()}:</Text>
      </Row>
    </LanguagePrefixWrapper>
  )
}

export function InputWithLanguagePrefix({
  language,
  ...props
}: InputWithLanguagePrefixProps) {
  return (
    <Input
      {...props}
      containerStyle={{ marginTop: 0 }}
      style={{
        ...props.style,
        paddingLeft: theme.space.xxl,
      }}
      leftCustomComponent={
        language ? <InputLanguagePrefix language={language} /> : undefined
      }
    />
  )
}
