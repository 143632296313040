import { ONE_HUNDRED_DOLLARS_MONEY_DTO } from '@traba/consts'
import { Money } from '@traba/types'
import { ShiftPayType } from '@traba/types'
import { formatDuration } from 'src/shared/utils/dateUtils'
import { getMoneyString } from 'src/utils/stringUtils'

export function getPayRate({
  companyMin,
  hotSettingMin,
}: {
  companyMin: number | undefined
  hotSettingMin: number
}) {
  return companyMin ?? hotSettingMin
}

//Returns true if the shift is valid for all validation functions
export function validatePayRate({
  payType,
  payRate,
  minHourlyPayRate,
  numberOfUnits,
}: {
  payType: ShiftPayType
  payRate?: number
  minHourlyPayRate: number
  numberOfUnits?: number
}): boolean {
  if (payRate === undefined) {
    return false
  }
  if (payType === ShiftPayType.HOURLY) {
    // in the ops console we allow posting 0 dollar an hour shifts so allow the 0 case
    return payRate >= minHourlyPayRate || payRate === 0
  }
  if (payType === ShiftPayType.UNIT) {
    return payRate >= 0 && !!numberOfUnits
  }
  return false
}

//Returns true if the shift is valid for all validation functions
export function disabledMessageForInvalidPayRate({
  payType,
  payRate,
  minHourlyPayRate,
  numberOfUnits,
}: {
  payType: ShiftPayType
  payRate?: number
  minHourlyPayRate: number
  numberOfUnits?: number
}): string | undefined {
  if (payRate === undefined) {
    return 'Pay rate is required'
  }
  if (payType === ShiftPayType.HOURLY) {
    // in the ops console we allow posting 0 dollar an hour shifts so allow the 0 case
    if (payRate < minHourlyPayRate && payRate !== 0) {
      return 'Pay rate must be greater than or equal to the minimum hourly pay rate or $0'
    }
    return undefined
  }
  if (payType === ShiftPayType.UNIT) {
    if (payRate < 0) {
      return 'Pay rate must be greater than or equal to 0'
    }
    if (!numberOfUnits) {
      return 'Number of units is required'
    }
    return undefined
  }
  return 'Pay rate type is unrecognized'
}

export function validatePaidBackups({
  paidBackupSlotsRequested,
  paidBackupPayAmount,
  paidBackupPayAmountMax = ONE_HUNDRED_DOLLARS_MONEY_DTO,
}: {
  paidBackupSlotsRequested?: number
  paidBackupPayAmount?: number
  paidBackupPayAmountMax?: Money
}) {
  if (
    (paidBackupSlotsRequested &&
      (!paidBackupPayAmount ||
        paidBackupPayAmount <= 0 ||
        paidBackupPayAmount > paidBackupPayAmountMax.amount)) ||
    (paidBackupPayAmount && !paidBackupSlotsRequested)
  ) {
    return false
  }
  return true
}

export function validateUnprovenThreshold(unprovenThreshold?: number): boolean {
  if (unprovenThreshold && (unprovenThreshold > 1 || unprovenThreshold < 0)) {
    return false
  }
  return true
}

export function validateMinimumPaidTime(minimumPaidTime?: number): boolean {
  if (minimumPaidTime && minimumPaidTime < 0) {
    return false
  }
  return true
}

export function calculateEstimatedPay(
  payRate: number,
  payType: ShiftPayType,
  paidTimeInMinutes?: number,
  numberOfUnits?: number,
  slotsRequested?: number,
): { pay: number; payString: string } {
  let pay, payString
  switch (payType) {
    case ShiftPayType.HOURLY:
      if (paidTimeInMinutes === undefined) {
        throw new Error('paidTimeInMinutes required for hourly shift')
      }
      pay = payRate * (paidTimeInMinutes / 60)
      payString = `${getMoneyString(payRate)}/hr * ${formatDuration(
        paidTimeInMinutes,
      )} = $${pay.toFixed(2)}`
      break
    case ShiftPayType.UNIT:
      if (numberOfUnits === undefined) {
        throw new Error('numberOfUnits required for unit shift')
      }
      if (slotsRequested === undefined) {
        throw new Error('slots requested required for unit shift')
      }
      pay = (payRate * numberOfUnits) / slotsRequested
      payString = `$${payRate}/unit * ${numberOfUnits} units / ${slotsRequested} workers = $${pay.toFixed(
        2,
      )} per worker`
      break
  }
  return { pay, payString }
}
