import { IMenuItem } from '@traba/types'
import { useTags } from './useTags'

const POD_NAMESPACE = 'pods'

export function usePods({
  companyId,
  regionId,
}: {
  companyId?: string
  regionId?: string
} = {}) {
  const { tags, isSearchingTags } = useTags({
    namespace: POD_NAMESPACE,
    associations: companyId || regionId ? [{ companyId, regionId }] : undefined,
  })

  return {
    podsMenuItems:
      tags
        ?.map<IMenuItem>((tag) => ({
          label: tag.name,
          value: tag.id,
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { numeric: true }),
        ) ?? [],
    podTags: tags,
    isSearchingTags,
  }
}
