import { theme } from '@traba/theme'
import { Row } from 'src/components/base'
import Checkbox from 'src/components/base/Checkbox'

interface Props {
  checked: boolean
  label: string
  shouldWarnOnBypass?: boolean
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
}

export const BypassCheckboxItem = (props: Props) => {
  const { checked, onChange, label, shouldWarnOnBypass } = props
  return (
    <Row mb={theme.space.xxs}>
      <Checkbox
        checked={checked}
        label={label}
        onChange={onChange}
        textvariant={shouldWarnOnBypass ? 'error' : undefined}
      />
    </Row>
  )
}
