import { Tooltip } from '@mui/material'
import { LoadingSpinner, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { W9FormStatus } from '@traba/types'
import { useMemo } from 'react'
import { Dropdown, DropdownElement } from '../../../components/base/Dropdown'

export type W9FormStatusBadgeProps = {
  hasActiveAction: boolean
  onUpdateAction: (
    hasActiveAction: boolean,
    w9FormStatus?: W9FormStatus,
  ) => void
  w9FormStatus?: W9FormStatus
  isLoading?: boolean
}

const w9FormStatusToBadgeVariant: Record<W9FormStatus, string> = {
  [W9FormStatus.Verified]: 'success',
  [W9FormStatus.Requested]: 'warning',
  [W9FormStatus.Unverified]: 'danger',
  [W9FormStatus.Pending]: 'info',
}

const getDropdownOptions = (
  hasActiveAction: boolean,
  onUpdateAction: (
    hasActiveAction: boolean,
    w9FormStatus?: W9FormStatus,
  ) => void,
  status?: W9FormStatus,
  isLoading?: boolean,
): DropdownElement[] => {
  const isDisabled =
    !hasActiveAction &&
    (isLoading ||
      status === W9FormStatus.Verified ||
      status === W9FormStatus.Pending)

  let buttonLabel = 'Push In App'
  if (!hasActiveAction && !status) {
    buttonLabel = 'Request Form'
  } else if (hasActiveAction) {
    buttonLabel = 'Lift Action'
  }

  return [
    {
      label: buttonLabel,
      handleClick: () => onUpdateAction(hasActiveAction, status),
      disabled: isDisabled,
      icon: () => (
        <SvgIcon name={hasActiveAction ? 'unblock' : 'notification'} />
      ),
    },
  ]
}

export default function W9FormStatusBadge({
  w9FormStatus,
  hasActiveAction,
  onUpdateAction,
  isLoading,
}: W9FormStatusBadgeProps) {
  const badgeConfig = useMemo(() => {
    if (!w9FormStatus) {
      return {
        title: 'None',
        variant: 'danger',
      }
    }
    const formattedStatus =
      w9FormStatus.charAt(0) + w9FormStatus.slice(1).toLowerCase()
    const displayTitle = hasActiveAction ? 'Pushed' : formattedStatus
    const badgeVariant = w9FormStatusToBadgeVariant[w9FormStatus]

    return {
      title: displayTitle,
      variant: badgeVariant,
    }
  }, [hasActiveAction, w9FormStatus])

  const dropdownOptions = getDropdownOptions(
    hasActiveAction,
    onUpdateAction,
    w9FormStatus,
    isLoading,
  )

  if (isLoading) {
    return <LoadingSpinner style={{ height: 25, width: 25 }} />
  }

  return (
    <Tooltip
      title={hasActiveAction && w9FormStatus ? w9FormStatus : ''}
      arrow
      placement="left"
    >
      <div>
        <Dropdown
          orientation="right"
          dropdownOptions={dropdownOptions}
          listContainerStyling={{ width: '175px' }}
          listItemStyling={{
            alignItems: 'center',
            color: theme.colors.Violet80,
            fontWeight: '500',
            fontSize: 14,
          }}
          buttonStyling={{
            whiteSpace: 'nowrap',
            minWidth: 'fit-content',
          }}
          useBadge
          badgeVariant={badgeConfig.variant}
          title={badgeConfig.title}
        />
      </div>
    </Tooltip>
  )
}
