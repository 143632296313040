import { BadgeVariant, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerIncentive, WorkerIncentiveStatus } from '@traba/types'
import { addDays } from 'date-fns'
import { useMemo } from 'react'
import { useWorkerIncentiveAuditLogs } from 'src/hooks/useIncentives'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import { getMoneyString } from 'src/utils/stringUtils'
import { extractProgressFromArray } from 'src/utils/workerIncentiveUtils'
import { Badge, Col, CopyTextIcon, Link } from '../base'
import { CircularProgress } from '../base/CircularProgress/CircularProgress'
import {
  DataTable,
  DataTableHeader,
  TableCell,
  TableRow,
} from '../base/Table/DataTable'
import TransferStatusBadge from '../TransferStatusBadge'

type PendingClockOutsItemTableProps = {
  pendingIncentive: WorkerIncentive
  collapsed: boolean
}

const WORKER_INCENTIVE_STATUS_TO_BADGE_VARIANT: Map<
  WorkerIncentiveStatus,
  BadgeVariant
> = new Map([
  [WorkerIncentiveStatus.Complete, BadgeVariant.SUCCESS],
  [WorkerIncentiveStatus.Failed, BadgeVariant.DANGER],
  [WorkerIncentiveStatus.Reversed, BadgeVariant.WARNING],
  [WorkerIncentiveStatus.InProgress, BadgeVariant.INFO],
])

export function PendingIncentiveItemTable({
  pendingIncentive,
  collapsed,
}: PendingClockOutsItemTableProps) {
  const beginningOfToday = new Date(new Date().setHours(0, 0, 0, 0))
  const beginningOfTomorrow = addDays(beginningOfToday, 1).toISOString()

  const { auditLogs, isLoading: auditLogsLoading } =
    useWorkerIncentiveAuditLogs({
      workerId: pendingIncentive.workerId,
      workerIncentiveId: pendingIncentive.id,
      after: pendingIncentive.incentiveInfo.startTime.toISOString(),
      before: beginningOfTomorrow,
      enabled: !collapsed,
    })

  const headers: DataTableHeader[] = [
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'previousStatus',
      label: 'Previous Status',
    },
    {
      key: 'updatedStatus',
      label: 'Updated Status',
    },
    {
      key: 'progress',
      label: 'Progress',
    },
    {
      key: 'changedShifts',
      label: 'Changed Shifts',
    },
  ]

  const tableRows: TableRow[] = useMemo(
    () =>
      auditLogs?.map((al) => {
        const createdAt = al.createdAt
        const { previousStatus, updatedStatus, changedShifts, progress } =
          al.properties
        const tableCells: TableCell[] = [
          {
            key: `${al.auditLogId}date`,
            renderFn: () => formatDateTimeWithTimezone(createdAt),
            sortKey: al.createdAt.toString(),
          },
          {
            key: `${al.auditLogId}previousStatus`,
            renderFn: () =>
              previousStatus ? (
                <Badge
                  variant={WORKER_INCENTIVE_STATUS_TO_BADGE_VARIANT.get(
                    previousStatus,
                  )}
                  title={previousStatus}
                  style={{ minWidth: 80, height: 25, borderRadius: 8 }}
                />
              ) : (
                '-'
              ),
          },
          {
            key: `${al.auditLogId}updatedStatus`,
            renderFn: () => (
              <Badge
                variant={WORKER_INCENTIVE_STATUS_TO_BADGE_VARIANT.get(
                  updatedStatus,
                )}
                title={updatedStatus}
                style={{ minWidth: 80, height: 25, borderRadius: 8 }}
              />
            ),
          },
          {
            key: `${al.auditLogId}progress`,
            renderFn: () =>
              progress ? extractProgressFromArray(progress) : '-',
          },
          {
            key: `${al.auditLogId}changedShifts`,
            renderFn: () =>
              changedShifts.map((cs) => (
                <div key={`${al.auditLogId}-${cs}`}>
                  <Link
                    to={`/field-monitor/${cs}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cs}
                  </Link>
                  <CopyTextIcon textToCopy={cs} />
                </div>
              )),
          },
        ]
        return {
          cells: tableCells,
          key: al.auditLogId,
        }
      }) || [],
    [auditLogs],
  )

  const transactionHeaders: DataTableHeader[] = [
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'id',
      label: 'Id',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'amount',
      label: 'Amount',
    },
  ]

  const transactions = pendingIncentive.transactions
  const transactionTableRows: TableRow[] = useMemo(
    () =>
      transactions?.map((t) => {
        const createdAt = new Date(t.created)
        const tableCells: TableCell[] = [
          {
            key: t.id + 'date',
            renderFn: () => formatDateTimeWithTimezone(createdAt),
            sortKey: Date.parse(t.created.toString()),
          },
          {
            key: t.id + 'type',
            renderFn: () => t.type,
          },
          {
            key: t.id + 'id',
            renderFn: () => t.id,
          },
          {
            key: t.id + 'status',
            renderFn: () => <TransferStatusBadge transferStatus={t.status} />,
          },
          {
            key: t.id + 'amount',
            renderFn: () => getMoneyString(t.amount / 100),
          },
        ]
        return {
          cells: tableCells,
          key: t.id,
        }
      }) || [],
    [transactions],
  )

  if (collapsed) {
    return null
  }

  return (
    <Col mt={theme.space.sm}>
      {auditLogsLoading ? (
        <CircularProgress size="small" />
      ) : tableRows.length > 0 ? (
        <DataTable headers={headers} rows={tableRows} />
      ) : (
        <Text>No logs recorded</Text>
      )}
      {transactionTableRows.length > 0 && (
        <DataTable headers={transactionHeaders} rows={transactionTableRows} />
      )}
    </Col>
  )
}
