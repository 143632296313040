import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { ApplicationItem, LanguageContentObj } from '@traba/types'
import { captureSentryError } from '@traba/utils'

export const OPS_MANUAL_STEP_BY_APPLICATION_ITEM_ID_QUERY_KEY =
  'ops-manual-step-by-application-item-id'

export type OpsManualStepResponse = {
  id: string
  parsedWorkerAppTitle: LanguageContentObj[]
  parsedWorkerAppDescription?: LanguageContentObj[]
  opsConsoleTitle: string
  opsConsoleDescription: string
  applicationItem?: ApplicationItem
  opsInputNotes?: string
}

async function getOpsManualStepByApplicationItemId(
  applicationItemId: string,
): Promise<OpsManualStepResponse | undefined> {
  try {
    const res = await trabaApi.get(
      `ops-manual-action/application-item/${applicationItemId}`,
    )

    return res.data
  } catch (error) {
    console.error(
      'useOpsManualStepByApplicationItemId -> getOpsManualStepByApplicationItemId() ERROR',
      error,
    )
    captureSentryError(error, {
      tags: {
        action:
          'useOpsManualStepByApplicationItemId -> getOpsManualStepByApplicationItemId() ERROR',
      },
    })
    throw error
  }
}

export function useOpsManualStepByApplicationItemId(applicationItemId: string) {
  const {
    isLoading,
    isError,
    data: opsManualStep,
    error,
    isFetched,
    refetch,
  } = useQuery<OpsManualStepResponse | undefined, Error>({
    queryKey: [
      OPS_MANUAL_STEP_BY_APPLICATION_ITEM_ID_QUERY_KEY,
      applicationItemId,
    ],
    queryFn: () => getOpsManualStepByApplicationItemId(applicationItemId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!applicationItemId,
  })

  return {
    isLoading,
    isError,
    opsManualStep,
    error,
    isFetched,
    refetch,
  }
}
