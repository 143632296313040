import { Text } from '@traba/react-components'
import { Badge, BadgeVariant } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { CancellationBusinessChargeType } from '@traba/types'
import { Row } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { FunnyMoney } from 'src/components/CostSummary/FunnyMoney'
import { PriceLine } from 'src/components/PriceLine'
import { getMoneyString } from 'src/utils/stringUtils'

export default function PaidCancellationSummary(props: {
  numberWorkersToAppease: number
  numberOfWorkersToChargeBusinessFor: number
  baseWorkerPay: number
  baseBusinessCharge: number
  baseBusinessHoursCharged: number
  totalBusinessCharge?: number
  totalWorkerPay?: number
  cancellationBusinessTimeWindow?: number
  cancellationBusinessChargeType: CancellationBusinessChargeType
  cancellationChargeSummary?: string
  shouldWaiveCancellationFees: boolean
}) {
  const {
    numberWorkersToAppease,
    numberOfWorkersToChargeBusinessFor,
    baseBusinessHoursCharged,
    baseWorkerPay,
    totalWorkerPay,
    baseBusinessCharge,
    totalBusinessCharge,
    cancellationBusinessChargeType,
    cancellationChargeSummary,
    shouldWaiveCancellationFees,
  } = props
  return (
    <>
      {/* Worker Payment */}
      <div>
        <Text
          variant="h6"
          style={{
            fontSize: '14px',
            fontWeight: 700,
            marginTop: 40,
          }}
        >
          Worker Payout
        </Text>

        <Divider
          wrapperStyle={{
            marginTop: '12px',
            marginBottom: '12px',
          }}
        />

        <PriceLine label={'WORKERS SCHEDULED'}>
          {numberWorkersToAppease} worker
          {makePlural(numberWorkersToAppease)}
        </PriceLine>

        <Divider
          wrapperStyle={{
            marginTop: '12px',
            marginBottom: '12px',
          }}
        />

        <PriceLine label={'PAYOUT PER WORKER'}>
          <Badge
            variant={BadgeVariant.OPAQUE_RED}
            title={'FIXED'}
            style={{ marginRight: 10 }}
          />

          {getMoneyString(baseWorkerPay)}
        </PriceLine>

        {totalWorkerPay !== undefined && (
          <>
            <Divider
              wrapperStyle={{
                marginTop: '12px',
                marginBottom: '12px',
              }}
            />

            <PriceLine label={'TOTAL WORKER PAYOUT'}>
              <div>
                <FunnyMoney amount={totalWorkerPay} superSize={true} />
              </div>
            </PriceLine>
          </>
        )}
      </div>

      {/* Business Charge */}
      <Text
        variant="h6"
        style={{
          fontSize: '14px',
          fontWeight: 700,
          marginTop: 40,
        }}
      >
        Charge to Business
      </Text>

      <Divider
        wrapperStyle={{
          marginTop: '12px',
          marginBottom: '12px',
        }}
      />

      <PriceLine label={'WORKERS SCHEDULED'}>
        {numberOfWorkersToChargeBusinessFor} worker
        {makePlural(numberOfWorkersToChargeBusinessFor)}
      </PriceLine>

      <Divider
        wrapperStyle={{
          marginTop: '12px',
          marginBottom: '12px',
        }}
      />

      <PriceLine label={'CHARGE PER WORKER'}>
        <div style={{ display: 'flex' }}>
          {cancellationChargeSummary && (
            <Badge
              variant={BadgeVariant.OPAQUE_RED}
              title={
                cancellationBusinessChargeType ===
                CancellationBusinessChargeType.Hourly
                  ? `${cancellationChargeSummary}`
                  : `${baseBusinessHoursCharged} HR (${cancellationChargeSummary})`
              }
              style={{ marginRight: 10 }}
            />
          )}
          <Text
            variant="h7"
            style={{
              marginLeft: '8px',
              textDecoration: shouldWaiveCancellationFees ? 'line-through' : '',
            }}
          >
            {getMoneyString(baseBusinessCharge)}
          </Text>
          {shouldWaiveCancellationFees && (
            <Text
              variant="h7"
              style={{
                marginLeft: '8px',
              }}
            >
              {getMoneyString(0)}
            </Text>
          )}
        </div>
      </PriceLine>

      {totalBusinessCharge !== undefined && (
        <>
          <Divider
            wrapperStyle={{
              marginTop: '12px',
              marginBottom: '12px',
            }}
          />

          <PriceLine label={'TOTAL CHARGE TO BUSINESS'}>
            <Row>
              <FunnyMoney
                strikeThrough={shouldWaiveCancellationFees}
                amount={totalBusinessCharge}
                superSize={true}
              />
              {shouldWaiveCancellationFees && (
                <FunnyMoney
                  amount={0}
                  superSize={true}
                  style={{ marginLeft: 8 }}
                />
              )}
            </Row>
          </PriceLine>
        </>
      )}
    </>
  )
}
