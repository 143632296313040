import {
  CheckboxImage,
  Col,
  LoadingSpinner,
  RadioButton,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  QUALIFICATION_QUESTION_TYPE_TO_TEXT,
  QualificationQuestionAnswerQuestionType,
} from '@traba/types'
import { getEnglishContent, isAnswerOptionSelected } from '@traba/utils'
import {
  useQualifierQuestionAnswerById,
  useWorkerAnswersByWorkerApplicationItemId,
} from 'src/hooks/useQualifierQuestionAnswer'
import { HyperlinkLocalizedText } from '../../HyperlinkLocalizedText'
import { StyledAnswerText } from '../../styles'

interface CustomQuestionApplicationItemResultsProps {
  workerApplicationItemId: string
  applicationItemSourceId: string
}

export function CustomQuestionApplicationItemResults({
  workerApplicationItemId,
  applicationItemSourceId,
}: CustomQuestionApplicationItemResultsProps) {
  const {
    qualifierQuestionAnswer,
    isLoading: isLoadingQualifierQuestionAnswer,
  } = useQualifierQuestionAnswerById(applicationItemSourceId)
  const isAcknowledgment =
    qualifierQuestionAnswer?.questionType ===
    QualificationQuestionAnswerQuestionType.ACKNOWLEDGMENT

  const { workerAnswers, isLoading: isLoadingWorkerAnswers } =
    useWorkerAnswersByWorkerApplicationItemId(workerApplicationItemId)

  if (isLoadingWorkerAnswers || isLoadingQualifierQuestionAnswer) {
    return <LoadingSpinner />
  }

  const questionTitle = getEnglishContent(
    qualifierQuestionAnswer?.parsedQuestionTitle,
  )
  const questionDescription = getEnglishContent(
    qualifierQuestionAnswer?.parsedQuestionDescription,
  )
  const answerOptions = qualifierQuestionAnswer?.parsedAnswerOptions?.map(
    (answerOption) => ({
      label: getEnglishContent(answerOption),
      id: getEnglishContent(answerOption),
      isSelected: isAcknowledgment
        ? workerAnswers?.response?.[0] === 'true'
        : isAnswerOptionSelected(answerOption, workerAnswers?.response) ||
          false,
    }),
  )

  return (
    <Col gap={theme.space.med}>
      {qualifierQuestionAnswer && (
        <>
          <Col gap={theme.space.xxs}>
            <Text variant="body3">QUESTION TYPE</Text>
            <Col gap={theme.space.xxxs}>
              <Text variant="body1">
                {
                  QUALIFICATION_QUESTION_TYPE_TO_TEXT[
                    qualifierQuestionAnswer.questionType
                  ]
                }
              </Text>
            </Col>
          </Col>
          <Col gap={theme.space.xxs}>
            <Text variant="body3">QUESTION</Text>
            <Col gap={theme.space.xxxs}>
              <HyperlinkLocalizedText
                text={questionTitle || ''}
                variant="body1"
                linkColor={theme.colors.brand}
              />

              {questionDescription && (
                <HyperlinkLocalizedText
                  text={questionDescription}
                  variant="body1"
                  linkColor={theme.colors.brand}
                />
              )}
            </Col>
          </Col>
        </>
      )}

      <Col gap={theme.space.xxs}>
        <Text variant="body3">WORKER ANSWER</Text>
        {!!answerOptions && answerOptions.length > 0 ? (
          <>
            {answerOptions.map((answerOption) => {
              let prefixComponent = null
              switch (qualifierQuestionAnswer?.questionType) {
                case QualificationQuestionAnswerQuestionType.SINGLE_CHOICE:
                  prefixComponent = (
                    <RadioButton selected={answerOption.isSelected} />
                  )
                  break
                case QualificationQuestionAnswerQuestionType.MULTIPLE_CHOICE:
                case QualificationQuestionAnswerQuestionType.ACKNOWLEDGMENT:
                  prefixComponent = (
                    <CheckboxImage
                      checkboxContainerSize="24px"
                      checkboxIconSize={16}
                      checkboxIconColor={theme.colors.Violet60}
                      checkboxContainerBackgroundColor={theme.colors.Violet20}
                      checked={answerOption.isSelected}
                    />
                  )
                  break
              }

              return (
                <Row key={answerOption.id} alignCenter gap={theme.space.xs}>
                  {prefixComponent}
                  <HyperlinkLocalizedText
                    text={answerOption.label || ''}
                    textColor={
                      answerOption.isSelected
                        ? theme.colors.MidnightBlue
                        : theme.colors.Grey40
                    }
                    linkColor={theme.colors.brand}
                    variant="body1"
                    style={{
                      fontSize: answerOption.isSelected ? '18px' : '14px',
                    }}
                  />
                </Row>
              )
            })}
          </>
        ) : workerAnswers?.response && workerAnswers?.response?.length > 0 ? (
          <StyledAnswerText isSelected>
            {workerAnswers?.response.join(', ')}
          </StyledAnswerText>
        ) : (
          <StyledAnswerText>No answers found</StyledAnswerText>
        )}
      </Col>
    </Col>
  )
}
