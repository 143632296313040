import { Drawer, LinearProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerSearchParamsRaw } from '@traba/types'
import { useState } from 'react'
import { Button } from '../../../../components/base'
import { buildBugleSearchParams } from '../../../../components/BugleDrawer/hooks/useBugleForm'
import { workerSearchRaw } from '../../../../hooks/searchWorkersApi'
import { SortOrder, useBasicPagination } from '../../../../hooks/usePagination'

import { extractArrayValues } from '../../../../hooks/useWorkers'
import { downloadBlob } from '../../../../utils/helperUtils'
import { PopulatedWorker } from '../../worker-search.types'
import * as S from './styles'
import { convertToCSV, getExportMessage, getLinearProgressValue } from './utils'

const LIMIT = 200

type ExportBugleDataDrawerProps = {
  isOpen: boolean
  onClose: () => void
  bugleParams: BugleSearchParamsProps
}

type BugleSearchParamsProps = {
  parameters: WorkerSearchParamsRaw
  targetShiftId?: string
  savedSearchId?: string
  targetCompanyId?: string
}

const ExportBugleDataDrawer = ({
  isOpen,
  onClose,
  bugleParams: { parameters, savedSearchId, targetShiftId, targetCompanyId },
}: ExportBugleDataDrawerProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [totalWorkers, setTotalWorkers] = useState(0)

  const { currentPage, setCurrentPage } = useBasicPagination()

  let offset = 0
  let totalCount = 0
  let workers: PopulatedWorker[] = []

  const handleExport = async () => {
    try {
      setIsLoading(true)

      // rewrite this using do while loop

      while (true) {
        const searchData = extractArrayValues(
          buildBugleSearchParams(
            parameters,
            targetShiftId,
            savedSearchId,
            targetCompanyId,
          ),
        )

        // Since saved searches do not have a total count, we need to fetch all the workers in a single request and return early
        if (savedSearchId) {
          if (
            !window.confirm(
              'Are you sure you want to export this list of workers?',
            )
          ) {
            setIsLoading(false)
            return
          }
          const { workers: result } = await workerSearchRaw(searchData, {
            sortBy: 'createdAt',
            sortOrder: SortOrder.asc,
          })
          workers = result
          break
        }

        const { workers: result, count } = await workerSearchRaw(
          searchData,
          {
            limit: LIMIT,
            offset,
            sortBy: 'createdAt',
            sortOrder: SortOrder.asc,
          },
          {
            worker: [
              'id',
              'createdAt',
              'updatedAt',
              'accessToVehicle',
              'acquisitionSource',
              'deviceId',
              'email',
              'firstName',
              'lastName',
              'phoneNumber',
              'photoUrl',
              'referralCode',
              'referredBy',
              'regionId',
              'stripeAccountId',
              'zipCode',
              'zipCodeGeohash',
              'lastActiveAt',
              'reportedGender',
              'opsReportedGender',
              'isProfilePhotoValid',
            ],
          },
        )

        workers = [...workers, ...result]
        totalCount = count

        setCurrentPage(Math.ceil(offset / LIMIT) + 1)
        setTotalWorkers(count)

        if (workers.length >= totalCount) {
          break
        }

        offset += LIMIT
        await new Promise((resolve) => setTimeout(resolve, 1500))
      }

      const csv = convertToCSV(workers)
      downloadBlob(csv, 'workers.csv')

      workers = []
      window.analytics.track('Workers Search List Exported', {
        parameters,
        savedSearchId,
        targetShiftId,
        targetCompanyId,
      })
    } catch (error) {
      console.log('Error exporting workers:', error)
    } finally {
      workers = []
      setIsLoading(false)
    }
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <S.DrawerContainer>
        <Text variant="h4" my={theme.space.xs}>
          Export workers to CSV
        </Text>
        <Text variant="body1" mb={theme.space.med}>
          This will generate a file with the total number of workers found with
          the set params in the search
        </Text>

        <S.DrawerBody>
          <Text variant="label" mb={theme.space.xxs}>
            {getExportMessage({
              currentPage,
              totalRecords: totalWorkers,
              isExporting: isLoading,
              limit: LIMIT,
              savedSearchId,
              entity: 'workers',
            })}
          </Text>
          <div style={{ backgroundColor: 'green' }}>
            {isLoading && (
              <LinearProgress
                variant="determinate"
                value={getLinearProgressValue(currentPage, totalWorkers, LIMIT)}
                style={{ height: 10 }}
              />
            )}
          </div>
          <Button
            onClick={handleExport}
            loading={isLoading}
            style={{ width: 180 }}
            mt={theme.space.xs}
          >
            {isLoading ? 'Exporting...' : 'Export'}
          </Button>
        </S.DrawerBody>
      </S.DrawerContainer>
    </Drawer>
  )
}

export default ExportBugleDataDrawer
