import { CircularProgress, IconButton, List } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useEffect, useRef } from 'react'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { Col, Row } from '../../../../components/base'
import WorkerCard from '../../../../components/WorkerCard/WorkerCard'
import * as S from './styles'
import { WorkerListItem } from './WorkerListItem'

const TipsCard = () => (
  <Col mt={theme.space.lg} px={theme.space.xxs}>
    <Text variant="h5">Tips:</Text>
    <ul style={{ paddingLeft: theme.space.xs }}>
      <Text mt={theme.space.xxs}>
        <li>Select the workers you want to add to the shift</li>
        <li>Mouse over a worker in the list to see more details</li>
        <li>You can click on the worker avatar to expand it</li>
      </Text>
    </ul>
  </Col>
)
interface SearchResultsProps {
  workersSearchResult: PopulatedWorker[]
  checkedWorkers: PopulatedWorker[]
  hoverWorker: PopulatedWorker | undefined
  totalFound: number
  searchLimit: number
  disableAvatarClick?: boolean
  setFocusedWorker: (worker: PopulatedWorker) => void
  setHoverWorker: (worker: PopulatedWorker | undefined) => void
  handleWorker: (worker: PopulatedWorker) => void
  onClose: () => void
  isLoading?: boolean
  openInline?: boolean
  top?: string
}

const SearchResults = ({
  workersSearchResult,
  checkedWorkers,
  setFocusedWorker,
  setHoverWorker,
  hoverWorker,
  totalFound,
  searchLimit,
  handleWorker,
  onClose,
  disableAvatarClick,
  isLoading,
  openInline,
  top,
}: SearchResultsProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClose])

  return (
    <S.ResultsWrapper ref={ref} openInline={openInline} top={top}>
      <S.SearchResultsContainer openInline={openInline}>
        <Row justifyBetween pr={theme.space.xs}>
          <Row alignCenter>
            <Text variant="h6" mx={theme.space.xs} my={theme.space.xxs}>
              {searchLimit > totalFound
                ? `Found ${totalFound} workers`
                : `Showing the first ${searchLimit} workers out of ${totalFound} found`}
            </Text>
            {isLoading && <CircularProgress size={16} />}
          </Row>
          <IconButton
            onClick={onClose}
            style={{
              fontSize: 14,
              color: theme.colors.Violet,
              fontWeight: 600,
            }}
          >
            close
          </IconButton>
        </Row>
        <List sx={{ maxHeight: '90%', overflow: 'auto' }}>
          {workersSearchResult.map((worker: PopulatedWorker, i: number) => (
            <WorkerListItem
              key={worker.id}
              worker={worker}
              handleSelection={handleWorker}
              workerIsSelected={checkedWorkers.some((w) => w.id === worker.id)}
              onAvatarClick={!disableAvatarClick ? setFocusedWorker : undefined}
              onWorkerHover={setHoverWorker}
              index={i}
            />
          ))}
        </List>
      </S.SearchResultsContainer>
      <S.WorkerCardContainer openInline={openInline}>
        {hoverWorker ? <WorkerCard worker={hoverWorker} /> : <TipsCard />}
      </S.WorkerCardContainer>
    </S.ResultsWrapper>
  )
}

export { SearchResults }
