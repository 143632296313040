import { Col } from '@traba/react-components'
import {
  ApplicationItemContent as ApplicationItemContentType,
  ApplicationItemType,
  UpsertApplicationItemRequestType,
} from '@traba/types'
import { ApplicationItemCreationAdditionalProps } from '../types'
import { CreateAndEditCustomQuestionApplicationItem } from './items/CreateAndEditCustomQuestionApplicationItem'
import { CreateAndEditOpsManualStepApplicationItem } from './items/CreateAndEditOpsManualStepApplicationItem'
import { CreateAndEditResumeUploadApplicationItem } from './items/CreateAndEditResumeUploadApplicationItem'
import { CreateAndEditWorkerVettingCallApplicationItem } from './items/CreateAndEditWorkerVettingCallApplicationItem'

type CreateAndEditApplicationItemContentProps = {
  applicationItemId?: string
  applicationItemType: ApplicationItemType
  applicationItemSourceId?: string
  onSave: (
    upsertApplicationItemRequest: UpsertApplicationItemRequestType,
  ) => void
  itemContent?: ApplicationItemContentType
} & ApplicationItemCreationAdditionalProps

export const CreateAndEditApplicationItemContent = ({
  applicationItemId,
  applicationItemType,
  applicationItemSourceId,
  onSave,
  itemContent,
  ...additionalPropsForCreateAndEditApplicationItemContent
}: CreateAndEditApplicationItemContentProps) => {
  switch (applicationItemType) {
    case ApplicationItemType.AI_VETTING_CALL:
      return (
        <CreateAndEditWorkerVettingCallApplicationItem
          applicationItemId={applicationItemId}
          applicationItemSourceId={applicationItemSourceId}
          onSave={onSave}
          itemContent={itemContent}
          {...additionalPropsForCreateAndEditApplicationItemContent}
        />
      )
    case ApplicationItemType.OPS_MANUAL_STEP:
      return (
        <CreateAndEditOpsManualStepApplicationItem
          applicationItemId={applicationItemId}
          itemContent={itemContent}
          onSave={onSave}
        />
      )
    case ApplicationItemType.RESUME_UPLOAD:
      return (
        <CreateAndEditResumeUploadApplicationItem
          applicationItemId={applicationItemId}
          onSave={onSave}
        />
      )
    case ApplicationItemType.CUSTOM_QUESTION:
      return (
        <CreateAndEditCustomQuestionApplicationItem
          applicationItemId={applicationItemId}
          applicationItemSourceId={applicationItemSourceId}
          onSave={onSave}
          itemContent={itemContent}
          {...additionalPropsForCreateAndEditApplicationItemContent}
        />
      )
    default:
      return <Col>Nothing to display</Col>
  }
}
