import Drawer from '@mui/material/Drawer'
import {
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  Col,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { doesWorkerAccountExistForWorkerApplication } from '@traba/types'
import { AccountStatusBadge, Icon } from 'src/components/base'
import Divider from 'src/components/base/Divider'
import { PhoneLinkAndCopyButton } from 'src/screens/ShiftDetailsScreen/components/PhoneLinkAndCopyButton'
import {
  WorkerApplicationResults,
  WorkerApplicationResultsProps,
} from './WorkerApplicationResults'

type WorkerApplicationResultsDrawerProps = {
  isOpen: boolean
  onClose: () => void
} & WorkerApplicationResultsProps

export function WorkerApplicationResultsDrawer({
  isOpen,
  onClose,
  ...workerApplicationResultsProps
}: WorkerApplicationResultsDrawerProps) {
  const workerApplication = workerApplicationResultsProps.workerApplication

  const workerExists =
    doesWorkerAccountExistForWorkerApplication(workerApplication)

  const workerInfo = workerExists
    ? workerApplication.workerInfo
    : workerApplication.ghostProfileInfo

  const accountApprovalStatus = workerExists
    ? workerApplication?.workerInfo?.accountApprovalStatus
    : undefined

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '880px',
            maxWidth: '75%',
            boxShadow: `-10px 0 15px -5px ${theme.colors.Violet20}`,
          },
        }}
      >
        <Col p={theme.space.sm} gap={theme.space.xs} style={{ height: '100%' }}>
          <Row justifyBetween alignCenter>
            <Button
              style={{ padding: theme.space.xsmed }}
              variant={ButtonVariant.OUTLINED}
              onClick={onClose}
            >
              <Icon name="cancel" />
            </Button>

            {/* TODO: Add CTAs here */}
          </Row>

          <Col gap={theme.space.sm} fullWidth>
            <Col gap={theme.space.xxs}>
              <Row alignCenter justifyBetween>
                <Row alignCenter gap={theme.space.xsmed}>
                  <Text variant="h4">
                    {workerInfo.firstName} {workerInfo.lastName}
                  </Text>
                  {accountApprovalStatus ? (
                    <AccountStatusBadge
                      accountStatus={accountApprovalStatus}
                      workerId={workerInfo.id}
                    />
                  ) : (
                    <Badge variant={BadgeVariant.INFO} title="👻 Ghost" />
                  )}
                </Row>

                <PhoneLinkAndCopyButton phoneNumber={workerInfo.phoneNumber} />
              </Row>

              <Text variant="h5">Application Results</Text>
            </Col>

            <Divider />
          </Col>

          <Col>
            <WorkerApplicationResults
              {...workerApplicationResultsProps}
              workerId={workerInfo.id}
            />
          </Col>
        </Col>
      </Drawer>
    </>
  )
}
