import { Tooltip } from '@mui/material'
import {
  Button,
  Col,
  Input,
  LoadingSpinner,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ApplicationItemContent,
  ApplicationItemOpsManualStepItemContent,
  ApplicationItemType,
  LanguageContentObj,
  OpsManualStepCreateParams,
  SupportedLanguage,
  UpsertApplicationItemRequestType,
} from '@traba/types'
import { getEnglishContent, getSpanishContent } from '@traba/utils'
import { useCallback, useMemo, useState } from 'react'
import {
  OpsManualStepResponse,
  useOpsManualStepByApplicationItemId,
} from 'src/hooks/useOpsManualStep'
import { Either } from 'src/types'
import {
  convertToOpsManualStepApplicationItemContent,
  buildInitialInputFromOpsManualStepApplicationItemContent,
} from 'src/utils/applicationUtils'
import { LanguageContentInputSection } from '../../LanguageContentInputSection'
import { HelperInfoForApplicationItem } from '../HelperInfoForApplicationItem'

export type CreateAndEditOpsManualStepApplicationItemProps = {
  applicationItemId?: string
  onSave: (
    createOpsManualActionRequestDto: UpsertApplicationItemRequestType,
  ) => void
  itemContent?: ApplicationItemContent
}
enum OpsManualStepErrorState {
  EMPTY_OPS_TITLE = 'Please enter a title for the Ops action',
  EMPTY_OPS_CAPTION = 'Please enter a caption for the Ops action',
  EMPTY_WORKER_APP_TITLE = 'Please enter a title for the Worker App step',
  LANGUAGE_MISSING = 'Please ensure each language has the same fields filled out',
}

type InputsInitialState = {
  opsConsoleTitle: string
  opsConsoleDescription: string
  workerAppTitleEn: string
  workerAppSubtitleEn: string
  workerAppTitleEs: string
  workerAppSubtitleEs: string
}

function buildInitialInput(
  opsManualStep?: OpsManualStepResponse,
  initialStateFromItemContent?: {
    opsConsoleTitle?: string
    opsConsoleDescription?: string
    workerAppTitle?: LanguageContentObj[]
    workerAppDescription?: LanguageContentObj[]
  },
): InputsInitialState {
  const workerAppTitle =
    opsManualStep?.parsedWorkerAppTitle ||
    initialStateFromItemContent?.workerAppTitle ||
    []
  const workerAppDescription =
    opsManualStep?.parsedWorkerAppDescription ||
    initialStateFromItemContent?.workerAppDescription ||
    []
  return {
    opsConsoleTitle:
      opsManualStep?.opsConsoleTitle ||
      initialStateFromItemContent?.opsConsoleTitle ||
      '',
    opsConsoleDescription:
      opsManualStep?.opsConsoleDescription ||
      initialStateFromItemContent?.opsConsoleDescription ||
      '',
    workerAppTitleEn: getEnglishContent(workerAppTitle) || '',
    workerAppSubtitleEn: getEnglishContent(workerAppDescription) || '',
    workerAppTitleEs: getSpanishContent(workerAppTitle) || '',
    workerAppSubtitleEs: getSpanishContent(workerAppDescription) || '',
  }
}

function buildInputRequestFromFormState({
  opsConsoleTitle,
  opsConsoleDescription,
  workerAppTitleEn,
  workerAppSubtitleEn,
  workerAppTitleEs,
  workerAppSubtitleEs,
}: InputsInitialState): Pick<
  OpsManualStepCreateParams,
  | 'workerAppTitle'
  | 'workerAppDescription'
  | 'opsConsoleTitle'
  | 'opsConsoleDescription'
> {
  return {
    workerAppTitle: [
      { language: SupportedLanguage.en, content: workerAppTitleEn },
      { language: SupportedLanguage.es, content: workerAppTitleEs },
    ],
    workerAppDescription: workerAppSubtitleEn
      ? [
          { language: SupportedLanguage.en, content: workerAppSubtitleEn },
          { language: SupportedLanguage.es, content: workerAppSubtitleEs },
        ]
      : undefined,
    opsConsoleTitle,
    opsConsoleDescription,
  }
}

const formStateToRequest = (
  formState: {
    opsConsoleTitle: string
    opsConsoleDescription: string
    workerAppTitleEn: string
    workerAppSubtitleEn: string
    workerAppTitleEs: string
    workerAppSubtitleEs: string
    applicationItemId?: string
  },
  isEditingExistingItem: boolean,
): Either<OpsManualStepErrorState, ApplicationItemOpsManualStepItemContent> => {
  const {
    opsConsoleTitle,
    opsConsoleDescription,
    workerAppTitleEn,
    workerAppSubtitleEn,
    workerAppTitleEs,
    workerAppSubtitleEs,
    applicationItemId,
  } = formState

  if (opsConsoleTitle === '') {
    return { left: OpsManualStepErrorState.EMPTY_OPS_TITLE }
  }
  if (opsConsoleDescription === '') {
    return { left: OpsManualStepErrorState.EMPTY_OPS_CAPTION }
  }
  if (workerAppTitleEn === '' || workerAppTitleEs === '') {
    return {
      left: OpsManualStepErrorState.EMPTY_WORKER_APP_TITLE,
    }
  }
  if (!!workerAppSubtitleEn !== !!workerAppSubtitleEs) {
    return {
      left: OpsManualStepErrorState.LANGUAGE_MISSING,
    }
  }

  const inputs = buildInputRequestFromFormState(formState)

  const createOpsManualActionRequestDto = isEditingExistingItem
    ? undefined
    : inputs

  const updateOpsManualActionRequestDto = isEditingExistingItem
    ? { ...inputs, applicationItemId }
    : undefined

  return {
    right: {
      createOpsManualActionRequestDto,
      updateOpsManualActionRequestDto,
    },
  }
}

export function CreateAndEditOpsManualStepApplicationItem({
  applicationItemId,
  onSave,
  itemContent = {},
}: CreateAndEditOpsManualStepApplicationItemProps) {
  const { opsManualStep, isLoading: isOpsManualStepLoading } =
    useOpsManualStepByApplicationItemId(applicationItemId || '')
  const isEditingExistingItem = !!applicationItemId

  const initialValues = useMemo(() => {
    const opsManualStepItemContent =
      convertToOpsManualStepApplicationItemContent(itemContent)
    const initialValuesFromItemContent =
      buildInitialInputFromOpsManualStepApplicationItemContent(
        opsManualStepItemContent,
      )
    return buildInitialInput(opsManualStep, initialValuesFromItemContent)
  }, [opsManualStep, itemContent])

  const [opsConsoleTitle, setOpsConsoleTitle] = useState(
    initialValues.opsConsoleTitle,
  )
  const [opsConsoleDescription, setOpsConsoleDescription] = useState(
    initialValues.opsConsoleDescription,
  )
  const [workerAppTitleEn, setWorkerAppTitleEn] = useState(
    initialValues.workerAppTitleEn,
  )
  const [workerAppSubtitleEn, setWorkerAppSubtitleEn] = useState(
    initialValues.workerAppSubtitleEn,
  )
  const [workerAppTitleEs, setWorkerAppTitleEs] = useState(
    initialValues.workerAppTitleEs,
  )
  const [workerAppSubtitleEs, setWorkerAppSubtitleEs] = useState(
    initialValues.workerAppSubtitleEs,
  )

  const { left: error, right: request } = formStateToRequest(
    {
      opsConsoleTitle,
      opsConsoleDescription,
      workerAppTitleEn,
      workerAppSubtitleEn,
      workerAppTitleEs,
      workerAppSubtitleEs,
      applicationItemId,
    },
    isEditingExistingItem,
  )

  const handleSave = useCallback(() => {
    if (request) {
      onSave({
        applicationItemId,
        applicationItemType: ApplicationItemType.OPS_MANUAL_STEP,
        itemContent: request,
      } as UpsertApplicationItemRequestType)
    }
  }, [applicationItemId, onSave, request])

  if (isOpsManualStepLoading) {
    return <LoadingSpinner />
  }

  return (
    <Col gap={theme.space.sm}>
      <HelperInfoForApplicationItem
        applicationItemType={ApplicationItemType.OPS_MANUAL_STEP}
      />

      <Text variant="h4">Ops Facing</Text>

      <Col gap={theme.space.xxs}>
        <Text variant="body3">Action</Text>
        <Input
          onChange={(e) => setOpsConsoleTitle(e.target.value)}
          value={opsConsoleTitle}
          containerStyle={{ marginTop: 0 }}
        />
      </Col>
      <Col gap={theme.space.xxs}>
        <Text variant="body3">Note</Text>
        <Input
          onChange={(e) => setOpsConsoleDescription(e.target.value)}
          value={opsConsoleDescription}
          containerStyle={{ marginTop: 0 }}
        />
      </Col>

      <Text variant="h4">Worker App Facing</Text>
      <LanguageContentInputSection
        sectionTitle="Step title"
        languageContentMap={{
          en: workerAppTitleEn,
          es: workerAppTitleEs,
        }}
        onChange={(language, content) => {
          if (language === 'en') {
            setWorkerAppTitleEn(content)
          } else {
            setWorkerAppTitleEs(content)
          }
        }}
      />

      <LanguageContentInputSection
        sectionTitle="Step subtitle (optional)"
        languageContentMap={{
          en: workerAppSubtitleEn,
          es: workerAppSubtitleEs,
        }}
        onChange={(language, content) => {
          if (language === 'en') {
            setWorkerAppSubtitleEn(content)
          } else {
            setWorkerAppSubtitleEs(content)
          }
        }}
      />
      <Row justifyEnd>
        <Tooltip title={error}>
          <Button onClick={handleSave} disabled={!!error}>
            Save
          </Button>
        </Tooltip>
      </Row>
    </Col>
  )
}
