import { Text, Row } from '@traba/react-components'
import { Card } from '@traba/react-components'
import { theme } from '@traba/theme'
import React, { useState } from 'react'
import { CollapsibleIcon } from 'src/components/base'
import Divider from 'src/components/base/Divider'

type ShiftPostingInputContainerProps = {
  title: string
  titleActions?: React.ReactNode
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const ShiftPostingInputContainer = (
  props: ShiftPostingInputContainerProps,
) => {
  const { title, titleActions, children, style } = props
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <Row mb={theme.space.xs} style={style}>
      <Card>
        <Row alignCenter justifyBetween mb={theme.space.xs}>
          <Text variant="h4">{title}</Text>
          <Row gap={theme.space.xs} alignCenter>
            {titleActions}
            <CollapsibleIcon
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          </Row>
        </Row>
        {!collapsed && (
          <>
            <Divider />
            <Row mt={theme.space.xs}>{children}</Row>
          </>
        )}
      </Card>
    </Row>
  )
}

type ShiftPostingInputContainerSectionProps = {
  label: string
  input: React.ReactNode
  right?: boolean
}

export const ShiftPostingInputContainerSection = (
  props: ShiftPostingInputContainerSectionProps,
) => {
  const { label, input, right } = props

  return (
    <Row alignCenter style={{ flex: 1 }} wrap>
      {right && input}
      <Text
        variant="h6"
        style={{ marginRight: theme.space.xxs, whiteSpace: 'wrap' }}
      >
        {label}
      </Text>
      {!right && input}
    </Row>
  )
}
