import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Col,
  LoadingSpinner,
  Row,
  Text,
  TimeField,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Company,
  ParentInvoiceGroup,
  RecordStatus,
  Shift,
  ShiftRequest,
  ShiftRequestEditType,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import {
  combineTwoDatesForDateAndTime,
  mergeEditsOntoShiftRequest,
} from '@traba/utils'
import { differenceInMinutes } from 'date-fns'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import Divider from 'src/components/base/Divider'
import { useModal } from 'src/components/base/Modal/Modal'
import { useApplicationForEntity } from 'src/hooks/useApplications'
import { useLocations } from 'src/hooks/useCompanyLocations'
import { useCompanyUsers } from 'src/hooks/useCompanyUsers'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { useRoles } from 'src/hooks/useRoles'
import { useRosters } from 'src/hooks/useRosters'
import { useShiftRequestEditsMutation } from 'src/hooks/useShiftRequestEdits'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { getPayRate } from 'src/modals/EditShiftModal/utils'
import { CreateOrEditInvoiceGroupModal } from 'src/screens/CompanyDetailsScreen/components/CreateOrEditInvoiceGroupModal'
import { ApplicationsSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/ApplicationSection/ApplicationsSection'
import { ApplicationsSectionLoader } from 'src/screens/PostShiftScreen/components/PostShiftForm/ApplicationSection/ApplicationsSectionLoader'
import { EditExistingApplicationsSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/ApplicationSection/EditExistingApplicationsSection'
import { RolesAndWorkersSection } from 'src/screens/PostShiftScreen/components/PostShiftForm/RolesAndWorkersSection'
import { SelectBreakFormWithSchedules } from 'src/screens/PostShiftScreen/components/PostShiftForm/SelectBreakFormWithSchedules'
import { getShiftRequestForEditOrAdd } from 'src/screens/PostShiftScreen/components/PostShiftForm/utils'
import { ShiftPostingInputContainer } from 'src/screens/PostShiftScreen/components/ShiftPostingInputContainer'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

interface Props {
  company: Company
  shifts?: Shift[]
  selectedShiftRequest: ShiftRequest
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  selectedDate: Date
  onSuccess: () => void
  onBack: () => void
}

export const EditScheduleShiftRequestDetailsBody = ({
  company,
  shifts,
  selectedShiftRequest,
  shiftRequestParent,
  selectedDate,
  onSuccess,
  onBack,
}: Props) => {
  const originalShiftRequest = useMemo(() => {
    const shiftRequestWithEditsApplied = mergeEditsOntoShiftRequest({
      shiftRequest: selectedShiftRequest,
      shiftRequestEdits: selectedShiftRequest.shiftRequestEdits ?? [],
      selectedDate,
    })
    return getShiftRequestForEditOrAdd(
      shiftRequestParent,
      shiftRequestWithEditsApplied,
      company,
    )
  }, [selectedShiftRequest, selectedDate, shiftRequestParent, company])

  const [createShiftRequests, setCreateShiftRequests] = useState<
    CreateShiftRequest[]
  >([originalShiftRequest])

  useEffect(() => {
    setCreateShiftRequests([originalShiftRequest])
  }, [originalShiftRequest])

  const [workersToInvite, setWorkersToInvite] = useState<PopulatedWorker[]>([])
  const shiftsList = shifts?.filter(
    (s) => s.shiftRequestId === selectedShiftRequest.shiftRequestId && !!s.id,
  )
  const { companyId } = company
  // TODO(gavin): fix this to only use the roles hook once
  const { roles: activeRoles = [], isLoading: isLoadingActiveRoles } = useRoles(
    {
      companyId,
      recordStatus: RecordStatus.ACTIVE,
    },
  )
  const { roles: archivedRoles = [], isLoading: isLoadingArchivedRoles } =
    useRoles({
      companyId,
      recordStatus: RecordStatus.ARCHIVED,
    })
  const { application, isLoading: isLoadingApplication } =
    useApplicationForEntity({
      shiftRequestId: selectedShiftRequest.shiftRequestId,
    })

  const [editedStartTime, setEditedStartTime] = useState<Date | null>(null)
  const [editedEndTime, setEditedEndTime] = useState<Date | null>(null)

  const { showError, showSuccess } = useAlert()
  const { activeCompanyUsers, isLoading: isLoadingUsers } =
    useCompanyUsers(companyId)
  const { rosters, isLoading: isLoadingRosters } = useRosters(companyId)
  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()

  const { refetch: refetchInvoiceGroups } = useInvoiceGroups(companyId)
  const { locations, isLoading: isLoadingLocations } = useLocations(companyId)
  const createOrEditInvoiceGroupModal = useModal()

  const { editShiftRequest, isPending } = useShiftRequestEditsMutation(
    shiftRequestParent.shiftRequestParentId,
    shiftRequestParent.companyId,
  )
  const handleCreateInvoiceGroupModalClose = async (
    newGroup?: ParentInvoiceGroup,
  ) => {
    await refetchInvoiceGroups()
    if (newGroup) {
      setCreateShiftRequests((prev) => {
        return prev.map((shiftRequest) => {
          return {
            ...shiftRequest,
            parentInvoiceGroupId: newGroup.id,
          }
        })
      })
    }
    createOrEditInvoiceGroupModal.handleClose()
  }

  const minHourlyPayRate = useMemo(() => {
    return getPayRate({
      companyMin: company.minHourlyPayRate,
      hotSettingMin: hotSettings?.platformMinHourlyPayRate ?? 0,
    })
  }, [company.minHourlyPayRate, hotSettings?.platformMinHourlyPayRate])

  if (
    isLoadingUsers ||
    isLoadingHotSettings ||
    isLoadingActiveRoles ||
    isLoadingArchivedRoles ||
    isLoadingRosters ||
    isLoadingLocations
  ) {
    return <LoadingSpinner />
  }

  if (!shiftsList || shiftsList.length === 0) {
    return (
      <Text variant="error">
        There's no shifts in upcoming 3 weeks for this schedule and role
      </Text>
    )
  }

  const roles = [...activeRoles, ...archivedRoles]
  const { startTime: originalStartTime, endTime } =
    createShiftRequests[0].schedules[0]

  const onConfirmUpdates = async () => {
    try {
      if (!selectedDate) {
        return showError(
          'Please select a date that your change will take effect',
        )
      }

      if (createShiftRequests[0]?.createApplicationError) {
        return showError(createShiftRequests[0].createApplicationError)
      }

      const differences = _.omitBy(createShiftRequests[0], (value, key) =>
        // @ts-ignore - this is a hack to get the differences between the two objects
        _.isEqual(value, originalShiftRequest[key]),
      )
      // TODO(ENG-13685): support reverting to original shift request's times
      const startTimeOffsetMinutes = editedStartTime
        ? differenceInMinutes(editedStartTime, originalStartTime)
        : editedEndTime
          ? 0
          : undefined
      const endTimeOffsetMinutes = editedEndTime
        ? differenceInMinutes(editedEndTime, originalStartTime)
        : editedStartTime
          ? differenceInMinutes(endTime, originalStartTime)
          : undefined
      await editShiftRequest({
        edit: {
          ...differences,
          startTimeOffsetMinutes,
          endTimeOffsetMinutes,
          editType: ShiftRequestEditType.ALL_FUTURE,
          shiftRequestId: selectedShiftRequest.shiftRequestId,
          originalStartTime: combineTwoDatesForDateAndTime(
            selectedDate,
            shiftsList[0].originalStartTime,
          ),
        },
      })
      onSuccess()
      showSuccess('Role edited successfully')
    } catch (error: any) {
      showError(error.message ?? 'Error updating shift request')
      console.error(error)
    }
  }
  return (
    <>
      {/* Uncomment this if we want to allow location change
    <LocationSection
    setCreateShiftRequests={setCreateShiftRequests}
    createShiftRequests={createShiftRequests}
    locations={locations}
    timezone={shiftsList[0].timezone}
    /> */}
      <ShiftPostingInputContainer title={'Shift times'}>
        <Col fullWidth gap={theme.space.xs}>
          <Row gap={theme.space.xs}>
            <Text variant="h6">Start Time</Text>
            <TimeField
              time={editedStartTime ?? originalStartTime}
              setTime={setEditedStartTime}
              timezone={shiftsList[0].timezone}
            />
            <Text variant="h6">End Time</Text>
            <TimeField
              time={editedEndTime ?? endTime}
              setTime={setEditedEndTime}
              timezone={shiftsList[0].timezone}
            />
          </Row>
          <Divider />
          <Col fullWidth gap={theme.space.zero}>
            <SelectBreakFormWithSchedules
              createShiftRequests={createShiftRequests}
              setCreateShiftRequests={setCreateShiftRequests}
              defaultBreaks={
                selectedShiftRequest.scheduledBreaks[0] || undefined
              }
            />
          </Col>
        </Col>
      </ShiftPostingInputContainer>

      <RolesAndWorkersSection
        createShiftRequests={createShiftRequests}
        setCreateShiftRequests={setCreateShiftRequests}
        roles={activeRoles}
        companyId={companyId}
        companyUsers={activeCompanyUsers}
        minHourlyPayRate={minHourlyPayRate}
        overrideBusinessStartTime={shiftsList[0].businessStartTime ?? undefined}
        workersToInvite={workersToInvite}
        setWorkersToInvite={setWorkersToInvite}
        rosters={rosters}
        isEditExistingRole
        shiftEmploymentType={createShiftRequests[0].shiftEmploymentType}
      />
      {/*  Uncomment this if we want to allow invoice group change
      <InvoiceSection
      createOrEditInvoiceGroupModal={createOrEditInvoiceGroupModal}
      createShiftRequests={createShiftRequests}
      setCreateShiftRequests={setCreateShiftRequests}
      activeInvoiceGroups={activeInvoiceGroups}
    /> */}
      {isLoadingApplication ? (
        <ApplicationsSectionLoader />
      ) : application ? (
        <EditExistingApplicationsSection
          applicationItems={application.applicationItems}
          applicationId={application.id}
          roleName={
            roles?.find((role) => role.roleId === selectedShiftRequest.roleId)
              ?.roleName ?? 'Role application'
          }
          shiftRequestId={selectedShiftRequest.shiftRequestId}
          applicationRecordStatus={application.recordStatus}
          analyticsSource="edit-schedule-shift-request-details-edit-existing-applications-section"
          analyticsParams={{
            companyId,
            roleId: selectedShiftRequest.roleId,
          }}
        />
      ) : (
        <ApplicationsSection
          location={locations?.find(
            (loc) => loc.locationId === createShiftRequests[0].locationId,
          )}
          roles={roles}
          createShiftRequests={createShiftRequests}
          setCreateShiftRequests={setCreateShiftRequests}
          shiftRequest={selectedShiftRequest}
          analyticsSource="edit-schedule-shift-request-details-applications-section"
        />
      )}
      <Row alignCenter justifyBetween>
        <Button variant={ButtonVariant.OUTLINED} onClick={onBack}>
          Back
        </Button>
        <Button
          onClick={onConfirmUpdates}
          loading={isPending}
          disabled={!selectedDate}
        >
          Confirm updates
        </Button>
      </Row>
      <CreateOrEditInvoiceGroupModal
        handleClose={handleCreateInvoiceGroupModalClose}
        isOpen={createOrEditInvoiceGroupModal.isOpen}
        companyId={companyId}
      />
    </>
  )
}
