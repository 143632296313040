import { useMutation } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { captureSentryError } from '@traba/utils'
import pLimit from 'p-limit'

async function assignWorkerToEligibilityGroup(params: {
  companyId: string
  workerId: string
  tagName: string
  initiatedBy: string
}) {
  try {
    const res = await trabaApi.post(`/connections/apply-tag-to-worker`, {
      companyId: params.companyId,
      workerId: params.workerId,
      tagName: params.tagName,
      initiatedBy: params.initiatedBy,
    })
    return res.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

async function assignWorkersToEligibilityGroupBatch({
  companyId,
  workerIds,
  tagName,
  initiatedBy,
  onProgress,
}: {
  companyId: string
  workerIds: string[]
  tagName: string
  initiatedBy: string
  onProgress?: (args: { completed: number; remaining: number }) => void
}) {
  const limit = pLimit(20)

  const succeeded: string[] = []
  const failed: { workerId: string; error: unknown }[] = []

  const promises = workerIds.map((workerId) =>
    limit(async () => {
      try {
        await assignWorkerToEligibilityGroup({
          companyId,
          workerId,
          tagName,
          initiatedBy,
        })

        succeeded.push(workerId)
      } catch (error) {
        failed.push({ workerId, error })
      } finally {
        onProgress?.({
          completed: succeeded.length + failed.length,
          remaining: workerIds.length - succeeded.length - failed.length,
        })
      }
    }),
  )

  await Promise.all(promises)

  return {
    succeeded,
    failed,
  }
}

async function unassignWorkerFromEligibilityGroup(params: {
  companyId: string
  workerId: string
  tagName: string
  initiatedBy: string
}) {
  try {
    const res = await trabaApi.post(`/connections/remove-tag-from-worker`, {
      companyId: params.companyId,
      workerId: params.workerId,
      tagName: params.tagName,
      initiatedBy: params.initiatedBy,
    })
    return res.data
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

async function unassignWorkersFromEligibilityGroupBatch({
  companyId,
  workerIds,
  tagName,
  initiatedBy,
  onProgress,
}: {
  companyId: string
  workerIds: string[]
  tagName: string
  initiatedBy: string
  onProgress?: (args: { completed: number; remaining: number }) => void
}) {
  const limit = pLimit(20)

  const succeeded: string[] = []
  const failed: { workerId: string; error: unknown }[] = []

  const promises = workerIds.map((workerId) =>
    limit(async () => {
      try {
        await unassignWorkerFromEligibilityGroup({
          companyId,
          workerId,
          tagName,
          initiatedBy,
        })

        succeeded.push(workerId)
      } catch (error) {
        failed.push({ workerId, error })
      } finally {
        onProgress?.({
          completed: succeeded.length + failed.length,
          remaining: workerIds.length - succeeded.length - failed.length,
        })
      }
    }),
  )

  await Promise.all(promises)

  return {
    succeeded,
    failed,
  }
}

export function useEligibilityGroups() {
  const { showSuccess, showError } = useAlert()

  const {
    mutateAsync: assignWorkersToEligibilityGroupAsync,
    isPending: isPendingAssign,
  } = useMutation({
    mutationFn: assignWorkersToEligibilityGroupBatch,
    onSuccess: (data) => {
      if (data.failed.length > 0) {
        showError(
          `Failed to assign ${data.failed.length} workers to eligibility group`,
        )
      } else {
        showSuccess(
          `Successfully assigned ${data.succeeded.length} workers to eligibility group`,
        )
      }
    },
  })

  const {
    mutateAsync: unassignWorkersFromEligibilityGroupAsync,
    isPending: isPendingUnassign,
  } = useMutation({
    mutationFn: unassignWorkersFromEligibilityGroupBatch,
    onSuccess: (data) => {
      if (data.failed.length > 0) {
        showError(
          `Failed to unassign ${data.failed.length} workers from eligibility group`,
        )
      } else {
        showSuccess(
          `Successfully unassigned ${data.succeeded.length} workers from eligibility group`,
        )
      }
    },
  })

  return {
    assignWorkersToEligibilityGroup: assignWorkersToEligibilityGroupAsync,
    isPendingAssign,
    unassignWorkersFromEligibilityGroup:
      unassignWorkersFromEligibilityGroupAsync,
    isPendingUnassign,
  }
}
