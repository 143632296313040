import { LoadingSpinner } from '@traba/react-components'
import { useState } from 'react'
import { SchedulesTabContent } from 'src/components/Schedule/SchedulesTabContent'
import { useWorkerShiftRequestParents } from 'src/hooks/useWorkerRecurringSchedules'

interface Props {
  workerId: string
}

export const WorkerSchedulesTab = ({ workerId }: Props) => {
  const [showInactiveSchedules, setShowInactiveSchedules] = useState(false)
  const { isLoading, workerScheduleData } = useWorkerShiftRequestParents({
    workerId,
    showInactiveSchedules,
  })

  if (isLoading || !workerScheduleData) {
    return <LoadingSpinner />
  }
  const { shiftRequestParents } = workerScheduleData

  return (
    <SchedulesTabContent
      shiftRequestParents={shiftRequestParents}
      showInactiveSchedules={showInactiveSchedules}
      setShowInactiveSchedules={setShowInactiveSchedules}
    />
  )
}
