import { LoadingSpinner } from '@traba/react-components'
import { WorkerApplicationWithApplicant } from '@traba/types'
import { useState } from 'react'
import {
  useApplicationForEntity,
  useApplicationsSearch,
} from 'src/hooks/useApplications'
import { useWorkerApplicationsForEntity } from 'src/hooks/useWorkerApplications'
import { ShiftApplicantsTable } from '../ShiftDetailsScreen/components/ShiftApplicantsTable/ShiftApplicantsTable'
import { ApplicationTableFilters } from './ApplicationTableFilters'

export const RoleApplicantsTableContainer = ({
  roleId,
  companyId,
}: {
  roleId: string
  companyId?: string
}) => {
  const { application, isLoading: isLoadingApplication } =
    useApplicationForEntity({
      roleId,
    })
  const { searchResults } = useApplicationsSearch({
    applicationId: application?.id,
  })
  const applicationSearchResult = searchResults?.items[0]
  const { workerApplications: unfilteredWorkerApplications, isLoading } =
    useWorkerApplicationsForEntity({
      roleId,
    })

  const [workerApplications, setWorkerApplications] = useState<
    WorkerApplicationWithApplicant[]
  >(unfilteredWorkerApplications ?? [])

  return isLoadingApplication ? (
    <LoadingSpinner />
  ) : (
    <>
      {applicationSearchResult && unfilteredWorkerApplications && (
        <ApplicationTableFilters
          application={applicationSearchResult}
          unfilteredWorkerApplications={unfilteredWorkerApplications}
          onFilteredApplicationsChange={setWorkerApplications}
        />
      )}
      <ShiftApplicantsTable
        applications={workerApplications}
        shiftInvitations={[]}
        roleId={roleId}
        isLoading={isLoading}
        companyId={companyId}
      />
    </>
  )
}
