import {
  Badge,
  BadgeVariant,
  Col,
  Dialog,
  MODAL_SIZE,
  RadioButton,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { BasicOpsWorkerDetails, ShiftInvitationStatus } from '@traba/types'
import { useMemo, useCallback, useState } from 'react'
import { InputErrorMessage } from 'src/components/base/Input/Input.styles'
import { useScheduleInvitations } from 'src/hooks/useScheduleInvitations'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'
import { useApplicationsAnalytics } from 'src/screens/Applications/hooks/useApplicationsAnalytics'

interface InviteToSingleShiftOrScheduleModalProps {
  shiftId: string
  shiftRequestId: string
  shiftRequestParentId?: string
  companyId: string
  worker: Pick<BasicOpsWorkerDetails, 'id' | 'firstName' | 'lastName'>
  isOpen: boolean
  onClose: () => void
  analyticsSource: string
}

export function InviteToSingleShiftOrScheduleModal({
  shiftId,
  shiftRequestId,
  // shiftRequestParentId,
  companyId,
  worker,
  isOpen,
  onClose,
  analyticsSource = 'invite-to-single-shift-or-schedule-modal',
}: InviteToSingleShiftOrScheduleModalProps) {
  const commonAnalyticsParams = useMemo(
    () => ({
      shiftId,
      shiftRequestId,
      companyId,
      workerId: worker.id,
      source: analyticsSource,
    }),
    [analyticsSource, shiftId, shiftRequestId, companyId, worker.id],
  )
  const { trackInviteToShift, trackInviteToSchedule } =
    useApplicationsAnalytics()
  const [inviteToSingleShift, setInviteToSingleShift] = useState(true)

  const {
    sendInvitations: sendInvitationsToSingleShift,
    sendInvitationsLoading,
    shiftInvitations,
  } = useShiftInvitations(shiftId)

  const { sendScheduleInvitation, isSendingScheduleInvitation } =
    useScheduleInvitations(shiftRequestId) // update to use shiftRequestParentId?

  const onConfirm = useCallback(() => {
    if (inviteToSingleShift) {
      trackInviteToShift(commonAnalyticsParams)
      sendInvitationsToSingleShift({
        shiftId,
        workerIds: [worker.id],
        includeRescinded: true,
      })
    } else {
      trackInviteToSchedule(commonAnalyticsParams)
      sendScheduleInvitation({
        shiftRequestId,
        workerIds: [worker.id],
        companyId,
      })
    }
    onClose()
  }, [
    inviteToSingleShift,
    commonAnalyticsParams,
    shiftId,
    worker.id,
    shiftRequestId,
    companyId,
    onClose,
    sendInvitationsToSingleShift,
    sendScheduleInvitation,
    trackInviteToShift,
    trackInviteToSchedule,
  ])

  const foundRescindedShiftInvitation = shiftInvitations?.some(
    (invitation) =>
      invitation.workerId === worker.id &&
      invitation.status === ShiftInvitationStatus.Rescinded,
  )
  const disabledScheduleInviteOption = !!foundRescindedShiftInvitation

  const isLoading = isSendingScheduleInvitation || sendInvitationsLoading

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={isOpen}
      onClose={onClose}
      onConfirmCTA="Confirm & Invite Worker"
      dialogTitle={'Invite worker to shift or schedule'}
      formId="invite-worker"
      size={MODAL_SIZE.MEDIUM}
      onConfirm={onConfirm}
      confirming={isLoading}
      confirmDisabled={isLoading}
    >
      <Col gap={theme.space.med}>
        <Col gap={theme.space.xxs}>
          <Text variant="body1">Selected worker</Text>

          <Badge
            style={{ textTransform: 'unset', alignSelf: 'start' }}
            variant={BadgeVariant.INFO}
            title={`${worker.firstName} ${worker.lastName} (${worker.id})`}
          />
        </Col>

        <Col gap={theme.space.xs}>
          <Row
            alignCenter
            gap={theme.space.xs}
            style={{ cursor: 'pointer' }}
            onClick={() => setInviteToSingleShift(true)}
          >
            <RadioButton selected={inviteToSingleShift} />
            <Text variant="body1">Invite to single shift</Text>
          </Row>

          <Row
            alignCenter
            gap={theme.space.xs}
            style={{
              cursor: disabledScheduleInviteOption ? undefined : 'pointer',
            }}
            onClick={() => {
              if (disabledScheduleInviteOption) {
                return
              }
              setInviteToSingleShift(false)
            }}
            fullWidth
          >
            <RadioButton
              selected={!inviteToSingleShift}
              disabled={disabledScheduleInviteOption}
            />
            <Text variant="body1">Invite to schedule</Text>
          </Row>
          {foundRescindedShiftInvitation && (
            <InputErrorMessage>
              You can only re-invite a rescinded invitation to a single shift
            </InputErrorMessage>
          )}
        </Col>
      </Col>
    </Dialog>
  )
}
