import { theme } from '@traba/theme'
import { InfoRow } from '../InfoRow'

interface Props {
  text?: string
}

export function InputInfoCalloutRow({ text }: Props) {
  if (!text) {
    return null
  }

  return <InfoRow text={text} color={theme.colors.Grey60} />
}
