import { Button, Text, ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Modal, Row } from 'src/components/base'

export interface ActionConfirmationOption {
  icon: React.ReactNode
  title: string
  helperText: string
  buttonText: string
  buttonVariant: ButtonVariant
  onButtonClick: () => void
}

export interface ActionConfirmationProps {
  isOpen: boolean
  onClose: () => void
  title: string
  options: ActionConfirmationOption[]
}

export const ActionConfirmation = (props: ActionConfirmationProps) => {
  const { isOpen, onClose, title, options } = props

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      title={title}
      style={{ minHeight: 'auto', minWidth: '80%' }}
    >
      {/* Container for all options */}
      <Row center>
        {options.map((option, index) => (
          // Container for each option
          <Row
            key={index}
            justifyCenter
            p={theme.space.sm}
            m={theme.space.sm}
            style={{
              border: `1px solid ${theme.border.color}`,
              borderRadius: theme.border.radius,
              width: '50%',
            }}
            flexCol
          >
            <Row justifyStart mb={theme.space.sm} style={{ height: '25%' }}>
              {option.icon}
            </Row>
            <Text variant="h5" mb={theme.space.xs} style={{ height: '25%' }}>
              {option.title}
            </Text>
            <Text
              variant="body1"
              color={theme.colors.Grey60}
              style={{ height: '25%' }}
            >
              {option.helperText}
            </Text>
            <Button
              onClick={option.onButtonClick}
              variant={option.buttonVariant}
              style={{ marginTop: theme.space.sm, padding: '20px' }}
              full
            >
              {option.buttonText}
            </Button>
          </Row>
        ))}
      </Row>
    </Modal>
  )
}
