import { Toggle } from '@traba/react-components'
import { ApplicationRecordStatus } from '@traba/types'
import { useCallback } from 'react'
import {
  useApplicationForEntity,
  useApplicationsMutations,
} from 'src/hooks/useApplications'
import {
  BaseApplicationAnalyticsParams,
  useApplicationsAnalytics,
} from 'src/screens/Applications/hooks/useApplicationsAnalytics'

interface DisableApplicationToggleButtonProps {
  shiftRequestId?: string
  roleId?: string
  analyticsSource?: string
  analyticsParams?: BaseApplicationAnalyticsParams
}

export function DisableApplicationToggleButton({
  shiftRequestId,
  roleId,
  analyticsSource = 'disable-application-toggle-button',
  analyticsParams,
}: DisableApplicationToggleButtonProps) {
  const { application, isLoading } = useApplicationForEntity({
    shiftRequestId,
    roleId: shiftRequestId ? undefined : roleId, // prioritize shift request if it exists
  })
  const { trackApplicationDisabled, trackApplicationEnabled } =
    useApplicationsAnalytics()

  const {
    updateApplicationRecordStatus,
    isUpdateApplicationRecordStatusLoading,
  } = useApplicationsMutations()

  const handleToggleApplicationEnabled = useCallback(() => {
    if (
      !isLoading &&
      !isUpdateApplicationRecordStatusLoading &&
      application?.id
    ) {
      const isApplicationStatusActive =
        application.recordStatus === ApplicationRecordStatus.ACTIVE
      let userHasConfirmed = false
      if (isApplicationStatusActive) {
        userHasConfirmed = window.confirm(
          'Are you sure you want to disable worker applications for all shifts for this role in this shift schedule?',
        )
      }

      if (!isApplicationStatusActive || userHasConfirmed) {
        if (isApplicationStatusActive) {
          trackApplicationDisabled({
            ...analyticsParams,
            applicationId: application.id,
            shiftRequestId,
            source: analyticsSource,
          })
        } else {
          trackApplicationEnabled({
            ...analyticsParams,
            applicationId: application.id,
            shiftRequestId,
            source: analyticsSource,
          })
        }

        const newStatus = isApplicationStatusActive
          ? ApplicationRecordStatus.DISABLED
          : ApplicationRecordStatus.ACTIVE
        updateApplicationRecordStatus({
          applicationId: application.id,
          recordStatus: newStatus,
        })
      }
    }
  }, [
    application?.id,
    application?.recordStatus,
    updateApplicationRecordStatus,
    isLoading,
    isUpdateApplicationRecordStatusLoading,
    analyticsSource,
    analyticsParams,
  ])

  if (!application?.id || isLoading) {
    return null
  }

  return (
    <Toggle
      disabled={isUpdateApplicationRecordStatusLoading}
      runOnChange={handleToggleApplicationEnabled}
      buttonState={application.recordStatus === ApplicationRecordStatus.ACTIVE}
      label={`${
        application.recordStatus === ApplicationRecordStatus.ACTIVE
          ? 'Application is ENABLED'
          : 'Application is DISABLED'
      }`}
    />
  )
}
