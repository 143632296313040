import { Col, InlineBanner, LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AdditionalInformationForItemCreateOrUpdate,
  ApplicationItem,
  UpsertApplicationItemRequestType,
} from '@traba/types'
import { useCallback, useMemo } from 'react'
import { useApplicationItemsMutations } from 'src/hooks/useApplications'
import { useRoles } from 'src/hooks/useRoles'
import { useShiftRequestById } from 'src/hooks/useShiftRequestById'
import { convertFromExistingApplicationItemToEmptyUpsertApplicationItem } from 'src/utils/applicationUtils'
import { CreateAndEditApplicationItemsContent } from './CreateAndEditApplicationItemsContent'
import {
  BaseApplicationAnalyticsParams,
  useApplicationsAnalytics,
} from './hooks/useApplicationsAnalytics'
import { ApplicationItemCreationAdditionalProps } from './types'
import { ViewAndEditExistingApplicationItem } from './ViewAndEditExistingApplicationItem'

type ViewAndEditExistingApplicationItemsListProps = {
  applicationId: string
  applicationItems: ApplicationItem[]
  isEditable?: boolean
  analyticsSource?: string
  analyticsParams?: BaseApplicationAnalyticsParams
  shiftRequestId?: string
  roleId?: string
  companyId?: string
} & ApplicationItemCreationAdditionalProps

export const ViewAndEditExistingApplicationItemsList = ({
  applicationId,
  applicationItems,
  analyticsSource = 'view-and-edit-existing-application-items-list',
  analyticsParams,
  shiftRequestId,
  roleId,
  companyId,
  isEditable,
}: ViewAndEditExistingApplicationItemsListProps) => {
  const sortedApplicationItems = useMemo(() => {
    return applicationItems.sort((item1, item2) => {
      if (item1.sectionIndex === item2.sectionIndex) {
        return (
          (item1.sectionDisplayOrderIndex || 0) -
          (item2.sectionDisplayOrderIndex || 0)
        )
      }
      return (item1.sectionIndex || 0) - (item2.sectionIndex || 0)
    })
  }, [applicationItems])

  const { shiftRequest, isLoading: isShiftRequestLoading } =
    useShiftRequestById(shiftRequestId || '')
  const { roles, isLoading: isRoleLoading } = useRoles({ companyId })
  const role = useMemo(
    () => roles?.find((role) => role.roleId === roleId),
    [roles, roleId],
  )

  const additionalInformationForItemCreateOrUpdate: AdditionalInformationForItemCreateOrUpdate =
    useMemo(
      () => ({
        shiftInformationForVettingConfig: {
          shiftRoleId: shiftRequest?.roleId || role?.roleId || '',
          shiftRequestParentTitle: shiftRequest?.shiftRequestParentTitle,
          shiftStartTime:
            shiftRequest?.schedules?.[0]?.startTime?.toISOString(),
          shiftRoleName: shiftRequest?.shiftRole || role?.roleName || '',
        },
      }),
      [shiftRequest, role],
    )

  const {
    archiveApplicationItem,
    isArchiveApplicationItemLoading,
    updateApplicationItemContent,
    isUpdateApplicationItemContentLoading,
    addApplicationItem,
    isAddApplicationItemLoading,
    reorderApplicationItems,
    isReorderApplicationItemsLoading,
  } = useApplicationItemsMutations()

  const {
    trackApplicationItemUpdated,
    trackAddNewItemToExistingApplication,
    trackApplicationItemsReordered,
    trackApplicationItemArchived,
  } = useApplicationsAnalytics()

  const handleArchiveApplicationItem = useCallback(
    (applicationItemIndex: number) => {
      const userHasConfirmed = window.confirm(
        'Are you sure you want to remove this application step? This step will show as cancelled on all existing worker applications and will not show up on future worker applications.',
      )

      if (!userHasConfirmed) {
        return
      }

      trackApplicationItemArchived({
        ...analyticsParams,
        applicationItemId: sortedApplicationItems[applicationItemIndex].id,
        applicationItemType:
          sortedApplicationItems[applicationItemIndex].applicationItemType,
        source: analyticsSource,
      })

      const applicationItemId = sortedApplicationItems[applicationItemIndex].id
      archiveApplicationItem({
        applicationItemId,
        shiftRequestId,
        roleId: shiftRequestId ? undefined : roleId,
      })
    },
    [archiveApplicationItem, sortedApplicationItems, shiftRequestId, roleId],
  )

  const handleUpdateApplicationItemContent = useCallback(
    (
      applicationItemId: string,
      upsertedItem: UpsertApplicationItemRequestType,
    ) => {
      trackApplicationItemUpdated({
        ...analyticsParams,
        applicationItemId,
        applicationItemType: upsertedItem.applicationItemType,
        source: analyticsSource,
      })
      return updateApplicationItemContent({
        applicationItemId,
        updateSingleApplicationItemRequest: {
          itemContent: upsertedItem.itemContent,
        },
        additionalInformationForItemCreateOrUpdate,
      })
    },
    [
      updateApplicationItemContent,
      trackApplicationItemUpdated,
      analyticsSource,
      analyticsParams,
      additionalInformationForItemCreateOrUpdate,
    ],
  )

  const handleAddNewApplicationItemContent = useCallback(
    (upsertedItem: UpsertApplicationItemRequestType) => {
      trackAddNewItemToExistingApplication({
        ...analyticsParams,
        applicationItemType: upsertedItem.applicationItemType,
        source: analyticsSource,
      })
      return addApplicationItem({
        applicationId,
        createApplicationItemRequest: {
          applicationItemType: upsertedItem.applicationItemType,
          itemContent: upsertedItem.itemContent,
        },
        shiftRequestId,
        roleId: shiftRequestId ? undefined : roleId,
        additionalInformationForItemCreateOrUpdate,
      })
    },
    [
      addApplicationItem,
      trackAddNewItemToExistingApplication,
      analyticsSource,
      additionalInformationForItemCreateOrUpdate,
      shiftRequestId,
      roleId,
      analyticsParams,
      applicationId,
    ],
  )

  const handleSaveItem = useCallback(
    (applicationItemIndex: number) =>
      (item: UpsertApplicationItemRequestType) => {
        // if the item is out of range of the list, it means it's a new item
        if (sortedApplicationItems.length <= applicationItemIndex) {
          return handleAddNewApplicationItemContent(item)
        } else {
          const applicationItemId =
            sortedApplicationItems[applicationItemIndex].id
          return handleUpdateApplicationItemContent(applicationItemId, item)
        }
      },
    [
      handleAddNewApplicationItemContent,
      handleUpdateApplicationItemContent,
      sortedApplicationItems,
    ],
  )

  const handleReorderItems = useCallback(
    (applicationItems: UpsertApplicationItemRequestType[]) => {
      const reorderedItems: {
        applicationItemId: string
        sectionIndex: number
      }[] = applicationItems
        .map((item) => ({
          applicationItemId: item.applicationItemId || '',
          sectionIndex: item.sectionIndex || 0,
        }))
        .filter((item) => !!item.applicationItemId)

      reorderApplicationItems({
        applicationId,
        items: reorderedItems,
      })
      trackApplicationItemsReordered({
        ...analyticsParams,
        applicationId,
        source: analyticsSource,
      })
    },
    [
      analyticsParams,
      analyticsSource,
      trackApplicationItemsReordered,
      applicationId,
      reorderApplicationItems,
    ],
  )

  if (
    isAddApplicationItemLoading ||
    isUpdateApplicationItemContentLoading ||
    isArchiveApplicationItemLoading ||
    isRoleLoading ||
    isShiftRequestLoading ||
    isReorderApplicationItemsLoading
  ) {
    return <LoadingSpinner />
  }

  // we only support editing shift applications for now, not role applications
  const showEditableView = isEditable && !!shiftRequestId

  const upsertedItems: UpsertApplicationItemRequestType[] =
    sortedApplicationItems
      .map((item) =>
        convertFromExistingApplicationItemToEmptyUpsertApplicationItem({
          applicationItemId: item.id,
          applicationItemType: item.applicationItemType,
          applicationItemSourceId: item.sourceId,
        }),
      )
      .filter((item): item is UpsertApplicationItemRequestType => !!item)

  return showEditableView ? (
    <Col gap={theme.space.xs}>
      <InlineBanner
        text="All edits will immediately apply to all new workers that view this shift."
        severity="warning"
      />

      <CreateAndEditApplicationItemsContent
        applicationItems={upsertedItems}
        onSaveItem={handleSaveItem}
        onRemoveItem={handleArchiveApplicationItem}
        analyticsSource={analyticsSource}
        shiftRequest={shiftRequest}
        role={role}
        onSaveReorderedItems={handleReorderItems}
      />
    </Col>
  ) : (
    <>
      {sortedApplicationItems.map((applicationItem, index) => (
        <ViewAndEditExistingApplicationItem
          key={applicationItem.id}
          applicationItemId={applicationItem.id}
          applicationItemType={applicationItem.applicationItemType}
          applicationItemSourceId={applicationItem.sourceId}
          stepIndex={index + 1}
          isEditable={false}
        />
      ))}
    </>
  )
}
