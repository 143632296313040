import { trabaApi } from '@traba/api-utils'
import { Training } from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { useQuery } from 'react-query'

const getTrainings = async (companyId?: string) => {
  try {
    let url = '/trainings'
    if (companyId) {
      url += `?companyId=${companyId}`
    }
    const response = await trabaApi.get(url)
    return response.data
  } catch (error) {
    console.error('useTrainings -> getTrainings() ERROR', error)
    captureSentryError(error, {
      tags: { action: 'useTrainings -> getTrainings()' },
    })
  }
}

export const useTrainings = (companyId: string) => {
  const {
    isLoading,
    isError,
    data: trainings,
    error,
    isFetched,
  } = useQuery<Training[], Error>(['trainings', companyId], () =>
    getTrainings(companyId),
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    trainings,
  }
}
