import {
  CancellationSource,
  ScheduleInvitationStatus,
  WorkerShift,
} from '@traba/types'
import { Badge } from '../base-components/Badge/Badge'
import { BadgeVariant } from '../base-components/Badge/Badge.styles'

export function ScheduleInvitationBadge({
  id,
  status,
  canceledWorkerShift,
  failedEligibilityChecks,
}: {
  id: string
  status: ScheduleInvitationStatus
  canceledWorkerShift?: WorkerShift
  failedEligibilityChecks?: string[]
}) {
  let badgeTitle = ''
  let badgeVariant: BadgeVariant

  switch (status) {
    case ScheduleInvitationStatus.ACCEPTED: {
      if (
        canceledWorkerShift &&
        canceledWorkerShift.cancellationSource !== CancellationSource.Business
      ) {
        badgeVariant = BadgeVariant.DANGER
        if (
          canceledWorkerShift.cancellationSource === CancellationSource.Worker
        ) {
          badgeTitle = 'Worker canceled'
        } else {
          badgeTitle = 'Removed by Traba'
        }
      } else {
        badgeTitle = 'Accepted'
        badgeVariant = BadgeVariant.SUCCESS
      }
      break
    }

    case ScheduleInvitationStatus.ACCEPTED_SOME_SHIFTS:
      badgeTitle = 'Accepted some shifts'
      badgeVariant = BadgeVariant.SUCCESS
      break

    case ScheduleInvitationStatus.ACCEPTED_SCHEDULE:
      badgeTitle = 'Accepted schedule'
      badgeVariant = BadgeVariant.SUCCESS
      break

    case ScheduleInvitationStatus.DECLINED:
      badgeTitle = 'Declined'
      badgeVariant = BadgeVariant.DANGER
      break

    case ScheduleInvitationStatus.RESCINDED:
      badgeTitle = 'Rescinded'
      badgeVariant = BadgeVariant.YELLOW
      break

    case ScheduleInvitationStatus.SENT:
      badgeTitle = 'Waiting for a response'
      badgeVariant = BadgeVariant.WARNING
      break

    case ScheduleInvitationStatus.INELIGIBLE:
      badgeTitle = 'Ineligible'
      badgeVariant = BadgeVariant.DANGER
      break

    case ScheduleInvitationStatus.FAILED_TO_SEND:
      badgeTitle = 'Failed to send'
      badgeVariant = BadgeVariant.DANGER
      break

    case ScheduleInvitationStatus.PENDING:
      badgeTitle = 'Pending'
      badgeVariant = BadgeVariant.WARNING
      break

    default:
      badgeTitle = 'Unknown'
      badgeVariant = BadgeVariant.DANGER
      break
  }
  return (
    <Badge
      key={id}
      title={badgeTitle}
      variant={badgeVariant}
      tooltipText={
        status === ScheduleInvitationStatus.INELIGIBLE ||
        failedEligibilityChecks?.length
          ? `Failed eligibility checks: [${failedEligibilityChecks?.join(', ')}]`
          : undefined
      }
    />
  )
}
