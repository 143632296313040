import { useMutation, useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  CreateInvitationsData,
  ShiftInvitation,
  ShiftInvitationWithWorkerInfo,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { AxiosError } from 'axios'
import { BulkResendInvitationsResponse } from 'src/types/shift-invitations'
import { getErrorMessage } from 'src/utils/errorUtils'

export const SHIFT_INVITATIONS_QUERY_KEY = 'shiftInvitations'

async function getShiftInvitationsByShiftId(
  shiftId?: string,
): Promise<ShiftInvitationWithWorkerInfo[] | undefined> {
  if (!shiftId) {
    return undefined
  }
  try {
    const res = await trabaApi.get(`shifts/${shiftId}/invitations`)
    return res.data
  } catch (error: any) {
    console.error(
      'useShiftInvitations -> getShiftInvitationsByShiftId ERROR',
      error.message ?? error,
    )
    captureSentryError(error)
    throw error
  }
}

const rescindInvitation = async ({
  shiftId,
  workerId,
}: {
  shiftId: string
  workerId: string
}) => {
  try {
    const response = await trabaApi.patch(
      `/shifts/${shiftId}/invitations/${workerId}/rescind`,
    )
    return response.data
  } catch (error: any) {
    console.error(
      'useShiftInvitations -> rescindInvitation ERROR',
      error.message ?? error,
    )
    captureSentryError(error)
    throw error
  }
}

async function sendInvitationsByShiftId({
  shiftId,
  workerIds,
  includeRescinded,
}: CreateInvitationsData): Promise<ShiftInvitation[]> {
  try {
    const res = await trabaApi.post(`shifts/${shiftId}/invitations`, {
      workerIds,
      includeRescinded,
    })
    return res.data
  } catch (error: any) {
    console.error(
      'useShiftInvitations -> sendInvitationsByShiftId ERROR',
      error.message ?? error,
    )
    captureSentryError(error)
    throw error
  }
}

async function resendInvitation({
  shiftId,
  workerId,
}: {
  shiftId: string
  workerId: string
}) {
  try {
    const response = await trabaApi.patch(
      `/shifts/${shiftId}/invitations/${workerId}/resend`,
    )
    return response.data
  } catch (error: any) {
    console.error(
      'useShiftInvitations -> resendInvitation ERROR',
      error.message ?? error,
    )
    captureSentryError(error)
    throw error
  }
}

async function bulkResendInvitations({
  shiftId,
}: {
  shiftId: string
}): Promise<BulkResendInvitationsResponse> {
  try {
    const response = await trabaApi.post(
      `/shifts/${shiftId}/invitations/resend`,
    )
    return response.data
  } catch (error: any) {
    console.error(
      'useShiftInvitations -> bulkResendInvitations ERROR',
      error.message ?? error,
    )
    captureSentryError(error)
    throw error
  }
}

export function useShiftInvitations(shiftId?: string) {
  const {
    isLoading,
    isError,
    data: shiftInvitations,
    error,
    isFetched,
    refetch,
  } = useQuery<ShiftInvitationWithWorkerInfo[] | undefined, Error>({
    queryKey: [SHIFT_INVITATIONS_QUERY_KEY, shiftId],
    queryFn: () => getShiftInvitationsByShiftId(shiftId),
  })
  const { handleError, showSuccess } = useAlert()

  const rescindInvitationMutation = useMutation<
    ShiftInvitation,
    AxiosError,
    ShiftInvitation
  >({
    mutationFn: rescindInvitation,
    onSuccess: () => {
      refetch()
      showSuccess('Invitation rescinded successfully')
    },
    onError: (error: AxiosError) => {
      handleError(
        error,
        'useShiftInvitations -> rescindInvitation()',
        getErrorMessage(error),
        'Error rescinding invitation',
      )
    },
  })

  const sendInvitationsMutation = useMutation<
    ShiftInvitation[],
    AxiosError,
    CreateInvitationsData
  >({
    mutationFn: sendInvitationsByShiftId,
    onSuccess: () => {
      refetch()
      showSuccess('Invitations successfully sent')
    },
    onError: (error: AxiosError) => {
      handleError(
        error,
        'useShiftInvitations -> sendInvitationsByShiftId()',
        getErrorMessage(error),
        'Error sending invitations',
      )
    },
  })

  const resendInvitationMutation = useMutation<
    any,
    AxiosError,
    { shiftId: string; workerId: string }
  >({
    mutationFn: resendInvitation,
    onSuccess: () => {
      showSuccess(
        `Sent SMS to worker`,
        'Successfully sent invitation notification',
        2000,
      )
      refetch()
    },
    onError: (error: AxiosError) => {
      handleError(
        error,
        'useShiftInvitations -> resendInvitation()',
        getErrorMessage(error),
        'Error sending invitation notification',
        4000,
      )
    },
  })

  const bulkResendInvitationsMutation = useMutation<
    any,
    AxiosError,
    { shiftId: string }
  >({
    mutationFn: bulkResendInvitations,
    onSuccess: (data: BulkResendInvitationsResponse) => {
      if (data.errors.length === 0) {
        showSuccess(
          `Sent invitations to ${data.successfulWorkerIds.length} workers`,
          'Successfully sent invitations',
          4000,
        )
      } else {
        // Notify user of partial success case where some invitations failed
        handleError(
          data.errors[0],
          'useShiftInvitations -> bulkResendInvitations()',
          `Failed to send invitations to workers: ${data.failedWorkerIds.join(',')}`,
          `Partial error sending invitations to ${data.failedWorkerIds.length} workers`,
          4000,
        )
      }
      refetch()
    },
    onError: (error: AxiosError) => {
      handleError(
        error,
        'useShiftInvitations -> bulkResendInvitations()',
        getErrorMessage(error),
        'Error sending invitation notification',
        4000,
      )
    },
  })

  return {
    isLoading,
    isError,
    shiftInvitations,
    error,
    isFetched,
    refetch,
    rescindInvitation: rescindInvitationMutation.mutate,
    sendInvitations: sendInvitationsMutation.mutate,
    sendInvitationsAsync: sendInvitationsMutation.mutateAsync,
    sendInvitationsLoading: sendInvitationsMutation.isPending,
    resendInvitation: resendInvitationMutation.mutate,
    bulkResendInvitations: bulkResendInvitationsMutation.mutate,
  }
}
