export enum SupportedLanguage {
  en = 'en',
  es = 'es',
}

export type LanguageContentObj = {
  language: SupportedLanguage
  content: string
}

export const SUPPORTED_LANGUAGES = [SupportedLanguage.en, SupportedLanguage.es]

export const DEFAULT_LANGUAGE_CONTENT = {
  [SupportedLanguage.en]: '',
  [SupportedLanguage.es]: '',
}

export type LanguageContentMap = {
  [SupportedLanguage.en]?: string
  [SupportedLanguage.es]?: string
}

export enum QualificationQuestionAnswerQuestionType {
  FREEFORM = 'FREEFORM',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  ACKNOWLEDGMENT = 'ACKNOWLEDGMENT',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
}

export const QUESTION_TYPES_REQUIRING_ANSWER_OPTIONS = [
  QualificationQuestionAnswerQuestionType.MULTIPLE_CHOICE,
  QualificationQuestionAnswerQuestionType.SINGLE_CHOICE,
  QualificationQuestionAnswerQuestionType.ACKNOWLEDGMENT,
]

export const QUESTION_TYPES_REQUIRING_MORE_THAN_ONE_ANSWER_OPTION = [
  QualificationQuestionAnswerQuestionType.MULTIPLE_CHOICE,
  QualificationQuestionAnswerQuestionType.SINGLE_CHOICE,
]

export const QUALIFICATION_QUESTION_TYPE_TO_TEXT = {
  [QualificationQuestionAnswerQuestionType.FREEFORM]: 'Text Input',
  [QualificationQuestionAnswerQuestionType.SINGLE_CHOICE]: 'Single Choice',
  [QualificationQuestionAnswerQuestionType.MULTIPLE_CHOICE]: 'Multi-Choice',
  [QualificationQuestionAnswerQuestionType.ACKNOWLEDGMENT]: 'Acknowledge',
}

export type QualificationQuestionAnswerCreateParams = {
  questionTitle: LanguageContentObj[]
  questionDescription?: LanguageContentObj[]
  answerOptions?: LanguageContentObj[][]
  questionType: QualificationQuestionAnswerQuestionType
  applicationItemId?: string
}

export type QualificationQuestionAnswerUpdateParams = {
  questionId: string
  questionTitle?: LanguageContentObj[]
  questionDescription?: LanguageContentObj[] | null
  answerOptions?: LanguageContentObj[][]
  applicationItemId?: string
}
