import { Card, ScheduleOccurrenceSelector, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Company,
  Shift,
  ShiftRequest,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import { useState } from 'react'
import { EditScheduleShiftRequestDetailsBody } from './EditScheduleShiftRequestDetailsBody'

interface Props {
  company: Company
  shifts?: Shift[]
  selectedShiftRequest: ShiftRequest
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  onBack: () => void
  onSuccess: () => void
}

export const EditScheduleShiftRequestDetails = ({
  company,
  shiftRequestParent,
  selectedShiftRequest,
  shifts,
  onBack,
  onSuccess,
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>()

  return (
    <>
      <Card style={{ marginBottom: theme.space.sm }}>
        <Text variant="h6" color={theme.colors.Red70}>
          Note:
        </Text>
        <Text mt={theme.space.xs}>
          - You are in edit mode for a schedule, shift type and schedule
          recurring pattern are not editable.
        </Text>
        <Text>
          - RMSA option will be applied to all future shifts immediately.
        </Text>
      </Card>

      <ScheduleOccurrenceSelector
        selectedDate={selectedDate}
        onSelectDate={setSelectedDate}
        shiftRequest={selectedShiftRequest}
      />

      {selectedDate && (
        <EditScheduleShiftRequestDetailsBody
          company={company}
          shifts={shifts}
          selectedShiftRequest={selectedShiftRequest}
          shiftRequestParent={shiftRequestParent}
          selectedDate={selectedDate}
          onSuccess={onSuccess}
          onBack={onBack}
        />
      )}
    </>
  )
}
