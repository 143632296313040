import { INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS } from '@traba/consts'
import {
  RecurringSchedule,
  Schedule,
  ShiftRequest,
  ShiftRequestEdit,
  ShiftRequestEditType,
  ShiftRequestParentWithShiftRequest,
  ShiftStatus,
} from '@traba/types'
import {
  addWeeks,
  addYears,
  isAfter,
  isBefore,
  min,
  startOfDay,
} from 'date-fns'
import { RRule } from 'rrule'
import {
  anyToDate,
  isBeforeOrEqual,
  setPartsToUTCDate,
  setUTCPartsToDate,
} from '../dateUtils'

export const isRecurringShiftRequest = (
  shiftRequest: ShiftRequest,
): shiftRequest is ShiftRequest & {
  schedules: {
    isRecurringSchedule: true
    recurringSchedule: RecurringSchedule
  }[]
} => {
  return shiftRequest.schedules.some(
    (schedule) => schedule.isRecurringSchedule && !!schedule.recurringSchedule,
  )
}

export const isFiniteRecurringShiftRequest = (
  shiftRequest: ShiftRequest,
): shiftRequest is ShiftRequest & {
  schedules: {
    isRecurringSchedule: true
    recurringSchedule: RecurringSchedule & { endDate: Date }
  }[]
} => {
  return shiftRequest.schedules.some(
    (schedule) =>
      schedule.isRecurringSchedule &&
      !!schedule.recurringSchedule &&
      !!schedule.recurringSchedule.endDate,
  )
}

export const isShiftRequestInactive = (sr: ShiftRequest): boolean => {
  const today = new Date()
  return (
    sr.status === ShiftStatus.CANCELED ||
    sr.shiftRequestEdits?.some(
      (edit) =>
        edit.editType === ShiftRequestEditType.ALL_FUTURE &&
        edit.status === ShiftStatus.CANCELED,
    ) ||
    sr.schedules.some(
      (schedule) =>
        !schedule.isRecurringSchedule ||
        (schedule.recurringSchedule?.endDate &&
          isBefore(schedule.recurringSchedule?.endDate, today)),
    )
  )
}

export const isShiftRequestParentInactive = (
  srp: ShiftRequestParentWithShiftRequest,
): boolean => {
  return srp.shiftRequests.every(isShiftRequestInactive)
}

export const isScheduleFinite = (schedule: Schedule): boolean => {
  return !!schedule.recurringSchedule?.endDate
}

export const getStartTime = (schedules: Schedule[]): Date => {
  return min(schedules.map((s) => s.startTime))
}

export function getScheduleOccurrences(schedules: Schedule[]): Date[] {
  return schedules
    .flatMap((schedule) => {
      if (!schedule.recurringSchedule) {
        return []
      }
      const rruleInput = getZonedRRule({
        recurringSchedule: schedule.recurringSchedule,
        startTime: schedule.startTime,
        endDateIfInfinite: addYears(new Date(), 1),
      })
      return new RRule(rruleInput).all()
    })
    .map((d) => setUTCPartsToDate(d))
}

export function getZonedRRule({
  recurringSchedule,
  startTime,
  endDateIfInfinite,
}: {
  recurringSchedule: RecurringSchedule
  startTime: Date
  endDateIfInfinite?: Date
}) {
  // Repeat Every:
  const {
    interval = 1, // 2
    freq = 'WEEKLY', // weeks (WEEKLY)
    repeatOn, // on Monday, Wednesday ([MO, WE])
    endDate, // Until May 22nd
  } = recurringSchedule

  const dtstart = setPartsToUTCDate(startTime)
  const until =
    endDate ??
    endDateIfInfinite ??
    addWeeks(new Date(), INFINITE_SHIFT_REQ_CREATE_SHIFT_LEAD_TIME_WEEKS)

  switch (freq) {
    case 'WEEKLY':
      return {
        freq: RRule.WEEKLY,
        interval,
        byweekday: repeatOn.map((weekDay) => RRule[weekDay]),
        until,
        wkst: RRule.SU,
        dtstart,
      }
  }
}

export function mergeEditsOntoShiftRequest({
  shiftRequest,
  shiftRequestEdits,
  selectedDate,
}: {
  shiftRequest: ShiftRequest
  shiftRequestEdits: ShiftRequestEdit[]
  selectedDate: Date
}): ShiftRequest {
  if (!shiftRequestEdits || shiftRequestEdits.length === 0) {
    return shiftRequest
  }
  shiftRequestEdits.sort(
    (a, b) =>
      anyToDate(a.originalStartTime).getTime() -
      anyToDate(b.originalStartTime).getTime(),
  )

  const applicableEdits = shiftRequestEdits.filter(
    (edit) =>
      edit.editType === ShiftRequestEditType.ALL_FUTURE &&
      isBeforeOrEqual(
        startOfDay(anyToDate(edit.originalStartTime)),
        startOfDay(selectedDate),
      ),
  )
  let mergedShiftRequest: ShiftRequest = shiftRequest
  for (const edit of applicableEdits) {
    const {
      editType: _editType,
      shiftId: _shiftId,
      originalStartTime: _originalStartTime,
      shiftRequestEditId: _shiftRequestEditId,
      ...rest
    } = edit
    if (
      isAfter(
        startOfDay(anyToDate(_originalStartTime)),
        startOfDay(selectedDate),
      )
    ) {
      continue
    }
    mergedShiftRequest = {
      ...mergedShiftRequest,
      ...Object.fromEntries(
        Object.entries(rest).filter(([_, value]) => !!value), // Filters out null and undefined values
      ),
    }
  }
  return { ...mergedShiftRequest, shiftRequestEdits }
}
