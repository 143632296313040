import { LoadingSpinner } from '@traba/react-components'
import {
  doesWorkerAccountExistForWorkerApplication,
  ShiftInvitation,
  WorkerApplicationWithApplicant,
  WorkerApplicationWithWorkerInfo,
} from '@traba/types'
import { useCallback, useMemo, useState } from 'react'
import {
  useApplicationForEntity,
  useApplicationsSearch,
} from 'src/hooks/useApplications'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'
import { OpsExtendedShift } from 'src/hooks/useShifts'
import { useWorkerApplicationsForEntity } from 'src/hooks/useWorkerApplications'
import { useWorkerShifts } from 'src/hooks/useWorkerShifts'
import { ApplicationTableFilters } from 'src/screens/Applications/ApplicationTableFilters'

import { ShiftApplicantsTable } from './ShiftApplicantsTable'

export type ShiftApplicantsTableContainerProps = {
  shift?: OpsExtendedShift
}

export function ShiftApplicantsTableContainer({
  shift,
}: ShiftApplicantsTableContainerProps) {
  const { shiftId, shiftRequestId } = shift || {}

  const {
    shiftInvitations,
    isLoading: isLoadingShiftInvitations,
    rescindInvitation,
  } = useShiftInvitations(shiftId)

  const { workerApplications: unfilteredWorkerApplications, isLoading } =
    useWorkerApplicationsForEntity({
      shiftRequestId,
    })

  const { application } = useApplicationForEntity({
    shiftRequestId,
  })
  const { searchResults } = useApplicationsSearch({
    applicationId: application?.id,
  })
  const applicationSearchResult = searchResults?.items[0]

  const { workerShifts = [] } = useWorkerShifts(shiftId || '')
  const workerShiftWorkerIdsSet = useMemo(
    () => new Set(workerShifts.map((ws) => ws.workerId)),
    [workerShifts],
  )

  const handleRescindInvitation = useCallback(
    (shiftInvitation: ShiftInvitation) => {
      if (
        !window.confirm('Are you sure you want to rescind this invitation?')
      ) {
        return
      }
      rescindInvitation(shiftInvitation)
    },
    [rescindInvitation],
  )

  const [workerApplications, setWorkerApplications] = useState<
    WorkerApplicationWithWorkerInfo[]
  >(unfilteredWorkerApplications || [])

  const handleFilteredApplicationsChange = useCallback(
    // all shift request applicants should be real workers
    (workerApplications: WorkerApplicationWithApplicant[]) => {
      setWorkerApplications(
        workerApplications.filter(doesWorkerAccountExistForWorkerApplication),
      )
    },
    [],
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      {applicationSearchResult && unfilteredWorkerApplications && (
        <ApplicationTableFilters
          application={applicationSearchResult}
          unfilteredWorkerApplications={unfilteredWorkerApplications}
          onFilteredApplicationsChange={handleFilteredApplicationsChange}
        />
      )}
      <ShiftApplicantsTable
        shift={shift}
        applications={workerApplications}
        timezone={shift?.timezone}
        shiftInvitations={shiftInvitations ?? []}
        workerShiftWorkerIdsSet={workerShiftWorkerIdsSet}
        onRescindInvitation={handleRescindInvitation}
        isLoading={isLoadingShiftInvitations}
        companyId={shift?.companyId}
        roleId={shift?.roleId}
      />
    </>
  )
}
