import { SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ApplicationItemContent as ApplicationItemContentType,
  ApplicationItemType,
  QualificationQuestionAnswerQuestionType,
} from '@traba/types'
import { useGetQuestionTypeForQualifierQuestionAnswer } from 'src/hooks/useQualifierQuestionAnswer'
import { HyperlinkLocalizedText } from '../HyperlinkLocalizedText'
import { HelperTextContainer } from '../styles'
import { ApplicationItemCreationAdditionalProps } from '../types'

const ITEM_TYPE_TO_HELPER_TEXT: Record<ApplicationItemType, string> = {
  [ApplicationItemType.AI_VETTING_CALL]:
    'Prompt our AI Scout tool to message and call each worker before they are able to join the shift. <a href="https://docs.google.com/document/d/1aJRis-Km-fiLlhCTlHDx6uoe5r9ZuK_leQfviP3AkzY/edit?usp=sharing">Full guide here</a>',
  [ApplicationItemType.RESUME_UPLOAD]:
    'The worker will be prompted to upload a resume or choose an existing one.',
  [ApplicationItemType.CUSTOM_QUESTION]:
    'Select a question type and create a question for the worker to answer',
  [ApplicationItemType.OPS_MANUAL_STEP]:
    'Create a step that blocks the worker from proceeding to join the shift until you take action. This could be a step for ops to call the worker and confirm their shift, to schedule a business interview, or to follow up with shift-specific instructions.',
  [ApplicationItemType.DRUG_SCREENING]:
    'Require the worker to take a drug screening test before they are able to join the shift',
  [ApplicationItemType.GHOST_PROFILE_LINKED]: '',
  [ApplicationItemType.VETTING_CAMPAIGN_SPREADSHEET]: '',
}

const getHelperTextForCustomQuestion = (
  questionType?: QualificationQuestionAnswerQuestionType,
) => {
  switch (questionType) {
    case QualificationQuestionAnswerQuestionType.FREEFORM:
      return 'Create a question where the worker is able to type out a free response answer in an input field'
    case QualificationQuestionAnswerQuestionType.MULTIPLE_CHOICE:
      return 'Create a question where the worker is able to select more than one answer choice from the ones defined below'
    case QualificationQuestionAnswerQuestionType.SINGLE_CHOICE:
      return 'Create a question where the worker is able to select only one answer choice from the ones defined below'
    case QualificationQuestionAnswerQuestionType.ACKNOWLEDGMENT:
      return 'Show a confirmation statement that the worker has to click the checkbox on before they are able to proceed'
    default:
      return ITEM_TYPE_TO_HELPER_TEXT[ApplicationItemType.CUSTOM_QUESTION]
  }
}
type HelperInfoForApplicationItemProps = {
  applicationItemType?: ApplicationItemType
  applicationItemSourceId?: string
  itemContent?: ApplicationItemContentType
  style?: React.CSSProperties
} & ApplicationItemCreationAdditionalProps

export const HelperInfoForApplicationItem = ({
  applicationItemType,
  applicationItemSourceId,
  itemContent = {},
  style,
}: HelperInfoForApplicationItemProps) => {
  // Get additional info needed from the application item source id
  const qualifierQuestionAnswerType =
    useGetQuestionTypeForQualifierQuestionAnswer(
      applicationItemType === ApplicationItemType.CUSTOM_QUESTION
        ? applicationItemSourceId
        : undefined,
    )

  if (!applicationItemType) {
    return null
  }

  let helperText = ITEM_TYPE_TO_HELPER_TEXT[applicationItemType]

  if (applicationItemType === ApplicationItemType.CUSTOM_QUESTION) {
    const questionType =
      'createQualifierQuestionRequestDto' in itemContent
        ? itemContent?.createQualifierQuestionRequestDto?.questionType
        : qualifierQuestionAnswerType

    helperText = getHelperTextForCustomQuestion(questionType)
  }

  if (!helperText) {
    return null
  }

  return (
    <HelperTextContainer style={style}>
      <div>
        <SvgIcon size={20} name="alert" color={theme.colors.brand} />
      </div>
      <HyperlinkLocalizedText text={helperText} variant="h7" />
    </HelperTextContainer>
  )
}
