import { SearchSelect, IMenuItem } from '@traba/react-components'
import { AccountApprovalStatus } from '@traba/types'
import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { OrFields } from '../../hooks/searchWorkersApi'
import { useSearchWorkers } from '../../hooks/useWorkers'
import { PopulatedWorker } from '../../screens/WorkerSearchScreen/worker-search.types'

const SEARCH_LIMIT = 20
const DEFAULT_STATUSES = [
  AccountApprovalStatus.Approved,
  AccountApprovalStatus.Suspended,
  AccountApprovalStatus.Unapproved,
]

interface WorkerMenuItem extends IMenuItem<string> {
  worker: PopulatedWorker
}

interface WorkerSearchSelectProps {
  label?: string
  placeholder?: string
  selectedWorkers: PopulatedWorker[]
  onSelectWorkers: (workers: PopulatedWorker[]) => void
  errorMessage?: string
  width?: number | string
}

export const WorkerSearchSelect = ({
  label,
  placeholder = '-',
  selectedWorkers,
  onSelectWorkers,
  errorMessage,
  width,
}: WorkerSearchSelectProps) => {
  const [searchText, setSearchText] = useState('')

  const { isLoading, searchWorkers, workersSearchResult } = useSearchWorkers()

  const workerOptions: WorkerMenuItem[] = workersSearchResult.map((worker) => ({
    value: worker.id || '',
    label: `${worker.firstName} ${worker.lastName}`,
    secondaryLabel: worker.phoneNumber || '',
    worker,
  }))

  const selectedItems: WorkerMenuItem[] = selectedWorkers.map((worker) => ({
    value: worker.id || '',
    label: `${worker.firstName} ${worker.lastName}`,
    secondaryLabel: worker.phoneNumber || '',
    worker,
  }))

  const runSearch = useCallback(
    (text: string) =>
      searchWorkers({
        searchData: {
          accountStatuses: DEFAULT_STATUSES,
        },
        direction: 'initial',
        activeOrFields: [
          OrFields.firstName,
          OrFields.lastName,
          OrFields.id,
          OrFields.phoneNumber,
        ],
        limit: SEARCH_LIMIT,
        includes: { workerMetric: true },
        cacheKey: 'worker-search-select',
        fullTextSearchParam: text,
      }),
    [searchWorkers, searchText],
  )

  const debouncedSearchRef = useRef(
    debounce((text: string) => {
      runSearch(text)
    }, 200),
  )

  useEffect(() => {
    debouncedSearchRef.current(searchText)
  }, [searchText])

  const handleSearchChange = (text: string) => {
    setSearchText(text)
  }

  const handleSelectWorker = (item: IMenuItem<string> | undefined) => {
    if (item) {
      const workerItem = item as WorkerMenuItem
      onSelectWorkers([workerItem.worker])
    } else {
      onSelectWorkers([])
    }
  }

  return (
    <div>
      <SearchSelect
        label={label}
        placeholder={placeholder}
        options={workerOptions}
        selectItem={selectedItems.length > 0 ? selectedItems[0] : undefined}
        handleSelect={handleSelectWorker}
        errorMessage={errorMessage}
        multiple={false}
        width={width}
        isLoading={isLoading}
        shouldAlsoSearchSecondaryLabel={true}
        showClearButton
        runOnTextFieldChange={handleSearchChange}
      />
    </div>
  )
}
