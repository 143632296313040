import { Button, ButtonVariant, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AddRecurringWorkerShiftError,
  AddRecurringWorkerShiftResponse,
} from '@traba/types'
import { truncateString } from '@traba/utils'
import { CopyTextIcon, DataTable } from 'src/components/base'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { AddWorkerToFiniteScheduleResults } from './AddWorkerToFiniteScheduleResults'
import { AddWorkerToNoEndScheduleResults } from './AddWorkerToNoEndScheduleResults'

interface Props {
  responses: (AddRecurringWorkerShiftResponse | AddRecurringWorkerShiftError)[]
  checkedWorkers: PopulatedWorker[]
  onDone: () => void
  isScheduleFinite?: boolean
}

export const AddWorkerToScheduleResults = ({
  responses,
  checkedWorkers,
  onDone,
  isScheduleFinite,
}: Props) => {
  return (
    <>
      <DataTable
        headers={['Name', 'Worker ID', 'Results']}
        rows={responses?.map((response) => {
          const { workerId, fulfilled, rejected, addedToSchedule } = response
          const errorMessage =
            'errorMessage' in response ? response.errorMessage : ''
          const worker = checkedWorkers.find((cw) => cw.id === workerId)
          if (!worker) {
            throw new Error(
              `Worker ${workerId} not found from the original add list`,
            )
          }

          return {
            key: workerId,
            cells: [
              {
                renderFn: () => (
                  <div>
                    {worker.firstName} {worker.lastName}
                  </div>
                ),
              },
              {
                renderFn: () => (
                  <>
                    {truncateString(workerId)}
                    <CopyTextIcon textToCopy={workerId} />{' '}
                  </>
                ),
              },
              {
                renderFn: () => {
                  return isScheduleFinite ? (
                    <AddWorkerToFiniteScheduleResults
                      fulfilled={fulfilled}
                      rejected={rejected}
                      errorMessage={errorMessage}
                    />
                  ) : (
                    <AddWorkerToNoEndScheduleResults
                      addedToSchedule={addedToSchedule}
                      fulfilled={fulfilled}
                      rejected={rejected}
                      errorMessage={errorMessage}
                    />
                  )
                },
              },
            ],
          }
        })}
      />
      <Row justifyEnd mt={theme.space.lg}>
        <Button variant={ButtonVariant.FILLED} onClick={onDone}>
          Done
        </Button>
      </Row>
    </>
  )
}
