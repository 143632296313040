import { ButtonVariant, SvgIcon, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { StyledButton } from './styles'

interface RemoveStepButtonProps {
  onRemoveStep: () => void
  isLoading?: boolean
}

export function RemoveStepButton({
  onRemoveStep,
  isLoading,
}: RemoveStepButtonProps) {
  return (
    <StyledButton
      disabled={isLoading}
      loading={isLoading}
      variant={ButtonVariant.REDOUTLINED}
      onClick={onRemoveStep}
      iconPadding="0"
      leftIcon={
        <SvgIcon
          name="trash"
          type="svg"
          size={theme.space.xs}
          color={theme.colors.red}
        />
      }
    >
      <Text
        ml={theme.space.xxs}
        variant="h6"
        style={{ color: theme.colors.red }}
      >
        Remove Step
      </Text>
    </StyledButton>
  )
}
