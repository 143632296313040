import { Col } from '@traba/react-components'
import {
  ApplicationItemType,
  WorkerApplicationItem,
  WorkerApplicationItemStatus,
} from '@traba/types'
import { CustomQuestionApplicationItemResults } from './items/CustomQuestionApplicationItemResults'
import { GhostProfileLinkedApplicationItemResults } from './items/GhostProfileLinkedApplicationItemResults'
import { OpsManualStepApplicationItemResults } from './items/OpsManualStepApplicationItemResults'
import { ResumeUploadApplicationItemResults } from './items/ResumeUploadApplicationItemResults/ResumeUploadApplicationItemResults'
import { VettingCampaignSpreadsheetApplicationItemResults } from './items/VettingCampaignSpreadsheetApplicationItemResults'
import { WorkerVettingCallApplicationItemResults } from './items/WorkerVettingCallApplicationItemResults'

interface WorkerApplicationItemResultsProps {
  workerApplicationItem: WorkerApplicationItem
  workerId?: string
}

export function WorkerApplicationItemResults({
  workerApplicationItem,
  workerId,
}: WorkerApplicationItemResultsProps) {
  const defaultContent = <Col>Nothing to display</Col>

  switch (workerApplicationItem.applicationItem.applicationItemType) {
    case ApplicationItemType.AI_VETTING_CALL:
      return (
        <WorkerVettingCallApplicationItemResults
          applicationItemSourceId={workerApplicationItem.sourceId || ''}
        />
      )
    case ApplicationItemType.RESUME_UPLOAD:
      return (
        <ResumeUploadApplicationItemResults
          applicationItemSourceId={workerApplicationItem.sourceId}
          workerId={workerId}
        />
      )
    case ApplicationItemType.VETTING_CAMPAIGN_SPREADSHEET:
      return (
        <VettingCampaignSpreadsheetApplicationItemResults
          applicationItemSourceId={
            workerApplicationItem.applicationItem.sourceId
          }
        />
      )
    case ApplicationItemType.GHOST_PROFILE_LINKED:
      return (
        <GhostProfileLinkedApplicationItemResults
          hasWorkerCreatedAccount={
            workerApplicationItem.status ===
            WorkerApplicationItemStatus.COMPLETED
          }
        />
      )
    case ApplicationItemType.OPS_MANUAL_STEP:
      return (
        <OpsManualStepApplicationItemResults
          applicationItemId={workerApplicationItem.applicationItem.id}
        />
      )
    case ApplicationItemType.CUSTOM_QUESTION:
      return (
        <CustomQuestionApplicationItemResults
          applicationItemSourceId={
            workerApplicationItem.applicationItem.sourceId || ''
          }
          workerApplicationItemId={workerApplicationItem.id}
        />
      )

    default:
      return defaultContent
  }
}
