import { CircularProgress } from '@mui/material'
import { ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Shift, ShiftRequest } from '@traba/types'
import { addMonths, endOfDay, startOfToday } from 'date-fns'
import { useState } from 'react'
import { Button, Row } from 'src/components/base'
import { AddWorkerToScheduleModal } from 'src/components/Schedule/AddWorkerToSchedule/AddWorkerToScheduleModal'
import { useCompanyShifts } from 'src/hooks/useCompanyShifts'
import { useWorkersOnSchedule } from 'src/hooks/useWorkersOnSchedule'
import {
  ActionConfirmationOption,
  ActionConfirmation,
} from 'src/modals/EditShiftModal/ActionConfirmation'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'

export const AddWorkersToShiftsOrSchedule = ({
  shift,
  workers,
  shiftRequestParentId,
  shiftRequests,
  selectShiftIcon,
  selectScheduleIcon,
  openModal,
}: {
  shift: Shift
  workers: PopulatedWorker[]
  shiftRequestParentId: string
  shiftRequests: ShiftRequest[]
  selectShiftIcon: React.ReactNode
  selectScheduleIcon: React.ReactNode
  openModal: () => void
}) => {
  const [showAddWorkersToScheduleModal, setShowAddWorkersToScheduleModal] =
    useState<boolean>(false)
  const [showAddWorkersActions, setShowAddWorkersActions] =
    useState<boolean>(false)

  const searchParams = {
    shiftRequestParentIds: [shiftRequestParentId],
    companyId: shift.companyId,
    startAfter: startOfToday(),
    startBefore: endOfDay(addMonths(new Date(), 1)),
  }

  const { rawShifts } = useCompanyShifts({
    searchParams,
    queryRecurringShifts: true,
  })

  const {
    isLoading: isLoadingScheduledWorkers,
    refetch: refetchWorkersOnSchedule,
  } = useWorkersOnSchedule({
    workerOnScheduleParams: {},
    shiftRequestParentId: shiftRequestParentId,
  })

  if (isLoadingScheduledWorkers) {
    return <CircularProgress size={theme.space.med} />
  }

  const shifts = rawShifts?.pages.flatMap((page) => page?.data)
  const shiftRequestIdWithOneShift = shifts?.reduce((acc, shift) => {
    if (!shift.shiftRequestId) {
      return acc
    }
    if (!acc.has(shift.shiftRequestId)) {
      acc.set(shift.shiftRequestId, shift)
    }
    return acc
  }, new Map<string, Shift>())

  const AddWorkersActions = ({ isOpen }: { isOpen: boolean }) => {
    const addWorkerOptions: ActionConfirmationOption[] = [
      {
        icon: selectShiftIcon,
        title: 'I want to add workers to specific shift(s)',
        helperText: 'You can select the exact set of shifts to add workers to.',
        buttonText: 'Add Workers to Shift(s)',
        onButtonClick: () => {
          openModal()
          setShowAddWorkersActions(false)
        },
        buttonVariant: ButtonVariant.FILLED,
      },

      {
        icon: selectScheduleIcon,
        title: 'I want to add workers to the schedule',
        helperText:
          'You can add workers to all shifts in the schedule starting on any particular date for all future shifts.',
        buttonText: 'Add Workers to Entire Schedule',
        onButtonClick: () => {
          setShowAddWorkersToScheduleModal(true)
          setShowAddWorkersActions(false)
        },
        buttonVariant: ButtonVariant.BRANDNEW,
      },
    ]

    return (
      <Row>
        <ActionConfirmation
          isOpen={isOpen}
          onClose={() => setShowAddWorkersActions(false)}
          options={addWorkerOptions}
          title="Add Workers to Shift"
        />
      </Row>
    )
  }

  const AddWorkersButton = () => {
    return (
      <Button
        onClick={() => setShowAddWorkersActions(true)}
        style={{
          marginLeft: theme.space.xxs,
        }}
      >
        Add workers to multiple shifts
      </Button>
    )
  }

  return (
    <>
      <AddWorkersButton />

      <AddWorkerToScheduleModal
        handleClose={() => {
          setShowAddWorkersToScheduleModal(false)
        }}
        isOpen={showAddWorkersToScheduleModal}
        shiftRequestIdWithOneShift={
          shiftRequestIdWithOneShift ?? new Map<string, Shift>()
        }
        shiftRequests={shiftRequests}
        refetchWorkersOnSchedule={refetchWorkersOnSchedule}
        checkedWorkers={workers}
      />
      <AddWorkersActions isOpen={showAddWorkersActions} />
    </>
  )
}
