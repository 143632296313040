import { Badge, BadgeVariant } from '@traba/react-components'
import { WorkerApplicationItemCompletionStatus } from '@traba/types'
import { MouseEventHandler } from 'react'
import styled from 'styled-components'

export type WorkerApplicationItemCompletionStatusBadgeProps = {
  itemCompletionStatus: WorkerApplicationItemCompletionStatus
  style?: React.CSSProperties
  onClick?: MouseEventHandler<HTMLDivElement>
}

const APPLICATION_ITEM_COMPLETION_STATUS_TO_BADGE_VARIANT: Record<
  WorkerApplicationItemCompletionStatus,
  BadgeVariant
> = {
  [WorkerApplicationItemCompletionStatus.ELIGIBLE]: BadgeVariant.SUCCESS,
  [WorkerApplicationItemCompletionStatus.INELIGIBLE]: BadgeVariant.DARK_ORANGE,
  [WorkerApplicationItemCompletionStatus.OPS_WAIVED]: BadgeVariant.INFO,
  [WorkerApplicationItemCompletionStatus.OPS_MARKED_ELIGIBLE]:
    BadgeVariant.SUCCESS,
  [WorkerApplicationItemCompletionStatus.OPS_MARKED_INELIGIBLE]:
    BadgeVariant.DARK_ORANGE,
  [WorkerApplicationItemCompletionStatus.INCONCLUSIVE]:
    BadgeVariant.DARK_ORANGE,
  [WorkerApplicationItemCompletionStatus.EXPIRED]: BadgeVariant.DARK_RED,
}

const CUSTOM_STATUS_TO_TEXT: Record<
  WorkerApplicationItemCompletionStatus,
  string
> = {
  [WorkerApplicationItemCompletionStatus.ELIGIBLE]: 'Eligible',
  [WorkerApplicationItemCompletionStatus.INELIGIBLE]: 'Ineligible',
  [WorkerApplicationItemCompletionStatus.OPS_WAIVED]: 'Ops Waived',
  [WorkerApplicationItemCompletionStatus.OPS_MARKED_ELIGIBLE]: 'Ops Eligible',
  [WorkerApplicationItemCompletionStatus.OPS_MARKED_INELIGIBLE]:
    'Ops Ineligible',
  [WorkerApplicationItemCompletionStatus.INCONCLUSIVE]: 'Inconclusive',
  [WorkerApplicationItemCompletionStatus.EXPIRED]: 'Expired',
}

const ClickableBadge = styled(Badge)<{ $clickable?: boolean }>`
  ${(props) =>
    props.$clickable &&
    `
    cursor: pointer;
    transition: opacity 0.2s ease, transform 0.2s ease;

    &:hover {
      opacity: 0.85;
      transform: scale(1.1);
    }
  `}
`

export default function WorkerApplicationItemCompletionStatusBadge({
  itemCompletionStatus,
  style,
  onClick,
}: WorkerApplicationItemCompletionStatusBadgeProps) {
  const badgeVariant =
    APPLICATION_ITEM_COMPLETION_STATUS_TO_BADGE_VARIANT[itemCompletionStatus]

  return (
    <ClickableBadge
      $clickable={!!onClick}
      style={style}
      title={CUSTOM_STATUS_TO_TEXT[itemCompletionStatus]}
      variant={badgeVariant}
      onClick={onClick}
    />
  )
}
