import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { EmploymentType } from '@traba/types'
import { useState } from 'react'
import { Button, Col, Icon, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import {
  TableRow,
  TableCell,
  DataTableHeader,
  DataTable,
} from 'src/components/base/Table/DataTable'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import {
  GetPendingRequestResponseDTO,
  usePendingAdjustments,
} from '../../../../hooks/usePendingAdjustments'
import PendingAdjustmentReviewModal from './PendingAdjustmentReviewModal'

interface PendingAdjustmentsProps {
  employmentType: EmploymentType
}

export const PendingAdjustments = ({
  employmentType,
}: PendingAdjustmentsProps) => {
  const {
    isLoading,
    isFetching,
    pendingAdjustments,
    error,
    refetch: refetchPendingAdjustments,
    editPendingAdjustment,
  } = usePendingAdjustments(employmentType)
  const [reviewingAdjustment, setReviewingAdjustment] =
    useState<GetPendingRequestResponseDTO | null>(null)
  const tableHeaders: DataTableHeader[] = [
    {
      key: 'date',
      label: 'Created At',
      sortable: true,
    },
    {
      key: 'companyName',
      label: 'Company Name',
    },
    {
      key: 'workerName',
      label: 'Worker Name',
    },
    {
      key: 'shiftRole',
      label: 'Shift Role',
    },
    {
      key: 'regionId',
      label: 'Region Id',
    },
    {
      key: 'actions',
      label: '',
    },
  ]

  const tableRows: TableRow[] =
    pendingAdjustments?.map((pA) => {
      const createdAt = new Date(pA.createdAt)
      const worker = pA.workerShift.worker
      const tableCells: TableCell[] = [
        {
          key: pA.id + 'createdAt',
          renderFn: () => formatDateTimeWithTimezone(createdAt),
          sortKey: Date.parse(pA.createdAt),
        },
        {
          key: pA.id + 'companyName',
          renderFn: () => (
            <Link
              to={`/companies/${pA.workerShift.shift.company.id}`}
              target="_blank"
            >
              <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                {pA.workerShift.shift.company.employerName}
              </Button>
            </Link>
          ),
        },
        {
          key: pA.id + 'workerName',
          renderFn: () => (
            <Link to={`/workers/${worker.id}`} target="_blank">
              <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                {worker.firstName + ' ' + worker.lastName}
              </Button>
            </Link>
          ),
        },
        {
          key: pA.id + 'shiftRole',
          renderFn: () => (
            <Link to={`/field-monitor/${pA.shiftId}`} target="_blank">
              <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                {pA.workerShift.shift.role.roleName}
              </Button>
            </Link>
          ),
        },
        {
          key: pA.id + 'regionId',
          renderFn: () => pA.workerShift.shift.regionId || 'N/A',
        },
        {
          key: pA.id + 'review',
          renderFn: () => (
            <Button
              style={{ margin: theme.space.xxs }}
              onClick={() => setReviewingAdjustment(pA)}
            >
              Review
            </Button>
          ),
        },
      ]
      return {
        cells: tableCells,
        key: pA.id,
      }
    }) || []

  return (
    <Col
      mt={theme.space.sm}
      style={{
        border: `2px solid ${theme.colors.Grey20}`,
        padding: theme.space.xs,
        borderRadius: theme.space.xxs,
      }}
    >
      <Row gap={theme.space.xxs}>
        <Text variant="h4">Pending Adjustments</Text>

        <Button
          variant={ButtonVariant.TEXT}
          leftIcon={<Icon name="refresh" />}
          style={{
            padding: 0,
          }}
          onClick={() => refetchPendingAdjustments()}
        >
          Refresh
        </Button>
      </Row>

      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: theme.space.sm,
        }}
      >
        {isLoading || isFetching ? (
          <CircularProgress />
        ) : (
          <DataTable
            headers={tableHeaders}
            rows={tableRows}
            initialSortByAscOrder={false}
          />
        )}
        {error && <p>Something went wrong: {(error as Error).message}</p>}
        {reviewingAdjustment && (
          <PendingAdjustmentReviewModal
            isOpen
            onClose={() => {
              setReviewingAdjustment(null)
              refetchPendingAdjustments()
            }}
            pendingAdjustment={reviewingAdjustment}
            editPendingAdjustment={editPendingAdjustment}
          />
        )}
      </Row>
    </Col>
  )
}

export default PendingAdjustments
