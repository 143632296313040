export interface PushToken {
  email: string
  token: string
  createdAt: Date
}

export enum PushDataSource {
  WorkerCertificationReviewed = 'WorkerCertificationReviewed',
  NewShiftAvailable = 'NewShiftAvailable',
  ShiftDetails = 'ShiftDetails',
  PastShift = 'PastShift',
  ScheduledShift = 'ScheduledShift',
  TrainingShift = 'TrainingShift',
  AccountStatus = 'AccountStatus',
  BusinessCancel = 'BusinessCancel',
  BusinessToWorkerAnnouncements = 'BusinessToWorkerAnnouncements',
  OpsCancel = 'OpsCancel',
  MessageSummary = 'MessageSummary',
  PersonalInformation = 'PersonalInformation',
  SUPERVISOR_SHIFTS_REVIEW = 'SupervisorShiftsReview',
  SUPERVISOR_PRE_SHIFT = 'SupervisorPreShift',
  SHIFT_CONFIRM = 'ShiftConfirm',
  SHIFT_REMINDER = 'ShiftReminder',
  Resume = 'Resume',
}
export interface PushData {
  source: PushDataSource
}
export interface WorkerCertificationPushData extends PushData {
  source: PushDataSource.WorkerCertificationReviewed
  certificationId: string
}

export interface ShiftDetailsPushData extends PushData {
  source: PushDataSource.ShiftDetails
  shiftId: string
}
export interface NewShiftAvailablePushData extends PushData {
  source: PushDataSource.NewShiftAvailable
  shiftId: string
}
export interface PaymentPushData extends PushData {
  source: PushDataSource.PastShift
  shiftId: string
}

export interface ScheduledShiftData extends PushData {
  source: PushDataSource.ScheduledShift
  shiftId: string
}

export type PushNotificationDto = {
  title: string
  body: string
  data:
    | ShiftDetailsPushData
    | NewShiftAvailablePushData
    | ScheduledShiftData
    | WorkerCertificationPushData
    | PaymentPushData
}

export interface ShiftDetailsOnlyPushNotification extends PushNotificationDto {
  data: ShiftDetailsPushData | NewShiftAvailablePushData | ScheduledShiftData
}
