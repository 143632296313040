import { GridColDef } from '@mui/x-data-grid'

import { GridRowSelectionModel } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { Anchor, Row } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import {
  RobocallCommunication,
  SentinelNotification,
  SentinelTriggerBasedEventType,
  Worker,
  WorkerShiftForOps,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { useMemo } from 'react'
import { formatPhoneNumber, truncateString } from 'src/utils/stringUtils'
import { useCompanyConnections } from '../../../hooks/useConnections'
import { useShiftInvitations } from '../../../hooks/useShiftInvitations'
import { sortByProp } from '../../../utils/helperUtils'
import { getWorkerShiftStringId } from '../../../utils/workerShiftUtils'
import { CopyTextIcon } from '../../base'
import WorkerBusinessApprovalScore from '../../WorkerTable/components/WorkerBusinessApprovalScore'
import WorkerReliabilityScore from '../../WorkerTable/components/WorkerReliabilityScore'
import WorkerTableConfirmationIcon from '../../WorkerTable/components/WorkerTableConfirmationIcon'
import WorkerTableJobStatusCell from '../../WorkerTable/components/WorkerTableJobStatusCell'
import { SentinelWorkerNameCell } from './SentinelWorkerNameCell'
import { SentinelTableDataGridStyle } from './styles'

function makeBackfillCallNoSignUpColumn() {
  const columns: GridColDef[] = [
    {
      field: 'workerName',
      headerName: 'Name',
      minWidth: 150,
      width: 150,
      renderCell: (params) => <SentinelWorkerNameCell params={params} />,
      flex: 1,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone #',
      minWidth: 80,
      renderCell: (params) => (
        <>
          <Anchor href={`tel:${params.value}`}>
            {formatPhoneNumber(params.value)}
          </Anchor>
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
    {
      field: 'workerId',
      headerName: 'Worker ID',
      minWidth: 80,
      renderCell: (params) => (
        <>
          {params.value}
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 2,
    },
  ]

  return columns
}

const makeWorkerDetailsColumns = (notification: SentinelNotification) => {
  const columns: GridColDef[] = [
    {
      field: 'workerName',
      headerName: 'Name',
      width: 150,
      minWidth: 150,
      renderCell: (params) => <SentinelWorkerNameCell params={params} />,
      flex: 1,
    },
    {
      field: 'jobStatus',
      headerName: 'Status',
      width: 140,
      renderCell: (params) => {
        return (
          <WorkerTableJobStatusCell
            workerShift={params.value}
            timezone={notification.data.shift.timezone}
          />
        )
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone #',
      width: 130,
      minWidth: 130,
      renderCell: (params) => (
        <>
          <Anchor href={`tel:${params.value}`}>
            {formatPhoneNumber(truncateString(params.value, 8))}
          </Anchor>
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
    {
      field: 'reliability',
      headerName: 'Rel',
      width: 80,
      renderCell: (params) => (
        <WorkerReliabilityScore workerId={params.row.id} />
      ),
    },
    {
      field: 'confirmation',
      headerName: 'Conf',
      width: 80,
      renderCell: (params) => (
        <WorkerTableConfirmationIcon
          workerShift={params.value}
          latestRobocall={params.value.latestRobocall}
        />
      ),
    },
    {
      field: 'businessApproval',
      headerName: 'Biz Aprv',
      width: 80,
      renderCell: (params) => (
        <WorkerBusinessApprovalScore workerId={params.row.id} />
      ),
    },
    {
      field: 'workerId',
      headerName: 'Worker ID',
      minWidth: 80,
      width: 50,
      renderCell: (params) => (
        <>
          {truncateString(params.value, 4)}
          <CopyTextIcon textToCopy={params.value} />
        </>
      ),
      flex: 1,
    },
  ]

  return columns
}

function makeBackfillCallNoSignUpRow(
  notification: SentinelNotification,
  isInvitedWorker = false,
  isFavoriteWorker = false,
) {
  return [
    {
      id: notification.data.worker?.workerId ?? `temp-${Date.now()}`,
      workerId: notification.data.worker?.workerId,
      workerName: `${notification.data.worker?.firstName} ${notification.data.worker?.lastName}`,
      phoneNumber: notification.data.worker?.phoneNumber,
      isFavoriteWorker,
      isInvitedWorker,
    },
  ]
}

const makeWorkerDetailsRow = (
  workerShift: WorkerShiftWithWorkerDetails & {
    latestRobocall?: RobocallCommunication
  },
  isInvitedWorker = false,
  isFavoriteWorker = false,
) => {
  return {
    id: workerShift.workerId,
    workerId: workerShift.workerId,
    workerName: `${workerShift.worker?.firstName} ${workerShift.worker?.lastName}`,
    phoneNumber: workerShift.worker?.phoneNumber,
    isFirstTimeWorkerShiftWithCompany:
      workerShift.isFirstTimeWorkerShiftWithCompany,
    isFavoriteWorker,
    isInvitedWorker,
    confirmation: workerShift,
    jobStatus: workerShift,
  }
}

const groupRobocallsByWorkerShift = (
  robocalls: RobocallCommunication[] = [],
): Map<string, RobocallCommunication[]> => {
  const robocallsByWorkerShift = new Map<string, RobocallCommunication[]>()
  for (const robocall of robocalls) {
    const key = getWorkerShiftStringId(
      robocall.workerId,
      robocall.relatedShiftId || '',
    )
    let workerShiftRobocalls = robocallsByWorkerShift.get(key)
    if (!workerShiftRobocalls) {
      workerShiftRobocalls = []
      robocallsByWorkerShift.set(key, workerShiftRobocalls)
    }
    workerShiftRobocalls.push(robocall)
  }
  return robocallsByWorkerShift
}

export const SentinelWorkerDetailsTable = ({
  workerShifts,
  robocalls,
  handleSelectWorkers,
  notification,
  slim,
}: {
  workerShifts: WorkerShiftForOps[]
  handleSelectWorkers: (workersSelected: GridRowSelectionModel) => void
  robocalls?: RobocallCommunication[]
  notification: SentinelNotification
  slim?: boolean
}) => {
  const { shift } = notification.data
  const { shiftInvitations, isLoading: isLoadingShiftInvitations } =
    useShiftInvitations(shift.shiftId)
  const { favoriteWorkers, isLoading: isLoadingFavorites } =
    useCompanyConnections(
      shift.companyId || workerShifts[0].shiftInfo.companyId,
    )

  const isBackfillCallNoSignUpNotif =
    notification.eventType ===
    SentinelTriggerBasedEventType.WORKER_ACCEPTED_BACKFILL_CALL_NO_SIGNUP

  const columns = useMemo(() => {
    return isBackfillCallNoSignUpNotif
      ? makeBackfillCallNoSignUpColumn()
      : makeWorkerDetailsColumns(notification)
  }, [isBackfillCallNoSignUpNotif, notification])

  const rows = useMemo(() => {
    const favoriteWorkerIds = new Set(
      favoriteWorkers.map((worker: Worker) => worker.uid),
    )
    const invitationsIdSet = new Set(
      shiftInvitations?.map((i) => i.worker.id) || [],
    )

    if (isBackfillCallNoSignUpNotif) {
      const targetWorkerId = notification.data.worker?.workerId
      const isInvitedWorker =
        !!targetWorkerId && invitationsIdSet.has(targetWorkerId)
      const isFavoriteWorker =
        !!targetWorkerId && favoriteWorkerIds.has(targetWorkerId)
      return makeBackfillCallNoSignUpRow(
        notification,
        isInvitedWorker,
        isFavoriteWorker,
      )
    }
    const robocallsByWorkerShift = groupRobocallsByWorkerShift(robocalls)
    return workerShifts.map((workerShift) => {
      const isInvitedWorker = invitationsIdSet.has(workerShift.workerId)
      const isFavoriteWorker = favoriteWorkerIds.has(workerShift.workerId)
      const key = getWorkerShiftStringId(
        workerShift.workerId,
        workerShift.shiftId,
      )
      const latestRobocall = robocallsByWorkerShift
        .get(key)
        ?.sort(sortByProp('createdAt', 'DESC'))[0]
      return makeWorkerDetailsRow(
        { ...workerShift, latestRobocall },
        isInvitedWorker,
        isFavoriteWorker,
      )
    })
  }, [
    isBackfillCallNoSignUpNotif,
    notification,
    workerShifts,
    robocalls,
    favoriteWorkers,
    shiftInvitations,
  ])

  return (
    <Row
      style={{
        height: slim ? 'auto' : 300,
        width: '100%',
        overflow: 'auto',
      }}
    >
      <DataGrid
        {...SentinelTableDataGridStyle}
        rows={rows}
        columns={columns}
        localeText={{
          footerRowSelected: (count: number) =>
            `${count} worker${makePlural(count)} selected`,
        }}
        onRowSelectionModelChange={handleSelectWorkers}
        loading={isLoadingShiftInvitations || isLoadingFavorites}
      />
    </Row>
  )
}
