import Drawer from '@mui/material/Drawer'
import {
  Col,
  LoadingSpinner,
  Row,
  Text,
  Button,
  ButtonVariant,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'src/components/base'
import { useApplicationForEntity } from 'src/hooks/useApplications'
import { ViewAndEditExistingApplicationItemsList } from 'src/screens/Applications/ViewAndEditExistingApplicationItemsList'
interface ViewApplicationDrawerProps {
  isOpen: boolean
  onOpenDrawer: () => void
  onCloseDrawer: () => void
  shiftRequestId?: string
  roleId?: string
  analyticsSource: string
  companyId?: string
}

export const ViewApplicationDrawer = ({
  isOpen,
  onOpenDrawer,
  onCloseDrawer,
  companyId,
  shiftRequestId,
  analyticsSource = 'view-application-drawer',
  roleId,
}: ViewApplicationDrawerProps) => {
  const { application, isLoading } = useApplicationForEntity({
    shiftRequestId,
    roleId: shiftRequestId ? undefined : roleId, // prioritize shift request if it exists
  })
  const navigate = useNavigate()

  const handleRedirectToCreateApplication = () => {
    navigate(`/applications`)
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  // TODO(gail): Add redirect to create application page when ready
  const showApplicationCreateButton = false

  if (!application && showApplicationCreateButton) {
    return (
      <Button
        onClick={handleRedirectToCreateApplication}
        leftIcon={<Icon name="plus" />}
      >
        Create new application
      </Button>
    )
  }

  if (!application) {
    return null
  }

  return (
    <>
      <Button variant={ButtonVariant.OUTLINED} onClick={onOpenDrawer}>
        View application details
      </Button>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: {
            width: '880px',
            maxWidth: '75%',
            boxShadow: `-10px 0 15px -5px ${theme.colors.Violet20}`,
          },
        }}
      >
        <Col p={theme.space.sm} gap={theme.space.xs} style={{ height: '100%' }}>
          <Row justifyBetween alignCenter>
            <Button
              style={{ padding: theme.space.xsmed }}
              variant={ButtonVariant.OUTLINED}
              onClick={onCloseDrawer}
            >
              <Icon name="cancel" />
            </Button>
          </Row>

          <Col gap={theme.space.xs} fullWidth pb={theme.space.lg}>
            <Text variant="h3">Application Details</Text>

            <ViewAndEditExistingApplicationItemsList
              applicationId={application.id}
              applicationItems={application.applicationItems}
              analyticsSource={analyticsSource}
              companyId={companyId}
              roleId={roleId}
              shiftRequestId={shiftRequestId}
              isEditable
            />
          </Col>
        </Col>
      </Drawer>
    </>
  )
}
