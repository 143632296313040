import styled from 'styled-components'

const getFilepath = (email: string) => {
  switch (email) {
    case 'javier@traba.work':
      return '/img/lotr.png'
    case 'nazer@traba.work':
      return '/img/zelda.png'
    case 'mstaunton@traba.work':
      return '/img/arsenal.jpg'
    case 'tanzir@traba.work':
      return '/img/tz.png'
    case 'jike@traba.work':
      return '/img/biubiu.png'
    default:
      return '/img/company-cover.png'
  }
}

interface CompanyHeaderProps {
  email: string
}

export const CompanyHeaderWrapper = styled.div<CompanyHeaderProps>`
  margin: -56px -25px 85px;
  padding: 0 25px;
  background-image: url(${(props) => getFilepath(props.email)});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 160px;
`
