import { useDragAndDrop } from '@formkit/drag-and-drop/react'
import { useAlert } from '@traba/context'
import { Col, Draggable } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ALLOW_MULTIPLE_STEPS_OF_APPLICATION_ITEM_TYPES,
  ApplicationItemContent,
  ApplicationItemType,
  UpsertApplicationItemRequestType,
} from '@traba/types'
import { useCallback, useEffect, useState } from 'react'
import { useEnabledApplicationStepsForAppCreation } from 'src/hooks/useApplications'
import { convertToUpsertApplicationItemRequest } from 'src/utils/applicationUtils'
import { v4 as uuidv4 } from 'uuid'
import { AddNewApplicationItemButton } from './AddNewApplicationItemButton'
import { CreateNewApplicationItem } from './CreateNewApplicationItem'
import { useApplicationsAnalytics } from './hooks/useApplicationsAnalytics'
import { ApplicationItemCreationAdditionalProps } from './types'
import { ViewAndEditExistingApplicationItem } from './ViewAndEditExistingApplicationItem'

export const EMPTY_APPLICATION_ERROR_MESSAGE =
  'Add application steps to application or remove application for the role.'
const UNSAVED_APPLICATION_ERROR_MESSAGE =
  'All application items must be saved or removed from role application.'

type UpsertApplicationItemRequestTypeWithDragAndDropId = {
  dragAndDropId: string
} & UpsertApplicationItemRequestType

type EmptyApplicationItem = {
  applicationItemType?: ApplicationItemType
  applicationItemId?: string
  applicationItemSourceId?: string
  itemContent?: ApplicationItemContent
}

type CreateAndEditApplicationItemsContentProps = {
  applicationItems?: UpsertApplicationItemRequestType[]
  onSaveItem: (
    applicationItemIndex: number,
  ) => (item: UpsertApplicationItemRequestType) => void
  onRemoveItem: (applicationItemIndex: number) => void
  setErrorForApplication?: (error: string | undefined) => void
  analyticsSource?: string
  onSaveReorderedItems?: (
    applicationItems: UpsertApplicationItemRequestType[],
  ) => void
} & ApplicationItemCreationAdditionalProps

export function CreateAndEditApplicationItemsContent({
  applicationItems,
  onSaveItem,
  onRemoveItem,
  onSaveReorderedItems,
  setErrorForApplication,
  analyticsSource = 'create-and-edit-application-items-content',
  ...additionalPropsForCreateAndEditApplicationItemContent
}: CreateAndEditApplicationItemsContentProps) {
  const { showError } = useAlert()

  const [newItem, setNewItem] = useState<EmptyApplicationItem | undefined>(
    undefined,
  )

  const { trackAddNewApplicationItem } = useApplicationsAnalytics()

  const onAddNewEmptyItem = useCallback(() => {
    setNewItem({})
    setErrorForApplication?.(UNSAVED_APPLICATION_ERROR_MESSAGE)

    const { shiftRequest, role } =
      additionalPropsForCreateAndEditApplicationItemContent
    trackAddNewApplicationItem({
      source: analyticsSource,
      shiftRequestId: shiftRequest?.shiftRequestId,
      roleId: role?.roleId,
      companyId: role?.companyId,
    })
  }, [
    additionalPropsForCreateAndEditApplicationItemContent.role,
    additionalPropsForCreateAndEditApplicationItemContent.shiftRequest,
    trackAddNewApplicationItem,
    analyticsSource,
    setErrorForApplication,
  ])
  const onRemoveNewEmptyItem = useCallback(() => {
    setNewItem(undefined)

    // TODO(gail): remove error once we support applications with no steps
    setErrorForApplication?.(
      applicationItems?.length ? undefined : EMPTY_APPLICATION_ERROR_MESSAGE,
    )
  }, [applicationItems?.length, setErrorForApplication])

  const [dragging, setDragging] =
    useState<UpsertApplicationItemRequestTypeWithDragAndDropId | null>(null)

  const [ref, draggableApplicationItems, setDraggableApplicationItems] =
    useDragAndDrop<
      HTMLDivElement,
      UpsertApplicationItemRequestTypeWithDragAndDropId
    >(
      applicationItems?.map((item) => ({
        dragAndDropId: item.applicationItemId || uuidv4(),
        ...item,
      })) ?? [],
      {
        dragHandle: '.dragTable',
        dragPlaceholderClass: 'dragging',
        onDragstart: function (_, state) {
          setDragging(
            state.currentTargetValue as UpsertApplicationItemRequestTypeWithDragAndDropId,
          )
        },
        handleDragend() {
          setDragging(null)
          onSaveReorderedItems?.(draggableApplicationItems)
        },
      },
    )

  useEffect(() => {
    if (applicationItems) {
      setDraggableApplicationItems(
        applicationItems?.map((item) => ({
          dragAndDropId: item.applicationItemId || uuidv4(),
          ...item,
        })),
      )
    }
  }, [setDraggableApplicationItems, applicationItems])

  const handleRemoveItem = useCallback(
    (index: number) => () => {
      onRemoveItem(index)
      setErrorForApplication?.(
        applicationItems?.length && applicationItems.length > 1
          ? undefined
          : EMPTY_APPLICATION_ERROR_MESSAGE,
      )

      setDraggableApplicationItems((prev) => {
        const newDraggableApplicationItems = [...prev]
        newDraggableApplicationItems.splice(index, 1)
        return newDraggableApplicationItems
      })
    },
    [onRemoveItem, setErrorForApplication, applicationItems?.length],
  )

  const handleSaveItem = useCallback(
    (applicationItemIndex: number, isNewItem?: boolean) =>
      ({
        applicationItemType,
        applicationItemId,
        itemContent,
      }: {
        applicationItemType: ApplicationItemType
        applicationItemId?: string
        itemContent?: ApplicationItemContent
      }) => {
        if (!applicationItemType) {
          showError('Application item type is required')
          return
        }

        const upsertApplicationItemRequest:
          | UpsertApplicationItemRequestType
          | undefined = convertToUpsertApplicationItemRequest({
          applicationItemId,
          applicationItemType,
          itemContent,
        })

        if (!upsertApplicationItemRequest) {
          showError('Missing fields to create application item')
          return
        }

        onSaveItem(applicationItemIndex)(upsertApplicationItemRequest)

        setDraggableApplicationItems((prev) => {
          const newDraggableApplicationItems = [...prev]
          if (isNewItem) {
            newDraggableApplicationItems.push({
              ...upsertApplicationItemRequest,
              dragAndDropId: uuidv4(),
            })
          } else {
            newDraggableApplicationItems[applicationItemIndex] = {
              ...newDraggableApplicationItems[applicationItemIndex],
              ...upsertApplicationItemRequest,
            }
          }
          return newDraggableApplicationItems
        })

        if (isNewItem) {
          onRemoveNewEmptyItem()
          setErrorForApplication?.(undefined)
        }
      },
    [onSaveItem, onRemoveNewEmptyItem, showError, setErrorForApplication],
  )

  const enabledSteps = useEnabledApplicationStepsForAppCreation()

  const selectableApplicationItemTypes = enabledSteps.filter(
    (type) =>
      ALLOW_MULTIPLE_STEPS_OF_APPLICATION_ITEM_TYPES.includes(type) ||
      !applicationItems?.find((item) => item.applicationItemType === type),
  )

  const allowDragging = !!onSaveReorderedItems

  return (
    <Col gap={theme.space.sm}>
      {draggableApplicationItems?.length > 0 && (
        <Col gap={theme.space.sm} ref={ref}>
          {draggableApplicationItems?.map((applicationItem, index) => {
            return (
              <Draggable
                key={applicationItem.dragAndDropId}
                className="dragTable"
                isDragging={
                  dragging !== null &&
                  dragging?.dragAndDropId === applicationItem.dragAndDropId
                }
                isDraggingClassName="dragging"
                dragHandleStyle={
                  allowDragging
                    ? undefined
                    : {
                        visibility: 'hidden',
                        width: '0px',
                      }
                }
              >
                <ViewAndEditExistingApplicationItem
                  key={`${applicationItem.applicationItemId}-${index}`}
                  applicationItemId={applicationItem.applicationItemId}
                  applicationItemType={applicationItem.applicationItemType}
                  applicationItemSourceId={
                    applicationItem.applicationItemSourceId
                  }
                  stepIndex={index + 1}
                  onSaveStep={handleSaveItem(index)}
                  onRemoveStep={handleRemoveItem(index)}
                  itemContent={applicationItem.itemContent}
                  analyticsSource={analyticsSource}
                  isEditable
                  {...additionalPropsForCreateAndEditApplicationItemContent}
                />
              </Draggable>
            )
          })}
        </Col>
      )}

      {newItem && (
        <CreateNewApplicationItem
          stepIndex={(applicationItems?.length || 0) + 1}
          applicationItemId={newItem.applicationItemId}
          applicationItemType={newItem.applicationItemType}
          applicationItemSourceId={newItem.applicationItemSourceId}
          onSaveStep={handleSaveItem(applicationItems?.length ?? 0, true)}
          onRemoveStep={onRemoveNewEmptyItem}
          selectableApplicationItemTypes={selectableApplicationItemTypes}
          analyticsSource={analyticsSource}
          {...additionalPropsForCreateAndEditApplicationItemContent}
        />
      )}
      <AddNewApplicationItemButton
        onClick={onAddNewEmptyItem}
        disabled={!!newItem || selectableApplicationItemTypes.length === 0}
        tooltipText={
          selectableApplicationItemTypes.length === 0
            ? 'All application items available have been added'
            : newItem
              ? 'You must save or remove the current item before adding a new one'
              : undefined
        }
      />
    </Col>
  )
}
