import { useAlert } from '@traba/context'
import { Col, InfoRow } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ApplicationItemContent,
  ApplicationItemType,
  Role,
  UpsertApplicationItemRequestType,
} from '@traba/types'
import { SetStateAction, useCallback, useMemo } from 'react'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { AddNewApplicationItemButton } from 'src/screens/Applications/AddNewApplicationItemButton'
import { useApplicationsAnalytics } from 'src/screens/Applications/hooks/useApplicationsAnalytics'
import { ApplicationItemCreationAdditionalProps } from 'src/screens/Applications/types'
import { convertToUpsertApplicationItemRequest } from 'src/utils/applicationUtils'
import { ShiftPostingInputContainer } from '../../ShiftPostingInputContainer'
import { ApplicationForRoleSection } from './ApplicationForRoleSection'
type ApplicationsSectionProps = {
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void
  createShiftRequests: CreateShiftRequest[]
  roles: Role[] | undefined
  analyticsSource?: string
} & Pick<ApplicationItemCreationAdditionalProps, 'location' | 'shiftRequest'>

export function ApplicationsSection({
  setCreateShiftRequests,
  createShiftRequests,
  location,
  roles,
  analyticsSource = 'applications-section',
  shiftRequest: shiftRequestFromProps,
}: ApplicationsSectionProps) {
  const { showError } = useAlert()
  const {
    trackApplicationItemSaved,
    trackApplicationItemRemoved,
    trackApplicationForRoleRemoved,
    trackApplicationItemsReordered,
  } = useApplicationsAnalytics()

  const roleIdToRole = useMemo(() => {
    return (
      roles?.reduce((acc: Record<string, Role>, role) => {
        acc[role.roleId] = role
        return acc
      }, {}) || {}
    )
  }, [roles])
  const shiftRequestsWithRoleAndIndex = useMemo(
    () =>
      createShiftRequests
        .filter((sr) => sr.roleId && roleIdToRole[sr.roleId])
        .map((sr, index) => ({ ...sr, role: roleIdToRole[sr.roleId], index })),
    [createShiftRequests, roleIdToRole],
  )

  const allowAddApplication =
    shiftRequestsWithRoleAndIndex.length !== 0 && !!location?.locationId

  const setErrorForShiftRequest = useCallback(
    (index: number) => (error: string | undefined) => {
      setCreateShiftRequests((prev) => {
        const newCreateShiftRequests = [...prev]
        newCreateShiftRequests[index].createApplicationError = error
        return newCreateShiftRequests
      })
    },
    [setCreateShiftRequests],
  )

  const handleSaveItem = useCallback(
    (shiftRequestIndex: number) =>
      (applicationItemIndex: number) =>
      ({
        applicationItemType,
        itemContent = {},
      }: {
        applicationItemType: ApplicationItemType
        itemContent?: ApplicationItemContent
      }) => {
        if (!applicationItemType) {
          showError('Application item type is required')
          return
        }

        const updatedApplicationItem = convertToUpsertApplicationItemRequest({
          applicationItemType,
          itemContent,
        })

        if (!updatedApplicationItem) {
          showError(
            `Application item ${applicationItemType} with item content ${itemContent} is not supported`,
          )
          return
        }

        setCreateShiftRequests((prev) => {
          const newCreateShiftRequests = [...prev]
          const prevApplicationItems =
            newCreateShiftRequests[shiftRequestIndex].application
              ?.applicationItems || []
          const newApplicationItems = [
            ...prevApplicationItems.slice(0, applicationItemIndex),
            updatedApplicationItem,
            ...prevApplicationItems.slice(applicationItemIndex + 1),
          ]

          newCreateShiftRequests[shiftRequestIndex] = {
            ...newCreateShiftRequests[shiftRequestIndex],
            application: {
              applicationItems: newApplicationItems,
            },
          }

          trackApplicationItemSaved({
            source: analyticsSource,
            applicationItemType,
            companyId: newCreateShiftRequests[shiftRequestIndex].companyId,
            roleId: newCreateShiftRequests[shiftRequestIndex].roleId,
          })

          return newCreateShiftRequests
        })
      },
    [
      setCreateShiftRequests,
      showError,
      analyticsSource,
      trackApplicationItemSaved,
    ],
  )

  const handleRemoveItem = useCallback(
    (shiftRequestIndex: number) => (applicationItemIndex: number) => {
      setCreateShiftRequests((prev) => {
        const newCreateShiftRequests = [...prev]
        const prevApplicationItems =
          newCreateShiftRequests[shiftRequestIndex].application
            ?.applicationItems || []
        const newApplicationItems = [
          ...prevApplicationItems.slice(0, applicationItemIndex),
          ...prevApplicationItems.slice(applicationItemIndex + 1),
        ]

        trackApplicationItemRemoved({
          source: analyticsSource,
          applicationItemType:
            prevApplicationItems[applicationItemIndex].applicationItemType,
          companyId: newCreateShiftRequests[shiftRequestIndex].companyId,
          roleId: newCreateShiftRequests[shiftRequestIndex].roleId,
        })

        newCreateShiftRequests[shiftRequestIndex] = {
          ...newCreateShiftRequests[shiftRequestIndex],
          application: {
            applicationItems: newApplicationItems,
          },
        }
        return newCreateShiftRequests
      })
    },
    [setCreateShiftRequests, analyticsSource, trackApplicationItemRemoved],
  )

  const handleUpdateItemsOrder = useCallback(
    (shiftRequestIndex: number) =>
      (applicationItems: UpsertApplicationItemRequestType[]) => {
        setCreateShiftRequests((prev) => {
          const newCreateShiftRequests = [...prev]
          trackApplicationItemsReordered({
            source: analyticsSource,
            companyId: newCreateShiftRequests[shiftRequestIndex].companyId,
            roleId: newCreateShiftRequests[shiftRequestIndex].roleId,
          })

          newCreateShiftRequests[shiftRequestIndex] = {
            ...newCreateShiftRequests[shiftRequestIndex],
            application: {
              applicationItems: applicationItems,
            },
          }
          return newCreateShiftRequests
        })
      },
    [setCreateShiftRequests, analyticsSource, trackApplicationItemsReordered],
  )

  const handleRemoveApplicationForRole = useCallback(
    (shiftRequestIndex: number) => () => {
      setCreateShiftRequests((prev) => {
        const newCreateShiftRequests = [...prev]

        newCreateShiftRequests[shiftRequestIndex] = {
          ...newCreateShiftRequests[shiftRequestIndex],
          application: undefined,
        }

        trackApplicationForRoleRemoved({
          source: analyticsSource,
          companyId: newCreateShiftRequests[shiftRequestIndex].companyId,
          roleId: newCreateShiftRequests[shiftRequestIndex].roleId,
        })

        return newCreateShiftRequests
      })
    },
    [setCreateShiftRequests, analyticsSource, trackApplicationForRoleRemoved],
  )

  return (
    <ShiftPostingInputContainer
      title="Applications (optional)"
      style={{ background: theme.colors.Grey10, borderRadius: '10px' }}
    >
      <Col fullWidth gap={theme.space.sm}>
        {!allowAddApplication && (
          <InfoRow
            text="Add location and roles first before creating an application"
            iconSize={18}
          />
        )}

        {shiftRequestsWithRoleAndIndex.length === 0 && (
          <AddNewApplicationItemButton disabled={!allowAddApplication} />
        )}

        {shiftRequestsWithRoleAndIndex.map((shiftRequest, i) => {
          const role = roleIdToRole[shiftRequest.roleId]
          const shiftRequestIndex = shiftRequest.index

          return (
            <ApplicationForRoleSection
              key={`${role.roleId}-${shiftRequestIndex}-${i}`}
              roleName={role?.roleName ?? 'Role application'}
              applicationItems={
                shiftRequest.application?.applicationItems || []
              }
              onSaveApplicationItem={handleSaveItem(shiftRequestIndex)}
              onRemoveApplicationItem={handleRemoveItem(shiftRequestIndex)}
              onRemoveApplicationForRole={handleRemoveApplicationForRole(
                shiftRequestIndex,
              )}
              disableTurnOnApplicationForRole={!allowAddApplication}
              role={role}
              shiftRequest={{
                ...shiftRequest,
                shiftRequestId: shiftRequestFromProps?.shiftRequestId,
                shiftRole:
                  shiftRequestFromProps?.shiftRole || role?.roleName || '',
                schedules: shiftRequestFromProps?.schedules || [],
              }}
              location={location}
              setErrorForRole={setErrorForShiftRequest(shiftRequestIndex)}
              onSaveReorderedItems={handleUpdateItemsOrder(shiftRequestIndex)}
              analyticsSource={analyticsSource}
            />
          )
        })}
      </Col>
    </ShiftPostingInputContainer>
  )
}
