import { WorkerApplicationItemCompletionStatus } from '@traba/types'
import { WorkerApplicationItemStatus } from '@traba/types'
import { useCallback } from 'react'
import { ApplicationItemType } from 'shared/types/src/lib/applications'
import { useAnalytics } from 'src/hooks/useAnalytics'

type AnalyticsSource = { source: string }

export type BaseApplicationAnalyticsParams = {
  companyId?: string
  roleId?: string
  shiftRequestId?: string
  shiftId?: string
}

export type ApplicationAnalyticsParams = BaseApplicationAnalyticsParams & {
  applicationId?: string
}

export type ApplicationItemAnalyticsParams = ApplicationAnalyticsParams & {
  applicationItemId?: string
  applicationItemType?: ApplicationItemType
}

export type WorkerApplicationAnalyticsParams = ApplicationAnalyticsParams & {
  workerApplicationId?: string
  workerId?: string
}

export type WorkerApplicationItemAnalyticsParams =
  WorkerApplicationAnalyticsParams & {
    workerApplicationItemId?: string
    applicationItemId?: string
    applicationItemType?: ApplicationItemType
    previousStatus?: WorkerApplicationItemStatus
    previousCompletionStatus?: WorkerApplicationItemCompletionStatus
    newStatus?: WorkerApplicationItemStatus
    newCompletionStatus?: WorkerApplicationItemCompletionStatus
  }

export function useApplicationsAnalytics() {
  const { trackAnalytics } = useAnalytics()

  // Application creation / edit / actions
  const trackApplicationItemSaved = useCallback(
    (params: ApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application item saved for shift request', params)
    },
    [trackAnalytics],
  )

  const trackApplicationItemRemoved = useCallback(
    (params: ApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application item removed from shift request', params)
    },
    [trackAnalytics],
  )

  const trackEditApplicationItem = useCallback(
    (params: ApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Edit application item clicked', params)
    },
    [trackAnalytics],
  )

  const trackAddNewItemToExistingApplication = useCallback(
    (params: ApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Add new item to existing application saved', params)
    },
    [trackAnalytics],
  )

  const trackApplicationItemArchived = useCallback(
    (params: ApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application item archived', params)
    },
    [trackAnalytics],
  )

  const trackApplicationItemUpdated = useCallback(
    (params: ApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application item update saved', params)
    },
    [trackAnalytics],
  )

  const trackApplicationItemTypeSelectedForNewItem = useCallback(
    (params: ApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application item type selected for new item', params)
    },
    [trackAnalytics],
  )

  const trackAddNewApplicationItem = useCallback(
    (params: ApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Add new application item clicked', params)
    },
    [trackAnalytics],
  )

  const trackApplicationItemsReordered = useCallback(
    (params: ApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application items reordered', params)
    },
    [trackAnalytics],
  )

  const trackApplicationForRoleToggledOn = useCallback(
    (params: ApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application toggled on for shift request', params)
    },
    [trackAnalytics],
  )

  const trackApplicationForRoleRemoved = useCallback(
    (params: ApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application toggled off for shift request', params)
    },
    [trackAnalytics],
  )

  const trackApplicationDisabled = useCallback(
    (params: ApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application disabled from enabled state', params)
    },
    [trackAnalytics],
  )

  const trackApplicationEnabled = useCallback(
    (params: ApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Application enabled from disabled state', params)
    },
    [trackAnalytics],
  )

  const trackViewApplicationDetails = useCallback(
    (params: ApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('View application details clicked', params)
    },
    [trackAnalytics],
  )

  // Worker application views / actions

  const trackInviteModalOpened = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Invite from application modal opened', params)
    },
    [trackAnalytics],
  )

  const trackInviteModalClosed = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Invite from application modal closed', params)
    },
    [trackAnalytics],
  )

  const trackWorkerApplicationResultsDrawerClosed = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Worker application results drawer closed', params)
    },
    [trackAnalytics],
  )

  const trackResendInviteClicked = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Resend invite clicked', params)
    },
    [trackAnalytics],
  )

  const trackInviteToSchedule = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Invite to schedule from application clicked', params)
    },
    [trackAnalytics],
  )

  const trackInviteToShift = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Invite to shift from application clicked', params)
    },
    [trackAnalytics],
  )

  const trackAddToShiftModalOpened = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Add to shift modal opened', params)
    },
    [trackAnalytics],
  )

  const trackAddToShiftModalClosed = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Add to shift modal closed', params)
    },
    [trackAnalytics],
  )

  const trackRescindInvitation = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Rescind invitation from application clicked', params)
    },
    [trackAnalytics],
  )

  const trackExpandWorkerApplication = useCallback(
    (params: WorkerApplicationAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Expand worker application', params)
    },
    [trackAnalytics],
  )

  const trackViewWorkerApplicationItemDetails = useCallback(
    (params: WorkerApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('View worker application item details clicked', params)
    },
    [trackAnalytics],
  )

  const trackWorkerApplicationItemStatusUpdated = useCallback(
    (params: WorkerApplicationItemAnalyticsParams & AnalyticsSource) => {
      trackAnalytics('Worker application item status updated by ops', params)
    },
    [trackAnalytics],
  )

  return {
    trackApplicationItemSaved,
    trackApplicationItemRemoved,
    trackEditApplicationItem,
    trackApplicationItemTypeSelectedForNewItem,
    trackApplicationForRoleToggledOn,
    trackApplicationForRoleRemoved,
    trackAddNewApplicationItem,
    trackApplicationItemsReordered,
    trackAddNewItemToExistingApplication,
    trackApplicationItemArchived,
    trackApplicationItemUpdated,
    trackApplicationDisabled,
    trackApplicationEnabled,
    trackInviteModalOpened,
    trackInviteModalClosed,
    trackResendInviteClicked,
    trackInviteToSchedule,
    trackInviteToShift,
    trackAddToShiftModalOpened,
    trackAddToShiftModalClosed,
    trackRescindInvitation,
    trackViewApplicationDetails,
    trackWorkerApplicationResultsDrawerClosed,
    trackViewWorkerApplicationItemDetails,
    trackExpandWorkerApplication,
    trackWorkerApplicationItemStatusUpdated,
  }
}
