import { useAlert } from '@traba/context'
import { theme } from '@traba/theme'
import {
  CostCenterForShiftType,
  JobStatus,
  UpdateSegmentsForWorkerShiftRequest,
} from '@traba/types'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Badge, Col, Modal, MODAL_SIZE, Table, Tr } from '../base-components'
import { BadgeVariant } from '../base-components/Badge/Badge.styles'
import {
  AddRowButtons,
  ModalBanners,
  ModalButtons,
  SegmentRow,
  WorkerShiftInfo,
} from './components'
import { EditSegment, SegmentError, SegmentErrorProperties } from './types'
import {
  fillInTheGaps,
  flattenSegments,
  generateUpdateRequest,
  roundSegment,
  sortSegments,
  validateSegments,
} from './util'

export interface UpdateCostCentersForWorkerShiftModalProps {
  workerShift: {
    worker: {
      firstName: string
      lastName: string
      photoUrl?: string
    }
    status: JobStatus
    clockInTime: Date
    clockOutTime?: Date
    workedTime: number
    totalBreakTime: number
  }
  shift: { endTime: Date }
  segments: Omit<EditSegment, 'id'>[]
  costCenters: CostCenterForShiftType[]
  isOpen: boolean
  timezone: string
  handleClose: () => void
  updateWorkerShiftSegments: (
    req: UpdateSegmentsForWorkerShiftRequest,
  ) => Promise<void>
  areSegmentsUpdating: boolean
}

export function WorkerShiftCostCentersModal(
  props: UpdateCostCentersForWorkerShiftModalProps,
): React.JSX.Element {
  const {
    workerShift: {
      worker: { firstName, lastName, photoUrl },
      status,
      clockInTime,
      clockOutTime,
      workedTime,
      totalBreakTime,
    },
    segments: initialSegments,
    costCenters,
    shift: { endTime: shiftEndTime },
    updateWorkerShiftSegments: updateWorkerSegments,
    areSegmentsUpdating,
    timezone,
    isOpen,
    handleClose,
  } = props

  const { showError } = useAlert()

  const [segments, setSegments] = useState<EditSegment[]>(
    fillInTheGaps(
      clockInTime,
      clockOutTime,
      initialSegments.map((s) => ({ id: uuidv4(), ...s })),
    ),
  )
  const [invalidRows, setInvalidRows] = useState(
    {} as Record<string, SegmentError[]>,
  )
  const [isSaved, setIsSaved] = useState(false)
  const [promptUserConfirmation, setPromptUserConfirmation] = useState(false)
  // Used to track whether to hide the user prompt.
  const hasExplicitlySetPrompt = useRef(false)
  const hasExplicitlySetSaved = useRef(false)

  const hasValidationErrors = useMemo(
    () =>
      Object.values(invalidRows)
        .flatMap((errors) => errors)
        .map((error) => SegmentErrorProperties[error])
        .some(({ isWarning }) => !isWarning),
    [invalidRows],
  )

  const onSave = useCallback(async () => {
    const roundedSegments = segments.map((s) =>
      roundSegment(clockInTime, clockOutTime, s),
    )
    const cleanedUpSegment = flattenSegments(
      fillInTheGaps(clockInTime, clockOutTime, sortSegments(roundedSegments)),
    )

    // If we changed segments, we do not want to save, but rather have the
    // user confirm the segments again.
    if (!isEqual(segments, cleanedUpSegment)) {
      setSegments(cleanedUpSegment)
      setPromptUserConfirmation(true)
      hasExplicitlySetPrompt.current = true
      return
    }

    try {
      await updateWorkerSegments(generateUpdateRequest(segments))
    } catch (err) {
      showError(`Unable to Save Segments`, undefined, 3000)
      return
    }

    setIsSaved(true)
    hasExplicitlySetSaved.current = true
    // After save, all segments are accounted, setting the field to false.
    setSegments((segments) =>
      segments.map((segment) => ({
        ...segment,
        isUnaccounted: false,
      })),
    )
    setPromptUserConfirmation(false)
  }, [clockInTime, clockOutTime, segments, showError, updateWorkerSegments])

  const addRow = useCallback((isBreak: boolean) => {
    setSegments((segments) => {
      const lastSegment = segments[segments.length - 1]
      return [
        ...segments,
        {
          id: uuidv4(),
          isBreak,
          costCenterId: undefined,
          startTime: lastSegment?.endTime,
          endTime: undefined,
        },
      ]
    })
  }, [])

  const removeRow = useCallback((id: string) => {
    setSegments((segments) => segments.filter((s) => s.id !== id))
  }, [])

  const updateTime = useCallback(
    ({
      startTime,
      endTime,
      id,
    }: {
      startTime: Date | null
      endTime: Date | null
      id: string
    }) => {
      setSegments((segments) =>
        segments.map((s) =>
          s.id === id
            ? roundSegment(clockInTime, clockOutTime, {
                ...s,
                startTime: startTime ?? undefined,
                endTime: endTime ?? undefined,
              })
            : s,
        ),
      )
    },
    [clockInTime, clockOutTime],
  )

  const selectCostCenter = useCallback(
    (costCenterId: string | undefined, id: string) => {
      setSegments((segments) =>
        segments.map((s) =>
          s.id === id ? { ...s, costCenterId, isUnaccounted: false } : s,
        ),
      )
    },
    [],
  )

  const costCenterMenuItems = useMemo(
    () => costCenters.map(({ id, name }) => ({ label: name, value: id })),
    [costCenters],
  )

  const resetStateAndClose = useCallback(() => {
    setSegments(
      fillInTheGaps(
        clockInTime,
        clockOutTime,
        initialSegments.map((s) => ({ id: uuidv4(), ...s })),
      ),
    )
    handleClose()
  }, [clockInTime, clockOutTime, handleClose, initialSegments])

  useEffect(() => {
    setInvalidRows(
      validateSegments({ status, clockInTime, clockOutTime, segments }),
    )
  }, [clockInTime, clockOutTime, segments, status])

  // On segment change, we want to set saved and prompt user confirmation to off
  // only after the first render. The logic below uses the ref to determine if
  // it was the first render, else change the state of the two variables.
  useEffect(() => {
    if (hasExplicitlySetPrompt.current) {
      hasExplicitlySetPrompt.current = false
    } else {
      setPromptUserConfirmation(false)
    }

    if (hasExplicitlySetSaved.current) {
      hasExplicitlySetSaved.current = false
    } else {
      setIsSaved(false)
    }
  }, [segments])

  return (
    <Modal
      size={MODAL_SIZE.EXTRA_EXTRA_LARGE}
      isOpen={isOpen}
      handleClose={resetStateAndClose}
      title={`Edit Cost Centers For ${firstName} ${lastName}`}
    >
      <Col gap={theme.space.sm}>
        {(status === JobStatus.InProgress || status === JobStatus.OnBreak) && (
          <Badge variant={BadgeVariant.WARNING} title="Shift In Progress" />
        )}
        <Table
          headers={[
            '',
            'Cost Center',
            'Clock In',
            'Clock Out',
            'Worked Time',
            'Break',
            '',
          ]}
        >
          <Tr key="worker">
            <WorkerShiftInfo
              timezone={timezone}
              photoUrl={photoUrl}
              firstName={firstName}
              lastName={lastName}
              clockInTime={clockInTime}
              clockOutTime={clockOutTime}
              workedTime={workedTime}
              totalBreakTime={totalBreakTime}
            />
          </Tr>

          {segments.map((segment) => (
            <SegmentRow
              key={`row-${segment.id}`}
              segment={segment}
              costCenterMenuItems={costCenterMenuItems}
              timezone={timezone}
              clockInTime={clockInTime}
              clockOutTime={clockOutTime}
              shiftEndTime={shiftEndTime}
              validationErrors={invalidRows[segment.id]}
              updateTime={updateTime}
              removeRow={removeRow}
              selectCostCenter={selectCostCenter}
            />
          ))}

          <Tr key="Add Row">
            <AddRowButtons
              onAddBreakClick={() => addRow(true)}
              onAddTimeClick={() => addRow(false)}
            />
          </Tr>
        </Table>
        <ModalBanners
          segments={segments}
          invalidRows={invalidRows}
          needsConfirmation={promptUserConfirmation}
        />
        <ModalButtons
          isSaved={isSaved}
          isUpdating={areSegmentsUpdating}
          onSave={onSave}
          onCancel={resetStateAndClose}
          saveDisabled={hasValidationErrors}
        />
      </Col>
    </Modal>
  )
}
