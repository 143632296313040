import { Collapse, Menu } from '@mui/material'
import {
  Button,
  ButtonVariant,
  Col,
  Row,
  SvgIcon,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  APPLICATION_ITEM_TYPE_TO_TITLE,
  WorkerApplicationItem,
  WorkerApplicationItemCompletionStatus,
  WorkerApplicationItemStatus,
} from '@traba/types'
import { useCallback, useMemo, useState } from 'react'
import { Icon } from 'src/components/base'
import WorkerApplicationItemCompletionStatusBadge from 'src/components/base/Badge/WorkerApplicationItemCompletionStatusBadge'
import WorkerApplicationItemStatusBadge from 'src/components/base/Badge/WorkerApplicationItemStatusBadge'
import { useWorkerApplicationsMutations } from 'src/hooks/useWorkerApplications'
import { ApplicationItemNumberAndTitle } from 'src/screens/Applications/ApplicationItemNumberAndTitle'
import { WorkerApplicationItemResults } from 'src/screens/Applications/WorkerApplicationItemResults/WorkerApplicationItemResults'
import { isWorkerApplicationItemActionable } from 'src/utils/applicationUtils'
import { useApplicationsAnalytics } from '../hooks/useApplicationsAnalytics'

interface CollapsibleWorkerApplicationItemResultsProps {
  workerApplicationItem: WorkerApplicationItem
  workerId?: string
  startOpen?: boolean
  analyticsSource?: string
}

export function CollapsibleWorkerApplicationItemResults({
  workerApplicationItem,
  workerId,
  startOpen = false,
  analyticsSource = 'collapsible-worker-application-item-results',
}: CollapsibleWorkerApplicationItemResultsProps) {
  const { trackWorkerApplicationItemStatusUpdated } = useApplicationsAnalytics()

  const { updateWorkerApplicationItemStatus } = useWorkerApplicationsMutations()
  const applicationItemTitle =
    APPLICATION_ITEM_TYPE_TO_TITLE[
      workerApplicationItem.applicationItem.applicationItemType
    ] || 'Application Item'
  const [isResultsOpen, setIsResultsOpen] = useState(startOpen)

  const completionStatus = useMemo(
    () => workerApplicationItem.completionStatus,
    [workerApplicationItem.completionStatus],
  )

  const status = useMemo(
    () => workerApplicationItem.status,
    [workerApplicationItem.status],
  )

  const stepIndex = useMemo(() => {
    const applicationItem = workerApplicationItem.applicationItem
    return (applicationItem?.sectionDisplayOrderIndex ?? 0) + 1
  }, [workerApplicationItem.applicationItem])

  const allowUpdateStatus = isWorkerApplicationItemActionable(
    workerApplicationItem,
  )

  const handleUpdateWorkerApplicationItemStatusFromDropdown = useCallback(
    (
      completionStatus: WorkerApplicationItemCompletionStatus,
      source?: string,
    ) =>
      () => {
        trackWorkerApplicationItemStatusUpdated({
          workerId,
          workerApplicationId: workerApplicationItem.id,
          applicationId: workerApplicationItem.applicationItem.applicationId,
          workerApplicationItemId: workerApplicationItem.id,
          previousStatus: workerApplicationItem.status,
          previousCompletionStatus: workerApplicationItem.completionStatus,
          newStatus: WorkerApplicationItemStatus.COMPLETED,
          newCompletionStatus: completionStatus,
          source: `${analyticsSource}-${source}`,
        })
        updateWorkerApplicationItemStatus({
          workerApplicationItemId: workerApplicationItem.id,
          status: WorkerApplicationItemStatus.COMPLETED,
          completionStatus,
        })
        setIsStatusActionOpen(false)
      },
    [
      updateWorkerApplicationItemStatus,
      workerApplicationItem.id,
      workerApplicationItem.applicationItem.applicationId,
      workerApplicationItem.status,
      workerApplicationItem.completionStatus,
      workerId,
      analyticsSource,
      trackWorkerApplicationItemStatusUpdated,
    ],
  )
  const openStatusActionMenu = useCallback((e: { currentTarget: unknown }) => {
    setAnchorEl(e.currentTarget as unknown as HTMLButtonElement)
    setIsStatusActionOpen(true)
  }, [])

  const [isStatusActionOpen, setIsStatusActionOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  return (
    <Col fullWidth>
      <Row alignCenter fullWidth justifyBetween>
        {stepIndex ? (
          <ApplicationItemNumberAndTitle
            applicationItemType={
              workerApplicationItem.applicationItem.applicationItemType
            }
            stepNumber={stepIndex}
            titleVariant="h5"
          />
        ) : (
          <Text variant="h4">{applicationItemTitle}</Text>
        )}
        <Row>
          <div>
            {completionStatus ? (
              <WorkerApplicationItemCompletionStatusBadge
                itemCompletionStatus={completionStatus}
                onClick={openStatusActionMenu}
              />
            ) : status ? (
              <WorkerApplicationItemStatusBadge
                itemStatus={status}
                onClick={openStatusActionMenu}
              />
            ) : null}
            <Menu
              id={`status-menu-${workerApplicationItem.id}`}
              style={{
                marginTop: theme.space.xxs / 2,
              }}
              anchorEl={anchorEl}
              open={isStatusActionOpen}
              onClose={() => setIsStatusActionOpen(false)}
              MenuListProps={{
                'aria-labelledby': `status-menu-button-${workerApplicationItem.id}`,
                sx: {
                  padding: 0,
                },
              }}
            >
              {allowUpdateStatus ? (
                <Col>
                  <Button
                    variant={ButtonVariant.SUCCESS}
                    onClick={handleUpdateWorkerApplicationItemStatusFromDropdown(
                      WorkerApplicationItemCompletionStatus.OPS_MARKED_ELIGIBLE,
                      'status-menu',
                    )}
                    leftIcon={
                      <SvgIcon name="thumbsUp" color={theme.colors.White} />
                    }
                    style={{ borderRadius: '7px 7px 0 0' }}
                  >
                    <Text
                      color={theme.colors.White}
                      style={{ alignSelf: 'left' }}
                      variant="h6"
                    >
                      Mark as Eligible
                    </Text>
                  </Button>
                  <Button
                    variant={ButtonVariant.CANCEL}
                    onClick={handleUpdateWorkerApplicationItemStatusFromDropdown(
                      WorkerApplicationItemCompletionStatus.OPS_MARKED_INELIGIBLE,
                      'status-menu',
                    )}
                    leftIcon={
                      <SvgIcon name="thumbsDown" color={theme.colors.White} />
                    }
                    style={{ borderRadius: 0 }}
                  >
                    <Text
                      color={theme.colors.White}
                      style={{ alignContent: 'left' }}
                      variant="h6"
                    >
                      Mark as Ineligible
                    </Text>
                  </Button>
                  <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleUpdateWorkerApplicationItemStatusFromDropdown(
                      WorkerApplicationItemCompletionStatus.OPS_WAIVED,
                      'status-menu',
                    )}
                    leftIcon={<Icon name="minus" />}
                    style={{ borderRadius: '0 0 7px 7px' }}
                  >
                    <Text
                      color={theme.colors.Grey80}
                      style={{ alignContent: 'left' }}
                      variant="h6"
                    >
                      Waive
                    </Text>
                  </Button>
                </Col>
              ) : (
                <Row p={theme.space.xxs}>
                  <Text>You cannot action on this application step</Text>
                </Row>
              )}
            </Menu>
          </div>
          <Button
            style={{
              marginLeft: theme.space.sm,
              marginRight: theme.space.sm,
            }}
            variant={ButtonVariant.BRANDLINK}
            onClick={() => setIsResultsOpen(!isResultsOpen)}
          >
            {isResultsOpen ? (
              <SvgIcon name="chevronUp" color={theme.colors.Grey50} size={20} />
            ) : (
              <SvgIcon
                name="chevronDown"
                color={theme.colors.Grey50}
                size={20}
              />
            )}
          </Button>
        </Row>
      </Row>
      <Collapse in={isResultsOpen}>
        <Col fullWidth py={theme.space.sm}>
          <WorkerApplicationItemResults
            workerApplicationItem={workerApplicationItem}
            workerId={workerId}
          />
        </Col>
        {workerApplicationItem.status ===
          WorkerApplicationItemStatus.REVIEW_REQUIRED && (
          <Row mt={theme.space.sm} fullWidth gap={theme.space.xs}>
            <Button
              variant={ButtonVariant.SUCCESS}
              style={{ flex: 2 }}
              leftIcon={<SvgIcon name="thumbsUp" color={theme.colors.White} />}
              onClick={handleUpdateWorkerApplicationItemStatusFromDropdown(
                WorkerApplicationItemCompletionStatus.OPS_MARKED_ELIGIBLE,
                'large-button-row',
              )}
            >
              Mark as Eligible
            </Button>
            <Button
              variant={ButtonVariant.CANCEL}
              style={{ flex: 2 }}
              leftIcon={
                <SvgIcon name="thumbsDown" color={theme.colors.White} />
              }
              onClick={handleUpdateWorkerApplicationItemStatusFromDropdown(
                WorkerApplicationItemCompletionStatus.OPS_MARKED_INELIGIBLE,
                'large-button-row',
              )}
            >
              Mark as Ineligible
            </Button>
            <Button
              variant={ButtonVariant.OUTLINED}
              leftIcon={<SvgIcon name="minus" color={theme.colors.Grey80} />}
              style={{ marginRight: theme.space.sm, flex: 2 }}
              onClick={handleUpdateWorkerApplicationItemStatusFromDropdown(
                WorkerApplicationItemCompletionStatus.OPS_WAIVED,
                'large-button-row',
              )}
            >
              Waive
            </Button>
          </Row>
        )}
      </Collapse>
    </Col>
  )
}
