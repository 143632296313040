import { Button, ButtonVariant, Row, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { WorkerVettingCampaign } from '@traba/types'
import { useState } from 'react'
import { Icon } from 'src/components/base'
import { usePromptContext } from 'src/hooks/usePromptContext'
import { useVettingCampaignMutations } from 'src/hooks/useVettingCampaigns'
import { ConfirmVettingCampaignAIVettingModal } from 'src/modals/ConfirmVettingCampaignAIVettingModal/ConfirmVettingCampaignAIVettingModal'
import { ConfirmVettingCampaignSyncModal } from 'src/modals/ConfirmVettingCampaignSyncModal/ConfirmVettingCampaignSyncModal'
import { getMostRecentWorkerVettingCampaign } from 'src/utils/vettingCampaignUtils'

export const VettingCampaignActions = ({
  campaignId,
  roleIds,
  workerVettingCampaigns,
  showRefreshBanner = false,
}: {
  campaignId: string
  roleIds: string[]
  workerVettingCampaigns: WorkerVettingCampaign[]
  showRefreshBanner?: boolean
}) => {
  const {
    syncVettingCampaign,
    isSyncPending,
    startAIVetting,
    isStartAIVettingPending,
    refreshVettingCampaign,
    isRefreshPending,
  } = useVettingCampaignMutations()

  const workerVettingCampaign = getMostRecentWorkerVettingCampaign(
    workerVettingCampaigns,
  )
  const { saveVettingPromptContext } = usePromptContext(
    workerVettingCampaign?.activePromptContextId,
  )

  const [isConfirmSyncModalOpen, setIsConfirmSyncModalOpen] = useState(false)
  const [isConfirmAIVettingModalOpen, setIsConfirmAIVettingModalOpen] =
    useState(false)

  const handleSyncClick = () => {
    setIsConfirmSyncModalOpen(true)
  }

  const handleAIVettingClick = () => {
    setIsConfirmAIVettingModalOpen(true)
  }

  return (
    <>
      <Row
        justifyEnd
        alignCenter
        gap={theme.space.xsmed}
        mt={theme.space.xxxs}
        mb={theme.space.xxxs}
        style={{ width: '100%' }}
      >
        <Button
          leftIcon={
            <SvgIcon
              name="refresh"
              color={theme.colors.Violet}
              style={{ transform: 'scaleX(-1)' }}
            />
          }
          style={{
            width: '86px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '16px',
          }}
          loading={isSyncPending || isRefreshPending}
          variant={ButtonVariant.BRANDNEW}
          onClick={handleSyncClick}
        >
          Sync
        </Button>
        <Button
          style={{
            color: theme.colors.Violet,
            backgroundColor: theme.colors.White,
            width: '40px',
            height: '40px',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '40px',
          }}
          variant={ButtonVariant.OUTLINED}
          onClick={handleAIVettingClick}
        >
          <Icon name="aiPlatform" color={theme.colors.Violet} />
        </Button>
      </Row>
      <ConfirmVettingCampaignSyncModal
        isOpen={isConfirmSyncModalOpen}
        handleClose={() => setIsConfirmSyncModalOpen(false)}
        onSync={() => syncVettingCampaign(campaignId)}
        onRefresh={() => refreshVettingCampaign(campaignId)}
        showRefreshBanner={showRefreshBanner}
      />
      <ConfirmVettingCampaignAIVettingModal
        isOpen={isConfirmAIVettingModalOpen}
        handleClose={() => setIsConfirmAIVettingModalOpen(false)}
        onSubmit={(startAIVettingRequest) =>
          startAIVetting({
            ...startAIVettingRequest,
            vettingCampaignId: campaignId,
            roleIds,
          })
        }
        workerVettingCampaign={workerVettingCampaign}
        onSave={(data) =>
          saveVettingPromptContext({
            ...data,
            vettingCampaignId: campaignId,
          })
        }
        loading={isStartAIVettingPending}
      />
    </>
  )
}
