import { Switch } from '@mui/material'
import { Text, Col, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { UpsertApplicationItemRequestType } from '@traba/types'
import { useCallback, useState } from 'react'
import {
  CreateAndEditApplicationItemsContent,
  EMPTY_APPLICATION_ERROR_MESSAGE,
} from 'src/screens/Applications/CreateAndEditApplicationItemsContent'
import { useApplicationsAnalytics } from 'src/screens/Applications/hooks/useApplicationsAnalytics'
import { ApplicationItemCreationAdditionalProps } from 'src/screens/Applications/types'

type ApplicationForRoleSectionProps = {
  onRemoveApplicationForRole: () => void
  onSaveApplicationItem: (
    applicationItemIndex: number,
  ) => (item: UpsertApplicationItemRequestType) => void
  onRemoveApplicationItem: (applicationItemIndex: number) => void
  roleName: string
  applicationItems: UpsertApplicationItemRequestType[]
  disableTurnOnApplicationForRole: boolean
  setErrorForRole: (error: string | undefined) => void
  analyticsSource?: string
  onSaveReorderedItems?: (
    applicationItems: UpsertApplicationItemRequestType[],
  ) => void
} & ApplicationItemCreationAdditionalProps

export function ApplicationForRoleSection({
  onRemoveApplicationForRole,
  onSaveApplicationItem,
  onRemoveApplicationItem,
  roleName,
  applicationItems,
  disableTurnOnApplicationForRole,
  setErrorForRole,
  analyticsSource = 'application-for-role-section',
  onSaveReorderedItems,
  ...additionalPropsForCreateAndEditApplicationItemContent
}: ApplicationForRoleSectionProps) {
  const { trackApplicationForRoleToggledOn } = useApplicationsAnalytics()
  const [toggleApplicationForRole, setToggleApplicationForRole] = useState(
    applicationItems.length > 0,
  )

  const onToggleApplicationForRole = useCallback(() => {
    setToggleApplicationForRole((prev) => {
      if (prev) {
        onRemoveApplicationForRole()
        setErrorForRole(undefined)
      } else {
        const { shiftRequest, role } =
          additionalPropsForCreateAndEditApplicationItemContent
        trackApplicationForRoleToggledOn({
          companyId: role?.companyId,
          roleId: role?.roleId,
          shiftRequestId: shiftRequest?.shiftRequestId,
          source: analyticsSource,
        })

        setErrorForRole(EMPTY_APPLICATION_ERROR_MESSAGE)
      }
      return !prev
    })
  }, [
    onRemoveApplicationForRole,
    setErrorForRole,
    trackApplicationForRoleToggledOn,
    analyticsSource,
    additionalPropsForCreateAndEditApplicationItemContent.shiftRequest,
    additionalPropsForCreateAndEditApplicationItemContent.role,
  ])

  return (
    <Col
      fullWidth
      p={theme.space.sm}
      style={{
        borderRadius: theme.space.xxs,
        border: `1px solid ${theme.colors.Grey20}`,
        backgroundColor: theme.colors.White,
      }}
      gap={theme.space.xs}
    >
      <Row alignCenter gap={theme.space.xxs}>
        <Text variant="h4">{roleName}</Text>

        <Switch
          inputProps={{ 'aria-label': 'Toggle application for role' }}
          disabled={disableTurnOnApplicationForRole}
          checked={toggleApplicationForRole}
          onClick={onToggleApplicationForRole}
        />
      </Row>

      {toggleApplicationForRole && (
        <CreateAndEditApplicationItemsContent
          applicationItems={applicationItems}
          onSaveItem={onSaveApplicationItem}
          onRemoveItem={onRemoveApplicationItem}
          setErrorForApplication={setErrorForRole}
          analyticsSource={analyticsSource}
          onSaveReorderedItems={onSaveReorderedItems}
          {...additionalPropsForCreateAndEditApplicationItemContent}
        />
      )}
    </Col>
  )
}
