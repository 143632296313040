import { Text } from '@traba/react-components'
import { CardTile } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AuditLog } from '@traba/types'
import { Virtuoso } from 'react-virtuoso'
import { Col, Icon, Row } from 'src/components/base'
import { AuditLogType } from '../AuditLogsScreen'
import AuditLogRow from './AuditLogRow'

interface AuditLogTableProps {
  auditLogs: AuditLog[]
  logType: AuditLogType
}

export default function AuditLogTable(props: AuditLogTableProps) {
  const { auditLogs, logType } = props

  return (
    <Col style={{ borderTop: `1px solid ${theme.colors.Grey30}` }}>
      {auditLogs.length === 0 ? (
        <Row
          alignCenter
          justifyCenter
          fullWidth
          mt={theme.space.xxs}
          style={{
            border: `2px solid ${theme.colors.Grey30}`,
            borderRadius: theme.space.xs,
            backgroundColor: theme.colors.Grey10,
            padding: theme.space.xs,
          }}
        >
          <Row flexCol alignCenter justifyCenter>
            <CardTile size="50" style={{ marginBottom: theme.space.xxs }}>
              <Icon name="card" size={24} />
            </CardTile>
            <Text variant="h6" style={{ marginBottom: theme.space.xxs }}>
              No Logs Found for this Date Range
            </Text>
          </Row>
        </Row>
      ) : (
        <Virtuoso
          data={auditLogs}
          style={{ height: '1000px' }}
          totalCount={auditLogs.length}
          itemContent={(_, auditLog) => (
            <AuditLogRow
              auditLog={auditLog}
              logType={logType}
              key={auditLog.auditLogId}
            />
          )}
        />
      )}
    </Col>
  )
}
