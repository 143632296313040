import { Role } from './companies'
import { Worker } from './workers'

export enum ConnectionType {
  BLOCK = 'BLOCK',
  FAVORITE = 'FAVORITE',
  INELIGIBLE = 'INELIGIBLE',
  SCHEDULED_BLOCK = 'SCHEDULED_BLOCK',
  TEMP_TO_HIRE = 'TEMP_TO_HIRE',
  VETTED = 'VETTED',
}

export enum EligibilityConnectionType {
  INELIGIBLE = 'INELIGIBLE',
  ELIGIBLE = 'ELIGIBLE',
}

export enum EligibilityConnectionLevel {
  ROLE = 'ROLE',
  LOCATION = 'LOCATION',
  ROLE_AND_LOCATION = 'ROLE_AND_LOCATION',
}

export type EligibilityConnection = {
  roleId?: string
  locationId?: string
  eligibilityConnectionType: EligibilityConnectionType
  eligibilityConnectionLevel: EligibilityConnectionLevel
}

export type EligibilityConnectionsByType = {
  roles: EligibilityConnection[]
  locations: EligibilityConnection[]
  locationAndRole: EligibilityConnection[]
}

export type GlobalEligibilityConnections = {
  globalEligibilityForLocations?: EligibilityConnectionType
  globalEligibilityForRoles?: EligibilityConnectionType
}

export type GlobalEligibilityConnectionsPayload = {
  globalEligibilityForLocations: EligibilityConnectionType
  globalEligibilityForRoles: EligibilityConnectionType
}

export type EligibilityConnectionResponse = {
  eligibilityConnections: EligibilityConnectionsByType
  globalEligibilities: GlobalEligibilityConnections
}

export type EligibilityConnectionMutationPayload = {
  workerId: string
  companyId: string
  initiatedBy: string
  eligibilityConnections: EligibilityConnectionsByType
  globalEligibilities: GlobalEligibilityConnectionsPayload
}

export enum ConnectionSource {
  COMPANY = 'COMPANY',
  WORKER = 'WORKER',
}

// Keep this up to date with the BE KnownBlockLabels
// that are checked when connectionReason/connectionReasons are saved
// If you add new ones, make them keys
export enum ConnectionReason {
  // Used in Biz App
  UNABLE_TO_PERFORM_REQUIREMENTS = 'UNABLE_TO_PERFORM_REQUIREMENTS',
  UNPROFESSIONAL_UNCOOPERATIVE = 'UNPROFESSIONAL_UNCOOPERATIVE',
  SERIOUS_INCIDENT = 'SERIOUS_INCIDENT',
  LOW_PRODUCTIVITY = 'LOW_PRODUCTIVITY',
  // Used in Ops App
  DOES_NOT_FOLLOW_INSTRUCTIONS = 'DOES_NOT_FOLLOW_INSTRUCTIONS',
  ABANDONED_SHIFT = 'ABANDONED_SHIFT',
  UNDER_THE_INFLUENCE = 'UNDER_THE_INFLUENCE',
  CONSISTENTLY_LATE = 'CONSISTENTLY_LATE',
  DOES_NOT_MEET_SKILL_REQUIREMENTS = 'DOES_NOT_MEET_SKILL_REQUIREMENTS',
  NO_CALL_NO_SHOW = 'NO_CALL_NO_SHOW',
  TOO_MANY_ABSENCES = 'TOO_MANY_ABSENCES',
  DISTRACTED_OR_AVOIDS_WORK = 'DISTRACTED_OR_AVOIDS_WORK',
  DISRUPTIVE_OR_INAPPROPRIATE_BEHAVIOR = 'DISRUPTIVE_OR_INAPPROPRIATE_BEHAVIOR',
  SAFETY_INCIDENT = 'SAFETY_INCIDENT',
  TAKES_EXCESSIVELY_LONG_BREAKS = 'TAKES_EXCESSIVELY_LONG_BREAKS',
  // Catch all "Other" option
  CONNECTION_REASON_OTHER = 'CONNECTION_REASON_OTHER',

  /** @deprecated reasons below **/
  // DEPRECATED_DOES_NOT_FOLLOW_INSTRUCTIONS = 'Does not follow instructions', // updated to be a key format
  // DEPRECATED_ABANDONED_SHIFT = 'Abandoned shift', // updated to be a key format
  // DEPRECATED_UNDER_THE_INFLUENCE = 'Under the influence', // updated to be a key format
  // DEPRECATED_CONSISTENTLY_LATE = 'Consistently late', // updated to be a key format
  // DEPRECATED_CANNOT_PERFORM_REQUIRED_TASK = 'Cannot perform required task', // replaced by DOES_NOT_MEET_SKILL_REQUIREMENTS
  // DEPRECATED_DOES_NOT_WANT_TO_WORK = 'Does not want to work', // replaced by DISTRACTED_OR_AVOIDS_WORK
  // DEPRECATED_WORKER_ACTIVELY_AVOIDS_WORKING = 'Worker actively avoids working', // replaced by DISTRACTED_OR_AVOIDS_WORK
  // DEPRECATED_CANNOT_WORK_WELL_WITH_OTHERS = 'Cannot work well with others', // replaced by DISRUPTIVE_OR_INAPPROPRIATE_BEHAVIOR
  // DEPRECATED_WORKS_TOO_SLOWLY = 'Works too slowly',
  // DEPRECATED_CREATES_DISRUPTIVE_WORK_ENVIRONMENT = 'Creates disruptive work environment', // replaced by DISRUPTIVE_OR_INAPPROPRIATE_BEHAVIOR
  // DEPRECATED_ABUSIVE_BEHAVIOR = 'Abusive behavior', // replaced by DISRUPTIVE_OR_INAPPROPRIATE_BEHAVIOR
  // DEPRECATED_NO_SHOW_AT_SHIFT = 'No Show at Shift', // replaced by NO_CALL_NO_SHOW
}

// These are the text representations for the dropdown in the Ops App
// When they are blocking a worker
// (biz app sets up their text differently)
export const BLOCK_REASON_TO_TEXT_FOR_OPS: Partial<
  Record<ConnectionReason, string>
> = {
  [ConnectionReason.DOES_NOT_MEET_SKILL_REQUIREMENTS]:
    'Does not meet skill requirements',
  [ConnectionReason.NO_CALL_NO_SHOW]: 'No call no show',
  [ConnectionReason.TOO_MANY_ABSENCES]: 'Too many absences',
  [ConnectionReason.CONSISTENTLY_LATE]: 'Consistently late',
  [ConnectionReason.ABANDONED_SHIFT]: 'Abandoned shift',
  [ConnectionReason.DISTRACTED_OR_AVOIDS_WORK]: 'Distracted or Avoids work',
  [ConnectionReason.DOES_NOT_FOLLOW_INSTRUCTIONS]:
    'Does not follow instructions',
  [ConnectionReason.DISRUPTIVE_OR_INAPPROPRIATE_BEHAVIOR]:
    'Disruptive / Inappropriate Behavior',
  [ConnectionReason.UNDER_THE_INFLUENCE]: 'Under the influence',
  [ConnectionReason.SAFETY_INCIDENT]: 'Safety incident',
  [ConnectionReason.TAKES_EXCESSIVELY_LONG_BREAKS]:
    'Takes excessively long breaks',
  [ConnectionReason.LOW_PRODUCTIVITY]: 'Low productivity',
  [ConnectionReason.CONNECTION_REASON_OTHER]: 'Other',
}

export type WorkerConnections = {
  blocked: string[]
  ineligible: string[]
  workerFavorites: string[]
  companyFavorites: string[]
}

export type Connection = {
  id: string
  companyId: string
  connectionType: ConnectionType
  createdAt: string
  source: ConnectionSource
  workerId: string
  initiatedBy?: string
  note?: string
  connectionReason?: ConnectionReason
  connectionReasons?: ConnectionReason[]
  updatedAt: string
  roles?: RoleConnection[]
}

export type RoleConnection = {
  id: string
  createdAt: Date
  initiatedBy?: string
  role: Pick<Role, 'roleId' | 'roleName' | 'activeRoleId'>
  note?: string
}

export type WorkerConnectionResponse = Connection & {
  employerName: string
}

export type CompanyConnectionResponse = Connection & {
  worker: Worker
}

export type BaseConnection = {
  connectionType: ConnectionType
}

export type CompanyConnectionDto = BaseConnection & {
  workerId: string
}

export type WorkerConnectionDto = BaseConnection & {
  companyId: string
}

export type CompanyConnectionOnBehalfDto = CompanyConnectionDto & {
  initiatedBy: string
  note: string
  bypassRestrictions?: boolean
}

export type ConnectionDto = Connection & {
  bypassRestrictions?: boolean
}

export type ConnectionQueryDto = {
  companyId: string
  workerId?: string
  connectionType?: ConnectionType
  source?: ConnectionSource
}
