import { Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Money } from '@traba/types'
import { format } from 'date-fns'
import { Icon } from 'src/components/base'
import { getMoneyString } from 'src/utils/stringUtils'

interface InvoiceHeaderProps {
  companyName: string
  invoiceNumber: string
  invoiceMemo: string
  createdAt: Date
  dueDate: Date
  regionIds: string[]
  statementStartDate?: Date
  statementEndDate?: Date
  paymentUrl: string
  totalAmountBilled: Money
  trabaBilling: {
    name: string
    address: string[]
    email: string
  }
  customerBilling: {
    companyName: string
    name: string
    phone: string
    email: string
  }
  customerSupportInstructions: string
}

export function InvoiceHeader({
  companyName,
  invoiceNumber,
  invoiceMemo,
  createdAt,
  regionIds,
  statementStartDate,
  statementEndDate,
  paymentUrl,
  trabaBilling,
  customerBilling,
  totalAmountBilled,
  dueDate,
  customerSupportInstructions,
}: InvoiceHeaderProps) {
  const invoiceDate = format(createdAt, 'MM/dd/yyyy')

  const invoicePeriodStart = statementStartDate
    ? format(statementStartDate, 'MM/dd/yyyy')
    : '--'

  const invoicePeriodEnd = statementEndDate
    ? format(statementEndDate, 'MM/dd/yyyy')
    : '--'

  const totalAmountDue = getMoneyString(totalAmountBilled)

  const formattedDueDate = format(dueDate, 'MMM dd, yyyy')

  return (
    <Row flexCol gap={theme.space.xxs}>
      <Row justifyBetween alignCenter>
        <Text
          variant="h2"
          color={theme.colors.Black}
          style={{ fontWeight: '600' }}
        >
          Invoice {companyName}
        </Text>
        <Icon name="trabaFull" type="svg" height={48} width={160} />
      </Row>
      <Row justifyBetween>
        <Row flexCol gap={theme.space.us}>
          <Row gap={theme.space.us}>
            <Text
              variant="h6"
              color={theme.colors.Black}
              style={{ width: 100 }}
            >
              Invoice No
            </Text>
            <Text variant="body2" color={theme.colors.Black}>
              {invoiceNumber}
            </Text>
          </Row>
          <Row gap={theme.space.us}>
            <Text
              variant="h6"
              color={theme.colors.Black}
              style={{ width: 100 }}
            >
              Invoice Date
            </Text>
            <Text variant="body2" color={theme.colors.Black}>
              {invoiceDate}
            </Text>
          </Row>
          <Row gap={theme.space.us}>
            <Text
              variant="h6"
              color={theme.colors.Black}
              style={{ width: 100 }}
            >
              {regionIds.length > 1 ? 'Locations' : 'Location'}
            </Text>
            <Text variant="body2" color={theme.colors.Black}>
              {regionIds.join(', ')}
            </Text>
          </Row>
          {invoiceMemo && (
            <Row gap={theme.space.us}>
              <Text
                variant="h6"
                color={theme.colors.Black}
                style={{ width: 100 }}
              >
                Memo:
              </Text>
              <Text
                variant="body2"
                color={theme.colors.Black}
                style={{ whiteSpace: 'pre-line' }} // This allows newlines to be rendered
              >
                {invoiceMemo}
              </Text>
            </Row>
          )}
        </Row>
        <Row flexCol alignEnd gap={theme.space.xxs}>
          <Row>
            <Row flexCol alignEnd justifyEvenly gap={theme.space.xxs}>
              <Text
                variant="body2"
                color={theme.colors.Black}
                style={{
                  width: '140px',
                  textAlign: 'right',
                  marginRight: theme.space.xxs,
                }}
              >
                Pay Period Starting:
              </Text>
              <Text
                variant="body2"
                color={theme.colors.Black}
                style={{
                  width: '140px',
                  textAlign: 'right',
                  marginRight: theme.space.xxs,
                }}
              >
                Pay Period Ending:
              </Text>
            </Row>
            <Row flexCol>
              <Text
                variant="body2"
                color={theme.colors.Black}
                width={100}
                style={{
                  padding: `${theme.space.ms}px ${theme.space.xxs}px`,
                  textAlign: 'center',
                  border: '1px solid black',
                  backgroundColor: theme.colors.Blue10,
                }}
              >
                {invoicePeriodStart}
              </Text>
              <Text
                variant="body2"
                color={theme.colors.Black}
                width={100}
                style={{
                  padding: `${theme.space.ms}px ${theme.space.xxs}px`,
                  textAlign: 'center',
                  border: '1px solid black',
                  borderTop: 'none',
                  backgroundColor: theme.colors.Blue10,
                }}
              >
                {invoicePeriodEnd}
              </Text>
            </Row>
          </Row>
          <a href={paymentUrl}>
            <Text variant="link" style={{ textDecoration: 'underline' }}>
              Click Here to Pay Online
            </Text>
          </a>
        </Row>
      </Row>
      <Row gap={theme.space.xxs} mt={theme.space.xxs}>
        <Row flexCol gap={theme.space.us} mr={theme.space.sm}>
          <Text variant="h6" color={theme.colors.Black}>
            {trabaBilling.name}
          </Text>
          {trabaBilling.address.map((line, i) => (
            <Text key={i} color={theme.colors.Black} variant="body2">
              {line}
            </Text>
          ))}
          <Text variant="body2" color={theme.colors.Black}>
            {trabaBilling.email}
          </Text>
        </Row>
        <Row
          flexCol
          gap={theme.space.us}
          style={{
            maxWidth: '25vw',
            wordBreak: 'break-word',
          }}
        >
          <Text variant="h6" color={theme.colors.Black}>
            Bill To
          </Text>
          <Text variant="body2" color={theme.colors.Black}>
            {customerBilling.companyName}
          </Text>
          <Text variant="body2" color={theme.colors.Black}>
            {customerBilling.name}
          </Text>
          <Text variant="body2" color={theme.colors.Black}>
            {customerBilling.phone}
          </Text>
          <Text variant="body2" color={theme.colors.Black}>
            {customerBilling.email}
          </Text>
        </Row>
        <Row
          flexCol
          justifyEvenly
          gap={theme.space.us}
          style={{
            backgroundColor: theme.colors.Yellow10,
            border: '1px solid black',
            padding: theme.space.xs,
            height: 'fit-content',
            width: '50%',
            marginLeft: 'auto',
          }}
        >
          <Row justifyBetween mb={theme.space.sm}>
            <Text variant="h4" color={theme.colors.Black}>
              Amount Due
            </Text>
            <Text variant="h4" color={theme.colors.Black}>
              {totalAmountDue}
            </Text>
          </Row>
          <Row justifyBetween>
            <Text variant="h4" color={theme.colors.Black}>
              Due Date
            </Text>
            <Text variant="h4" color={theme.colors.Black}>
              {formattedDueDate}
            </Text>
          </Row>
        </Row>
      </Row>
      <Row mt={theme.space.xxs}>
        <Text variant="body2" color={theme.colors.Black}>
          {customerSupportInstructions}
        </Text>
      </Row>
    </Row>
  )
}
