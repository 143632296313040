import { ButtonVariant, SvgIcon } from '@traba/react-components'
import { theme } from '@traba/theme'
import { StyledButton } from './styles'

interface EditDetailsButtonProps {
  onEditStep: () => void
  isLoading?: boolean
}

export function EditDetailsButton({
  onEditStep,
  isLoading,
}: EditDetailsButtonProps) {
  return (
    <StyledButton
      variant={ButtonVariant.OUTLINED}
      onClick={onEditStep}
      disabled={isLoading}
      loading={isLoading}
    >
      <SvgIcon
        name="edit"
        style={{ marginRight: theme.space.xxs }}
        size={16}
        color={theme.colors.Grey50}
      />
      Edit Details
    </StyledButton>
  )
}
