import { useAlert } from '@traba/context'
import { Dialog, MediaUploader, MODAL_SIZE } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { getErrorMessage } from 'src/utils/errorUtils'
import { useWorkerResumeUpload } from '../../hooks/useWorkerResumeUpload'

interface WorkerResumeUploadModalProps {
  showModal: boolean
  onClose: () => void
  workerId: string
  trackEvent?: () => void
}

export function WorkerResumeUploadModal({
  showModal,
  onClose,
  workerId,
  trackEvent,
}: WorkerResumeUploadModalProps) {
  const { showError } = useAlert()
  const [resumeFile, setResumeFile] = useState<File | undefined>()
  const { uploadResume, isPending } = useWorkerResumeUpload(workerId)

  const handleSubmit = async () => {
    if (!resumeFile) {
      return
    }

    await uploadResume(resumeFile)
    trackEvent?.()
    setResumeFile(undefined)
    onClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={() => {
        setResumeFile(undefined)
        onClose()
      }}
      onConfirmCTA="Save Resume"
      dialogTitle="Upload Resume"
      confirmDisabled={!resumeFile || isPending}
      onConfirm={handleSubmit}
      size={MODAL_SIZE.MEDIUM}
    >
      <MediaUploader
        id="worker-resume-uploader"
        label="Select resume (PDF)"
        file={resumeFile}
        onChange={setResumeFile}
        onDelete={() => setResumeFile(undefined)}
        onError={(error) => {
          showError(
            getErrorMessage(error),
            'File upload error. Please try again.',
          )
        }}
        fileType="pdf"
        maxFileSizeMB={5}
        marginTop={theme.space.xs}
      />
    </Dialog>
  )
}
