import { theme } from '@traba/theme'
import { IconName } from '@traba/types'
import Row from './Row'
import { SvgIcon } from './SvgIcon'
import { Text } from './Text'

interface InfoRowProps {
  text: string
  iconName?: IconName
  color?: string
  iconSize?: number
}

export function InfoRow(props: InfoRowProps) {
  const {
    text,
    iconName = 'alert',
    color = theme.colors.brand,
    iconSize = 16,
  } = props

  return (
    <Row alignCenter gap={theme.space.xxs}>
      <SvgIcon
        name={iconName}
        size={iconSize}
        color={color}
        style={{ minWidth: `${iconSize}px` }}
      />
      <Text variant="body3" color={color}>
        {text}
      </Text>
    </Row>
  )
}
