import { Badge, Col, LoadingSpinner, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  QualificationQuestionAnswerQuestionType,
  LanguageContentObj,
  QUALIFICATION_QUESTION_TYPE_TO_TEXT,
  QUESTION_TYPES_REQUIRING_ANSWER_OPTIONS,
} from '@traba/types'
import { getAlphabeticalLabelFromIndex, getEnglishContent } from '@traba/utils'
import { useQualifierQuestionAnswerById } from 'src/hooks/useQualifierQuestionAnswer'
import { HyperlinkLocalizedText } from '../../HyperlinkLocalizedText'
import { DetailRow } from '../DetailRow'

type CustomQuestionApplicationItemContentProps = {
  applicationItemSourceId?: string
  questionType?: QualificationQuestionAnswerQuestionType
  questionTitle?: LanguageContentObj[]
  questionDescription?: LanguageContentObj[]
  answerOptions?: LanguageContentObj[][]
}

export function CustomQuestionApplicationItemContent({
  applicationItemSourceId,
  questionType,
  questionTitle,
  questionDescription,
  answerOptions,
}: CustomQuestionApplicationItemContentProps) {
  const { qualifierQuestionAnswer, isLoading: isQuestionInfoLoading } =
    useQualifierQuestionAnswerById(applicationItemSourceId || '')

  if (isQuestionInfoLoading) {
    return <LoadingSpinner />
  }

  const {
    questionType: foundQuestionType,
    parsedQuestionTitle,
    parsedQuestionDescription,
    parsedAnswerOptions,
  } = qualifierQuestionAnswer || {}

  const displayedQuestionType = foundQuestionType || questionType
  const questionTypeText = displayedQuestionType
    ? QUALIFICATION_QUESTION_TYPE_TO_TEXT[displayedQuestionType]
    : '-'
  const titleLanguageContent = parsedQuestionTitle || questionTitle
  const descriptionLanguageContent =
    parsedQuestionDescription || questionDescription
  const answers = parsedAnswerOptions || answerOptions

  const showAnswersSection =
    displayedQuestionType &&
    QUESTION_TYPES_REQUIRING_ANSWER_OPTIONS.includes(displayedQuestionType) &&
    answers &&
    answers.length > 0

  return (
    <Col gap={theme.space.xs}>
      <DetailRow
        label="Question Type"
        customResultsComponent={<Badge title={questionTypeText} />}
      />
      <DetailRow
        label="Title"
        value={getEnglishContent(titleLanguageContent) || '-'}
      />
      <DetailRow
        label="Subtitle (optional)"
        value={getEnglishContent(descriptionLanguageContent) || '-'}
      />
      {showAnswersSection && (
        <DetailRow
          label={
            displayedQuestionType ===
            QualificationQuestionAnswerQuestionType.ACKNOWLEDGMENT
              ? 'Acknowledgements'
              : 'Answers'
          }
          customResultsComponent={
            <Col>
              {answers?.map((answerOption, index) => (
                <Row key={index} gap={theme.space.xxs} alignCenter>
                  <Text variant="body1">
                    {getAlphabeticalLabelFromIndex(index)}
                  </Text>
                  <HyperlinkLocalizedText
                    text={getEnglishContent(answerOption) || '-'}
                    variant="body1"
                    linkColor={theme.colors.brand}
                  />
                </Row>
              ))}
            </Col>
          }
        />
      )}
    </Col>
  )
}
