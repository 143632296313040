import {
  DetailedBackgroundCheckStatus,
  PhoneNumberStatus,
  W9FormStatus,
} from './account-status'
import { ShiftTag } from './shift-enums'

export enum WatchlistRequirementFilter {
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  PHONE_NUMBER = 'PHONE_NUMBER',
  STRIPE = 'STRIPE_ACCOUNT',
  W9_FORM = 'W9_FORM',
}

export enum StripeStatusForWatchlist {
  NO_ACCOUNT = 'NO_ACCOUNT',
  PAYOUTS_DISABLED = 'PAYOUTS_DISABLED',
  PAYOUTS_DISABLED_SOON = 'PAYOUTS_DISABLED_SOON',
  UP_TO_DATE = 'UP_TO_DATE',
}

export enum WatchlistRequirementStatus {
  DONE = 'DONE',
  NOT_DONE = 'NOT_DONE',
  ACTION = 'ACTION',
}

export type WorkerDetailsForWatchlist = {
  workerId: string
  firstName: string
  lastName: string
  phoneNumber: string
  nextShiftId: string
  nextShiftStartTime: Date
  shiftsAtRisk: number
  waivedShiftsRemaining: number
  detailedBackgroundCheckStatus: DetailedBackgroundCheckStatus
  stripeStatus: StripeStatusForWatchlist
  phoneNumberStatus: PhoneNumberStatus
  w9formStatus: W9FormStatus
  score: number
  hasActiveW9FormStatusAction: boolean
  hasActivePhoneNumberStatusAction: boolean
  hasActiveBackgroundCheckAction: boolean
  companyId: string
  companyName: string
  isScheduledWorker: boolean
}

export type WatchlistSearchParams = {
  excludedCompanyIds?: string[]
  regions?: string[]
  nextShiftStartTimeBefore?: Date
  searchText?: string
  requirementFilters: WatchlistRequirementFilter[]
  excludeApprovedWorkers?: boolean
  showMarkedAsDone?: boolean
  limit?: number
  offset?: number
  shiftTags?: ShiftTag[]
  pods?: string[]
}
