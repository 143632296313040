import { useStatsigClient } from '@statsig/react-bindings'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  Application,
  ApplicationItemContent,
  DynamicConfigs,
  ApplicationItemType,
  ApplicationRecordStatus,
  ENABLED_APPLICATION_ITEM_TYPES,
  ApplicationEntity,
  ApplicationItem,
  AdditionalInformationForItemCreateOrUpdate,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'
import { OPS_MANUAL_STEP_BY_APPLICATION_ITEM_ID_QUERY_KEY } from './useOpsManualStep'
import { QUALIFIER_QUESTION_ANSWER_QUERY_KEY } from './useQualifierQuestionAnswer'
import { WORKER_VETTING_CAMPAIGN_QUERY_KEY } from './useWorkerVetting'

export const APPLICATIONS_FOR_ENTITY_QUERY_KEY = 'applications-for-entity'
const APPLICATIONS_BY_ID_QUERY_KEY = 'applications-by-id'
const APPLICATIONS_BATCH_QUERY_KEY = 'applications-batch'

// when we use query by entity, we will always *only* pass in either shiftRequestId OR roleId
// with shiftRequestId taking precedence.
// This function sets up the query key to accommodate this.
const getApplicationForEntityQueryKey = (
  shiftRequestId?: string,
  roleId?: string,
) => {
  const shiftRequestIdQueryKey = shiftRequestId
  const roleIdForQueryKey = shiftRequestId ? undefined : roleId
  return [
    APPLICATIONS_FOR_ENTITY_QUERY_KEY,
    shiftRequestIdQueryKey,
    roleIdForQueryKey,
  ]
}

type GetApplicationForEntityParams = {
  shiftRequestId?: string
  roleId?: string
  recordStatuses?: ApplicationRecordStatus[]
}

type GetApplicationByIdParams = {
  applicationId: string
}

type GetApplicationsParams = {
  companyId?: string
  page?: number
  pageSize?: number
  applicationId?: string
}
export type ApplicationEntitySearchResult = ApplicationEntity & {
  employerName?: string
  roleName?: string
  shiftRole?: string
  regionIds: string[]
  scheduleId?: string
  shiftRequestParentTitle?: string
  shiftRequestParentId?: string
  nextShiftId?: string
  companyId?: string
}

export type ApplicationSearchResult = Application & {
  applicationEntities: ApplicationEntitySearchResult[]
  createdAt: Date
  applicantCount: number
}

export type SearchApplicationsResponse = {
  items: ApplicationSearchResult[]
  page: number
  pageSize: number
  pageCount: number
}

export function useEnabledApplicationStepsForAppCreation(): ApplicationItemType[] {
  const { client: statsigClient } = useStatsigClient()
  const { value } = statsigClient.getDynamicConfig(
    DynamicConfigs.ATS_DYNAMIC_CONFIG,
  )
  const disabledSteps =
    (value?.opsConsoleDisabledSteps as ApplicationItemType[]) || []

  const enabledSteps = ENABLED_APPLICATION_ITEM_TYPES.filter(
    (type) => !disabledSteps.includes(type),
  )

  return enabledSteps
}

async function getApplicationById({
  applicationId,
}: GetApplicationByIdParams): Promise<Application | undefined> {
  try {
    const res = await trabaApi.get(`/applications/get/${applicationId}`)

    return res.data
  } catch (error) {
    console.error('useApplicationById -> getApplicationById() ERROR', error)
    captureSentryError(error, {
      tags: {
        action: 'useApplicationById -> getApplicationById() ERROR',
      },
    })
    throw error
  }
}

async function searchApplications({
  companyId,
  page,
  pageSize,
  applicationId,
}: GetApplicationsParams): Promise<SearchApplicationsResponse | undefined> {
  try {
    const res = await trabaApi.post(`/applications/search/batch`, {
      companyId,
      page,
      pageSize,
      applicationId,
    })
    return res.data
  } catch (error) {
    console.error('useApplications -> getApplications() ERROR', error)
    captureSentryError(error, {
      tags: {
        action: 'useApplications -> getApplications() ERROR',
      },
    })
    throw error
  }
}
const DEFAULT_RECORD_STATUSES = [
  ApplicationRecordStatus.ACTIVE,
  ApplicationRecordStatus.DISABLED,
]

async function getApplicationForEntity({
  shiftRequestId,
  roleId,
  recordStatuses = DEFAULT_RECORD_STATUSES,
}: GetApplicationForEntityParams): Promise<Application | undefined> {
  try {
    const res = await trabaApi.get(`/applications/search-by-entity`, {
      params: {
        shiftRequestId,
        recordStatuses,
        roleId,
      },
    })
    return res.data
  } catch (error) {
    console.error(
      'useApplicationForEntity -> getApplicationForEntity() ERROR',
      error,
    )
    captureSentryError(error, {
      tags: {
        action: 'useApplicationForEntity -> getApplicationForEntity() ERROR',
      },
    })
    throw error
  }
}

export function useApplicationForEntity(params: GetApplicationForEntityParams) {
  const {
    isLoading,
    isError,
    data: application,
    error,
    isFetched,
    refetch,
  } = useQuery<Application | undefined, Error>({
    queryKey: [
      APPLICATIONS_FOR_ENTITY_QUERY_KEY,
      params.shiftRequestId,
      params.roleId,
      params.recordStatuses,
    ],
    queryFn: () => getApplicationForEntity(params),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!params.shiftRequestId || !!params.roleId,
  })

  return {
    isLoading,
    isError,
    application,
    error,
    isFetched,
    refetch,
  }
}

export function useApplicationById(params: GetApplicationByIdParams) {
  const {
    isLoading,
    isError,
    data: application,
    error,
    isFetched,
    refetch,
  } = useQuery<Application | undefined, Error>({
    queryKey: [APPLICATIONS_BY_ID_QUERY_KEY, params.applicationId],
    queryFn: () => getApplicationById(params),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!params.applicationId,
  })

  return {
    isLoading,
    isError,
    application,
    error,
    isFetched,
    refetch,
  }
}

export function useApplicationsSearch(params: GetApplicationsParams) {
  const {
    isLoading,
    isError,
    data: searchResults,
    error,
    isFetched,
    refetch,
  } = useQuery<SearchApplicationsResponse | undefined, Error>({
    queryKey: [
      APPLICATIONS_BATCH_QUERY_KEY,
      params.companyId,
      params.applicationId,
      params.page,
      params.pageSize,
    ],
    queryFn: () => searchApplications(params),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    searchResults,
    error,
    isFetched,
    refetch,
  }
}

interface UpdateApplicationRecordStatusParams {
  applicationId: string
  recordStatus: ApplicationRecordStatus
}

async function updateApplicationRecordStatus({
  applicationId,
  recordStatus,
}: UpdateApplicationRecordStatusParams): Promise<Application | undefined> {
  if (!applicationId) {
    throw new Error('applicationId is required')
  }
  if (!recordStatus) {
    throw new Error('recordStatus is required')
  }

  try {
    const response = await trabaApi.patch(
      `applications/update/${applicationId}`,
      {
        recordStatus,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action:
          'useApplicationsMutations -> updateApplicationRecordStatus() ERROR',
      },
    })
    throw error
  }
}

export function useApplicationsMutations() {
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()

  const updateApplicationRecordStatusMutation = useMutation<
    Application | undefined,
    Error,
    UpdateApplicationRecordStatusParams,
    Application
  >({
    mutationFn: updateApplicationRecordStatus,
    onSuccess: (data: Application | undefined, { recordStatus }) => {
      data?.applicationEntities.forEach((ae) => {
        queryClient.invalidateQueries({
          queryKey: getApplicationForEntityQueryKey(
            ae.shiftRequestId,
            ae.roleId,
          ),
        })
      })
      showSuccess(`Application was successfully set to ${recordStatus}`)
    },
    onError: (error) => {
      showError(error?.message, 'Error changing application enabled status')
    },
  })

  return {
    updateApplicationRecordStatus: updateApplicationRecordStatusMutation.mutate,
    isUpdateApplicationRecordStatusLoading:
      updateApplicationRecordStatusMutation.isPending,
  }
}

interface UpdateApplicationItemParams {
  applicationItemId: string
  updateSingleApplicationItemRequest?: {
    itemContent?: ApplicationItemContent
  }
  additionalInformationForItemCreateOrUpdate?: AdditionalInformationForItemCreateOrUpdate
}

async function updateApplicationItemContent({
  applicationItemId,
  updateSingleApplicationItemRequest,
  additionalInformationForItemCreateOrUpdate,
}: UpdateApplicationItemParams): Promise<ApplicationItem | undefined> {
  if (!applicationItemId) {
    throw new Error('applicationItemId is required')
  }

  try {
    const response = await trabaApi.patch(
      `application-processor/item/${applicationItemId}`,
      {
        updateSingleApplicationItemRequest,
        additionalInformationForItemCreateOrUpdate,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action:
          'useApplicationItemsMutations -> updateApplicationItemContent() ERROR',
      },
    })
    throw error
  }
}

interface ArchiveApplicationItemParams {
  applicationItemId: string
  // used to invalidate queries
  shiftRequestId?: string
  roleId?: string
}

async function archiveApplicationItem({
  applicationItemId,
}: ArchiveApplicationItemParams): Promise<undefined> {
  if (!applicationItemId) {
    throw new Error('applicationItemId is required')
  }

  try {
    const response = await trabaApi.patch(
      `application-processor/item/${applicationItemId}/archive`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action:
          'useApplicationItemsMutations -> archiveApplicationItem() ERROR',
      },
    })
    throw error
  }
}

interface AddApplicationItemParams {
  applicationId: string
  createApplicationItemRequest: {
    applicationItemType: ApplicationItemType
    itemContent: ApplicationItemContent
  }
  shiftRequestId?: string
  roleId?: string
  additionalInformationForItemCreateOrUpdate?: AdditionalInformationForItemCreateOrUpdate
}

async function addApplicationItem({
  applicationId,
  createApplicationItemRequest,
  shiftRequestId,
  roleId,
  additionalInformationForItemCreateOrUpdate,
}: AddApplicationItemParams): Promise<undefined> {
  if (!applicationId) {
    throw new Error('applicationId is required')
  }

  try {
    const response = await trabaApi.post(
      `application-processor/application/${applicationId}/item`,
      {
        applicationId,
        createApplicationItemRequest,
        shiftRequestId,
        roleId,
        additionalInformationForItemCreateOrUpdate,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action: 'useApplicationItemsMutations -> addApplicationItem() ERROR',
      },
    })
    throw error
  }
}

interface ReorderApplicationItemsParams {
  applicationId: string
  items: {
    applicationItemId: string
    sectionIndex: number
  }[]
}

async function reorderApplicationItems({
  applicationId,
  items,
}: ReorderApplicationItemsParams): Promise<Application | undefined> {
  if (!applicationId) {
    throw new Error('applicationId is required')
  }

  try {
    const response = await trabaApi.patch(
      `application-processor/reorder-items`,
      {
        applicationId,
        items,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error, {
      tags: {
        action:
          'useApplicationItemsMutations -> reorderApplicationItems() ERROR',
      },
    })
    throw error
  }
}

export function useApplicationItemsMutations() {
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()

  const updateApplicationItemContentMutation = useMutation<
    ApplicationItem | undefined,
    Error,
    UpdateApplicationItemParams,
    Application
  >({
    mutationFn: updateApplicationItemContent,
    onSuccess: (data: ApplicationItem | undefined) => {
      switch (data?.applicationItemType) {
        case ApplicationItemType.CUSTOM_QUESTION:
          queryClient.invalidateQueries({
            queryKey: [QUALIFIER_QUESTION_ANSWER_QUERY_KEY, data?.sourceId],
          })
          break
        case ApplicationItemType.AI_VETTING_CALL:
          queryClient.invalidateQueries({
            queryKey: [WORKER_VETTING_CAMPAIGN_QUERY_KEY, data?.sourceId],
          })
          break
        case ApplicationItemType.OPS_MANUAL_STEP:
          queryClient.invalidateQueries({
            queryKey: [
              OPS_MANUAL_STEP_BY_APPLICATION_ITEM_ID_QUERY_KEY,
              data?.id,
            ],
          })
          break
        default:
          break
      }

      showSuccess(`Application item was successfully updated`)
    },
    onError: (error) => {
      showError(error?.message, 'Error updating application item')
    },
  })

  const archiveApplicationItemMutation = useMutation<
    undefined,
    Error,
    ArchiveApplicationItemParams,
    undefined
  >({
    mutationFn: archiveApplicationItem,
    onSuccess: (_, { shiftRequestId, roleId }) => {
      queryClient.invalidateQueries({
        queryKey: getApplicationForEntityQueryKey(shiftRequestId, roleId),
      })
      showSuccess(`Application item was successfully archived`)
    },
    onError: (error) => {
      showError(error?.message, 'Error archiving application item')
    },
  })

  const addApplicationItemMutation = useMutation<
    undefined,
    Error,
    AddApplicationItemParams,
    Application
  >({
    mutationFn: addApplicationItem,
    onSuccess: (_, variables: AddApplicationItemParams) => {
      queryClient.invalidateQueries({
        queryKey: getApplicationForEntityQueryKey(
          variables.shiftRequestId,
          variables.roleId,
        ),
      })
      showSuccess(`Application item was successfully added`)
    },
    onError: (error) => {
      showError(error?.message, 'Error adding application item')
    },
  })

  const reorderApplicationItemsMutation = useMutation<
    Application | undefined,
    Error,
    ReorderApplicationItemsParams,
    Application | undefined
  >({
    mutationFn: reorderApplicationItems,
    onSuccess: (data: Application | undefined) => {
      data?.applicationEntities.forEach((ae) => {
        queryClient.invalidateQueries({
          queryKey: getApplicationForEntityQueryKey(
            ae.shiftRequestId,
            ae.roleId,
          ),
        })
      })
      showSuccess(`Application items were successfully reordered`)
    },
    onError: (error) => {
      showError(error?.message, 'Error reordering application items')
    },
  })

  return {
    updateApplicationItemContent: updateApplicationItemContentMutation.mutate,
    isUpdateApplicationItemContentLoading:
      updateApplicationItemContentMutation.isPending,
    archiveApplicationItem: archiveApplicationItemMutation.mutate,
    isArchiveApplicationItemLoading: archiveApplicationItemMutation.isPending,
    addApplicationItem: addApplicationItemMutation.mutate,
    isAddApplicationItemLoading: addApplicationItemMutation.isPending,
    reorderApplicationItems: reorderApplicationItemsMutation.mutate,
    isReorderApplicationItemsLoading: reorderApplicationItemsMutation.isPending,
  }
}
