import { useAlert } from '@traba/context'
import { FileType } from '@traba/hooks'

import { useFileUploader } from '@traba/hooks'
import {
  Button,
  Col,
  FileDrop,
  Input,
  Row,
  Text,
  Tab,
  Tabs,
  TabPanel,
} from '@traba/react-components'
import { ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useState } from 'react'
import { useUploads } from 'src/hooks/useUploads'

export const ArtifactUploadContent = ({
  workerId,
  handleClose,
}: {
  workerId: string
  handleClose: () => void
}) => {
  const { handleUpload } = useFileUploader()
  const { createWorkerUpload } = useUploads(workerId)
  const [upload, setUpload] = useState<File | undefined>()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [selectedTab, setSelectedTab] = useState(0)
  const [link, setLink] = useState<string>('')
  const { showError } = useAlert()

  const onChangeFile = (f: File | undefined) => {
    setUpload(f)
  }

  const onHandleSubmit = async () => {
    if (title === '' || description === '') {
      return
    }

    if (selectedTab === 0) {
      if (!upload) {
        showError('Please provide a file for the artifact')
        return
      }
      const url = await handleUpload({
        fileType: FileType.WORKER_PERFORMANCE,
        media: upload,
        userId: workerId,
      })

      if (url === '') {
        showError('There was an error uploading the file.')
        return
      }

      await createWorkerUpload({
        upload: {
          url,
          title,
          description,
          fileType: FileType.WORKER_PERFORMANCE,
          mimeType: upload.type,
        },
        workerId: workerId || '',
      })
      setUpload(undefined)
    } else {
      if (!link) {
        showError('Please provide a link for the artifact')
        return
      }
      await createWorkerUpload({
        upload: {
          url: link,
          title,
          description,
          fileType: FileType.WORKER_PERFORMANCE,
          mimeType: 'text/uri-list',
        },
        workerId: workerId || '',
      })
      setLink('')
    }

    setTitle('')
    setDescription('')
  }

  const onHandleSubmitAndClose = async () => {
    await onHandleSubmit()
    handleClose()
  }

  const isCurrentTabFilled =
    (selectedTab === 0 && upload) || (selectedTab === 1 && link)
  const isSubmittable = title !== '' && description !== '' && isCurrentTabFilled

  return (
    <>
      <Col gap={theme.space.xs}>
        <Row alignCenter fullWidth justifyBetween>
          <Text variant="h6">Title</Text>
          <Input
            onChange={(item) => {
              setTitle(item.target.value)
            }}
            value={title}
            containerStyle={{ marginTop: 0, minWidth: '80%' }}
            style={{ width: '100%' }}
          />
        </Row>
        <Row alignCenter fullWidth justifyBetween>
          <Text variant="h6">Description</Text>
          <Input
            onChange={(item) => {
              setDescription(item.target.value)
            }}
            value={description}
            containerStyle={{
              marginTop: 0,
              minWidth: '80%',
            }}
          />
        </Row>

        <Col gap={theme.space.xs} mt={theme.space.xs}>
          <Tabs
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value)}
          >
            <Tab label="File Upload" />
            <Tab label="Link" />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <Text variant="h5">Documentation</Text>
            <FileDrop setUpload={onChangeFile} upload={upload} />
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <Text variant="h5">Link URL</Text>
            <Input
              onChange={(item) => {
                setLink(item.target.value)
              }}
              value={link}
              placeholder="Enter URL"
              containerStyle={{
                marginTop: theme.space.xs,
                width: '100%',
              }}
            />
          </TabPanel>
        </Col>
      </Col>
      <Row justifyBetween>
        <Button
          onClick={onHandleSubmitAndClose}
          style={{ margin: theme.space.xs }}
          disabled={!isSubmittable}
        >
          Submit
        </Button>
        <Button
          variant={ButtonVariant.BRANDLINK}
          onClick={onHandleSubmit}
          style={{ margin: theme.space.xs }}
          disabled={!isSubmittable}
        >
          Submit and attach another
        </Button>
      </Row>
    </>
  )
}
