import { useStatsigClient } from '@statsig/react-bindings'
import { ShiftTag, ShiftTagExcludingPods } from '@traba/types'
import { IMenuItem } from 'src/components/base/Select/Select'

export const useShiftTags = () => {
  const { client } = useStatsigClient()
  const isPodTagMigrationEnabled = client.checkGate('pod_tag_migration')

  const shiftTagArray = isPodTagMigrationEnabled
    ? (Object.values(ShiftTagExcludingPods) as string[])
    : (Object.values(ShiftTag) as string[])

  const shiftTagMenuItems: IMenuItem[] = shiftTagArray.map((tag) => {
    return { label: tag, value: tag }
  })

  return {
    shiftTagArray,
    shiftTagMenuItems,
  }
}
