import { Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ApplicationItemContent as ApplicationItemContentType,
  ApplicationItemType,
  UpsertApplicationItemRequestType,
} from '@traba/types'
import { useCallback, useState } from 'react'
import Divider from 'src/components/base/Divider'
import { ApplicationItemContent } from './ApplicationItemContent/ApplicationItemContent'
import { ApplicationItemNumberAndTitle } from './ApplicationItemNumberAndTitle'
import { CancelButton } from './CancelButton'
import { CreateAndEditApplicationItemContent } from './CreateAndEditApplicationItemContent/CreateAndEditApplicationItemContent'
import { EditDetailsButton } from './EditDetailsButton'
import { useApplicationsAnalytics } from './hooks/useApplicationsAnalytics'
import { RemoveStepButton } from './RemoveStepButton'
import { StyledApplicationStepContainer } from './styles'
import { ApplicationItemCreationAdditionalProps } from './types'

const APPLICATION_ITEMS_WITH_NO_EXTRA_DISPLAY_CONTENT = [
  ApplicationItemType.RESUME_UPLOAD,
  ApplicationItemType.GHOST_PROFILE_LINKED,
]

export type ViewAndEditExistingApplicationItemProps = {
  applicationItemId?: string
  applicationItemType: ApplicationItemType
  applicationItemSourceId?: string
  onRemoveStep?: () => void
  onSaveStep?: (
    upsertApplicationItemRequest: UpsertApplicationItemRequestType,
  ) => void
  isLoading?: boolean
  isEditable?: boolean
  stepIndex: number
  itemContent?: ApplicationItemContentType
  analyticsSource?: string
} & ApplicationItemCreationAdditionalProps

export function ViewAndEditExistingApplicationItem({
  applicationItemId,
  applicationItemType,
  applicationItemSourceId,
  onRemoveStep,
  onSaveStep,
  isLoading,
  isEditable,
  stepIndex,
  itemContent,
  analyticsSource = 'view-and-edit-existing-application-item',
  ...additionalPropsForCreateAndEditApplicationItemContent
}: ViewAndEditExistingApplicationItemProps) {
  const [editing, setEditing] = useState(false)
  const { trackEditApplicationItem } = useApplicationsAnalytics()

  const onClickEditStep = useCallback(() => {
    setEditing(true)

    const { shiftRequest, role } =
      additionalPropsForCreateAndEditApplicationItemContent
    trackEditApplicationItem({
      applicationItemId,
      applicationItemType,
      source: analyticsSource,
      shiftRequestId: shiftRequest?.shiftRequestId,
      roleId: role?.roleId,
      companyId: role?.companyId,
    })
  }, [
    applicationItemId,
    applicationItemType,
    trackEditApplicationItem,
    additionalPropsForCreateAndEditApplicationItemContent.shiftRequest,
    additionalPropsForCreateAndEditApplicationItemContent.role,
  ])

  const onClickCancelEdit = useCallback(() => {
    setEditing(false)
  }, [])

  const handleSaveStep = useCallback(
    (upsertApplicationItemRequest: UpsertApplicationItemRequestType) => {
      if (onSaveStep) {
        onSaveStep(upsertApplicationItemRequest)
        setEditing(false)
      }
    },
    [onSaveStep],
  )

  const allowEdit = isEditable && onSaveStep

  const hasNoEditableContent =
    APPLICATION_ITEMS_WITH_NO_EXTRA_DISPLAY_CONTENT.includes(
      applicationItemType,
    )
  const hideDivider = !editing && hasNoEditableContent

  return (
    <StyledApplicationStepContainer>
      <Row justifyBetween alignCenter>
        <ApplicationItemNumberAndTitle
          stepNumber={stepIndex}
          applicationItemType={applicationItemType}
          titleVariant="h5"
        />

        {allowEdit && (
          <Row gap={theme.space.xs}>
            {!hasNoEditableContent && (
              <>
                {editing ? (
                  <CancelButton
                    onCancel={onClickCancelEdit}
                    isLoading={isLoading}
                  />
                ) : (
                  <EditDetailsButton
                    onEditStep={onClickEditStep}
                    isLoading={isLoading}
                  />
                )}
              </>
            )}

            {onRemoveStep && (
              <RemoveStepButton
                onRemoveStep={onRemoveStep}
                isLoading={isLoading}
              />
            )}
          </Row>
        )}
      </Row>

      {!hideDivider && <Divider />}

      {editing && allowEdit ? (
        <CreateAndEditApplicationItemContent
          applicationItemId={applicationItemId}
          applicationItemType={applicationItemType}
          applicationItemSourceId={applicationItemSourceId}
          onSave={handleSaveStep}
          itemContent={itemContent}
          {...additionalPropsForCreateAndEditApplicationItemContent}
        />
      ) : (
        <ApplicationItemContent
          applicationItemId={applicationItemId}
          applicationItemType={applicationItemType}
          applicationItemSourceId={applicationItemSourceId}
          itemContent={itemContent}
        />
      )}
    </StyledApplicationStepContainer>
  )
}
