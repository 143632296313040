import {
  Col,
  DataTable,
  DataTableHeader,
  Link,
  LoadingSpinner,
  Row,
  SvgIcon,
  Toggle,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { Strike, WorkerUpload } from '@traba/types'
import { getStrikeReasonLabel } from '@traba/types'
import { useState } from 'react'
import { useUploads } from 'src/hooks/useUploads'
import { useStrikeMutation, useWorkerStrikes } from 'src/hooks/useWorkerStrikes'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'

export enum WorkerPerformanceTab {
  ARTIFACTS = 0,
  STRIKES = 1,
}

export const WorkerPerformanceDataTable = ({
  currentTab,
  workerId,
}: {
  currentTab: WorkerPerformanceTab
  workerId: string
}) => {
  const [invalidated, setInvalidated] = useState(false)
  const { uploads, isLoading: isLoadingUploads } = useUploads(workerId)
  const { strikes, isLoading: isLoadingStrikes } = useWorkerStrikes(
    workerId,
    invalidated,
  )
  const { invalidateStrikes } = useStrikeMutation()

  const getArtifactsTableHeaders = (): DataTableHeader[] => {
    return [
      {
        key: 'title',
        label: 'Title',
        sortable: true,
      },
      {
        key: 'description',
        label: 'Description',
        sortable: true,
      },
      {
        key: 'createdAt',
        label: 'Date',
        sortable: true,
      },
      {
        key: 'uploadedBy',
        label: 'Uploaded By',
        sortable: true,
      },
      {
        key: 'fileFormat',
        label: 'File Format',
        sortable: true,
      },
      {
        key: 'artifact',
        label: 'Artifact',
      },
    ]
  }

  const getStrikesTableHeaders = (invalidated: boolean): DataTableHeader[] => {
    return [
      {
        key: 'createdAt',
        label: 'Date',
        sortable: true,
      },
      {
        key: 'reason',
        label: 'Reason',
        sortable: true,
      },
      {
        key: 'strikePoints',
        label: 'Points',
        sortable: true,
      },
      {
        key: 'shiftId',
        label: 'Shift ID',
        sortable: true,
      },
      {
        key: 'proven',
        label: 'Proven',
      },
      {
        key: 'invalidate',
        label: 'Invalidate',
      },
      ...(invalidated
        ? [
            {
              key: 'invalidated',
              label: 'Invalidated By',
              sortable: true,
            },
          ]
        : []),
    ]
  }

  const getArtifactsTableRows = (uploads: WorkerUpload[] | undefined) => {
    return (
      uploads?.map((upload, index) => ({
        key: `table-row-${index}`,
        cells: [
          {
            key: `table-cell-${index}-title`,
            renderFn: () => upload.title,
            sortKey: upload.title,
          },
          {
            key: `table-cell-${index}-description`,
            renderFn: () => upload.description,
            sortKey: upload.description,
          },
          {
            key: `table-cell-${index}-createdAt`,
            renderFn: () => formatDateTimeWithTimezone(upload.createdAt),
            sortKey: upload.createdAt.getTime(),
          },
          {
            key: `table-cell-${index}-uploadedBy`,
            renderFn: () => upload.uploadedBy,
            sortKey: upload.uploadedBy,
          },
          {
            key: `table-cell-${index}-mimeType`,
            renderFn: () => upload.mimeType,
            sortKey: upload.mimeType,
          },
          {
            key: `table-cell-${index}-url`,
            renderFn: () => (
              <Link to={upload.url} target="_blank">
                Open
              </Link>
            ),
          },
        ],
      })) || []
    )
  }

  const getStrikesTableRows = (
    strikes: Strike[] | undefined,
    invalidated: boolean,
  ) => {
    return (
      strikes?.map((strike, index) => ({
        key: `table-row-${index}`,
        cells: [
          {
            key: `table-cell-${index}-createdAt`,
            renderFn: () => formatDateTimeWithTimezone(strike.createdAt),
            sortKey: strike.createdAt.getTime(),
          },
          {
            key: `table-cell-${index}-reason`,
            renderFn: () => getStrikeReasonLabel(strike.reason),
            sortKey: getStrikeReasonLabel(strike.reason),
          },
          {
            key: `table-cell-${index}-strikePoints`,
            renderFn: () => strike.strikePoints.toString(),
            sortKey: strike.strikePoints,
          },
          {
            key: `table-cell-${index}-shiftId`,
            renderFn: () => (
              <Link to={`/field-monitor/${strike.shiftId}`} target="_blank">
                {strike.shiftId}
              </Link>
            ),
            sortKey: strike.shiftId || '',
          },
          {
            key: `table-cell-${index}-proven`,
            renderFn: () => (strike.proven ? 'Yes' : 'No'),
            sortKey: strike.proven ? 1 : 0,
          },
          {
            key: `table-cell-${index}-invalidate`,
            renderFn: () => (
              <SvgIcon
                name="trash"
                onClick={() => invalidateStrikes({ strikeIds: [strike.id] })}
              />
            ),
            sortKey: strike.id,
          },
          ...(invalidated
            ? [
                {
                  key: `table-cell-${index}-invalidated`,
                  renderFn: () => strike.invalidatedBy,
                  sortKey: strike.invalidatedBy,
                },
              ]
            : []),
        ],
      })) || []
    )
  }

  switch (currentTab) {
    case WorkerPerformanceTab.ARTIFACTS:
      if (isLoadingUploads) {
        return <LoadingSpinner />
      }
      return (
        <DataTable
          headers={getArtifactsTableHeaders()}
          rows={getArtifactsTableRows(uploads)}
        />
      )
    case WorkerPerformanceTab.STRIKES:
      if (isLoadingStrikes) {
        return <LoadingSpinner />
      }
      return (
        <Col gap={theme.space.xs}>
          <Row>
            <Toggle
              label="Show Forgiven Strikes"
              onChange={() => setInvalidated(!invalidated)}
              buttonState={invalidated}
              runOnChange={() => setInvalidated(!invalidated)}
            />
          </Row>
          <DataTable
            headers={getStrikesTableHeaders(invalidated)}
            rows={getStrikesTableRows(strikes, invalidated)}
          />
        </Col>
      )
  }
}
