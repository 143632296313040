import { Tooltip } from '@mui/material'
import { Button } from '@traba/react-components'
import { Row } from 'src/components/base'

interface Props {
  isLoadingButton: boolean
  disabledButton: boolean
  buttonTitle: string
  onClick: () => void
  disabledButtonTooltip?: string
}

export const CreateShiftRequestButton = ({
  isLoadingButton,
  disabledButton,
  onClick,
  buttonTitle,
  disabledButtonTooltip,
}: Props) => {
  return (
    <Row justifyEnd>
      <Tooltip title={disabledButtonTooltip}>
        <Button
          style={{ width: '250px' }}
          onClick={onClick}
          loading={isLoadingButton}
          disabled={disabledButton}
        >
          {buttonTitle}
        </Button>
      </Tooltip>
    </Row>
  )
}
