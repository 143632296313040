import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AuditLog,
  CompanyEvents,
  ScheduleEvents,
  ShiftEvents,
  WorkerEvents,
} from '@traba/types'
import {
  addDays,
  isAfter,
  isBefore,
  isEqual,
  subDays,
  subYears,
} from 'date-fns'
import { useState } from 'react'
import { Col, DatePicker, IconButton, Row } from 'src/components/base'
import SelectableCard from 'src/components/base/SelectableCard/SelectableCard'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { AuditSearchParams, useAuditLogs } from 'src/hooks/useAuditLogs'
import AuditLogTable from './components/AuditLogTable'
import AuditSearchBox from './components/AuditSearchBox'

export type AuditLogType = 'SHIFT' | 'WORKER' | 'COMPANY' | 'SCHEDULE'
export type AuditLogEventFilter = (
  | ShiftEvents
  | WorkerEvents
  | CompanyEvents
  | ScheduleEvents
)[]

interface AuditLogsScreenProps {
  headerContent: JSX.Element
  logType: AuditLogType
  searchId: string
}
enum DateRangeOptions {
  Custom = 'Custom',
  Today = 'Today',
  Yesterday = 'Yesterday',
  OneWeek = '7D',
  OneMonth = '30D',
  OneYear = '1Y',
}

export default function AuditLogsScreen(props: AuditLogsScreenProps) {
  const { headerContent, logType, searchId } = props

  /*
   * Search States
   */
  const begOfToday = new Date(new Date().setHours(0, 0, 0, 0))
  const begOfTomorrow = addDays(begOfToday, 1)
  const [logSearch, setLogSearch] = useState<AuditSearchParams>({
    beforeCreationDate: begOfTomorrow,
    afterCreationDate: begOfToday,
    ...(logType === 'SHIFT' && { shiftId: searchId }),
    ...(logType === 'WORKER' && { workerId: searchId }),
    ...(logType === 'COMPANY' && { companyId: searchId }),
    ...(logType === 'SCHEDULE' && { scheduleId: searchId }),
  })
  const [dateRange, setDateRange] = useState<DateRangeOptions>(
    DateRangeOptions.Today,
  )
  const [eventFilter, setEventFilter] = useState<AuditLogEventFilter>([])

  const {
    auditLogs = [],
    isLoading: isLoadingLogs,
    refetch: refetchLogs,
    isFetching: isFetchingLogs,
  } = useAuditLogs(logSearch)

  function onChangeFromDate(newDate: Date, isFourDigitYear: boolean) {
    setLogSearch({
      ...logSearch,
      afterCreationDate: newDate,
    })
    if (
      (isAfter(newDate, logSearch.beforeCreationDate) ||
        isEqual(newDate, logSearch.beforeCreationDate)) &&
      isFourDigitYear
    ) {
      setLogSearch({
        ...logSearch,
        afterCreationDate: newDate,
        beforeCreationDate: addDays(newDate, 1),
      })
    } else {
      setLogSearch({
        ...logSearch,
        afterCreationDate: newDate,
      })
    }
  }

  function onChangeToDate(newDate: Date, isFourDigitYear?: boolean) {
    if (
      (isBefore(newDate, logSearch.afterCreationDate) ||
        isEqual(newDate, logSearch.afterCreationDate)) &&
      isFourDigitYear
    ) {
      setLogSearch({
        ...logSearch,
        beforeCreationDate: newDate,
        afterCreationDate: subDays(newDate, 1),
      })
    } else {
      setLogSearch({
        ...logSearch,
        beforeCreationDate: newDate,
      })
    }
  }

  const handleSelectDateRange = (rangeOption: DateRangeOptions) => {
    if (rangeOption === dateRange) {
      return
    }
    setDateRange(rangeOption)
    const begOfToday = new Date(new Date().setHours(0, 0, 0, 0))
    const begOfTomorrow = addDays(begOfToday, 1)

    switch (rangeOption) {
      case DateRangeOptions.Custom:
        break
      case DateRangeOptions.Today:
        setLogSearch({
          ...logSearch,
          beforeCreationDate: begOfTomorrow,
          afterCreationDate: begOfToday,
        })
        break
      case DateRangeOptions.Yesterday: {
        const begOfYesterday = subDays(begOfToday, 1)
        setLogSearch({
          ...logSearch,
          beforeCreationDate: begOfToday,
          afterCreationDate: begOfYesterday,
        })
        break
      }
      case DateRangeOptions.OneWeek: {
        const oneWeekAgo = subDays(begOfToday, 7)
        setLogSearch({
          ...logSearch,
          beforeCreationDate: begOfTomorrow,
          afterCreationDate: oneWeekAgo,
        })
        break
      }
      case DateRangeOptions.OneMonth: {
        const oneMonthAgo = subDays(begOfToday, 30)
        setLogSearch({
          ...logSearch,
          beforeCreationDate: begOfTomorrow,
          afterCreationDate: oneMonthAgo,
        })
        break
      }
      case DateRangeOptions.OneYear: {
        const oneYear = subYears(begOfToday, 1)
        setLogSearch({
          ...logSearch,
          beforeCreationDate: begOfTomorrow,
          afterCreationDate: oneYear,
        })
        break
      }
    }
  }

  let filteredAuditLogs: AuditLog[] = auditLogs
  if (eventFilter.length > 0) {
    filteredAuditLogs = auditLogs.filter((log) =>
      eventFilter.includes(log.event),
    )
  }

  return (
    <MainLayout title="Audit Logs">
      {headerContent}
      <Row
        fullHeight
        fullWidth
        mt={theme.space.sm}
        style={{ minHeight: '500px' }}
      >
        <Row
          flexCol
          style={{
            paddingRight: theme.space.xs,
            paddingTop: theme.space.xs,
            borderTop: `2px solid ${theme.colors.Grey30}`,
          }}
        >
          <Text variant="h5" style={{ marginBottom: theme.space.xs }}>
            Find An Event
          </Text>
          <AuditSearchBox
            logSearch={logSearch}
            logType={logType}
            setLogSearch={setLogSearch}
            eventFilter={eventFilter}
            setEventFilter={setEventFilter}
          />
        </Row>
        <Row style={{ flex: 1 }}>
          <Col
            style={{
              borderTop: `2px solid ${theme.colors.Grey30}`,
              borderLeft: `2px solid ${theme.colors.Grey30}`,
              padding: theme.space.sm,
            }}
          >
            <Row mb={theme.space.sm} alignCenter justifyBetween>
              <Row alignCenter>
                <Text variant="h5" style={{ marginRight: theme.space.xs }}>
                  Event Log ({filteredAuditLogs.length})
                </Text>
                <IconButton
                  iconName="refresh"
                  onClick={() => refetchLogs()}
                  loading={isLoadingLogs || isFetchingLogs}
                />
              </Row>
              <Row>
                {dateRange === DateRangeOptions.Custom && (
                  <>
                    <DatePicker
                      label="From"
                      date={logSearch.afterCreationDate}
                      setDate={(date, isFourDigitYear) =>
                        onChangeFromDate(date!, isFourDigitYear || false)
                      }
                      width={260}
                      isClearable={false}
                      showTimeFieldInPopover
                    />
                    <DatePicker
                      label="To"
                      date={logSearch.beforeCreationDate}
                      setDate={(date, isFourDigitYear) =>
                        onChangeToDate(date!, isFourDigitYear || false)
                      }
                      width={260}
                      isClearable={false}
                      showTimeFieldInPopover
                    />
                  </>
                )}
                {Object.values(DateRangeOptions).map((option) => (
                  <Row ml={theme.space.xxs} key={`filterWrapper_${option}`}>
                    <SelectableCard
                      key={option}
                      label={option}
                      onClick={() => handleSelectDateRange(option)}
                      selected={dateRange === option}
                    />
                  </Row>
                ))}
              </Row>
            </Row>
            {isLoadingLogs ? (
              <CircularProgress size={'medium'} color={'primary'} />
            ) : (
              <AuditLogTable auditLogs={filteredAuditLogs} logType={logType} />
            )}
          </Col>
        </Row>
      </Row>
    </MainLayout>
  )
}
