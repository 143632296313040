import { useAlert } from '@traba/context'
import {
  Dialog,
  InlineBanner,
  Input,
  MODAL_SIZE,
  SearchSelect,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { IMenuItem, InputStatus, RecordStatus } from '@traba/types'
import { useMemo, useState } from 'react'
import { Row } from 'src/components/base'
import { useRoles } from 'src/hooks/useRoles'
import { useTags } from 'src/hooks/useTags'

const ELIGIBILITY_GROUP_INFO = [
  'An eligibility group is a collection of roles that can be assigned to multiple workers.',
  'When an eligibility group is assigned to a worker, it automatically creates eligibility connections for each role in the group.',
  'When a worker is assigned to more than one group, eligibility connections are created for the union of all groups.',
].join(' ')

type CompanyEligibilityGroupModalProps = {
  companyId: string
  handleClose: () => void
  isOpen: boolean
}

type RoleGroupForm = {
  groupName: string
  roleIds: IMenuItem[]
}

export const CompanyEligibilityGroupModal = (
  props: CompanyEligibilityGroupModalProps,
) => {
  const { isOpen, handleClose, companyId } = props

  const { showSuccess, showError } = useAlert()
  const { roles } = useRoles({ companyId, recordStatus: RecordStatus.ACTIVE })
  const { applyTags } = useTags({
    onApplySuccess: (tagNames) => {
      showSuccess(`Created eligibility group: ${tagNames.join(', ')}`)
    },
    onApplyError: (error) => {
      showError(error.message, 'Failed to create eligibility group', 10000)
    },
  })

  const roleOptions = useMemo(
    () =>
      roles?.map((role) => ({
        label: role.roleName,
        value: role.roleId,
      })) || [],
    [roles],
  )

  const initialForm = useMemo(
    () => ({ groupName: '', groupNameDescription: '', roleIds: roleOptions }),
    [roleOptions],
  )

  const [form, setForm] = useState<RoleGroupForm>(initialForm)
  const [groupNameStatus, setGroupNameStatus] = useState({
    status: InputStatus.default,
    message: '',
  })

  const onConfirm = () => {
    if (!form.groupName) {
      setGroupNameStatus({
        status: InputStatus.error,
        message: 'Group name is required',
      })
      return
    }

    setGroupNameStatus({
      status: InputStatus.default,
      message: '',
    })

    applyTags({
      tagNames: [form.groupName],
      associations: form.roleIds.map((role) => ({ roleId: role.value })),
    })

    setForm(initialForm)
    handleClose()
  }

  const onClose = () => {
    setForm(initialForm)
    setGroupNameStatus({
      status: InputStatus.default,
      message: '',
    })
    handleClose()
  }

  return (
    <Dialog
      maxWidth={false}
      fullWidth
      size={MODAL_SIZE.LARGE}
      onClose={onClose}
      onConfirm={onConfirm}
      open={isOpen}
      dialogTitle={'Create Eligibility Group'}
    >
      <Row
        flexCol
        fullHeight
        fullWidth
        gap={theme.space.med}
        className="modalWrapper"
      >
        <InlineBanner severity="info" text={ELIGIBILITY_GROUP_INFO} />
        <Row flexCol gap={theme.space.xxs}>
          <Text variant="h6" color={theme.colors.Black}>
            Group Name
          </Text>
          <Input
            id="groupName"
            value={form.groupName ?? ''}
            onChange={(e) =>
              setForm((prevForm) => ({
                ...prevForm,
                groupName: e.target.value,
              }))
            }
            autoComplete="off"
            containerStyle={{ marginTop: 0 }}
            inputStatus={groupNameStatus.status}
            errorMessage={groupNameStatus.message}
          />
        </Row>
        <Row flexCol gap={theme.space.xxs}>
          <Text variant="h6" color={theme.colors.Black}>
            Roles
          </Text>
          <SearchSelect
            options={roleOptions}
            selectedItems={form.roleIds}
            handleSelectMultiple={(roleIds: IMenuItem[]) =>
              setForm((prevForm) => ({ ...prevForm, roleIds }))
            }
            showClearButton
            multiple
          />
        </Row>
      </Row>
    </Dialog>
  )
}
