/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Anchor,
  Button,
  ButtonVariant,
  Col,
  LoadingSpinner,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { ApplicationEntityType } from '@traba/types'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Icon from 'src/components/base/Icon'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useApplicationsSearch } from 'src/hooks/useApplications'
import { useShift } from 'src/hooks/useShifts'
import { ShiftApplicantsTableContainer } from '../ShiftDetailsScreen/components/ShiftApplicantsTable/ShiftApplicantsTableContainer'
import { RoleApplicantsTableContainer } from './RoleApplicantsTableContainer'
import { extractEntityNames } from './utils/applicationUtils'

export function ApplicationDetailsScreen() {
  // TODO(ENG-13216): consume role applications table

  const { applicationId } = useParams()
  const navigate = useNavigate()
  const { searchResults, isLoading: isSearchLoading } = useApplicationsSearch({
    applicationId: applicationId,
  })
  const application = useMemo(() => searchResults?.items?.[0], [searchResults])
  const {
    employerName,
    roleName,
    nextShiftId,
    shiftRequestParentId,
    shiftRequestParentTitle,
    companyId,
  } = useMemo(
    () => extractEntityNames(application?.applicationEntities),
    [application],
  )

  const { shift, isLoading: isShiftLoading } = useShift(nextShiftId || '')
  const isShiftRequestApplication = application?.applicationEntities.some(
    (entity) =>
      entity.applicationEntityType === ApplicationEntityType.SHIFT_REQUEST,
  )
  const roleId = useMemo(
    () =>
      application?.applicationEntities.find(
        (entity) => entity.applicationEntityType === ApplicationEntityType.ROLE,
      )?.roleId,
    [application],
  )

  // TODO(ENG-13787): Update when duplicate is ready
  const showDuplicateButton = false

  if (isSearchLoading || isShiftLoading) {
    return <LoadingSpinner />
  }

  if (searchResults?.items.length === 0 || !applicationId) {
    return (
      <MainLayout title="Application Details">
        <Text variant="h2" mb={theme.space.lg}>
          Application not found
        </Text>
      </MainLayout>
    )
  }

  return (
    <MainLayout title="Application Details">
      <Row>
        <Button
          variant={ButtonVariant.TEXT}
          onClick={() => {
            navigate('/applications')
          }}
        >
          <Icon name="leftArrow" style={{ marginRight: theme.space.xs }} />
          <Text> Back to all applications</Text>
        </Button>
      </Row>
      <Row justifyBetween mb={theme.space.lg}>
        <Text variant="h2">Application Details</Text>

        {showDuplicateButton && (
          <Button variant={ButtonVariant.OUTLINED} disabled={true}>
            Duplicate Application
          </Button>
        )}
      </Row>
      <Row
        justifyBetween
        p={theme.space.sm}
        style={{
          borderBottom: `1px solid ${theme.colors.Grey30}`,
        }}
      >
        <Col>
          <Text variant="caption">COMPANY</Text>
          <Text variant="body1">{employerName}</Text>
        </Col>
        <Col>
          <Text variant="caption">ROLE</Text>
          <Text variant="body1">{roleName}</Text>
        </Col>
        <Col>
          {shiftRequestParentId ? (
            <>
              <Text variant="caption">Schedule</Text>
              <Anchor href={`/field-monitor/${shiftRequestParentId}`}>
                {shiftRequestParentTitle}
              </Anchor>
            </>
          ) : (
            nextShiftId && (
              <>
                <Text variant="caption">SHIFT NAME</Text>
                <Anchor href={`/field-monitor/${nextShiftId}`}>
                  {'View Next Upcoming Shift '}
                  <Icon name="link" style={{ marginRight: theme.space.xxxs }} />
                </Anchor>
              </>
            )
          )}
        </Col>
        <Col>
          <div />
        </Col>
      </Row>
      {isShiftRequestApplication ? (
        <ShiftApplicantsTableContainer shift={shift} />
      ) : application && roleId ? (
        <RoleApplicantsTableContainer roleId={roleId} companyId={companyId} />
      ) : (
        <Text variant="warning">No shift or role found for application</Text>
      )}
    </MainLayout>
  )
}
