import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import { ModalButtons, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  CommunicationSubject,
  PushDataSource,
  WorkerShiftForOps as WorkerShift,
} from '@traba/types'
import { useState } from 'react'
import {
  Button,
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Select,
} from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { MODAL_SIZE } from 'src/components/base/Modal/types'
import { useActiveQueries } from 'src/hooks/useActiveQueries'
import { useAnalytics } from '../../hooks/useAnalytics'

type DirectPushModalProps = {
  handleClose: () => void
  isOpen: boolean
  workerShifts: WorkerShift[]
  sentinelNotificationToUserId?: string
}

const SCREEN_OPTIONS = [
  {
    value: PushDataSource.ShiftDetails,
    label: 'Shift Details Page',
  },
  {
    value: PushDataSource.PersonalInformation,
    label: 'Personal Information Page',
  },
  {
    value: PushDataSource.Resume,
    label: 'Resume Upload Page',
  },
]

export default function DirectPushModal({
  isOpen,
  handleClose,
  workerShifts,
  sentinelNotificationToUserId,
}: DirectPushModalProps) {
  const [title, setTitle] = useState<string>('')
  const [screen, setScreen] = useState<string>(PushDataSource.ShiftDetails)
  const [body, setBody] = useState<string>('')

  const { refetchActiveQueries } = useActiveQueries()
  const { showError, showSuccess } = useAlert()
  const { trackAnalytics } = useAnalytics()

  const [loading, setLoading] = useState<boolean>(false)

  const onHandleClose = () => {
    setTitle('')
    setScreen('')
    setBody('')
    handleClose()
  }

  const handleSendDirectPush = async () => {
    setLoading(true)
    await Promise.allSettled(
      workerShifts.map((workerShift) => {
        if (sentinelNotificationToUserId) {
          trackAnalytics('Sentinel Action Taken Direct Push', {
            sentinelNotificationToUserId,
            workerId: workerShift.workerId,
            shiftId: workerShift.shiftId,
          })
        }
        return trabaApi.post(`/communication/send-push-notification`, {
          notification: {
            title: title,
            body: body,
            data: {
              shiftId: workerShift.shiftId,
              source: screen,
            },
          },
          workerId: workerShift.workerId,
          subject: CommunicationSubject.SENTINEL_DIRECT_PUSH_NOTIFICATION,
          relatedShiftId: workerShift.shiftId,
          sentinelNotificationToUserId,
        })
      }),
    ).then((results) => {
      const failed = results.filter((result) => result.status === 'rejected')
      if (failed.length > 0) {
        showError(`Failed to send ${failed.length} push notifications.`)
      } else {
        showSuccess(
          `Successfully sent ${workerShifts.length} push notifications.`,
        )
      }
    })
    refetchActiveQueries()
    setLoading(false)
    onHandleClose()
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newTitle = e.target.value
    setTitle(newTitle)
  }

  const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value
    setBody(newMessage)
  }

  const handleScreenChange = (newScreen: string) => {
    setScreen(newScreen)
  }

  return (
    <Modal
      handleClose={onHandleClose}
      isOpen={isOpen}
      size={MODAL_SIZE.EXTRA_SMALL}
    >
      <Col
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Row flexCol fullWidth fullHeight>
          <Row
            justifyBetween
            alignCenter
            style={{ marginBottom: theme.space.xs }}
          >
            <Text variant="h4">Send Direct Push to Worker</Text>
            <Button variant={ButtonVariant.TRANSPARENT} onClick={onHandleClose}>
              <Icon name="cancel" />
            </Button>
          </Row>
          <Row>
            <Col style={{ marginRight: theme.space.xs }}>
              <Input
                style={{ height: theme.space.lg }}
                width="100%"
                label="Title"
                onChange={handleTitleChange}
                value={title}
              />
            </Col>
            <Col>
              <Select
                dropdownStyle={{
                  height: theme.space.lg,
                  overflow: 'hidden',
                }}
                label="Screen"
                menuItems={SCREEN_OPTIONS}
                value={screen}
                handleSelect={handleScreenChange}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: theme.space.xs }}>
            <Input
              label="Body"
              type="textarea"
              onChange={handleBodyChange}
              value={body}
              style={{ width: '100%', minHeight: '150px' }}
            />
          </Row>
        </Row>
        <ModalButtons
          handleClose={onHandleClose}
          handleConfirm={handleSendDirectPush}
          loading={loading}
          confirmText="Send"
          isDisabled={!title || !body || !workerShifts.length}
        />
      </Col>
    </Modal>
  )
}
