import { Dialog } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AccountStatus, Worker } from '@traba/types'
import { GrantIncentivesTab } from '../../IncentivesMonitorScreen/GrantIncentivesTab'

interface GrantIncentiveModalProps {
  worker: Worker & AccountStatus
  isOpen: boolean
  handleClose: () => void
}

export const GrantIncentiveModal = ({
  worker,
  isOpen,
  handleClose,
}: GrantIncentiveModalProps) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      dialogTitle="Grant Incentive"
      hideFooter
      sx={{
        margin: '5vh 5vw',
        '& .MuiDialog-paper': {
          borderRadius: theme.space.us,
        },
      }}
    >
      <GrantIncentivesTab worker={worker} />
    </Dialog>
  )
}
