import { useApi } from '@traba/hooks'
import {
  Button,
  Col,
  LoadingSpinner,
  Row,
  Tab,
  Tabs,
  TabsContainer,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  AccountStatus,
  TierLevel,
  WorkerMetric,
  Worker,
  WorkerScores,
  WorkerProfile,
} from '@traba/types'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AccountStatusBadge, Avatar, Icon } from 'src/components/base'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { useMobile } from 'src/hooks/useMobile'
import { useWorkerW2Termination } from 'src/hooks/useW2WorkerTermination'
import { useWorkerScores } from 'src/hooks/useWorkerMetrics'
import { useWorkerProfile } from 'src/hooks/useWorkerProfile'
import {
  getLevelNumberFromStarAttributeLevel,
  getStarRatingFromWorkerProfileAttributes,
} from 'src/utils/attributeUtils'
import { toPercentString } from 'src/utils/stringUtils'
import { InfoTable } from './components/InfoTable'
import { MultiArtifactUploadModal } from './components/MultiArtifactUploadModal'
import { TerminationModal } from './components/TerminationModal'
import { TerminationSummaryModal } from './components/TerminationSummaryModal'
import {
  WorkerPerformanceDataTable,
  WorkerPerformanceTab,
} from './components/WorkerPerformanceDataTable'
import * as S from './WorkerDetailsScreen.styles'

interface WorkerMetricsResult {
  otaRate: string
  lmcrRate: string
  noShowRate: string
  opsRating: number
  businessApprovalScore: string | number
  shiftsCompleted: number
  shiftsAbandoned: number
  reliabilityScore: string
  blocks: number
  favorites: number
}

function calculateWorkerMetrics(
  metrics: WorkerMetric | undefined,
  workerScores: WorkerScores | undefined,
  workerProfileAttributes: WorkerProfile[] | undefined,
): WorkerMetricsResult {
  return {
    otaRate: `${toPercentString(metrics?.onTimeRate || 0)}%`,
    lmcrRate: `${toPercentString(metrics?.lateCancellationRate || 0)}%`,
    noShowRate: `${toPercentString(metrics?.noShowRate || 0)}%`,
    opsRating: getLevelNumberFromStarAttributeLevel(
      getStarRatingFromWorkerProfileAttributes(workerProfileAttributes),
    ),
    businessApprovalScore: workerScores?.businessApprovalScore ?? '-',
    shiftsCompleted: metrics?.completedShifts || 0,
    shiftsAbandoned: metrics?.abandonedShifts || 0,
    reliabilityScore: `${toPercentString(metrics?.lateCancellationRate || 0)}%`,
    blocks: workerScores?.numberOfBlocks || 0,
    favorites: workerScores?.numberOfFavorites || 0,
  }
}

function createDefaultEmailBody(name: string) {
  const dateString = new Date().toLocaleDateString('en-us', {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  return `Dear ${name},

We are reaching out to inform you that, as of today, ${dateString}, your employment with Labor That Works has been discontinued. This decision has been reached after careful consideration of your performance over the course of your employment.

If you believe this message was sent in error or have questions about your status, please feel free to contact us at workers@laborthatworks.com.

Thank you for working with Labor That Works, LLC.

Sincerely,

Labor That Works, LLC`
}

export function WorkerPerformanceScreen() {
  const { workerId } = useParams()
  const navigate = useNavigate()
  const { data: metrics, isLoading: isLoadingWorkerMetrics } =
    useApi<WorkerMetric>(`/workers/${workerId}/all-time-metrics`)
  const { data: workerTier, isLoading: isLoadingWorkerTier } = useApi<{
    tier: TierLevel
  }>(`/workers/${workerId}/tier`)
  const { data: worker, isLoading: isLoadingWorker } = useApi<
    Worker & AccountStatus
  >(`/workers/${workerId}/worker-and-profile`)
  const { workerScores } = useWorkerScores(workerId)
  const { workerProfileAttributes } = useWorkerProfile(workerId)
  const [currentTab, setCurrentTab] = useState(WorkerPerformanceTab.ARTIFACTS)
  const [showArtifactsModal, setShowArtifactsModal] = useState(false)
  const [showTerminationModal, setShowTerminationModal] = useState(false)
  const [showTerminationSummaryModal, setShowTerminationSummaryModal] =
    useState(false)
  const { workerW2Termination } = useWorkerW2Termination(workerId)
  const { isMobile } = useMobile()
  const terminationClaim = workerW2Termination?.claim
  const isTerminated = !!terminationClaim
  const {
    otaRate,
    lmcrRate,
    noShowRate,
    opsRating,
    businessApprovalScore,
    shiftsCompleted,
    shiftsAbandoned,
    reliabilityScore,
    blocks,
    favorites,
  } = calculateWorkerMetrics(metrics, workerScores, workerProfileAttributes)

  const TABS = [
    {
      key: 'worker-artifacts',
      label: 'Worker artifacts',
      onClick: () => setCurrentTab(WorkerPerformanceTab.ARTIFACTS),
    },
    {
      key: 'worker-strikes',
      label: 'Worker strikes',
      onClick: () => setCurrentTab(WorkerPerformanceTab.STRIKES),
    },
  ]

  function getTabLabel(index: number, tabLabel: string) {
    const labelComponent = (
      <Text
        variant="h5"
        color={
          currentTab === index ? theme.colors.MidnightBlue : theme.colors.Grey60
        }
      >
        {tabLabel}
      </Text>
    )

    switch (index) {
      case WorkerPerformanceTab.ARTIFACTS:
        return labelComponent
      case WorkerPerformanceTab.STRIKES:
        return labelComponent
      default:
        return labelComponent
    }
  }

  const defaultEmailBody = createDefaultEmailBody(
    `${worker?.firstName} ${worker?.lastName}`,
  )

  const headerPart = worker ? (
    <>
      <Row alignCenter>
        <Avatar src={worker.photoUrl} name={worker.firstName} />
        <Col ml={theme.space.sm} mt={theme.space.sm}>
          <Row mb={theme.space.xxs} alignCenter>
            <Text variant="h4" mr={theme.space.xxs}>
              {worker.firstName} {worker.lastName}
            </Text>
            <AccountStatusBadge
              accountStatus={worker.accountStatus}
              approvalStatusReason={worker.approvalStatusReason}
              accountSuspensionExpiration={worker.suspensionExpiration}
            />
          </Row>
        </Col>
      </Row>
      <Row gap={theme.space.sm}>
        <Button onClick={() => setShowArtifactsModal(true)}>
          Upload Artifacts
        </Button>
        <Button
          onClick={() =>
            isTerminated
              ? setShowTerminationSummaryModal(true)
              : setShowTerminationModal(true)
          }
        >
          {isTerminated ? 'View Termination' : 'Terminate Worker'}
        </Button>
      </Row>
    </>
  ) : null

  return (
    <MainLayout title={`${workerId} - Worker Performance`}>
      <S.Nav>
        <Row alignCenter>
          <Text variant="body2" onClick={() => navigate('/workers/')}>
            <Icon name="leftArrow" />
            Worker Search/
          </Text>
          <Text
            variant="body2"
            onClick={() => navigate(`/workers/${workerId}`)}
          >
            Worker Profile/
          </Text>
          <Text variant="h6">Worker Performance</Text>
        </Row>
      </S.Nav>
      {isLoadingWorkerMetrics ||
      isLoadingWorkerTier ||
      isLoadingWorker ||
      worker === undefined ||
      workerId === undefined ? (
        <LoadingSpinner />
      ) : (
        <>
          {isMobile ? (
            <Col>{headerPart}</Col>
          ) : (
            <Row alignCenter justifyBetween>
              {headerPart}
            </Row>
          )}

          <InfoTable
            title="Worker Performance"
            data={[
              { label: 'OPS Rating', value: opsRating.toString() || '-' },
              {
                label: 'Reliability',
                value: reliabilityScore,
              },
              { label: 'LMCR', value: lmcrRate },
              { label: 'Favorites', value: favorites.toString() },
              { label: 'Worker Tier', value: workerTier?.tier || '-' },
              {
                label: 'Shifts Completed',
                value: shiftsCompleted.toString(),
              },
              { label: 'OTA', value: otaRate },
              { label: 'Blocks', value: blocks.toString() },
              {
                label: 'Biz Approval',
                value: businessApprovalScore.toString(),
              },
              {
                label: 'Abandoned Shifts',
                value: shiftsAbandoned.toString(),
              },
              { label: 'No-Show', value: noShowRate },
              { label: 'Strikes', value: '0' },
            ]}
          />

          <TabsContainer>
            <Tabs
              variant="scrollable"
              value={currentTab}
              onChange={(_, v) => setCurrentTab(v)}
            >
              {TABS.map((tab, index) => (
                <Tab
                  key={tab.key}
                  onClick={tab.onClick}
                  label={getTabLabel(index, tab.label)}
                />
              ))}
            </Tabs>
          </TabsContainer>
          <WorkerPerformanceDataTable
            currentTab={currentTab}
            workerId={workerId}
          />
        </>
      )}

      <MultiArtifactUploadModal
        handleClose={() => setShowArtifactsModal(false)}
        isOpen={showArtifactsModal}
        workerId={workerId}
        workerName={worker?.firstName + ' ' + worker?.lastName}
      />
      {worker && (
        <TerminationModal
          handleClose={() => setShowTerminationModal(false)}
          isOpen={showTerminationModal}
          worker={worker}
          workerId={workerId}
          defaultEmailBody={defaultEmailBody}
        />
      )}
      <TerminationSummaryModal
        handleClose={() => setShowTerminationSummaryModal(false)}
        isOpen={showTerminationSummaryModal}
        terminationClaim={terminationClaim}
      />
    </MainLayout>
  )
}
