import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { Col, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ConnectionType } from '@traba/types'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { Button, Icon, IconButton, Link, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import Divider from 'src/components/base/Divider'
import { DotText } from 'src/components/base/DotText'
import { Dropdown } from 'src/components/base/Dropdown'
import { useWorkerConnections } from 'src/hooks/useConnections'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useVettingCampaigns } from 'src/hooks/useVettingCampaigns'
import {
  useWorkerVettingCallFeedbackMutation,
  useWorkerVettingPhoneCalls,
  useWorkerVettingSession,
  WorkerVettingPhoneCallFeedback,
  WorkerVettingPhoneCallResponse,
  WorkerVettingSessionWithPhoneCalls,
} from 'src/hooks/useWorkerVetting'
import { EditRolesOnConnectionContent } from 'src/modals/ManageWorkerModal/components/EditRolesOnConnectionContent'
import { AvatarWrapper } from 'src/screens/ShiftDetailsScreen/components/SearchWorkers/styles'
import { WorkerConnectionModal } from 'src/screens/WorkerDetailsScreen/components/WorkerConnectionModal'
import { formatPhoneNumber } from 'src/utils/stringUtils'
import { FeedbackButton } from '../styles'
import { vettingCallsHasSummaries } from '../utils'
import { WorkerVettingMessageItem } from './WorkerVettingMessageItem'
import { WorkerVettingPhoneCallFeedbackModal } from './WorkerVettingPhoneCallFeedbackModal'
import { WorkerVettingPhoneCallItem } from './WorkerVettingPhoneCallItem'

interface WorkerVettingSessionMessageViewerProps {
  vettingSession: WorkerVettingSessionWithPhoneCalls
  isDrawer?: boolean
  setSelectedSession?: Dispatch<
    SetStateAction<WorkerVettingSessionWithPhoneCalls | undefined>
  >
  containerStyle?: React.CSSProperties
}

export default function WorkerVettingSessionMessageViewer(
  props: WorkerVettingSessionMessageViewerProps,
) {
  const { vettingSession, setSelectedSession, isDrawer, containerStyle } = props
  const { session, isLoading, patchSession } = useWorkerVettingSession(
    vettingSession.id,
  )
  // TODO(aiden, ENG-12900): update this to use the phone calls from the session
  const { data: phoneCalls } = useWorkerVettingPhoneCalls({
    params: {
      phoneNumber: vettingSession.phoneNumber,
      workerVettingCampaignId: vettingSession.workerVettingCampaignId,
    },
    enabled: !!vettingSession.phoneNumber,
  })

  const { vettingCampaigns } = useVettingCampaigns({
    workerVettingCampaignId: vettingSession.workerVettingCampaignId,
  })

  const { updateWorkerVettingCall, isPending: mutationPending } =
    useWorkerVettingCallFeedbackMutation()

  const [showWorkerConnectionModal, setShowWorkerConnectionModal] =
    useState<boolean>(false)
  const [showAddRolesModal, setShowAddRolesModal] = useState<boolean>(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false)
  const [currentPhoneCall, setCurrentPhoneCall] = useState<
    WorkerVettingPhoneCallResponse | undefined
  >(undefined)

  const companyId = useMemo(() => {
    return vettingCampaigns?.[0]?.companies?.[0]?.companyId
  }, [vettingCampaigns])

  const { connections } = useWorkerConnections(
    vettingSession.workerId ?? undefined,
    companyId,
  )
  const existingVettedConnection = useMemo(() => {
    return connections?.find((c) => c.connectionType === ConnectionType.VETTED)
  }, [connections])

  const closeFeedbackModal = useCallback(() => {
    setShowFeedbackModal(false)
    setCurrentPhoneCall(undefined)
  }, [setShowFeedbackModal])

  const submitNegativeFeedback = useCallback(
    async (
      feedback: WorkerVettingPhoneCallFeedback[],
      callId?: string,
      otherFeedback?: string,
    ) => {
      if (!callId) {
        return
      }
      await updateWorkerVettingCall({
        callId,
        feedback,
        otherFeedback,
      })
      closeFeedbackModal()
    },
    [closeFeedbackModal, updateWorkerVettingCall],
  )

  const tz = useTimezonedDates()

  if (isLoading) {
    return (
      <Row center alignCenter>
        <CircularProgress
          size={32}
          sx={{
            position: 'absolute',
            top: '200px',
            left: '50%',
            marginTop: '-18px',
            marginLeft: '-18px',
          }}
        />
      </Row>
    )
  }

  if (!session) {
    return (
      <Row>
        <Text variant="body1">No session found</Text>
      </Row>
    )
  }

  const messages = session.messages

  //Want to have an icon for qualified, disqualified, and other
  const ReasonIcon = () => {
    if (vettingSession.score === 200) {
      return (
        <Row>
          <Icon name="greenCheck" />
        </Row>
      )
    }
    if (vettingSession.score === 500) {
      return <span role="img">❌</span>
    }
    return (
      <Row>
        <Icon name="caution" />
      </Row>
    )
  }

  return (
    <div
      style={{
        alignItems: 'center',
        borderLeft: isDrawer ? 'none' : `1px solid ${theme.colors.Grey30}`,
        borderBottom: `1px solid ${theme.colors.Grey30}`,
        width: isDrawer ? '100%' : '60%',
        marginLeft: theme.space.xs,
        paddingLeft: theme.space.xs,
        overflow: 'auto',
        ...containerStyle,
      }}
    >
      <Row justifyBetween>
        <Row>
          {setSelectedSession && (
            <IconButton
              iconName="chevronLeft"
              style={{ padding: theme.space.xs }}
              onClick={() => setSelectedSession(undefined)}
            />
          )}
        </Row>
        <Dropdown
          dropdownOptions={[
            {
              label: 'Good Match',
              handleClick: () => patchSession(vettingSession.id, 'qualify'),
              icon: () => <Icon name="checkmark" />,
            },
            {
              label: 'Bad Match',
              handleClick: () => patchSession(vettingSession.id, 'disqualify'),
              icon: () => <Icon name="cancel" />,
            },
            {
              label: 'Cancel',
              handleClick: () => patchSession(vettingSession.id, 'cancel'),
              icon: () => <Icon name="close" />,
            },
          ]}
          orientation="left"
        />
      </Row>

      <Row flexCol mt={theme.space.xs} mb={theme.space.xs}>
        {session.worker ? (
          <Row justifyBetween>
            <Row alignCenter>
              <AvatarWrapper style={{ marginRight: theme.space.xxs }}>
                <Avatar
                  alt={`worker_${session.worker.id}_avatar`}
                  src={session.worker.photoUrl}
                  sizes="40px"
                />
              </AvatarWrapper>
              <Link to={`/workers/${session.worker.id}`}>
                <Button variant={ButtonVariant.TEXT} style={{ padding: 0 }}>
                  {session.worker.firstName} {session.worker.lastName}
                </Button>
              </Link>
            </Row>
            <Text variant="caption">
              Last active at: {tz.getDateTime(vettingSession.lastActiveAt)}
            </Text>
          </Row>
        ) : (
          <Row alignCenter>
            <Text variant="h6">External Worker</Text>
            <DotText />
            <Text variant="h6">
              {formatPhoneNumber(vettingSession.phoneNumber, true)}
            </Text>
          </Row>
        )}
        <Row mt={theme.space.xxs} alignCenter>
          {session.opsScore !== null && (
            <Text variant="h6" style={{ marginRight: theme.space.xxs }}>
              [OPS SCORED]
            </Text>
          )}
          <ReasonIcon />
          <Text
            variant="body1"
            style={{ marginLeft: theme.space.xxs, whiteSpace: 'pre-line' }}
          >
            {vettingCallsHasSummaries(phoneCalls) ? (
              <Col>
                {phoneCalls
                  .filter((phoneCall) => phoneCall.summary)
                  .sort(
                    (a, b) =>
                      new Date(a.createdAt).getTime() -
                      new Date(b.createdAt).getTime(),
                  )
                  .map((phoneCall, index) => (
                    <Row
                      key={phoneCall.id}
                      fullWidth
                      justifyBetween
                      alignCenter
                    >
                      <Text>
                        <strong>Call {index + 1} Summary: </strong>
                        {phoneCall.summary}
                      </Text>
                      {
                        <Row>
                          <FeedbackButton
                            onClick={() => {
                              updateWorkerVettingCall({
                                callId: phoneCall.id,
                                feedback: [
                                  WorkerVettingPhoneCallFeedback.CORRECT,
                                ],
                              })
                              if (existingVettedConnection) {
                                setShowAddRolesModal(true)
                              } else {
                                setShowWorkerConnectionModal(true)
                              }
                            }}
                            disabled={mutationPending}
                            variant={ButtonVariant.TEXT}
                          >
                            <Icon
                              name="thumbsUp"
                              type="svg"
                              fill={
                                phoneCall.feedback?.includes(
                                  WorkerVettingPhoneCallFeedback.CORRECT,
                                )
                                  ? theme.colors.Green70
                                  : 'none'
                              }
                            />
                          </FeedbackButton>
                          <FeedbackButton
                            onClick={() => {
                              setCurrentPhoneCall(phoneCall)
                              setShowFeedbackModal(true)
                            }}
                            variant={ButtonVariant.TEXT}
                            disabled={mutationPending}
                          >
                            <Icon
                              name="thumbsDown"
                              type="svg"
                              fill={
                                !!phoneCall.feedback?.length &&
                                !phoneCall.feedback.includes(
                                  WorkerVettingPhoneCallFeedback.CORRECT,
                                )
                                  ? theme.colors.Red70
                                  : 'none'
                              }
                            />
                          </FeedbackButton>
                        </Row>
                      }
                    </Row>
                  ))}
              </Col>
            ) : vettingSession.messages.length > 0 ? (
              <Text>
                <strong>SMS Summary: </strong>
                {vettingSession.messages[0].reviewReason}
              </Text>
            ) : (
              'None'
            )}
          </Text>
        </Row>
        <Text variant="caption">
          Last evaluated: {tz.getDateTime(vettingSession.updatedAt)}
        </Text>
      </Row>
      <Divider />
      <div
        style={{
          overflow: 'auto',
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Row center alignCenter mt={theme.space.xxs}>
          <Text variant="body2">- Beginning of conversation -</Text>
        </Row>
        {messages.map((message) => (
          <WorkerVettingMessageItem
            key={message.id}
            workerVettingMessage={message}
            pictureUrl={session.worker?.photoUrl}
          />
        ))}
        {phoneCalls?.map((phoneCall) => {
          if (!phoneCall.transcript) {
            return undefined
          }
          return (
            <WorkerVettingPhoneCallItem
              phoneCall={phoneCall}
              key={phoneCall.id}
            />
          )
        })}
      </div>
      {currentPhoneCall && (
        <WorkerVettingPhoneCallFeedbackModal
          isOpen={showFeedbackModal}
          handleClose={closeFeedbackModal}
          phoneCallId={currentPhoneCall.id}
          handleSubmit={submitNegativeFeedback}
          initialFeedback={currentPhoneCall.feedback?.filter(
            (fb) => fb !== WorkerVettingPhoneCallFeedback.CORRECT,
          )}
          initialOtherFeedback={currentPhoneCall.otherFeedback}
          isLoading={mutationPending}
        />
      )}
      {vettingCampaigns && vettingSession.workerId && (
        <WorkerConnectionModal
          workerId={vettingSession.workerId}
          handleClose={() => {
            setShowWorkerConnectionModal(false)
          }}
          isOpen={showWorkerConnectionModal}
          defaultCompany={companyId}
          defaultConnectionType={ConnectionType.VETTED}
        />
      )}
      {existingVettedConnection && (
        <EditRolesOnConnectionContent
          isOpen={showAddRolesModal}
          onClose={() => {
            setShowAddRolesModal(false)
          }}
          connection={existingVettedConnection}
        />
      )}
    </div>
  )
}
