export enum StrikeReason {
  TARDY = 'TARDY',
  SHIFT_CANCELLATION = 'SHIFT_CANCELLATION',
  SHORT_NOTICE_SHIFT_CANCELLATION = 'SHORT_NOTICE_SHIFT_CANCELLATION',
  EARLY_LEAVE = 'EARLY_LEAVE',
  VERY_SHORT_NOTICE_SHIFT_CANCELLATION = 'VERY_SHORT_NOTICE_SHIFT_CANCELLATION',
  LAST_MINUTE_NOTICE_SHIFT_CANCELLATION = 'LAST_MINUTE_NOTICE_SHIFT_CANCELLATION',
  SHORT_NOTICE_ASSIGNMENT_CANCELLATION = 'SHORT_NOTICE_ASSIGNMENT_CANCELLATION',
  NO_CALL_NO_SHOW = 'NO_CALL_NO_SHOW',
  REJECTION = 'REJECTION',
}

export function getStrikeReasonLabel(reason: StrikeReason) {
  switch (reason) {
    case StrikeReason.TARDY:
      return 'Tardy'
    case StrikeReason.SHIFT_CANCELLATION:
      return 'Shift Cancellation'
    case StrikeReason.SHORT_NOTICE_SHIFT_CANCELLATION:
      return 'Short Notice SC'
    case StrikeReason.EARLY_LEAVE:
      return 'Early Leave'
    case StrikeReason.VERY_SHORT_NOTICE_SHIFT_CANCELLATION:
      return 'Very Short Shift Cancellation'
    case StrikeReason.LAST_MINUTE_NOTICE_SHIFT_CANCELLATION:
      return 'Last Minute SC'
    case StrikeReason.SHORT_NOTICE_ASSIGNMENT_CANCELLATION:
      return 'Short Notice AC'
    case StrikeReason.NO_CALL_NO_SHOW:
      return 'No Call No Show'
    case StrikeReason.REJECTION:
      return 'Rejection'
    default:
      return 'Unknown'
  }
}

export interface Strike {
  id: string
  createdAt: Date
  updatedAt: Date
  workerId: string
  shiftId: string | undefined
  reason: StrikeReason
  strikePoints: number
  notificationSent: boolean
  invalidatedBy: string | undefined
  invalidationReason: string | undefined
  proven: boolean
}

export type WorkerWithStrikes = {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  strikes: Strike[]
  currentStrikePoints: number
  operatorConversationId?: string
}

export interface OperatorConfig {
  operatorMissesConfig?: {
    operatorId: string
  }
}
