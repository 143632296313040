import {
  EmploymentType,
  Language,
  RequiredAttributeLevel,
  VettingConfig,
  VettingPromptContext,
  WorkerVettingCampaign,
} from '@traba/types'

import { isProduction } from './envUtils'
import { isEmploymentType } from './shiftUtils'

export function getLanguage(language = 'en'): Language {
  return language === Language.ES ? Language.ES : Language.EN
}

export const sortQuestionKeys = (
  selectedQuestions: string[],
  customQuestions: string[],
  generalQuestions: { key: string; displayName: string }[] | undefined,
) => {
  const nonCustomQuestions = selectedQuestions.filter(
    (q) => !customQuestions.includes(q),
  )
  const generalQuestionKeys = nonCustomQuestions.filter((q) =>
    generalQuestions?.some((gq) => gq.key === q),
  )
  const otherQuestions = nonCustomQuestions.filter(
    (q) => !generalQuestions?.some((gq) => gq.key === q),
  )

  const sortedGeneralQuestions = generalQuestionKeys.sort((a, b) => {
    const aIndex = generalQuestions?.findIndex((q) => q.key === a) ?? 0
    const bIndex = generalQuestions?.findIndex((q) => q.key === b) ?? 0
    return aIndex - bIndex
  })

  const sortedOtherQuestions = otherQuestions.sort()
  return [...sortedGeneralQuestions, ...sortedOtherQuestions]
}

export const AI_CONSENT = {
  PROMPT_DETAILS:
    'The only consideration to qualify the candidate is if they consent to AI calling. It is extremely important that the candidate consents to AI in order to qualify. No other follow-ups are required if the candidate consents to AI calling.',
  INITIAL_MESSAGE: {
    [Language.EN]:
      ' Can I give you a quick call to talk about the job? By replying "Yes", you consent to being contacted by our team for this job, including through automated communication tools.',
    [Language.ES]:
      ' ¿Puedo llamarte rápidamente para hablar sobre el trabajo? Al responder "Sí", aceptas que nuestro equipo se comunique contigo para este trabajo, incluso a través de herramientas de comunicación automatizadas.',
  },
}

export const DEFAULT_EXIT_MESSAGES = {
  QUALIFIED: {
    [Language.EN]: (phoneNumber: string) =>
      `Great, we'll give you a call! If we miss you or get disconnected, you can call us (toll-free) at ${phoneNumber} to further discuss the opportunity.`,
    [Language.ES]: (phoneNumber: string) =>
      `¡Genial, te llamaremos! Si no te atendemos o se corta la comunicación, puedes llamarnos (sin cargo) al ${phoneNumber} para analizar más a fondo la oportunidad.`,
  },
  UNQUALIFIED: {
    [Language.EN]:
      'Thanks for the response! Our team will review your responses and reach out if there is a good fit for this or any future opportunities.',
    [Language.ES]:
      '¡Gracias por tu respuesta! Nuestro equipo revisará tus respuestas y se pondrá en contacto contigo si crees que son adecuadas para esta o cualquier otra oportunidad futura.',
  },
}

const AI_VETTING_PHONE_NUMBERS = {
  PROD: {
    [EmploymentType.W2]: '1-844-435-5832',
    [EmploymentType.CONTRACTOR_1099]: '1-877-813-9157',
  },
  DEV: {
    [EmploymentType.W2]: '1-845-456-6848',
    [EmploymentType.CONTRACTOR_1099]: '1-864-528-0998',
  },
}

export function getPhoneNumber(employmentType: EmploymentType) {
  const env = isProduction() ? 'PROD' : 'DEV'
  return AI_VETTING_PHONE_NUMBERS[env][employmentType]
}

export const INITIAL_VETTING_CONTEXT_FORM_VALUES: VettingConfigInitialValues = {
  questions: [],
  promptDetails: '',
  customQualifiedExitMessage: DEFAULT_EXIT_MESSAGES.QUALIFIED[Language.EN](
    getPhoneNumber(EmploymentType.CONTRACTOR_1099),
  ),
  customUnqualifiedExitMessage: DEFAULT_EXIT_MESSAGES.UNQUALIFIED[Language.EN],
  initialMessage: '',
}

export function getMostRecentWorkerVettingCampaign(
  workerVettingCampaigns: WorkerVettingCampaign[],
): WorkerVettingCampaign | undefined {
  if (!workerVettingCampaigns.length) {
    return undefined
  }
  return workerVettingCampaigns.reduce((latest, current) => {
    if (!latest) {
      return current
    }
    return new Date(current.createdAt) > new Date(latest.createdAt)
      ? current
      : latest
  }, workerVettingCampaigns[0])
}

function isValidVettingContext(
  context: unknown,
): context is VettingPromptContext {
  if (typeof context !== 'object' || context === null) {
    return false
  }

  const {
    locationDetails,
    scheduleDetails,
    payRatePlainText,
    jobTitle,
    roleDetails,
    otherQuestions,
    language,
    employmentType,
  } = context as Record<string, unknown>

  return (
    typeof locationDetails === 'string' &&
    locationDetails.length > 0 &&
    typeof scheduleDetails === 'string' &&
    scheduleDetails.length > 0 &&
    typeof payRatePlainText === 'string' &&
    payRatePlainText.length > 0 &&
    typeof jobTitle === 'string' &&
    jobTitle.length > 0 &&
    (roleDetails === undefined || typeof roleDetails === 'string') &&
    (otherQuestions === undefined ||
      (Array.isArray(otherQuestions) &&
        otherQuestions.every((q) => typeof q === 'string'))) &&
    (language === undefined || typeof language === 'string') &&
    (employmentType === undefined || isEmploymentType(employmentType))
  )
}

export type VettingConfigInitialValues = Omit<
  VettingConfig,
  'vettingLogistics'
> & {
  vettingLogistics?: Partial<VettingPromptContext>
  requiredAttributeLevels?: RequiredAttributeLevel[]
}

export function buildVettingConfigInitialValues({
  workerVettingCampaign,
  promptContext,
}: {
  workerVettingCampaign?: WorkerVettingCampaign
  promptContext?: VettingPromptContext
}): VettingConfigInitialValues {
  return {
    vettingLogistics: isValidVettingContext(promptContext)
      ? promptContext
      : workerVettingCampaign?.vettingContext,
    questions: workerVettingCampaign?.questions ?? [],
    promptDetails: workerVettingCampaign?.promptDetails ?? '',
    customQualifiedExitMessage:
      workerVettingCampaign?.customQualifiedExitMessage ?? '',
    customUnqualifiedExitMessage:
      workerVettingCampaign?.customUnqualifiedExitMessage ?? '',
    initialMessage: workerVettingCampaign?.initialMessage ?? '',
    requiredAttributeLevels:
      workerVettingCampaign?.activePromptContext?.requiredAttributeLevels ?? [],
  }
}
