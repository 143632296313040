import { Col, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Dispatch, SetStateAction } from 'react'
import { EditScheduleOption } from './EditScheduleOption'

interface Props {
  selectedOption?: EditOptions
  setSelectedOption: Dispatch<SetStateAction<EditOptions | undefined>>
}

export enum EditOptions {
  MODIFY_END_DATE = 'Extend Schedule or Modify End Date',
  MODIFY_SHIFT_REQUEST = 'Modify an Existing Shift Request',
  ADD_SHIFT_REQUEST = 'Add a New Shift Request',
  CANCEL_SHIFT_REQUESTS = 'Cancel Shift Requests',
  CANCEL_SCHEDULE = 'Cancel Schedule',
  RENAME_SCHEDULE = 'Edit Schedule Name',
}

export const EditScheduleEntry = (props: Props) => {
  const { selectedOption, setSelectedOption } = props
  const onClickOption = (option: EditOptions) => {
    setSelectedOption(option)
  }
  return (
    <>
      <Text variant="h5" mb={theme.space.xs}>
        What actions you want to take?
      </Text>
      <Col gap={theme.space.sm}>
        <EditScheduleOption
          onClick={() => onClickOption(EditOptions.MODIFY_END_DATE)}
          selected={selectedOption === EditOptions.MODIFY_END_DATE}
          title={EditOptions.MODIFY_END_DATE}
        />
        <EditScheduleOption
          onClick={() => onClickOption(EditOptions.MODIFY_SHIFT_REQUEST)}
          selected={selectedOption === EditOptions.MODIFY_SHIFT_REQUEST}
          title={EditOptions.MODIFY_SHIFT_REQUEST}
        />
        <EditScheduleOption
          onClick={() => onClickOption(EditOptions.ADD_SHIFT_REQUEST)}
          selected={selectedOption === EditOptions.ADD_SHIFT_REQUEST}
          title={EditOptions.ADD_SHIFT_REQUEST}
        />
        <EditScheduleOption
          onClick={() => onClickOption(EditOptions.CANCEL_SHIFT_REQUESTS)}
          selected={selectedOption === EditOptions.CANCEL_SHIFT_REQUESTS}
          title={EditOptions.CANCEL_SHIFT_REQUESTS}
        />
        <EditScheduleOption
          onClick={() => onClickOption(EditOptions.CANCEL_SCHEDULE)}
          selected={selectedOption === EditOptions.CANCEL_SCHEDULE}
          title={EditOptions.CANCEL_SCHEDULE}
        />
        <EditScheduleOption
          onClick={() => onClickOption(EditOptions.RENAME_SCHEDULE)}
          selected={selectedOption === EditOptions.RENAME_SCHEDULE}
          title={EditOptions.RENAME_SCHEDULE}
        />
      </Col>
    </>
  )
}
