import { useDragAndDrop } from '@formkit/drag-and-drop/react'
import { Tooltip } from '@mui/material'
import { useAlert } from '@traba/context'
import {
  Button,
  ButtonVariant,
  Col,
  Draggable,
  LoadingSpinner,
  Row,
  SelectDropdown,
  SvgIcon,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ApplicationItemContent as ApplicationItemContentType,
  ApplicationItemCustomQuestionRequest,
  ApplicationItemType,
  DEFAULT_LANGUAGE_CONTENT,
  LanguageContentMap,
  LanguageContentObj,
  QUALIFICATION_QUESTION_TYPE_TO_TEXT,
  QualificationQuestionAnswerCreateParams,
  QualificationQuestionAnswerQuestionType,
  QUESTION_TYPES_REQUIRING_ANSWER_OPTIONS,
} from '@traba/types'
import {
  convertLanguageContentMapToLanguageContentObjArray,
  convertLanguageContentObjArrayToLanguageContentMap,
  getAlphabeticalLabelFromIndex,
  getValidationForQualifierQuestionAnswerInputs,
} from '@traba/utils'
import { useCallback, useMemo, useState } from 'react'
import {
  QualifierQuestionAnswerResponse,
  useQualifierQuestionAnswerById,
} from 'src/hooks/useQualifierQuestionAnswer'
import {
  buildInitialInputFromCustomQuestionApplicationItemContent,
  convertToCustomQuestionApplicationItemContent,
} from 'src/utils/applicationUtils'
import { v4 as uuidv4 } from 'uuid'
import { LanguageContentInputSection } from '../../LanguageContentInputSection'
import { HelperInfoForApplicationItem } from '../HelperInfoForApplicationItem'

type CreateAndEditCustomQuestionApplicationItemProps = {
  applicationItemId?: string
  applicationItemSourceId?: string
  onSave: (
    upsertApplicationItemRequest: ApplicationItemCustomQuestionRequest & {
      applicationItemId?: string
      applicationItemSourceId?: string
    },
  ) => void
  itemContent?: ApplicationItemContentType
}

type InputsInitialState = {
  questionType?: QualificationQuestionAnswerQuestionType
  questionTitle: LanguageContentMap
  questionDescription: LanguageContentMap
  answerOptions: { id: string; contentMap: LanguageContentMap }[]
}

function buildInitialInput(
  qualifierQuestionAnswer?: QualifierQuestionAnswerResponse,
  initialStateFromItemContent?: {
    questionType?: QualificationQuestionAnswerQuestionType
    questionTitle?: LanguageContentObj[]
    questionDescription?: LanguageContentObj[]
    answerOptions?: LanguageContentObj[][]
  },
): InputsInitialState {
  const title =
    qualifierQuestionAnswer?.parsedQuestionTitle ||
    initialStateFromItemContent?.questionTitle
  const description =
    qualifierQuestionAnswer?.parsedQuestionDescription ||
    initialStateFromItemContent?.questionDescription
  const answerOptions =
    qualifierQuestionAnswer?.parsedAnswerOptions ||
    initialStateFromItemContent?.answerOptions

  return {
    questionType:
      qualifierQuestionAnswer?.questionType ||
      initialStateFromItemContent?.questionType,
    questionTitle: convertLanguageContentObjArrayToLanguageContentMap(title),
    questionDescription:
      convertLanguageContentObjArrayToLanguageContentMap(description),
    answerOptions:
      answerOptions?.map((answerOption) => ({
        id: uuidv4(),
        contentMap:
          convertLanguageContentObjArrayToLanguageContentMap(answerOption),
      })) || [],
  }
}

function convertInputsForRequest(
  inputs: InputsInitialState,
): Pick<
  QualificationQuestionAnswerCreateParams,
  'questionTitle' | 'questionDescription' | 'answerOptions'
> {
  const { questionTitle, questionDescription, answerOptions } = inputs
  return {
    questionTitle:
      convertLanguageContentMapToLanguageContentObjArray(questionTitle),
    questionDescription:
      convertLanguageContentMapToLanguageContentObjArray(questionDescription),
    answerOptions: answerOptions.map((answerOption) =>
      convertLanguageContentMapToLanguageContentObjArray(
        answerOption.contentMap,
      ),
    ),
  }
}

export function CreateAndEditCustomQuestionApplicationItem({
  applicationItemId,
  applicationItemSourceId,
  onSave,
  itemContent = {},
}: CreateAndEditCustomQuestionApplicationItemProps) {
  const { showError } = useAlert()
  const { qualifierQuestionAnswer, isLoading: isQuestionInfoLoading } =
    useQualifierQuestionAnswerById(applicationItemSourceId || '')

  const isUpdatingExistingItem =
    !!applicationItemId && !!applicationItemSourceId

  const initialValues = useMemo(() => {
    const customQuestionItemContent =
      convertToCustomQuestionApplicationItemContent(itemContent)
    const initialValuesFromItemContent =
      buildInitialInputFromCustomQuestionApplicationItemContent(
        customQuestionItemContent,
      )
    return buildInitialInput(
      qualifierQuestionAnswer,
      initialValuesFromItemContent,
    )
  }, [qualifierQuestionAnswer, itemContent])

  const [questionType, setQuestionType] = useState<
    QualificationQuestionAnswerQuestionType | undefined
  >(initialValues?.questionType)

  const [inputs, setInputs] = useState({
    questionTitle: initialValues.questionTitle,
    questionDescription: initialValues.questionDescription,
  })

  const [dragging, setDragging] = useState<{
    id: string
    contentMap: LanguageContentMap
  } | null>(null)

  const [ref, answerOptions, setAnswerOptions] = useDragAndDrop<
    HTMLDivElement,
    { id: string; contentMap: LanguageContentMap }
  >(initialValues.answerOptions ?? [], {
    dragHandle: '.dragTable',
    dragPlaceholderClass: 'dragging',
    onDragstart: function (_, state) {
      setDragging(
        state.currentTargetValue as {
          id: string
          contentMap: LanguageContentMap
        },
      )
    },
    handleDragend() {
      setDragging(null)
    },
  })

  const { isValid: isInputValid, reason: invalidInputReason } = useMemo(
    () =>
      getValidationForQualifierQuestionAnswerInputs({
        questionType,
        questionTitle: inputs.questionTitle,
        questionDescription: inputs.questionDescription,
        answerOptions: answerOptions,
      }),
    [questionType, inputs, answerOptions],
  )

  // Once question type is saved & set, don't allow it to change
  const allowEditQuestionType = !initialValues.questionType

  const showAnswerOptionsSection =
    questionType &&
    QUESTION_TYPES_REQUIRING_ANSWER_OPTIONS.includes(questionType)

  const addAnswerOption = useCallback(() => {
    const newAnswerOption = {
      id: uuidv4(),
      contentMap: DEFAULT_LANGUAGE_CONTENT,
    }

    setAnswerOptions((prev) => [...prev, newAnswerOption])
  }, [setAnswerOptions])

  const deleteAnswerOption = useCallback(
    (index: number) => () => {
      setAnswerOptions((prev) => prev.filter((_, i) => i !== index))
    },
    [setAnswerOptions],
  )

  const handleSelectQuestionType = useCallback(
    (newQuestionType: QualificationQuestionAnswerQuestionType) => {
      // Cleanup unneeded params when input type changes
      if (!QUESTION_TYPES_REQUIRING_ANSWER_OPTIONS.includes(newQuestionType)) {
        setAnswerOptions([])
      }

      setQuestionType(newQuestionType)
    },
    [setAnswerOptions],
  )

  const handleUpdateQuestionTitle = useCallback(
    (language: string, content: string) => {
      setInputs((prev) => ({
        ...prev,
        questionTitle: { ...prev.questionTitle, [language]: content },
      }))
    },
    [],
  )

  const handleUpdateQuestionDescription = useCallback(
    (language: string, content: string) => {
      setInputs((prev) => ({
        ...prev,
        questionDescription: {
          ...prev.questionDescription,
          [language]: content,
        },
      }))
    },
    [],
  )

  const handleUpdateAnswerOption = useCallback(
    (answerId: string) => (language: string, content: string) => {
      setAnswerOptions((prev) =>
        prev.map((answerOption) =>
          answerOption.id === answerId
            ? {
                ...answerOption,
                contentMap: { ...answerOption.contentMap, [language]: content },
              }
            : answerOption,
        ),
      )
    },
    [setAnswerOptions],
  )

  const handleSaveQuestionAnswerOption = useCallback(() => {
    if (!questionType || !isInputValid) {
      showError(invalidInputReason || 'You are missing required fields')
      return
    }

    const inputsForRequest = convertInputsForRequest({
      questionTitle: inputs.questionTitle,
      questionDescription: inputs.questionDescription,
      answerOptions,
    })

    onSave({
      applicationItemType: ApplicationItemType.CUSTOM_QUESTION,
      itemContent: {
        createQualifierQuestionRequestDto: !isUpdatingExistingItem
          ? { ...inputsForRequest, questionType }
          : undefined,
        updateQualifierQuestionRequestDto: isUpdatingExistingItem
          ? { ...inputsForRequest, questionId: applicationItemSourceId }
          : undefined,
      },
      applicationItemId,
    })
  }, [
    applicationItemId,
    inputs,
    onSave,
    questionType,
    isInputValid,
    invalidInputReason,
    answerOptions,
    isUpdatingExistingItem,
    applicationItemSourceId,
    showError,
  ])

  if (isQuestionInfoLoading) {
    return <LoadingSpinner />
  }

  return (
    <Col gap={theme.space.med}>
      <HelperInfoForApplicationItem
        applicationItemType={ApplicationItemType.CUSTOM_QUESTION}
        itemContent={{ createQualifierQuestionRequestDto: { questionType } }}
      />

      <Col gap={theme.space.xs}>
        <Text variant="body3">Select question type</Text>
        <SelectDropdown
          placeholder="Select question type"
          value={questionType}
          handleSelect={handleSelectQuestionType}
          menuItems={Object.values(QualificationQuestionAnswerQuestionType).map(
            (type) => ({
              value: type,
              label: QUALIFICATION_QUESTION_TYPE_TO_TEXT[type],
            }),
          )}
          disabled={!!applicationItemId || !allowEditQuestionType}
        />
      </Col>

      {questionType && (
        <Col gap={theme.space.med}>
          <LanguageContentInputSection
            sectionTitle="Question title"
            languageContentMap={inputs.questionTitle}
            onChange={handleUpdateQuestionTitle}
          />

          <LanguageContentInputSection
            sectionTitle="Question subtitle (optional)"
            languageContentMap={inputs.questionDescription}
            onChange={handleUpdateQuestionDescription}
          />

          {showAnswerOptionsSection && (
            <Col gap={theme.space.sm}>
              <Text variant="h4">
                {questionType ===
                QualificationQuestionAnswerQuestionType.ACKNOWLEDGMENT
                  ? 'Acknowledgements'
                  : 'Answer choices'}
              </Text>

              {answerOptions.length > 0 && (
                <Col ref={ref} gap={theme.space.sm}>
                  {answerOptions?.map(({ id, contentMap }, index) => (
                    <Draggable
                      key={id}
                      className="dragTable"
                      isDragging={dragging !== null && dragging?.id === id}
                      isDraggingClassName="dragging"
                      dragHandleStyle={{ height: '100%' }}
                    >
                      <Row fullWidth alignCenter gap={theme.space.sm}>
                        <Text variant="body2" style={{ minWidth: '24px' }}>
                          {getAlphabeticalLabelFromIndex(index)}
                        </Text>
                        <LanguageContentInputSection
                          languageContentMap={contentMap}
                          onChange={handleUpdateAnswerOption(id)}
                        />

                        <Button
                          variant={ButtonVariant.TEXT}
                          onClick={deleteAnswerOption(index)}
                          style={{ padding: 0, paddingLeft: theme.space.xsmed }}
                        >
                          <SvgIcon
                            size={24}
                            name="trash"
                            color={theme.colors.Red60}
                          />
                        </Button>
                      </Row>
                    </Draggable>
                  ))}
                </Col>
              )}

              <Row>
                <Button
                  leftIcon={<SvgIcon name="plus" />}
                  variant={ButtonVariant.BRANDLINK}
                  onClick={addAnswerOption}
                >
                  Add option
                </Button>
              </Row>
            </Col>
          )}
        </Col>
      )}

      <Row justifyEnd>
        <Tooltip title={invalidInputReason}>
          <span>
            <Button
              disabled={!isInputValid}
              onClick={handleSaveQuestionAnswerOption}
            >
              Save
            </Button>
          </span>
        </Tooltip>
      </Row>
    </Col>
  )
}
