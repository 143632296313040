import { BadgeVariant } from '@traba/react-components'
import { BrandColors, otherColors } from '@traba/theme'
import styled, { css } from 'styled-components'

interface IColorAndBackgroundColor {
  color: string
  backgroundColor: string
  borderWidth?: string
  borderStyle?: string
}

type BadgeContainerProps = {
  round?: boolean
  dismissible?: boolean
  variant: BadgeVariant | string // Keep string for backward compatibility
  sm?: boolean
  short?: boolean
  clickable?: boolean
  disableUppercase?: boolean
}

export const colorVariants: { [key: string]: IColorAndBackgroundColor } = {
  [BadgeVariant.INFO]: {
    color: otherColors.Blue80,
    backgroundColor: otherColors.Blue10,
  },
  [BadgeVariant.BRAND]: {
    color: BrandColors.Violet,
    backgroundColor: otherColors.Violet10,
  },
  [BadgeVariant.DISABLED]: {
    color: otherColors.Grey60,
    backgroundColor: otherColors.Grey10,
  },
  [BadgeVariant.SUCCESS]: {
    color: otherColors.Green80,
    backgroundColor: otherColors.Green10,
  },
  [BadgeVariant.WARNING]: {
    color: otherColors.Yellow70,
    backgroundColor: otherColors.Yellow10,
  },
  [BadgeVariant.ADDITIONAL]: {
    color: otherColors.Violet70,
    backgroundColor: otherColors.Violet10,
  },
  [BadgeVariant.BUSINESS]: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  [BadgeVariant.DANGER]: {
    color: otherColors.Red60,
    backgroundColor: otherColors.Red10,
  },
  [BadgeVariant.OPAQUE_RED]: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Red40,
  },
  [BadgeVariant.CERTIFICATION]: {
    color: otherColors.Orange70,
    backgroundColor: otherColors.Orange10,
  },
  [BadgeVariant.PINK]: {
    color: otherColors.Red80,
    backgroundColor: '#FFC0CB',
  },
  [BadgeVariant.ORANGE]: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Orange60,
  },
  [BadgeVariant.BLUE]: {
    color: '#FFFFFF',
    backgroundColor: otherColors.Blue50,
  },
  [BadgeVariant.OUTLINE_VIOLET]: {
    color: BrandColors.Violet,
    backgroundColor: 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  [BadgeVariant.OUTLINE_GREEN]: {
    color: otherColors.Green80,
    backgroundColor: 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  [BadgeVariant.OUTLINE_RED]: {
    color: otherColors.Red80,
    backgroundColor: 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  [BadgeVariant.OUTLINE_YELLOW]: {
    color: otherColors.Yellow70,
    backgroundColor: 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  [BadgeVariant.DARK_ORANGE]: {
    color: otherColors.Peach70,
    backgroundColor: otherColors.Peach10,
  },
}

export { BadgeVariant }

export const BadgeContainer = styled.div<BadgeContainerProps>`
  border-radius: 4px;
  font-size: 10px;
  padding: 6px 12px;
  background-color: ${({ variant }) => colorVariants[variant].backgroundColor};
  color: ${({ variant }) => colorVariants[variant].color};
  border-width: ${({ variant }) => colorVariants[variant].borderWidth};
  border-style: ${({ variant }) => colorVariants[variant].borderStyle};
  border-color: ${({ variant }) => colorVariants[variant].color};
  font-weight: bold;
  text-transform: ${({ disableUppercase }) =>
    disableUppercase ? 'none' : 'uppercase'};
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    `};

  ${({ round }) =>
    round &&
    css`
      border-radius: 100%;
      padding: 0;
      width: 24px;
      height: 24px;
    `};

  ${({ short }) =>
    short &&
    css`
      display: block;
      max-width: 125px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `};

  ${({ dismissible }) =>
    dismissible &&
    css`
      border-radius: 8px;
      font-size: 12px;
      text-transform: inherit;
      padding: 8px 12px;
    `};

  ${({ sm }) =>
    sm &&
    css`
      margin-right: 4px;
      margin-bottom: 4px;
      font-size: 8px;
      padding: 4px 6px;
    `};
`
