export enum ShiftTag {
  VIP = 'VIP',
  NoBackfill = 'NO_BACKFILL',
  Ignore = 'IGNORE',
  National = 'NATIONAL',
  Enterprise1 = 'ENTERPRISE_ONE',
  Enterprise2 = 'ENTERPRISE_TWO',
  Enterprise3 = 'ENTERPRISE_THREE',
  Enterprise4 = 'ENTERPRISE_FOUR',
  Enterprise5 = 'ENTERPRISE_FIVE',
  Pod0 = 'POD_ZERO',
  Pod1 = 'POD_ONE',
  Pod2 = 'POD_TWO',
  Pod3 = 'POD_THREE',
  Pod4 = 'POD_FOUR',
  Pod5 = 'POD_FIVE',
  Pod6 = 'POD_SIX',
  Pod7 = 'POD_SEVEN',
  Pod8 = 'POD_EIGHT',
  Pod9 = 'POD_NINE',
  Pod10 = 'POD_TEN',
  Growth = 'GROWTH',
}

export enum ShiftTagExcludingPods {
  VIP = 'VIP',
  NoBackfill = 'NO_BACKFILL',
  Ignore = 'IGNORE',
}

export enum ForwardFillMax {
  NONE = 'NONE',
  // All eligible workers with experience at the business notified
  EXPERIENCED = 'EXPERIENCED', // DEPRECATED
  EXPERIENCED_FIRST = 'EXPERIENCED_FIRST',
  EXPERIENCED_ONLY = 'EXPERIENCED_ONLY',
  // All favorites at the business notified
  FAVORITES = 'FAVORITES',
  INVITED_ONLY = 'INVITED_ONLY',
  INVITED_FIRST = 'INVITED_FIRST',
  INEXPERIENCED_ONLY = 'INEXPERIENCED_ONLY',
}

export enum AssigneeDisplay {
  MARKET_OPS = 'MARKET_OPS',
  SCALED_OPS = 'SCALED_OPS',
}

export enum ForwardFillRenotificationMethod {
  NONE = 'NONE',
  SMS = 'SMS',
}

export enum PaymentType {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
  AUTO_WEEKLY = 'AUTO_WEEKLY',
}

export enum InvitedWorkers {
  ALL = 'ALL',
  FAVORITES_ONLY = 'FAVORITES_ONLY',
  FAVORITES_FIRST = 'FAVORITES_FIRST',
}

export enum ShiftStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
}

export enum ShiftSignupStatus {
  ALLOWED = 'ALLOWED',
  DISALLOWED = 'DISALLOWED',
}

export enum ShiftNotificationStatus {
  ALLOWED = 'ALLOWED',
  DISALLOWED = 'DISALLOWED',
}
