export enum ShiftSignupStatus {
  ALLOWED = 'ALLOWED',
  DISALLOWED = 'DISALLOWED',
}

export enum ShiftNotificationStatus {
  ALLOWED = 'ALLOWED',
  DISALLOWED = 'DISALLOWED',
}

export enum JobStatus {
  ToDo = 'TO_DO',
  InProgress = 'IN_PROGRESS',
  OnBreak = 'ON_BREAK',
  Complete = 'COMPLETE',
  Canceled = 'CANCELED',
  NoShow = 'NO_SHOW',
  Abandoned = 'ABANDONED',
  Rejected = 'REJECTED',
  Appeased = 'APPEASED',
}

type ArrayLengthMutationKeys =
  | 'splice'
  | 'push'
  | 'pop'
  | 'shift'
  | 'unshift'
  | number

type ArrayItems<T extends Array<any>> =
  T extends Array<infer TItems> ? TItems : never

export type FixedLengthArray<T extends any[]> = Pick<
  T,
  Exclude<keyof T, ArrayLengthMutationKeys>
> & { [Symbol.iterator]: () => IterableIterator<ArrayItems<T>> }

export type QueryParamArray = FixedLengthArray<
  [string, string | null | undefined]
>[]

export type ValueOf<T> = T[keyof T]

export type Either<L, R> =
  | { left: L; right?: never }
  | { left?: never; right: R }
