import { Anchor, Row } from '@traba/react-components'
import { CopyTextIcon } from 'src/components/base'

interface PhoneLinkAndCopyButtonProps {
  phoneNumber: string
}

export function PhoneLinkAndCopyButton({
  phoneNumber,
}: PhoneLinkAndCopyButtonProps) {
  return (
    <Row alignCenter>
      <Anchor openInNewTab={false} href={`tel:${phoneNumber}`}>
        {phoneNumber}
      </Anchor>
      <CopyTextIcon textToCopy={phoneNumber} />
    </Row>
  )
}
