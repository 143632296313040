import { Badge, BadgeVariant } from '@traba/react-components'
import {
  WorkerVettingCampaignStatus,
  WorkerVettingSessionStatus,
} from '@traba/types'
import { WorkerVettingPhoneCallStatus } from 'src/hooks/useWorkerVetting'
import { Badge as BadgeDEPRECATED } from './base'

type PhoneStatusConfig = {
  [key in WorkerVettingPhoneCallStatus]: {
    variant: BadgeVariant
    title: string
  }
}

const PHONE_STATUS_CONFIG: PhoneStatusConfig = {
  [WorkerVettingPhoneCallStatus.CALL_COMPLETE]: {
    variant: BadgeVariant.SUCCESS,
    title: 'Call Complete',
  },
  [WorkerVettingPhoneCallStatus.VETTING_COMPLETE]: {
    variant: BadgeVariant.SUCCESS,
    title: 'Vetting Complete',
  },
  [WorkerVettingPhoneCallStatus.INITIATED]: {
    variant: BadgeVariant.WARNING,
    title: 'Inbound In-Progress',
  },
  [WorkerVettingPhoneCallStatus.INITIATED_OUTBOUND]: {
    variant: BadgeVariant.WARNING,
    title: 'Outbound In-Progress',
  },
  [WorkerVettingPhoneCallStatus.MISSED_OUTBOUND]: {
    variant: BadgeVariant.DANGER,
    title: 'Missed Outbound',
  },
} satisfies PhoneStatusConfig

type VettingSessionStatusBadgeProps = {
  status: WorkerVettingSessionStatus
}

type VettingSessionPhoneCallsStatusBadgeProps = {
  status?: WorkerVettingPhoneCallStatus
}

const statusToBadgeVariantDict: Record<
  WorkerVettingSessionStatus,
  BadgeVariant
> = {
  [WorkerVettingSessionStatus.active]: BadgeVariant.WARNING,
  [WorkerVettingSessionStatus.canceled]: BadgeVariant.OPAQUE_RED,
  [WorkerVettingSessionStatus.complete]: BadgeVariant.SUCCESS,
  [WorkerVettingSessionStatus.exited]: BadgeVariant.INFO,
  [WorkerVettingSessionStatus.stale]: BadgeVariant.ORANGE,
}

export function VettingSessionStatusBadge({
  status,
}: VettingSessionStatusBadgeProps) {
  return (
    <BadgeDEPRECATED
      title={status}
      variant={statusToBadgeVariantDict[status]}
    />
  )
}

export function VettingSessionPhoneCallsStatusBadge({
  status,
}: VettingSessionPhoneCallsStatusBadgeProps) {
  if (status === undefined) {
    return (
      <Badge
        iconName="addChat"
        variant={BadgeVariant.DARK_RED}
        title="No Calls"
      />
    )
  }
  const config = PHONE_STATUS_CONFIG[status] ?? BadgeVariant.INFO
  return (
    <Badge iconName="addChat" variant={config.variant} title={config.title} />
  )
}

type VettingCampaignStatusBadgeProps = {
  status: WorkerVettingCampaignStatus
}

const campaignStatusToBadgeVariantDict: Record<
  WorkerVettingCampaignStatus,
  BadgeVariant
> = {
  [WorkerVettingCampaignStatus.active]: BadgeVariant.WARNING,
  [WorkerVettingCampaignStatus.canceled]: BadgeVariant.OPAQUE_RED,
  [WorkerVettingCampaignStatus.completed]: BadgeVariant.SUCCESS,
}

export function VettingCampaignStatusBadge({
  status,
}: VettingCampaignStatusBadgeProps) {
  return (
    <BadgeDEPRECATED
      title={status}
      variant={campaignStatusToBadgeVariantDict[status] ?? BadgeVariant.INFO}
      style={{ height: 28 }}
    />
  )
}
