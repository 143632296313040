import { CircularProgress } from '@mui/material'
import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Link, useParams } from 'react-router-dom'
import { Button, Col, CopyTextIcon, Icon, Row } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { PageHeader } from 'src/components/base/PageHeader'
import { useCompany } from 'src/hooks/useCompany'
import { useShiftRequestParent } from 'src/hooks/useShiftRequestParent'
import { formatDateTimeWithTimezone } from 'src/utils/dateUtils'
import AuditLogsScreen from './AuditLogsScreen'

export default function ScheduleLogsScreen() {
  const { scheduleId = '' } = useParams<{ scheduleId: string }>()
  const { shiftRequestParent, isLoading } = useShiftRequestParent(scheduleId)
  const { company, isLoading: isCompanyLoading } = useCompany(
    shiftRequestParent?.companyId,
  )

  if (isLoading || isCompanyLoading || !shiftRequestParent || !company) {
    return (
      <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size="large" />
      </Row>
    )
  }
  if (!shiftRequestParent) {
    return <div>Schedule not found</div>
  }

  const ScheduleLogsHeader = () => {
    return (
      <PageHeader
        avatarString={company.employerName}
        avatarUrl={company.companyLogo}
        children={
          <Col ml={theme.space.xs}>
            <Row alignCenter>
              <Link to={`/schedule/${scheduleId}`} target="_blank">
                <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                  <Text variant="h4">{shiftRequestParent.title}</Text>
                </Button>
              </Link>
            </Row>
            <Row alignCenter>
              <Text variant="h7">Schedule Id: {scheduleId}</Text>
              <CopyTextIcon textToCopy={scheduleId} />
            </Row>
            <Row alignCenter>
              <Text
                variant="body1"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Icon
                  name="calendar_active"
                  style={{ height: '17px', marginLeft: 2, marginRight: 6 }}
                />
                Created at:{' '}
                {formatDateTimeWithTimezone(shiftRequestParent.createdAt)}
              </Text>
            </Row>
          </Col>
        }
      />
    )
  }

  return (
    <AuditLogsScreen
      headerContent={<ScheduleLogsHeader />}
      logType="SCHEDULE"
      searchId={scheduleId}
    />
  )
}
