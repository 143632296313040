import { Col, LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Role,
  ApplicationItemAICallRequest,
  ApplicationItemType,
  VettingConfig,
  Locations,
  ApplicationItemContent as ApplicationItemContentType,
} from '@traba/types'
import { VettingContextForm } from 'src/components/VettingContextForm/VettingContextForm'
import { useWorkerVettingCampaign } from 'src/hooks/useWorkerVetting'
import { getMoneyString } from 'src/utils/stringUtils'
import {
  buildVettingConfigInitialValues,
  INITIAL_VETTING_CONTEXT_FORM_VALUES,
} from 'src/utils/vettingCampaignUtils'
import { ApplicationItemCreationAdditionalProps } from '../../types'
import { HelperInfoForApplicationItem } from '../HelperInfoForApplicationItem'

type CreateAndEditWorkerVettingCallApplicationItemProps = {
  applicationItemId?: string
  applicationItemSourceId?: string
  onSave: (
    upsertApplicationItemRequest: ApplicationItemAICallRequest & {
      applicationItemId?: string
    },
  ) => void
  itemContent?: ApplicationItemContentType
} & ApplicationItemCreationAdditionalProps

function buildInitialMessage({
  role,
  location,
  payRatePlainText,
}: {
  role?: Pick<Role, 'roleName' | 'roleDescription'>
  location?: Pick<Locations, 'locationInstructions' | 'regionId' | 'address'>
  payRatePlainText: string
}) {
  if (!role || !role.roleName) {
    return ''
  }
  const loc = location?.address.city || location?.regionId
  const locString = loc ? `in ${loc}` : ''
  return `Earn ${payRatePlainText}/hr as a ${role.roleName} ${locString}`
}

export function CreateAndEditWorkerVettingCallApplicationItem({
  applicationItemId,
  applicationItemSourceId,
  onSave,
  itemContent = {},
  role,
  shiftRequest,
  location,
}: CreateAndEditWorkerVettingCallApplicationItemProps) {
  const { campaign: workerVettingCampaign, isLoading } =
    useWorkerVettingCampaign(applicationItemSourceId || '')

  const handleSaveVettingConfig = (vettingConfig: VettingConfig) => {
    onSave({
      applicationItemType: ApplicationItemType.AI_VETTING_CALL,
      itemContent: {
        vettingConfig,
        shiftInformationForVettingConfig: {
          shiftRoleId: shiftRequest?.roleId || role?.roleId || '',
          shiftRequestParentTitle: shiftRequest?.shiftRequestParentTitle,
          shiftStartTime:
            shiftRequest?.schedules?.[0]?.startTime?.toISOString(),
          shiftRoleName: shiftRequest?.shiftRole || role?.roleName || '',
        },
      },
      applicationItemId,
    })
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  const payRatePlainText = getMoneyString(shiftRequest?.payRate)

  const vettingConfig =
    'vettingConfig' in itemContent ? itemContent.vettingConfig : undefined
  const vettingLogistics = vettingConfig?.vettingLogistics ??
    workerVettingCampaign?.activePromptContext?.context ?? {
      jobTitle: role?.roleName || '',
      roleDetails: role?.roleDescription || '',
      payRatePlainText,
      locationDetails: location?.locationInstructions || '',
      scheduleDetails: '',
    }

  const initialValues = workerVettingCampaign
    ? buildVettingConfigInitialValues({
        workerVettingCampaign,
        promptContext: workerVettingCampaign?.activePromptContext?.context,
      })
    : {
        ...INITIAL_VETTING_CONTEXT_FORM_VALUES,
        initialMessage: buildInitialMessage({
          role,
          location,
          payRatePlainText,
        }),
        vettingLogistics,
        ...vettingConfig,
      }

  return (
    <Col gap={theme.space.med}>
      <HelperInfoForApplicationItem
        applicationItemType={ApplicationItemType.AI_VETTING_CALL}
      />

      <VettingContextForm
        initialValues={initialValues}
        loading={isLoading}
        onSave={handleSaveVettingConfig}
        includeSMSVetting={false}
      />
    </Col>
  )
}
