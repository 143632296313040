import {
  ApplicationItemContent,
  ApplicationItemCustomQuestionItemContent,
  ApplicationItemType,
  QualificationQuestionAnswerQuestionType,
  LanguageContentObj,
  UpsertApplicationItemRequestType,
  ApplicationItemOpsManualStepItemContent,
  WorkerApplicationItem,
  APPLICATION_ITEM_TYPES_THAT_CANNOT_BE_ACTIONED_ON,
  APPLICATION_STATUSES_THAT_CANNOT_BE_ACTIONED_ON,
} from '@traba/types'

export const convertToUpsertApplicationItemRequest = ({
  applicationItemType,
  applicationItemId,
  itemContent = {},
}: {
  applicationItemType?: ApplicationItemType
  applicationItemId?: string
  itemContent?: ApplicationItemContent
}): UpsertApplicationItemRequestType | undefined => {
  if (!applicationItemType) {
    return undefined
  }

  switch (applicationItemType) {
    case ApplicationItemType.AI_VETTING_CALL:
      if (!('vettingConfig' in itemContent)) {
        // missing required itemContent to create AI_VETTING_CALL
        return undefined
      }

      return {
        applicationItemId,
        applicationItemType,
        itemContent,
      }
    case ApplicationItemType.RESUME_UPLOAD:
      return {
        applicationItemId,
        applicationItemType,
        itemContent,
      }
    case ApplicationItemType.CUSTOM_QUESTION: {
      const customQuestionItemContent =
        convertToCustomQuestionApplicationItemContent(itemContent)

      if (
        !applicationItemId &&
        !customQuestionItemContent?.createQualifierQuestionRequestDto
      ) {
        // missing required itemContent to create new CUSTOM_QUESTION
        return undefined
      }

      if (
        applicationItemId &&
        !customQuestionItemContent?.updateQualifierQuestionRequestDto
          ?.questionId
      ) {
        // missing required itemContent to update CUSTOM_QUESTION
        return undefined
      }

      return {
        applicationItemId,
        applicationItemType,
        itemContent,
      }
    }
    case ApplicationItemType.OPS_MANUAL_STEP: {
      return {
        applicationItemId,
        applicationItemType: ApplicationItemType.OPS_MANUAL_STEP,
        itemContent: itemContent as ApplicationItemOpsManualStepItemContent,
      }
    }
    default:
      return undefined
  }
}

export const convertFromExistingApplicationItemToEmptyUpsertApplicationItem = ({
  applicationItemType,
  applicationItemId,
  applicationItemSourceId,
}: {
  applicationItemType?: ApplicationItemType
  applicationItemId?: string
  applicationItemSourceId?: string
}): UpsertApplicationItemRequestType | undefined => {
  if (!applicationItemType) {
    return undefined
  }

  switch (applicationItemType) {
    case ApplicationItemType.AI_VETTING_CALL:
      return {
        applicationItemId,
        applicationItemType,
        applicationItemSourceId,
        itemContent: {
          vettingConfig: {
            vettingLogistics: {
              locationDetails: '',
              scheduleDetails: '',
              payRatePlainText: '',
              jobTitle: '',
            },
            questions: [],
            promptDetails: '',
            customUnqualifiedExitMessage: '',
            customQualifiedExitMessage: '',
            initialMessage: '',
            requiredAttributeLevels: [],
          },
          shiftInformationForVettingConfig: {
            shiftRoleId: '',
            shiftRequestParentTitle: '',
            shiftStartTime: '',
            shiftRoleName: '',
          },
        },
      }
    case ApplicationItemType.RESUME_UPLOAD:
      return {
        applicationItemId,
        applicationItemType,
        applicationItemSourceId,
        itemContent: {},
      }
    case ApplicationItemType.CUSTOM_QUESTION: {
      return {
        applicationItemId,
        applicationItemType,
        applicationItemSourceId,
        itemContent: {},
      }
    }
    case ApplicationItemType.OPS_MANUAL_STEP: {
      return {
        applicationItemId,
        applicationItemType,
        applicationItemSourceId,
        itemContent: {},
      }
    }
    default:
      return undefined
  }
}

export function convertToCustomQuestionApplicationItemContent(
  itemContent: ApplicationItemContent,
): ApplicationItemCustomQuestionItemContent | undefined {
  const createQualifierQuestionRequestDto =
    'createQualifierQuestionRequestDto' in itemContent
      ? itemContent.createQualifierQuestionRequestDto
      : undefined
  const updateQualifierQuestionRequestDto =
    'updateQualifierQuestionRequestDto' in itemContent
      ? itemContent.updateQualifierQuestionRequestDto
      : undefined

  if (
    !createQualifierQuestionRequestDto &&
    !updateQualifierQuestionRequestDto
  ) {
    return undefined
  }

  const customQuestionItemContent: ApplicationItemCustomQuestionItemContent = {
    createQualifierQuestionRequestDto,
    updateQualifierQuestionRequestDto,
  }

  return customQuestionItemContent
}

export function buildInitialInputFromCustomQuestionApplicationItemContent(
  itemContent?: ApplicationItemCustomQuestionItemContent,
): {
  questionType?: QualificationQuestionAnswerQuestionType
  questionTitle?: LanguageContentObj[]
  questionDescription?: LanguageContentObj[]
  answerOptions?: LanguageContentObj[][]
} {
  const questionTitle =
    itemContent?.createQualifierQuestionRequestDto?.questionTitle ||
    itemContent?.updateQualifierQuestionRequestDto?.questionTitle
  const questionDescription =
    itemContent?.createQualifierQuestionRequestDto?.questionDescription ||
    itemContent?.updateQualifierQuestionRequestDto?.questionDescription ||
    undefined
  const answerOptions =
    itemContent?.createQualifierQuestionRequestDto?.answerOptions ||
    itemContent?.updateQualifierQuestionRequestDto?.answerOptions

  return {
    questionType: itemContent?.createQualifierQuestionRequestDto?.questionType,
    questionTitle,
    questionDescription,
    answerOptions,
  }
}

export function convertToOpsManualStepApplicationItemContent(
  itemContent: ApplicationItemContent,
): ApplicationItemOpsManualStepItemContent | undefined {
  const createOpsManualActionRequestDto =
    'createOpsManualActionRequestDto' in itemContent
      ? itemContent.createOpsManualActionRequestDto
      : undefined

  const updateOpsManualActionRequestDto =
    'updateOpsManualActionRequestDto' in itemContent
      ? itemContent.updateOpsManualActionRequestDto
      : undefined

  if (!createOpsManualActionRequestDto && !updateOpsManualActionRequestDto) {
    return undefined
  }

  const opsManualStepItemContent: ApplicationItemOpsManualStepItemContent = {
    createOpsManualActionRequestDto,
    updateOpsManualActionRequestDto,
  }

  return opsManualStepItemContent
}

export function buildInitialInputFromOpsManualStepApplicationItemContent(
  itemContent?: ApplicationItemOpsManualStepItemContent,
): {
  workerAppTitle?: LanguageContentObj[]
  workerAppDescription?: LanguageContentObj[]
  opsConsoleTitle?: string
  opsConsoleDescription?: string
} {
  const workerAppTitle =
    itemContent?.createOpsManualActionRequestDto?.workerAppTitle ||
    itemContent?.updateOpsManualActionRequestDto?.workerAppTitle
  const workerAppDescription =
    itemContent?.createOpsManualActionRequestDto?.workerAppDescription ||
    itemContent?.updateOpsManualActionRequestDto?.workerAppDescription
  const opsConsoleTitle =
    itemContent?.createOpsManualActionRequestDto?.opsConsoleTitle ||
    itemContent?.updateOpsManualActionRequestDto?.opsConsoleTitle
  const opsConsoleDescription =
    itemContent?.createOpsManualActionRequestDto?.opsConsoleDescription ||
    itemContent?.updateOpsManualActionRequestDto?.opsConsoleDescription

  return {
    workerAppTitle,
    workerAppDescription,
    opsConsoleTitle,
    opsConsoleDescription,
  }
}

export function isWorkerApplicationItemActionable(
  workerApplicationItem: WorkerApplicationItem,
) {
  return (
    !APPLICATION_ITEM_TYPES_THAT_CANNOT_BE_ACTIONED_ON.includes(
      workerApplicationItem.applicationItem.applicationItemType,
    ) &&
    !APPLICATION_STATUSES_THAT_CANNOT_BE_ACTIONED_ON.includes(
      workerApplicationItem.status,
    )
  )
}
