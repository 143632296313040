import { Tooltip } from '@mui/material'
import { Button, ButtonVariant } from '@traba/react-components'

interface AddNewApplicationItemButtonProps {
  onClick?: () => void
  disabled?: boolean
  tooltipText?: string
}

export function AddNewApplicationItemButton({
  onClick,
  disabled,
  tooltipText,
}: AddNewApplicationItemButtonProps) {
  return (
    <Tooltip title={tooltipText}>
      <Button
        full
        variant={ButtonVariant.BRANDNEW}
        onClick={onClick}
        disabled={disabled}
      >
        + Add application step
      </Button>
    </Tooltip>
  )
}
