import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import {
  Association,
  SearchTagsResponse,
  TagWithAssociations,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'

const TAGS_QUERY_KEY = 'tags'

const applyTags = async ({
  tagNames,
  associations,
}: {
  tagNames: string[]
  associations: Association[]
}) => {
  try {
    await trabaApi.post(`/tag/apply`, {
      tagNames,
      associations,
    })
    return { tagNames }
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

const deleteTags = async ({ tagIds }: { tagIds: string[] }) => {
  try {
    await trabaApi.delete(`/tag`, {
      data: {
        tagIds,
      },
    })
    return { tagIds }
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

const searchTags = async ({
  associations,
  namespace,
}: {
  associations?: Association[]
  namespace?: string
}): Promise<TagWithAssociations[]> => {
  try {
    const { data } = await trabaApi.post<SearchTagsResponse>(`/tag/search`, {
      associations,
      namespace,
    })
    return data.tags
  } catch (error) {
    captureSentryError(error)
    console.error(error)
    throw error
  }
}

export function useTags({
  associations,
  namespace,
  onApplySuccess,
  onApplyError,
  onDeleteSuccess,
  onDeleteError,
}: {
  associations?: Association[]
  namespace?: string
  onApplySuccess?: (tagNames: string[]) => void
  onApplyError?: (error: Error) => void
  onDeleteSuccess?: (tagIds: string[]) => void
  onDeleteError?: (error: Error) => void
} = {}) {
  const client = useQueryClient()

  const {
    data: tags,
    isLoading: isSearchingTags,
    isError: isSearchingTagsError,
    refetch: refetchTags,
  } = useQuery({
    queryKey: [TAGS_QUERY_KEY, associations, namespace],
    queryFn: () => searchTags({ associations, namespace }),
    enabled: (associations && associations.length > 0) || !!namespace,
    refetchOnWindowFocus: true,
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const { mutateAsync: applyTagsAsync, isPending: isApplyingTags } =
    useMutation({
      mutationFn: applyTags,
      onSuccess: (data) => {
        onApplySuccess?.(data.tagNames)
        client.invalidateQueries({ queryKey: [TAGS_QUERY_KEY] })
      },
      onError: (error: Error) => onApplyError?.(error),
    })

  const { mutateAsync: deleteTagsAsync, isPending: isDeletingTags } =
    useMutation({
      mutationFn: deleteTags,
      onSuccess: (data) => {
        onDeleteSuccess?.(data.tagIds)
        client.invalidateQueries({ queryKey: [TAGS_QUERY_KEY] })
      },
      onError: (error: Error) => onDeleteError?.(error),
    })

  return {
    applyTags: applyTagsAsync,
    isApplyingTags,

    deleteTags: deleteTagsAsync,
    isDeletingTags,

    tags,
    isSearchingTags,
    isSearchingTagsError,
    refetchTags,
  }
}
