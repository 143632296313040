import { useHotSettings } from '@traba/hooks'
import {
  COMPANY_WIDE_ID,
  COMPANY_WIDE_TEXT,
  IconName,
  IMenuItem,
  InputStatus,
  User,
} from '@traba/types'
import {
  getLocationNameOrTruncatedAddress,
  getMembersWithAccessAtLocation,
  getUserFullName,
  isBizMemberCompanyWide,
  isBizMemberLocationAssigned,
} from '@traba/utils'
import { useCallback, useMemo } from 'react'
import { BadgeVariant } from '../Badge/Badge.styles'
import { SearchSelect } from '../SearchSelect/SearchSelect'
import { MenuItemGroup } from '../Select/Select'

const LOCATION_ASSIGNED_TEXT = 'Location assigned'
const LOCATION_ASSIGNED_DEFAULT_ID = 'location-assigned'

type Supervisor = Pick<
  User,
  'firstName' | 'lastName' | 'userAccessLevel' | 'locations'
> & { uid?: string }

interface SupervisorForShiftSearchSelectProps {
  allSupervisors: Supervisor[]
  selectedSupervisorId?: string
  selectedLocationId?: string
  handleSupervisorChange: (supervisorId: string) => void
  placeholder?: string
  errorMessage?: string
  disabled?: boolean
  disabledTooltipText?: string
  label?: string
  style?: React.CSSProperties
  selectStyle?: React.CSSProperties
  showEmailForUser?: boolean
}

export function SupervisorForShiftSearchSelect({
  allSupervisors,
  selectedSupervisorId,
  selectedLocationId,
  handleSupervisorChange,
  placeholder,
  errorMessage,
  disabled,
  disabledTooltipText,
  label,
  style,
  selectStyle,
  showEmailForUser,
}: SupervisorForShiftSearchSelectProps) {
  const { hotSettings } = useHotSettings()
  const supervisorsWithAccessAtLocation = useMemo(() => {
    return (
      selectedLocationId
        ? getMembersWithAccessAtLocation({
            members: allSupervisors,
            locationId: selectedLocationId,
          })
        : allSupervisors
    ).filter(
      (supervisor): supervisor is Partial<User> & { uid: string } =>
        !!supervisor.uid,
    )
  }, [selectedLocationId, allSupervisors])

  const selectedLocationName = useMemo(() => {
    const locationAssignedSupervisorAtLocation =
      supervisorsWithAccessAtLocation.find(isBizMemberLocationAssigned)
    const foundSelectedLocation =
      locationAssignedSupervisorAtLocation?.locations?.find(
        (loc) => loc.locationId === selectedLocationId,
      )
    return foundSelectedLocation
      ? getLocationNameOrTruncatedAddress(foundSelectedLocation)
      : undefined
  }, [supervisorsWithAccessAtLocation, selectedLocationId])

  const groups: MenuItemGroup[] = useMemo(() => {
    return [
      {
        id: selectedLocationId || LOCATION_ASSIGNED_DEFAULT_ID,
        title: selectedLocationName || LOCATION_ASSIGNED_TEXT,
      },
      {
        id: COMPANY_WIDE_ID,
        title: COMPANY_WIDE_TEXT,
      },
    ]
  }, [selectedLocationId, selectedLocationName])

  const supervisorOptions = useMemo(() => {
    return supervisorsWithAccessAtLocation.flatMap((supervisor) => {
      const isUserCompanyWide = isBizMemberCompanyWide(supervisor)
      const groupId = isUserCompanyWide
        ? COMPANY_WIDE_ID
        : selectedLocationId || LOCATION_ASSIGNED_DEFAULT_ID
      const groupName = isUserCompanyWide
        ? COMPANY_WIDE_TEXT
        : selectedLocationName || LOCATION_ASSIGNED_TEXT

      const trailingTags = hotSettings?.enableRegionalAccessPhase2
        ? [
            {
              title: groupName,
              iconName: 'location' as IconName,
              variant:
                groupId === COMPANY_WIDE_ID
                  ? BadgeVariant.DARK_ORANGE
                  : BadgeVariant.BUSINESS,
            },
          ]
        : undefined

      return {
        value: supervisor.uid,
        label: getUserFullName(supervisor),
        secondaryLabel: showEmailForUser ? supervisor.email : undefined,
        groupId,
        trailingTags,
      }
    })
  }, [
    supervisorsWithAccessAtLocation,
    hotSettings?.enableRegionalAccessPhase2,
    selectedLocationId,
    selectedLocationName,
  ])

  const selectItem = useMemo(() => {
    const foundSupervisor = allSupervisors.find(
      (supervisor): supervisor is Supervisor & { uid: string } =>
        !!supervisor.uid && supervisor.uid === selectedSupervisorId,
    )
    return foundSupervisor
      ? {
          value: foundSupervisor.uid,
          label: getUserFullName(foundSupervisor),
        }
      : undefined
  }, [supervisorOptions, allSupervisors, selectedSupervisorId])

  const handleSelectItem = useCallback(
    (item?: IMenuItem) => {
      if (!item?.value) {
        return
      }
      handleSupervisorChange(item?.value)
    },
    [handleSupervisorChange],
  )

  return (
    <SearchSelect
      placeholder={placeholder}
      selectItem={selectItem}
      options={supervisorOptions}
      handleSelect={handleSelectItem}
      label={label}
      disabled={disabled}
      disabledTooltipText={disabledTooltipText}
      inputStatus={errorMessage ? InputStatus.error : InputStatus.default}
      errorMessage={errorMessage}
      groups={groups}
      groupByGroup={hotSettings?.enableRegionalAccessPhase2}
      style={style}
      selectStyle={selectStyle}
      shouldAlsoSearchSecondaryLabel
      onlyShowLabel={!showEmailForUser}
    />
  )
}
