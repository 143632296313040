import {
  Button,
  ButtonVariant,
  Col,
  LoadingSpinner,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { useMemo, useState } from 'react'
import { WorkerResumeUploadModal } from 'src/components/WorkerResumeUploadModal/Index'
import { useAnalytics } from 'src/hooks/useAnalytics'
import { useWorkerUploadedResumes } from 'src/hooks/useWorkerUploadedResumes'
import { ResumeLinkWithSummaryCard } from './ResumeLinkWithSummaryCard'

interface ResumeUploadApplicationItemResultsProps {
  applicationItemSourceId?: string
  workerId?: string
}

export function ResumeUploadApplicationItemResults({
  applicationItemSourceId,
  workerId,
}: ResumeUploadApplicationItemResultsProps) {
  const { resumes, isLoading } = useWorkerUploadedResumes(workerId || '')
  const [showResumeUploadModal, setShowResumeUploadModal] = useState(false)
  const { trackAnalytics } = useAnalytics()

  const resumesWithIndex =
    useMemo(
      () =>
        resumes?.resumes.map((resume, i) => ({
          ...resume,
          index: i + 1,
        })) || [],
      [resumes],
    ) || []

  if (isLoading) {
    return <LoadingSpinner />
  }

  const selectedResume = resumesWithIndex.find(
    (resume) => resume.resumeId === applicationItemSourceId,
  )

  const allOtherResumes = resumesWithIndex.filter(
    (resume) => resume.resumeId !== applicationItemSourceId,
  )

  return (
    <Col pt={theme.space.xs} gap={theme.space.sm}>
      {resumesWithIndex.length === 0 ? (
        <Text>No resumes found</Text>
      ) : (
        <>
          <Col gap={theme.space.xxs}>
            <Text variant="h5">Selected Resume</Text>
            {selectedResume ? (
              <ResumeLinkWithSummaryCard
                resume={selectedResume}
                showGenerateResumeButton
                source="application_results"
              />
            ) : (
              <Text>No resume selected</Text>
            )}
          </Col>

          {allOtherResumes.length > 0 && (
            <Col gap={theme.space.xxs}>
              <Text variant="h5">Other Resumes</Text>
              {allOtherResumes.map((resume) => (
                <ResumeLinkWithSummaryCard
                  key={resume.resumeId}
                  resume={resume}
                  showGenerateResumeButton
                  source="application_results"
                />
              ))}
            </Col>
          )}
        </>
      )}
      <div>
        <Button
          variant={ButtonVariant.OUTLINED}
          onClick={() => setShowResumeUploadModal(true)}
        >
          Upload New Resume For Worker
        </Button>

        <WorkerResumeUploadModal
          showModal={showResumeUploadModal}
          onClose={() => setShowResumeUploadModal(false)}
          workerId={workerId ?? ''}
          trackEvent={() => {
            trackAnalytics(
              'Ops Uploaded New Resume For Worker From Application Results',
              {
                workerId: workerId ?? '',
              },
            )
          }}
        />
      </div>
    </Col>
  )
}
