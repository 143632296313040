import {
  DEFAULT_LANGUAGE_CONTENT,
  LanguageContentMap,
  LanguageContentObj,
  QUALIFICATION_QUESTION_TYPE_TO_TEXT,
  QualificationQuestionAnswerQuestionType,
  QUESTION_TYPES_REQUIRING_ANSWER_OPTIONS,
  QUESTION_TYPES_REQUIRING_MORE_THAN_ONE_ANSWER_OPTION,
  SUPPORTED_LANGUAGES,
  SupportedLanguage,
} from '@traba/types'

export function convertLanguageContentObjArrayToLanguageContentMap(
  languageContentObjArray?: LanguageContentObj[],
): LanguageContentMap {
  if (!languageContentObjArray) {
    return DEFAULT_LANGUAGE_CONTENT
  }

  return SUPPORTED_LANGUAGES.reduce(
    (acc, language) => {
      const item = languageContentObjArray?.find(
        (item) => item.language === language,
      )

      acc[language] = item?.content || ''

      return acc
    },
    {} as Record<string, string>,
  )
}

export function convertLanguageContentMapToLanguageContentObjArray(
  languageContentMap?: LanguageContentMap,
): LanguageContentObj[] {
  if (!languageContentMap) {
    return []
  }

  return SUPPORTED_LANGUAGES.reduce((acc, language) => {
    const content = languageContentMap[language] || ''
    acc.push({ language, content })
    return acc
  }, [] as LanguageContentObj[])
}

export function isContentMapTheSame(
  contentMap1?: LanguageContentMap,
  contentMap2?: LanguageContentMap,
): boolean {
  if (!contentMap1 && !contentMap2) {
    return true
  }

  if (!contentMap1 || !contentMap2) {
    return false
  }

  const contentMap1Keys: SupportedLanguage[] = Object.keys(
    contentMap1,
  ) as SupportedLanguage[]

  if (contentMap1Keys.length !== Object.keys(contentMap2).length) {
    return false
  }

  return contentMap1Keys.every((key) => contentMap1[key] === contentMap2[key])
}

export function validateRequiredLanguageContentMap(
  languageContentMap?: LanguageContentMap,
): boolean {
  return (
    !!languageContentMap &&
    SUPPORTED_LANGUAGES.length === Object.values(languageContentMap).length &&
    SUPPORTED_LANGUAGES.every(
      (language) => languageContentMap[language]?.trim() !== '',
    )
  )
}

export function validateOptionalLanguageContentMap(
  languageContentMap?: LanguageContentMap,
): boolean {
  if (!languageContentMap) {
    return true
  }

  return (
    SUPPORTED_LANGUAGES.length === Object.values(languageContentMap).length &&
    (SUPPORTED_LANGUAGES.every(
      (language) => languageContentMap[language]?.trim() !== '',
    ) ||
      SUPPORTED_LANGUAGES.every(
        (language) => languageContentMap[language]?.trim() === '',
      ))
  )
}

function getValidationForAnswerOptions({
  questionType,
  answerOptions,
}: {
  questionType?: QualificationQuestionAnswerQuestionType
  answerOptions?: { id: string; contentMap: LanguageContentMap }[]
}): { isValid: boolean; reason?: string } {
  if (!questionType) {
    return { isValid: false, reason: 'Question type is required' }
  }

  const requiredAnswerOptions =
    QUESTION_TYPES_REQUIRING_ANSWER_OPTIONS.includes(questionType)
  const moreThanOneAnswerOption =
    QUESTION_TYPES_REQUIRING_MORE_THAN_ONE_ANSWER_OPTION.includes(questionType)
  const questionTypeText = QUALIFICATION_QUESTION_TYPE_TO_TEXT[questionType]

  if (requiredAnswerOptions) {
    if (!answerOptions || answerOptions.length === 0) {
      return {
        isValid: false,
        reason: `${questionTypeText} question type requires answer options`,
      }
    }
    if (answerOptions.length === 1 && moreThanOneAnswerOption) {
      return {
        isValid: false,
        reason: `${questionTypeText} question type requires more than one answer option`,
      }
    }

    if (
      !answerOptions.every((answerOption) =>
        validateRequiredLanguageContentMap(answerOption.contentMap),
      )
    ) {
      return {
        isValid: false,
        reason:
          'Please provide English & Spanish translations for all answer options or remove the answer options',
      }
    }

    return { isValid: true }
  }

  return { isValid: true }
}

export function getValidationForQualifierQuestionAnswerInputs({
  questionType,
  questionTitle,
  questionDescription,
  answerOptions,
}: {
  questionType?: QualificationQuestionAnswerQuestionType
  questionTitle?: LanguageContentMap
  questionDescription?: LanguageContentMap
  answerOptions?: { id: string; contentMap: LanguageContentMap }[]
}): { isValid: boolean; reason?: string } {
  if (!questionType) {
    return { isValid: false, reason: 'Question type is required' }
  }

  if (!validateRequiredLanguageContentMap(questionTitle)) {
    return {
      isValid: false,
      reason: 'English & Spanish translations are required for question title',
    }
  }

  if (!validateOptionalLanguageContentMap(questionDescription)) {
    return {
      isValid: false,
      reason:
        'Either include both English & Spanish translations or remove both for question subtitle',
    }
  }

  return getValidationForAnswerOptions({
    questionType,
    answerOptions,
  })
}

export function getEnglishContent(languageContentObj?: LanguageContentObj[]) {
  return languageContentObj?.find((content) => content.language === 'en')
    ?.content
}

export function getSpanishContent(languageContentObj?: LanguageContentObj[]) {
  return languageContentObj?.find((content) => content.language === 'es')
    ?.content
}

export function isAnswerOptionSelected(
  answerOptions?: LanguageContentObj[],
  answerResponseItems?: string[],
) {
  return answerOptions?.some((answerOption) =>
    answerResponseItems?.includes(answerOption.content),
  )
}
