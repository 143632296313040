import {
  doesWorkerAccountExistForWorkerApplication,
  WorkerApplicationItemStatus,
  WorkerApplicationWithApplicant,
} from '@traba/types'
import { WorkerApplicationStatus } from '@traba/types'
import { TierLevel } from '@traba/types'
import { ApplicationEntitySearchResult } from 'src/hooks/useApplications'

export const extractEntityNames = (
  entities?: ApplicationEntitySearchResult[],
): {
  employerName: string
  roleName: string
  shiftRole: string
  regionIds: string[]
  shiftRequestParentTitle?: string
  shiftRequestParentId?: string
  scheduleId?: string
  nextShiftId?: string
  companyId?: string
} => {
  const defaultNames = {
    employerName: '',
    roleName: '',
    shiftRole: '',
    regionIds: [''],
    shiftRequestParentTitle: '',
    shiftRequestParentId: '',
    scheduleId: '',
    nextShiftId: '',
    companyId: '',
  }
  if (!entities) {
    return defaultNames
  }

  return entities.reduce((acc, entity) => {
    return {
      employerName: entity.employerName || acc.employerName,
      roleName: entity.roleName || acc.roleName,
      shiftRole: entity.shiftRole || acc.shiftRole,
      regionIds: entity.regionIds || acc.regionIds,
      shiftRequestParentTitle:
        entity.shiftRequestParentTitle || acc.shiftRequestParentTitle,
      shiftRequestParentId:
        entity.shiftRequestParentId || acc.shiftRequestParentId,
      scheduleId: entity.scheduleId || acc.scheduleId,
      nextShiftId: entity.nextShiftId || acc.nextShiftId,
      companyId: entity.companyId || acc.companyId,
    }
  }, defaultNames)
}

const workerTierFilter = (
  workerTier: TierLevel | '',
  workerApplication: WorkerApplicationWithApplicant,
) => {
  if (workerTier === '') {
    return true
  } else if (doesWorkerAccountExistForWorkerApplication(workerApplication)) {
    return workerApplication.workerInfo.tierLevel === workerTier
  }
  return false
}

const workerSearchFilter = (
  workerSearchKey: string | '',
  workerApplication: WorkerApplicationWithApplicant,
) => {
  if (workerSearchKey === '') {
    return true
  }
  const searchableFields = doesWorkerAccountExistForWorkerApplication(
    workerApplication,
  )
    ? [
        workerApplication.workerId,
        `${workerApplication.workerInfo.firstName} ${workerApplication.workerInfo.lastName}`,
      ]
    : [
        workerApplication.ghostProfileInfo.id,
        `${workerApplication.ghostProfileInfo.firstName} ${workerApplication.ghostProfileInfo.lastName}`,
      ]

  return searchableFields.some((field) =>
    field.toLowerCase().includes(workerSearchKey.toLowerCase()),
  )
}

const applicationStatusFilter = (
  applicationStatus: WorkerApplicationStatus | '',
  workerApplication: WorkerApplicationWithApplicant,
) => {
  if (applicationStatus === '') {
    return true
  }
  return workerApplication.status === applicationStatus
}

const stepsCompleteFilter = (
  completedStepId: string | '',
  workerApplication: WorkerApplicationWithApplicant,
) => {
  if (completedStepId === '') {
    return true
  }

  const isStepCompleted = workerApplication.workerApplicationItems.some(
    (item) => {
      return (
        item.applicationItem.id === completedStepId &&
        item.status === WorkerApplicationItemStatus.COMPLETED
      )
    },
  )
  return isStepCompleted
}

export const filterWorkerApplications = (
  workerApplications: WorkerApplicationWithApplicant[],
  workerTier: TierLevel | '',
  workerSearchKey: string | '',
  applicationStatus: WorkerApplicationStatus | '',
  completedStepId: string | '',
) => {
  return workerApplications.filter((workerApplication) => {
    return (
      workerTierFilter(workerTier, workerApplication) &&
      workerSearchFilter(workerSearchKey, workerApplication) &&
      applicationStatusFilter(applicationStatus, workerApplication) &&
      stepsCompleteFilter(completedStepId, workerApplication)
    )
  })
}
