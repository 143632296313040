import { theme } from '@traba/theme'
import { ShiftRequest } from '@traba/types'
import {
  getMinDateForScheduleChangeDatePicker,
  combineTwoDatesForDateAndTime,
  dateMinFromArray,
} from '@traba/utils'
import { scheduleOccurrenceMapDaysProvider } from '@traba/utils'
import { Card, MultiDatePicker, Text } from '../base-components'

interface ScheduleOccurrenceSelectorProps {
  selectedDate?: Date | null
  onSelectDate: (date: Date) => void
  shiftRequest: ShiftRequest
  minimal?: boolean
  topStart?: boolean
}

export function ScheduleOccurrenceSelector(
  props: ScheduleOccurrenceSelectorProps,
) {
  const { selectedDate, onSelectDate, shiftRequest, minimal, topStart } = props

  const minDate = getMinDateForScheduleChangeDatePicker(
    combineTwoDatesForDateAndTime(
      new Date(),
      dateMinFromArray(shiftRequest.schedules.map((s) => s.startTime)),
    ),
  )

  if (minimal) {
    return (
      <MultiDatePicker
        calendarPosition={topStart ? 'top-start' : undefined}
        minDate={minDate}
        selectedDates={selectedDate ? [selectedDate] : null}
        onSelectDates={(dates: Date[]) => {
          const d = dates[0]
          const newDate = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            minDate.getHours(),
            minDate.getMinutes(),
            minDate.getSeconds(),
          )
          onSelectDate(newDate)
        }}
        multiple={false}
        mapDays={scheduleOccurrenceMapDaysProvider(shiftRequest)}
      />
    )
  }

  return (
    <Card style={{ marginBottom: theme.space.sm }}>
      <Text variant="h5">Select when your changes should take effect.</Text>
      <Text variant="body2" mb={theme.space.xs}>
        For example, if you want changes to take effect starting June 30th
        onward, select June 30th
      </Text>

      <MultiDatePicker
        minDate={minDate}
        selectedDates={selectedDate ? [selectedDate] : null}
        onSelectDates={(dates: Date[]) => {
          const d = dates[0]
          const newDate = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            minDate.getHours(),
            minDate.getMinutes(),
            minDate.getSeconds(),
          )
          onSelectDate(newDate)
        }}
        multiple={false}
        mapDays={scheduleOccurrenceMapDaysProvider(shiftRequest)}
      />
    </Card>
  )
}
