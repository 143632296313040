/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Anchor,
  Badge,
  BadgeVariant,
  Button,
  ButtonVariant,
  Col,
  LoadingSpinner,
  Row,
  SearchSelect,
  Td,
  Text,
  Tr,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { APPLICATION_ITEM_TYPE_TO_TITLE } from '@traba/types'
import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon, Table } from 'src/components/base'
import Pagination from 'src/components/base/Pagination/Pagination'
import { IMenuItem } from 'src/components/base/Select/Select'
import { RegionCellContent } from 'src/components/CollapsibleShiftRow/components/RegionCell'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import {
  ApplicationSearchResult,
  useApplicationsSearch,
} from 'src/hooks/useApplications'
import { useCompanies } from 'src/hooks/useCompanies'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import ApplicationsDotMenu from './ApplicationsDotMenu'
import { extractEntityNames } from './utils/applicationUtils'

// TODO(ENG-13901): add Status header for enabled/disabled
const TABLE_HEADERS = [
  'Created On',
  'Company',
  'Role',
  'Shift Name',
  'Region ID',
  'Steps',
  'Applicants',
  'View',
  'Action',
]

export const ApplicationsScreen: React.FC = () => {
  const navigate = useNavigate()
  const userTz = useTimezonedDates()
  const { companies } = useCompanies({ isApproved: true })

  const companyOptions = useMemo<IMenuItem[]>(
    () => [
      {
        label: 'All Companies',
        value: '',
      },
      ...(companies
        ?.filter((company) => !!company.id)
        .map((company) => ({
          label: company.employerName,
          value: company.id,
        })) || []),
    ],
    [companies],
  )

  const [company, setCompany] = useState<IMenuItem | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    setCurrentPage(1)
  }, [company])
  const PAGE_SIZE = 25

  const { searchResults, isLoading } = useApplicationsSearch({
    companyId: company?.value,
    page: currentPage,
    pageSize: PAGE_SIZE,
  })

  const applications = useMemo<ApplicationSearchResult[]>(() => {
    return searchResults?.items || []
  }, [searchResults?.items])
  const totalApplications =
    searchResults?.pageCount !== undefined &&
    searchResults?.page === searchResults?.pageCount
      ? (searchResults.pageCount - 1) * PAGE_SIZE + applications.length
      : (searchResults?.pageCount || 0) * PAGE_SIZE

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  return (
    <MainLayout title="Applications">
      <Col p={theme.space.lg} style={{ height: '100%' }}>
        <Text variant="h2" mb={theme.space.lg}>
          Applications
        </Text>

        <Row
          mb={theme.space.sm}
          style={{
            width: '100%',
            gap: theme.space.sm,
            flexWrap: 'nowrap',
            alignItems: 'center',
          }}
          justifyBetween
          mt={theme.space.med}
        >
          <SearchSelect
            options={companyOptions}
            selectItem={company}
            handleSelect={setCompany}
            label="Company"
            width={260}
          />
          {/* TODO(ENG-13830) actually search on roles, shift name, and region */}
        </Row>

        <Col
          style={{
            flex: 1,
            border: `1px solid ${theme.colors.Grey10}`,
            borderRadius: '16px 16px 0 0',
            overflow: 'hidden',
          }}
        >
          {/* TODO(ENG-13801): fix table header bottom border radius */}
          <Table headers={TABLE_HEADERS}>
            {applications && applications.length > 0 && !isLoading ? (
              applications.map((application, index) => {
                const entityNames = extractEntityNames(
                  application.applicationEntities,
                )
                return (
                  <Tr key={application.id || index}>
                    <Td>{`${userTz.getDate(application.createdAt)}`}</Td>
                    <Td>{entityNames.employerName}</Td>
                    <Td>{entityNames.roleName}</Td>
                    <Td>{getShiftInfoCell(entityNames)}</Td>
                    <Td>
                      {entityNames.regionIds.map((regionId) => (
                        <div
                          key={regionId}
                          style={{ margin: theme.space.xxxs }}
                        >
                          <RegionCellContent regionId={regionId} />
                        </div>
                      ))}
                    </Td>
                    <Td>
                      {application.applicationItems?.map((item, index) => (
                        <div key={item.id || index}>
                          {
                            APPLICATION_ITEM_TYPE_TO_TITLE[
                              item.applicationItemType
                            ]
                          }
                        </div>
                      ))}
                    </Td>
                    <Td>{application.applicantCount}</Td>
                    <Td>
                      <Button
                        slim
                        variant={ButtonVariant.FILLED}
                        onClick={() =>
                          navigate(`/applications/${application.id}`)
                        }
                      >
                        <Text variant="body2" color={theme.colors.White}>
                          View
                        </Text>
                      </Button>
                    </Td>
                    <Td>
                      <Row justifyEnd>
                        <ApplicationsDotMenu application={application} />
                      </Row>
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <Tr>
                <Td
                  colSpan={TABLE_HEADERS.length}
                  style={{ textAlign: 'center', padding: theme.space.xl }}
                >
                  <Text variant="body1" color={theme.colors.Grey50}>
                    {isLoading ? <LoadingSpinner /> : 'No applications found'}
                  </Text>
                </Td>
              </Tr>
            )}
          </Table>
          {totalApplications > 0 && (
            <Row justifyEnd p={theme.space.sm}>
              <Pagination
                page={currentPage - 1}
                pageSize={PAGE_SIZE}
                onPageLeft={goToPreviousPage}
                onPageRight={goToNextPage}
                dataSize={applications ? applications.length : 0}
                totalFound={totalApplications}
                disabled={isLoading}
              />
            </Row>
          )}
        </Col>
      </Col>
    </MainLayout>
  )
}

const getShiftInfoCell = (
  entityNames: ReturnType<typeof extractEntityNames>,
) => {
  if (entityNames.shiftRequestParentTitle && entityNames.shiftRequestParentId) {
    return (
      <Anchor href={`/schedule/${entityNames.shiftRequestParentId}`}>
        {entityNames.shiftRequestParentTitle}
      </Anchor>
    )
  } else if (entityNames.nextShiftId) {
    return (
      <Anchor href={`/field-monitor/${entityNames.nextShiftId}`}>
        {'View Shift'}{' '}
        <Icon name="link" style={{ marginRight: theme.space.xxxs }} />
      </Anchor>
    )
  } else {
    return <Badge variant={BadgeVariant.INFO} title="NO SHIFT" />
  }
}
