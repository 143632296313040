import { WorkerShiftWithWorkerDetails } from './worker-shift'

export type AnalyticsTrackingFunction = (
  eventName: string,
  eventParams: Record<string, any>,
) => void
/**
 * Base interface for all analytics events.
 *
 * @interface BaseAnalyticsEvent
 * @property {Record<string, string>} ids - The ids of the event. What primary and foreign keys are connected to this event?
 * @property {Record<string, any>} properties - The properties of the event. Must be in snake_case.
 */
export interface BaseAnalyticsEvent {
  ids: Record<string, string>
  properties: Record<string, any>
}

export const BUSINESS_CLOCKED_IN_WORKERS =
  'Business Clocked In Workers' as const
export interface BusinessClockedInWorkers extends BaseAnalyticsEvent {
  ids: {
    workerId: string
    shiftId: string
  }
  properties: {
    inline: boolean
    workerShift: Array<WorkerShiftWithWorkerDetails>
    clockInTime: Date
  }
}

export const OPS_USER_DUPLICATED_SHIFT = 'Ops User Duplicated Shift' as const
export interface OpsUserDuplicatedShift extends BaseAnalyticsEvent {
  ids: {
    shiftId: string
  }
  properties: {
    initiatedBy: string
  }
}

export const USER_UPDATED_SLOTS_REQUESTED =
  'User Updated Slots Requested' as const
export interface UserUpdatedSlotsRequested extends BaseAnalyticsEvent {
  ids: {
    userId?: string
    companyId?: string
  }
  properties: {
    slotsRequested: number
    isEdit?: boolean
  }
}

export const USER_UPDATED_UNITS_REQUESTED =
  'User Updated Units Requested' as const
export interface UserUpdatedUnitsRequested extends BaseAnalyticsEvent {
  ids: {
    userId?: string
    companyId?: string
  }
  properties: {
    unitsRequested?: number
    isEdit?: boolean
  }
}

export type AnalyticsEvent =
  | BusinessClockedInWorkers
  | OpsUserDuplicatedShift
  | UserUpdatedSlotsRequested
  | UserUpdatedUnitsRequested
