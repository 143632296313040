import { CircularProgress } from '@mui/material'
import { useStatsigClient } from '@statsig/react-bindings'
import { Text, BadgeVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Company, ExtendedShift, InvoiceStatus } from '@traba/types'
import { getEarlyArrivalTimeBufferInMinutes } from '@traba/utils'
import { useState } from 'react'
import {
  Button,
  Badge,
  CopyTextIcon,
  Link,
  Icon,
  Row,
  ShiftStatusBadge,
  CompanyStatusBadge,
} from 'src/components/base'
import { useUserContext } from 'src/context/user/UserContext'
import { usePods } from 'src/hooks/usePods'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useWorkerShifts } from 'src/hooks/useWorkerShifts'
import { getShiftInfoString, getTagEmojiString } from 'src/utils/shiftUtils'
import {
  getAddressString,
  getShortUnitDescription,
  truncateString,
} from 'src/utils/stringUtils'
import { EmploymentTypeBadge } from '../base/Badge/EmploymentTypeBadge'
import { ButtonVariant } from '../base/Button/types'
import { EarlyArrivalBufferBadge } from '../CollapsibleShiftRow/components/EarlyArrivalBufferBadge'
import { CompanyLogo } from '../CompanyLogo/CompanyLogo'
import { CompanyNoteDrawer } from '../CompanyNoteDrawer'
import { CompanyHeaderWrapper } from './CompanyHeader.styles'

export interface CompanyHeaderProps {
  shift: ExtendedShift
  company: Company
}

export const CompanyHeader = (props: CompanyHeaderProps) => {
  const { company, shift } = props
  const { state } = useUserContext()
  const [companyNotesOpen, setCompanyNotesOpen] = useState<boolean>(false)
  const tz = useTimezonedDates(shift.timezone)
  const { podTags } = usePods({
    companyId: shift.companyId,
    regionId: shift.regionId,
  })
  const { client } = useStatsigClient()
  const isPodTagMigrationEnabled = client.checkGate('pod_tag_migration')

  const dateString = tz.getShiftDate(shift.startTime, shift.endTime)
  const timeString = tz.getShiftTime(shift.startTime, shift.endTime)
  const addressString = getAddressString(shift.location.address)
  const { workerShifts = [], isLoading: workerShiftsLoading } = useWorkerShifts(
    shift?.id,
  )
  const shiftInvoicingIssueBadge = () => {
    const invoiceInProgress = !(
      shift.invoiceStatus &&
      [InvoiceStatus.OPEN, InvoiceStatus.PAID].includes(shift.invoiceStatus)
    )
    if (!shift.invoiceRequiresManualAdjustment || !invoiceInProgress) {
      return
    }

    return (
      <Row ml={36} mt={25}>
        <Badge
          title="requires invoice adjustment"
          variant={BadgeVariant.DANGER}
        />
      </Row>
    )
  }

  /*
   * Determine if shift has a buffer
   */
  const earlyArrivalBuffer = shift
    ? getEarlyArrivalTimeBufferInMinutes({
        shiftStartTime: shift.startTime,
        businessStartTime: shift.businessStartTime,
      })
    : undefined

  return (
    <>
      <CompanyHeaderWrapper email={state.userProfile?.email || ''}>
        <Row pt={150} alignCenter>
          <Link to={`/companies/${shift.companyId}`} target="_blank">
            <CompanyLogo
              style={{
                fontSize: '40px',
              }}
              companyName={shift.employerName}
              companyLogoUrl={shift.companyLogo}
            />
          </Link>
          <Row flexCol ml={24} mt={25}>
            <Text variant="h4">
              {shift.shiftRole}{' '}
              <Text variant="brand">
                (${shift.payRate}/{getShortUnitDescription(shift.payType)})
              </Text>
            </Text>
            <Row>
              <Link to={`/companies/${shift.companyId}`} target="_blank">
                <Button
                  style={{ padding: 0, marginRight: theme.space.xxs }}
                  variant={ButtonVariant.TEXT}
                >
                  {shift.employerName}
                </Button>
              </Link>
              <CompanyStatusBadge isApproved={company.isApproved} />
              <Text variant="caption" style={{ marginLeft: theme.space.xsmed }}>
                {truncateString(shift.companyId)}
                <CopyTextIcon textToCopy={shift.companyId} />
              </Text>
              <Button
                variant={ButtonVariant.OUTLINED}
                style={{
                  padding: theme.space.xxs,
                  height: theme.space.sm,
                  marginLeft: theme.space.xxs,
                }}
                onClick={() => setCompanyNotesOpen(!companyNotesOpen)}
              >
                <Row center>
                  <Icon name="info" />
                  <Text variant="caption">Notes</Text>
                </Row>
              </Button>
            </Row>
          </Row>
          <Row flexCol ml={36} mt={25}>
            <Text
              variant="body1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icon
                name="calendar_active"
                style={{
                  height: '17px',
                  marginLeft: theme.space.us,
                  marginRight: theme.space.xms,
                }}
              />
              {`${dateString},  ${timeString}`}
            </Text>
            <Text
              variant="body1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icon
                name="location"
                style={{ height: '20px', marginRight: theme.space.xxxs }}
              />
              <Link
                to={`https://www.google.com/maps/place/${addressString}`}
                target="_blank"
              >
                <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                  {addressString}
                </Button>
              </Link>
            </Text>
          </Row>
          <Row ml={36} mt={25} gap={theme.space.xxs}>
            <ShiftStatusBadge shiftStatus={shift.status} />
            {!!shift.shiftEmploymentType && (
              <EmploymentTypeBadge
                employmentTypes={[shift.shiftEmploymentType]}
              />
            )}
            {!!earlyArrivalBuffer && (
              <EarlyArrivalBufferBadge buffer={earlyArrivalBuffer} />
            )}
            {workerShiftsLoading ? (
              <CircularProgress size={theme.space.sm} />
            ) : (
              <Text variant="caption" style={{ marginLeft: theme.space.xsmed }}>
                {'Copy Shift Info'}
                <CopyTextIcon
                  textToCopy={getShiftInfoString(shift, workerShifts)}
                />
              </Text>
            )}
          </Row>
          {shiftInvoicingIssueBadge()}
          <Row ml={36} mt={22}>
            {getTagEmojiString({
              tags: shift.tags || [],
              pods: podTags?.map((tag) => tag.name),
              useLegacyPodEmojis: !isPodTagMigrationEnabled,
            })}
          </Row>
        </Row>
      </CompanyHeaderWrapper>
      <CompanyNoteDrawer
        companyId={shift.companyId}
        isOpen={companyNotesOpen}
        setIsOpen={setCompanyNotesOpen}
        hideFAB
      />
    </>
  )
}
