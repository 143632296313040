import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { AcceptShiftBypassSetting } from '@traba/types'
import { GenericAcceptShiftBypasses, Shift } from '@traba/types'
import { EligibilityCriteria, WorkerShiftEligibility } from '@traba/types'
import { useEffect, useState } from 'react'
import { Icon, Row } from 'src/components/base'
import { useManyGeofences } from 'src/hooks/useGeofences'
import {
  checkShouldShowRecurringSlotBypass,
  checkShouldShowRecurringWorkerBypass,
  criteriaMapper,
  getUniqueCriteria,
} from 'src/utils/workerEligibilityUtils'
import { BypassCheckboxItem } from './BypassCheckboxItem'
import { BypassItem } from './BypassItem'

export type AcceptShiftBypassProps = {
  shifts: Shift[]
  bypasses: GenericAcceptShiftBypasses
  setBypasses: React.Dispatch<React.SetStateAction<GenericAcceptShiftBypasses>>
  eligibilityData?: WorkerShiftEligibility[]
  acceptShiftBypassSettings: AcceptShiftBypassSetting[]
  hasRequiredMultiShift?: boolean
  showPaidBackupsBypass?: boolean
}

export default function AcceptShiftBypass({
  shifts,
  bypasses,
  setBypasses,
  eligibilityData,
  acceptShiftBypassSettings,
  hasRequiredMultiShift,
  showPaidBackupsBypass,
}: AcceptShiftBypassProps) {
  const [isInitialBypassesSet, setIsInitialBypassesSet] = useState(false)
  const missingCriteria = getUniqueCriteria(eligibilityData || [])
  const { shiftAreaFences } = useManyGeofences(
    shifts.map((shift) => shift.locationId),
  )
  const missingGeofence = shiftAreaFences.some((fence) => !fence)

  // Set the initial bypasses when the component mounts
  useEffect(() => {
    if (!isInitialBypassesSet) {
      const defaultBypasses = missingCriteria.reduce((bypasses, criteria) => {
        const bypassKey = criteriaMapper[criteria]?.bypassKey
        if (bypassKey) {
          bypasses[bypassKey] = false
        }
        return bypasses
      }, {} as GenericAcceptShiftBypasses)
      setBypasses(defaultBypasses)
      setIsInitialBypassesSet(true)
    }
  }, [setBypasses, missingCriteria, isInitialBypassesSet])

  // Logic to allow overbook
  if (showPaidBackupsBypass) {
    // we do not need to override overbooks for paid backups
    const index = missingCriteria.indexOf('shiftIsNotFull')
    if (index !== -1) {
      missingCriteria.splice(index, 1)
    }
  } else if (shifts.length === 1) {
    const { slotsFilled, slotsRequested, overbookSlotsRequested } = shifts[0]
    const actualSlotsRequested = overbookSlotsRequested || slotsRequested
    const slotsRemaining = Math.max(actualSlotsRequested - slotsFilled, 0)
    if (
      eligibilityData &&
      eligibilityData.length > slotsRemaining &&
      missingCriteria.indexOf('shiftIsNotFull') < 0
    ) {
      missingCriteria.push('shiftIsNotFull')
    }
  } else if (missingCriteria.indexOf('shiftIsNotFull') < 0) {
    missingCriteria.push('shiftIsNotFull')
  }

  const requiredBypasses = missingCriteria?.map((criteriaKey) => (
    <Row mb={theme.space.xxs}>
      <BypassItem
        bypasses={bypasses}
        setBypasses={setBypasses}
        criteria={criteriaKey as keyof EligibilityCriteria}
        acceptShiftBypassSettings={acceptShiftBypassSettings}
      />
    </Row>
  ))

  /** Required multi shift checks */
  if (
    shifts.some((shift) => shift.isInRequiredMultiShift) ||
    hasRequiredMultiShift
  ) {
    requiredBypasses.push(
      <BypassCheckboxItem
        checked={bypasses.bypassRequiredMultiShift}
        label={'Bypass Required Multi Shift'}
        onChange={(e) => {
          setBypasses({
            ...bypasses,
            bypassRequiredMultiShift: e.target.checked,
          })
        }}
      />,
    )
  }

  /** Paid Backup Slots Bypass */
  if (
    showPaidBackupsBypass &&
    shifts.some(
      (shift) =>
        (shift.paidBackupSlotsFilled ?? 0) >=
        (shift.paidBackupSlotsRequested ?? 0),
    )
  ) {
    requiredBypasses.push(
      <BypassCheckboxItem
        checked={bypasses.bypassPaidBackupSlotsFull}
        label={'Bypass Paid Backup Slots Full'}
        onChange={(e) => {
          setBypasses({
            ...bypasses,
            bypassPaidBackupSlotsFull: e.target.checked,
          })
        }}
      />,
    )
  }

  if (showPaidBackupsBypass && missingGeofence) {
    requiredBypasses.push(
      <BypassCheckboxItem
        checked={bypasses.bypassPaidBackupGeofence}
        label={'Bypass Paid Backup Geofence'}
        onChange={(e) => {
          setBypasses({
            ...bypasses,
            bypassPaidBackupGeofence: e.target.checked,
          })
        }}
      />,
    )
  }

  if (checkShouldShowRecurringSlotBypass(eligibilityData)) {
    requiredBypasses.push(
      <BypassCheckboxItem
        checked={bypasses.bypassRecurringSlotAvailable}
        label={'Bypass recurring schedule is full'}
        onChange={(e) => {
          setBypasses({
            ...bypasses,
            bypassRecurringSlotAvailable: e.target.checked,
          })
        }}
      />,
    )
  }

  if (checkShouldShowRecurringWorkerBypass(eligibilityData)) {
    requiredBypasses.push(
      <BypassCheckboxItem
        checked={bypasses.bypassRecurringWorkerIsAvailable}
        label={'Bypass worker has conflict with schedule'}
        onChange={(e) => {
          setBypasses({
            ...bypasses,
            bypassRecurringWorkerIsAvailable: e.target.checked,
          })
        }}
        shouldWarnOnBypass={true}
      />,
    )
  }

  return (
    <Row flexCol alignStart mt={theme.space.xs} mb={theme.space.xs}>
      <div>
        {requiredBypasses.length > 0 ? (
          requiredBypasses
        ) : (
          <Row>
            <Icon name="greenCheck" />
            <Text ml={theme.space.xxxs} variant="h6">
              Workers are eligible for shift
            </Text>
          </Row>
        )}
      </div>
    </Row>
  )
}
