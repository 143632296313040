import { DotMenu } from '@traba/react-components'
import { theme } from '@traba/theme'
import { useNavigate } from 'react-router-dom'
import { ApplicationSearchResult } from 'src/hooks/useApplications'
export default function ApplicationsDotMenu({
  application,
}: {
  application: ApplicationSearchResult
}) {
  const navigate = useNavigate()
  return (
    <DotMenu
      style={{ maxWidth: '36px', maxHeight: '36px' }}
      type="application-actions"
      dotMenuKey={application.id}
      menuItems={[
        {
          hidden: false,
          title: 'Edit',
          onClick: () => {
            navigate(`/applications/${application.id}`)
          },
          color: theme.colors.brand,
          iconName: 'edit',
        },
        {
          hidden: true, // TODO(ENG-13787): duplicate application
          title: 'Duplicate',
          onClick: () => {
            console.log('duplicate application')
          },
          color: theme.colors.brand,
          iconName: 'copy',
        },
        {
          hidden: true, // TODO (ENG-13906)
          title: 'Disable',
          onClick: () => {
            console.log('disable application')
          },
          color: theme.colors.Red60,
          iconName: 'circleX',
        },
      ]}
    />
  )
}
