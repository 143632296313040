import { ForwardFillMax, PaymentType, ShiftSignupStatus } from '@traba/types'
import { GenderPreference, KioskModeType } from '@traba/types'
import { TierLevel } from '@traba/types'

export const MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB = 5

export const genderPreferenceOptions = [
  { value: GenderPreference.Female, label: 'Female' },
  { value: GenderPreference.Male, label: 'Male' },
]

export const minimumTierOptions = [
  { value: TierLevel.TIER_ONE, label: 'Tier One' },
  { value: TierLevel.TIER_TWO, label: 'Tier Two' },
  { value: TierLevel.TIER_THREE, label: 'Tier Three' },
  { value: TierLevel.UNPROVEN, label: 'Unproven' },
  { value: TierLevel.TIER_FOUR, label: 'Tier Four' },
  { value: TierLevel.TIER_FIVE, label: 'Tier Five' },
]

export const signupStatusOptions = Object.values(ShiftSignupStatus).map(
  (value) => {
    return { value: value, label: value }
  },
)

export const forwardFillTypeOptions = Object.values(ForwardFillMax).map(
  (value) => {
    return { value: value, label: value }
  },
)

export const kioskModeTypeOptions = [
  { value: KioskModeType.None, label: 'None' },
  { value: KioskModeType.QrOnly, label: 'QR Only' },
  { value: KioskModeType.PhoneNumberOnly, label: 'Phone Number Only' },
  { value: KioskModeType.SupervisorOnly, label: 'Supervisor Only' },
  {
    value: KioskModeType.ExternalTimekeepingOnly,
    label: 'External Timekeeping Only',
  },
]

export const paymentTypeOptions = Object.values(PaymentType).map((value) => ({
  value,
  label: value,
}))

export function validateOverbook(
  slotsRequested: number,
  overbookSlotsRequested?: number,
) {
  // There is no overbook, which is a valid overbook state.
  if (overbookSlotsRequested === undefined) {
    return true
  }

  if (overbookSlotsRequested === 0 || overbookSlotsRequested < slotsRequested) {
    return false
  }

  return true
}

export function validateSlots(
  slotsRequested: number,
  overbookSlotsRequested?: number,
): boolean {
  if (!validateOverbook(slotsRequested, overbookSlotsRequested)) {
    return false
  }

  return slotsRequested > 0
}

export function validateDynamicOverbookAllowed(
  slotsRequested: number,
  enableDynamicOverbooking: boolean,
) {
  if (
    enableDynamicOverbooking &&
    slotsRequested < MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB
  ) {
    return false
  }
  return true
}

export function disabledMessageForDynamicOverbook(
  slotsRequested: number,
  enableDynamicOverbooking: boolean,
) {
  if (
    enableDynamicOverbooking &&
    slotsRequested < MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB
  ) {
    return `Dynamic overbooking is not allowed for shifts with less than ${MIN_SLOTS_REQUESTED_REQUIRED_FOR_DYNAMIC_OB} slots requested`
  }
  return undefined
}

export function validateClockCodeLength(code: number) {
  return code.toString().length == 4
}
