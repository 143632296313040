import { Button, Col, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import styled from 'styled-components'

export const StyledButton = styled(Button).attrs({
  slim: true,
})`
  width: 200px;
`

export const StyledApplicationStepContainer = styled(Col).attrs({
  p: theme.space.sm,
  gap: theme.space.xs,
})`
  border: ${({ theme }) => `1px solid ${theme.colors.Grey20}`};
  border-radius: 8px;
`

export const StyledAnswerText = styled(Text).attrs({
  variant: 'body1',
})<{ isSelected?: boolean }>`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.MidnightBlue : theme.colors.Grey40};
  font-size: ${({ isSelected }) => (isSelected ? '18px' : '14px')};
`

export const HelperTextContainer = styled(Row).attrs({
  gap: theme.space.xxs,
  alignCenter: true,
  p: theme.space.xs,
})`
  background-color: ${({ theme }) => theme.colors.Violet10};
  border-radius: 8px;
`
