import { Col, Input, Row, SearchSelect } from '@traba/react-components'
import { theme } from '@traba/theme'
import { TierLevel } from '@traba/types'
import {
  WorkerApplicationStatus,
  WorkerApplicationWithApplicant,
} from '@traba/types'
import { useEffect, useState } from 'react'
import { useMemo } from 'react'
import { CUSTOM_STATUS_TO_TEXT } from 'src/components/base/Badge/WorkerApplicationStatusBadge'
import { ApplicationSearchResult } from 'src/hooks/useApplications'
import { workerTierItems } from 'src/hooks/useRegions'
import { filterWorkerApplications } from './utils/applicationUtils'

const ALL_STEPS_FILTER = {
  label: 'All',
  value: '',
}
export const ApplicationTableFilters = ({
  application,
  unfilteredWorkerApplications,
  onFilteredApplicationsChange,
}: {
  application: ApplicationSearchResult
  unfilteredWorkerApplications: WorkerApplicationWithApplicant[]
  onFilteredApplicationsChange: (
    workerApplications: WorkerApplicationWithApplicant[],
  ) => void
}) => {
  const [applicationStatus, setApplicationStatus] = useState<
    WorkerApplicationStatus | ''
  >('')
  const [workerSearchKey, setWorkerSearchKey] = useState<string>('')

  const [completedStepId, setCompletedStepId] = useState<string>('')
  const [workerTier, setWorkerTier] = useState<TierLevel | ''>('')
  const applicationMenuOptions = useMemo(
    () => [
      ALL_STEPS_FILTER,
      ...(application?.applicationItems.map((item) => ({
        label: item.applicationItemType,
        value: item.id,
      })) || []),
    ],
    [application],
  )

  const completedStepSelectItem = useMemo(() => {
    const step = application?.applicationItems.find(
      (item) => item.id === completedStepId,
    )
    return step
      ? {
          label: step.applicationItemType,
          value: completedStepId,
        }
      : ALL_STEPS_FILTER
  }, [application, completedStepId])

  const workerApplicationStatusOptions = useMemo(
    () => [
      {
        label: 'All',
        value: '',
      },
      ...Object.values(WorkerApplicationStatus).map((status) => ({
        label: CUSTOM_STATUS_TO_TEXT[status],
        value: status,
      })),
    ],
    [],
  )

  const workerApplications = useMemo(
    () =>
      filterWorkerApplications(
        unfilteredWorkerApplications || [],
        workerTier,
        workerSearchKey,
        applicationStatus,
        completedStepId,
      ),
    [
      unfilteredWorkerApplications,
      workerTier,
      workerSearchKey,
      applicationStatus,
      completedStepId,
    ],
  )
  useEffect(() => {
    if (onFilteredApplicationsChange) {
      onFilteredApplicationsChange(workerApplications)
    }
  }, [workerApplications, onFilteredApplicationsChange])

  return (
    <Row gap={theme.space.sm} mb={theme.space.sm} p={theme.space.sm}>
      <Col>
        <Input
          containerStyle={{ margin: 0 }}
          label="Search Workers"
          placeholder="Search workers"
          onChange={(e) => {
            setWorkerSearchKey(e.target.value)
          }}
          value={workerSearchKey}
        />
      </Col>
      <Col>
        <SearchSelect
          label="Worker Tier"
          options={[
            {
              label: 'All',
              value: '',
            },
            ...workerTierItems,
          ]}
          width={200}
          handleSelect={(item) => {
            setWorkerTier(item?.value as TierLevel | '')
          }}
          selectItem={{ label: workerTier, value: workerTier }}
        />
      </Col>
      <Col>
        <SearchSelect
          label="Steps Complete"
          options={applicationMenuOptions}
          width={200}
          handleSelect={(item) => {
            setCompletedStepId(item?.value as string | '')
          }}
          selectItem={completedStepSelectItem}
        />
      </Col>
      <Col style={{ marginRight: theme.space.sm }}>
        <SearchSelect
          label="Application Status"
          selectItem={{ label: applicationStatus, value: applicationStatus }}
          options={workerApplicationStatusOptions}
          handleSelect={(item) => {
            setApplicationStatus(item?.value as WorkerApplicationStatus | '')
          }}
          width={200}
        />
      </Col>
    </Row>
  )
}
