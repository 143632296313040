import { Tabs } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { TabPanel } from '@traba/react-components'
import { Text } from '@traba/react-components'
import { Shift } from '@traba/types'
import { useState } from 'react'
import * as S from './styles'
import { BugleStatisticsScreen } from './tabs/BugleStatisticsScreen'
import { NewBugleScreen } from './tabs/NewBugleScreen'
import { ScheduledBackfillCallsScreen } from './tabs/ScheduledBackfillCallsScreen'
import { ScheduledScreen } from './tabs/ScheduledScreen'

interface BugleDrawerProps {
  isOpen: boolean
  onClose: () => void
  shift: Shift
  defaultActionType?: BugleActionType
  preSelectedFields?: string[]
  sentinelNotificationToUserId?: string
}

export type CustomParams = 'custom' | 'backfill'

export enum BugleActionType {
  SMS = 'SMS',
  Push = 'PUSH',
  Invitation = 'INVITATION',
  BackfillCall = 'BACKFILL_CALL',
  Export = 'EXPORT',
  EligibilityGroup = 'ELIGIBILITY_GROUP',
}

const BugleDrawer = ({
  isOpen,
  onClose,
  shift,
  defaultActionType,
  preSelectedFields,
  sentinelNotificationToUserId,
}: BugleDrawerProps) => {
  const [currentTab, setCurrentTab] = useState(0)

  if (!isOpen) {
    return null
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <S.DrawerContainer>
        <S.TabSelector justifyBetween fullWidth alignCenter>
          <Tabs value={currentTab}>
            <S.Tab label="New Bugle" onClick={() => setCurrentTab(0)} />
            <S.Tab label="Scheduled" onClick={() => setCurrentTab(1)} />
            <S.Tab label="Backfill Calls" onClick={() => setCurrentTab(2)} />
            <S.Tab label="Statistics" onClick={() => setCurrentTab(3)} />
          </Tabs>
          <Text variant="body1">
            Shift <strong>{shift.shiftId}</strong>
          </Text>
        </S.TabSelector>
        <S.TabBody>
          <TabPanel value={currentTab} index={0}>
            <NewBugleScreen
              shift={shift}
              preSelectedFields={preSelectedFields}
              defaultActionType={defaultActionType}
              sentinelNotificationToUserId={sentinelNotificationToUserId}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <ScheduledScreen shift={shift} />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <ScheduledBackfillCallsScreen shift={shift} />
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <BugleStatisticsScreen shift={shift} />
          </TabPanel>
        </S.TabBody>
      </S.DrawerContainer>
    </Drawer>
  )
}

export default BugleDrawer
