import { captureException } from '@sentry/react'
import { trabaApi } from '@traba/api-utils'
import { EmploymentType, PendingAdjustment } from '@traba/types'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

export type GetPendingRequestResponseDTO = PendingAdjustment & {
  workerShift: {
    worker: {
      id: string
      firstName: string
      lastName: string
    }
    shift: {
      company: {
        id: string
        employerName: string
      }
      role: {
        roleName: string
      }
      regionId?: string
    }
  }
}

async function getPendingAdjustments(
  shiftEmploymentType: EmploymentType,
): Promise<GetPendingRequestResponseDTO[] | undefined> {
  try {
    const res = await trabaApi.get(
      `workers/pendingAdjustments?shiftEmploymentType=${shiftEmploymentType}`,
    )
    return res.data
  } catch (error: any) {
    console.error(
      'pendingAdjustments -> getCompanies() ERROR',
      error.message ?? error,
    )
    captureException(error)
    throw error
  }
}

export interface EditPendingAdjustmentParams {
  workerId: string
  shiftId: string
  action: 'approve' | 'decline'
  shouldAdjustPayment: boolean
  queryString: string
}

const editPendingAdjustment = async ({
  workerId,
  shiftId,
  action,
  shouldAdjustPayment,
  queryString,
}: EditPendingAdjustmentParams) => {
  const actionUrlSegment =
    action === 'approve'
      ? 'approve-pending-adjustment'
      : 'decline-pending-adjustment'
  const response = await trabaApi.post(
    `/workers/${workerId}/worker-shifts/${shiftId}/${actionUrlSegment}${
      shouldAdjustPayment ? `${queryString}` : ''
    }`,
  )
  return response.data
}

export function usePendingAdjustments(shiftEmploymentType: EmploymentType) {
  const {
    isLoading,
    isError,
    data: pendingAdjustments,
    error,
    isFetched,
    isFetching,
    refetch,
  } = useQuery<GetPendingRequestResponseDTO[] | undefined, Error>(
    `pendingAdjustments-${shiftEmploymentType}`,
    () => getPendingAdjustments(shiftEmploymentType),
    {
      staleTime: FIVE_MINUTES_IN_MS,
    },
  )

  const editPendingAdjustmentMutation = useMutation<
    any,
    AxiosError,
    EditPendingAdjustmentParams
  >(editPendingAdjustment)

  return {
    isLoading,
    isError,
    pendingAdjustments,
    error,
    isFetched,
    isFetching,
    refetch,
    editPendingAdjustment: editPendingAdjustmentMutation.mutateAsync,
  }
}
