import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import {
  ApplicationItem,
  QualificationQuestionAnswerQuestionType,
  LanguageContentObj,
} from '@traba/types'
import { captureSentryError } from '@traba/utils'

export const QUALIFIER_QUESTION_ANSWER_QUERY_KEY = 'qualifier-question-answer'
export const QUALIFIER_QUESTION_ANSWER_WORKER_APPLICATION_ITEM_QUERY_KEY =
  'qualifier-question-answer-worker-application-item'

export type QualifierQuestionAnswerResponse = {
  id: string
  questionType: QualificationQuestionAnswerQuestionType
  parsedQuestionTitle: LanguageContentObj[]
  parsedQuestionDescription?: LanguageContentObj[]
  parsedAnswerOptions?: LanguageContentObj[][]
  validatedAnswerOptions?: LanguageContentObj[][]
  applicationItem?: ApplicationItem
}

export type WorkerAnswerResponse = {
  id: string
  response: string[]
}

async function getQualifierQuestionAnswerById(
  qualifierQuestionAnswerId: string,
): Promise<QualifierQuestionAnswerResponse | undefined> {
  try {
    const res = await trabaApi.get(
      `/qualifier-question-answer/question/${qualifierQuestionAnswerId}`,
    )

    return res.data
  } catch (error) {
    console.error(
      'useQualifierQuestionAnswer -> getQualifierQuestionAnswer() ERROR',
      error,
    )
    captureSentryError(error, {
      tags: {
        action:
          'useQualifierQuestionAnswer -> getQualifierQuestionAnswer() ERROR',
      },
    })
    throw error
  }
}

export function useQualifierQuestionAnswerById(
  qualifierQuestionAnswerId: string,
) {
  const {
    isLoading,
    isError,
    data: qualifierQuestionAnswer,
    error,
    isFetched,
    refetch,
  } = useQuery<QualifierQuestionAnswerResponse | undefined, Error>({
    queryKey: [QUALIFIER_QUESTION_ANSWER_QUERY_KEY, qualifierQuestionAnswerId],
    queryFn: () => getQualifierQuestionAnswerById(qualifierQuestionAnswerId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!qualifierQuestionAnswerId,
  })

  return {
    isLoading,
    isError,
    qualifierQuestionAnswer,
    error,
    isFetched,
    refetch,
  }
}

export function useGetQuestionTypeForQualifierQuestionAnswer(
  qualifierQuestionAnswerId = '',
) {
  const { qualifierQuestionAnswer } = useQualifierQuestionAnswerById(
    qualifierQuestionAnswerId,
  )

  return qualifierQuestionAnswer?.questionType
}

async function getWorkerAnswersByWorkerApplicationItemId(
  workerApplicationItemId: string,
): Promise<WorkerAnswerResponse | undefined> {
  try {
    const res = await trabaApi.get(
      `/qualifier-question-answer/answers/worker-application-item/${workerApplicationItemId}`,
    )

    return res.data
  } catch (error) {
    console.error(
      'useWorkerAnswersByWorkerApplicationItemId -> getWorkerAnswersByWorkerApplicationItemId() ERROR',
      error,
    )
    captureSentryError(error, {
      tags: {
        action:
          'useWorkerAnswersByWorkerApplicationItemId -> getWorkerAnswersByWorkerApplicationItemId() ERROR',
      },
    })
    throw error
  }
}

export function useWorkerAnswersByWorkerApplicationItemId(
  workerApplicationItemId: string,
) {
  const {
    isLoading,
    isError,
    data: workerAnswers,
    error,
    isFetched,
    refetch,
  } = useQuery<WorkerAnswerResponse | undefined, Error>({
    queryKey: [
      QUALIFIER_QUESTION_ANSWER_WORKER_APPLICATION_ITEM_QUERY_KEY,
      workerApplicationItemId,
    ],
    queryFn: () =>
      getWorkerAnswersByWorkerApplicationItemId(workerApplicationItemId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!workerApplicationItemId,
  })

  return {
    isLoading,
    isError,
    workerAnswers,
    error,
    isFetched,
    refetch,
  }
}
