import { theme } from '@traba/theme'
import {
  AnalyticsTrackingFunction,
  COMPANY_WIDE_ID,
  COMPANY_WIDE_TEXT,
  IconName,
  InputStatus,
  User,
} from '@traba/types'
import {
  allMemberIdsForLocation,
  getLocationNameOrTruncatedAddress,
  getSupervisorsToReplaceAtLocation,
  getUserFullName,
  isBizMemberCompanyWide,
  sortMemberByFullName,
} from '@traba/utils'
import { useCallback, useMemo } from 'react'
import {
  SearchSelect,
  Col,
  IMenuItem,
  MenuItemGroup,
  Row,
  Text,
  SvgIcon,
  InputInfoCalloutRow,
} from '../base-components'
import { BadgeVariant } from '../base-components/Badge/Badge.styles'
import { ReplaceSupervisorsForSingleLocationSection } from '../BusinessMemberSettings/ReplaceSupervisorsForSingleLocationSection'

const ALL_MEMBERS_GROUP_ID = 'ALL_MEMBERS_GROUP_ID'

export type ReplacementSupervisorUser = Pick<
  User,
  'firstName' | 'lastName' | 'userAccessLevel' | 'locations'
> & {
  uid?: string
}

export type AssignMembersForLocationAndReplaceSupervisorsSectionProps = {
  locationId?: string
  assignedMemberIds: Set<string>
  setAssignedMemberIds: (newIds: Set<string>) => void
  replacementSupervisorMap: Record<string, string>
  setReplacementSupervisorMap: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >
  currentUserId?: string
  supervisorsForLocation: ReplacementSupervisorUser[]
  members: ReplacementSupervisorUser[]
  memberIdToMemberMap: Map<string, ReplacementSupervisorUser>
  loading?: boolean
  errorMessage?: string
  analyticsTrackingFunction?: AnalyticsTrackingFunction
}

export const AssignMembersForLocationAndReplaceSupervisorsSection = ({
  locationId,
  members,
  assignedMemberIds = new Set(allMemberIdsForLocation({ locationId, members })),
  setAssignedMemberIds,
  replacementSupervisorMap,
  setReplacementSupervisorMap,
  currentUserId,
  memberIdToMemberMap,
  loading,
  supervisorsForLocation,
  errorMessage,
  analyticsTrackingFunction,
}: AssignMembersForLocationAndReplaceSupervisorsSectionProps) => {
  // supervisors to replace at this location
  const membersToReplace = getSupervisorsToReplaceAtLocation({
    assignedMemberIdsAtLocation: assignedMemberIds,
    supervisorsForFutureShiftAtLocation: supervisorsForLocation,
  })

  const onUpdateMember = useCallback(
    (menuItems: IMenuItem[]) => {
      const newMemberIds = new Set(
        menuItems.map((item: IMenuItem) => item.value),
      )
      setAssignedMemberIds(newMemberIds)

      // if the supervisor map contains a replacement supervisor for a member
      // that has just been assigned to this current location, we no longer need
      // that replacement supervisor -> so remove them from the map
      const memberIdsToRemove = membersToReplace
        .map((member) => member.uid)
        .filter((memberId) => newMemberIds.has(memberId))

      setReplacementSupervisorMap(
        (currSupervisorMap: Record<string, string>) => {
          const newReplacementSupervisorMap = { ...currSupervisorMap }
          for (const id of memberIdsToRemove) {
            delete newReplacementSupervisorMap[id]
          }

          return newReplacementSupervisorMap
        },
      )

      if (analyticsTrackingFunction) {
        analyticsTrackingFunction('Users Assigned To Location', {
          locationId,
          locationIds: Array.from(newMemberIds),
        })
      }
    },
    [setAssignedMemberIds, membersToReplace, setReplacementSupervisorMap],
  )

  const groups: MenuItemGroup[] = [
    {
      id: ALL_MEMBERS_GROUP_ID,
      title: 'All Members',
    },
    {
      id: COMPANY_WIDE_ID,
      title: 'Company wide members',
    },
  ]

  const companyWideMemberIds = useMemo(() => {
    return new Set(
      members.filter(isBizMemberCompanyWide).map((member) => member.uid),
    )
  }, [members])

  const allOptions: IMenuItem[] = useMemo(
    () =>
      members.sort(sortMemberByFullName).map((member) => {
        const isMemberCompanyWide = isBizMemberCompanyWide(member)
        let tagsRow
        if (isMemberCompanyWide) {
          tagsRow = [
            {
              title: COMPANY_WIDE_TEXT,
              iconName: 'location' as IconName,
              variant: BadgeVariant.DARK_ORANGE,
            },
          ]
        } else {
          tagsRow = (member.locations || []).map((loc) => ({
            title: getLocationNameOrTruncatedAddress(loc),
            iconName: 'location' as IconName,
            variant: BadgeVariant.BUSINESS,
          }))
        }

        return {
          value: member.uid || '',
          label: getUserFullName(member),
          tagsRow,
          groupId: isMemberCompanyWide ? COMPANY_WIDE_ID : ALL_MEMBERS_GROUP_ID,
          disabled: member.uid === currentUserId,
        }
      }),
    [members, currentUserId],
  )

  const companyWideUsersOptions = allOptions.filter((option) =>
    companyWideMemberIds.has(option.value),
  )

  const assignedUserOptions = useMemo(
    () => allOptions.filter((option) => assignedMemberIds.has(option.value)),
    [allOptions, assignedMemberIds],
  )

  // only show members who are still assigned at the location
  const replacementMembers = members.filter((member) =>
    assignedMemberIds.has(member?.uid || ''),
  )

  return (
    <Col gap={theme.space.sm}>
      <Col gap={theme.space.xs}>
        <Text variant="h5">Assign Members</Text>
        <Text variant="body3">
          These are the members that will be able to view the shifts, workers,
          timesheets and invoices associated with this location.
        </Text>
        <Col gap={theme.space.xxs}>
          <SearchSelect
            groupByGroup
            groups={groups}
            multiple
            onlyShowLabel
            selectedItems={assignedUserOptions}
            preselectedItems={companyWideUsersOptions}
            handleSelectMultiple={onUpdateMember}
            options={allOptions}
            hidePreselectedItemsInSelectorText
            errorMessage={errorMessage}
            inputStatus={errorMessage ? InputStatus.error : InputStatus.default}
          />
          <InputInfoCalloutRow text="Company wide members have access to all locations" />
        </Col>
      </Col>

      {membersToReplace.length > 0 && (
        <Col gap={theme.space.sm}>
          <Col gap={theme.space.xs}>
            <Row gap={theme.space.xxs}>
              <SvgIcon name="info" size={24} color={theme.colors.Red60} />
              <Text variant="h5" color={theme.colors.Red60}>
                Add Replacement Supervisor
              </Text>
            </Row>
            <Text>
              This member is a supervisor for current or upcoming shifts, please
              select a replacement supervisor.
            </Text>
          </Col>
          <ReplaceSupervisorsForSingleLocationSection
            locationId={locationId}
            membersToReplace={membersToReplace}
            replacementMembers={replacementMembers}
            memberIdToMemberMap={memberIdToMemberMap}
            loading={loading}
            replacementSupervisorMap={replacementSupervisorMap}
            setReplacementSupervisorMap={setReplacementSupervisorMap}
            analyticsTrackingFunction={analyticsTrackingFunction}
          />
        </Col>
      )}
    </Col>
  )
}
