import { Tooltip } from '@mui/material'
import { Badge, BadgeVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ReactNode, useState, useMemo } from 'react'
import { Row } from 'src/components/base'

type ExpandableListProps = {
  title: ReactNode
  children?: ReactNode[]
  maxItems?: number
  style?: React.CSSProperties
}

export function ExpandableList(props: ExpandableListProps) {
  const { title, children = [], maxItems = 3 } = props
  const [showAll, setShowAll] = useState(false)

  const displayChildren = useMemo(
    () => (showAll ? children : children.slice(0, maxItems)),
    [children, showAll, maxItems],
  )

  if (!children || children.length === 0) {
    return null
  }

  const remainingCount = children.length - maxItems

  return (
    <Tooltip
      title={title}
      disableHoverListener={showAll || children.length <= maxItems}
    >
      <div>
        <Row
          gap={theme.space.xs}
          style={{
            flexWrap: 'wrap',
            ...props.style,
          }}
        >
          {displayChildren}
          {children.length > maxItems && (
            <Badge
              title={showAll ? `Show less` : `Show ${remainingCount} more`}
              onClick={() => setShowAll((p) => !p)}
              style={{ cursor: 'pointer' }}
              variant={BadgeVariant.TRANSPARENT}
            />
          )}
        </Row>
      </div>
    </Tooltip>
  )
}
