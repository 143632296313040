import { theme } from '@traba/theme'
import {
  AnalyticsTrackingFunction,
  COMPANY_WIDE_ID,
  COMPANY_WIDE_TEXT,
  IconName,
  InputStatus,
  User,
} from '@traba/types'
import {
  getLocationNameOrTruncatedAddress,
  getUserFullName,
  isBizMemberCompanyWide,
} from '@traba/utils'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingSpinner, Row, SearchSelect } from '../base-components'
import { BadgeVariant } from '../base-components/Badge/Badge.styles'
import { Col } from '../base-components/Col'
import { IMenuItem, MenuItemGroup, Tag } from '../base-components/Select/Select'
import { Text } from '../base-components/Text'

const LOCATION_ASSIGNED_DEFAULT_ID = 'location-assigned'
const LOCATION_ASSIGNED_TEXT = 'Location assigned'

const createItemFromMember = (member: Partial<User>): IMenuItem => {
  const isMemberCompanyWide = isBizMemberCompanyWide(member)
  const tagsRow: Tag[] = isMemberCompanyWide
    ? [
        {
          title: COMPANY_WIDE_TEXT,
          iconName: 'location' as IconName,
          variant: BadgeVariant.DARK_ORANGE,
        },
      ]
    : (member.locations || []).map((loc) => ({
        title: getLocationNameOrTruncatedAddress(loc),
        iconName: 'location' as IconName,
        variant: BadgeVariant.BUSINESS,
      }))
  return {
    value: member.uid as string,
    label: getUserFullName(member),
    tagsRow,
    groupId: isMemberCompanyWide
      ? COMPANY_WIDE_ID
      : LOCATION_ASSIGNED_DEFAULT_ID,
  }
}

export type ReplaceSupervisorsForLocationSectionProps = {
  locationId?: string
  membersToReplace: Partial<User>[]
  replacementMembers: Partial<User>[]
  memberIdToMemberMap: Map<string, Partial<User>>
  loading?: boolean
  replacementSupervisorMap: Record<string, string> // this map represents the memberToReplaceMemberId -> replacementMemberId
  setReplacementSupervisorMap: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >
  analyticsTrackingFunction?: AnalyticsTrackingFunction
}

export function ReplaceSupervisorsForSingleLocationSection({
  locationId,
  membersToReplace,
  replacementMembers,
  memberIdToMemberMap,
  loading,
  replacementSupervisorMap,
  setReplacementSupervisorMap,
  analyticsTrackingFunction,
}: ReplaceSupervisorsForLocationSectionProps) {
  const [replacementErrors, setReplacementErrors] = useState<
    Record<string, string | undefined>
  >({})

  const handleSetReplaceUserForUser = useCallback(
    (memberToReplaceId: string) => (menuItem: IMenuItem | undefined) => {
      const newUserId = menuItem?.value
      setReplacementSupervisorMap((prevMap) => ({
        ...prevMap,
        [memberToReplaceId]: newUserId || '',
      }))
      setReplacementErrors((prevErrors) => ({
        ...prevErrors,
        [memberToReplaceId]: undefined,
      }))

      if (analyticsTrackingFunction) {
        analyticsTrackingFunction(
          `User Set a Replacement Supervisor For User At Location`,
          {
            locationId,
            oldSupervisorId: memberToReplaceId,
            newSupervisorId: newUserId,
          },
        )
      }
    },
    [setReplacementSupervisorMap],
  )

  const groups: MenuItemGroup[] = useMemo(() => {
    return [
      {
        id: LOCATION_ASSIGNED_DEFAULT_ID,
        title: LOCATION_ASSIGNED_TEXT,
      },
      {
        id: COMPANY_WIDE_ID,
        title: COMPANY_WIDE_TEXT,
      },
    ]
  }, [])

  const getReplacementMembersForMember = useCallback(
    (memberUid: string | undefined) => {
      return replacementMembers
        .filter((m) => {
          const isNotCurrentUser = m.uid !== memberUid
          return isNotCurrentUser
        })
        .map(createItemFromMember)
    },
    [replacementMembers],
  )

  const onBlurSelect = (memberUid: string | undefined) => () => {
    if (memberUid && !replacementSupervisorMap[memberUid]) {
      setReplacementErrors((prevErrors) => ({
        ...prevErrors,
        [memberUid]: 'You must select a replacement member.',
      }))
    }
  }

  useEffect(() => {
    if (analyticsTrackingFunction) {
      analyticsTrackingFunction(
        'Replacement Supervisor For Single Location Section Shown',
        {
          locationId,
          memberIdsToReplace: membersToReplace.map((m) => m.uid),
        },
      )
    }
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }

  const emptyItem = { label: `--`, value: '' }

  return (
    <Col gap={theme.space.sm}>
      {membersToReplace.map((member) => {
        const selectedMemberId = replacementSupervisorMap[member.uid || '']
        const selectedMember = memberIdToMemberMap.get(selectedMemberId)
        const selectedMemberItem = selectedMember
          ? createItemFromMember(selectedMember)
          : emptyItem

        return (
          <Col key={member.uid} gap={theme.space.xxs}>
            <Row gap={theme.space.xxxs} alignCenter>
              <Text variant="h6">{getUserFullName(member)}</Text>
            </Row>
            <SearchSelect
              placeholder="Select a replacement supervisor"
              selectItem={selectedMemberItem}
              handleSelect={handleSetReplaceUserForUser(member.uid || '')}
              options={[
                emptyItem,
                ...getReplacementMembersForMember(member.uid),
              ]}
              style={{ width: '100%' }}
              selectStyle={{ maxWidth: '480px' }}
              menuItemStyle={{ maxWidth: '480px' }}
              onlyShowLabel
              onBlur={onBlurSelect(member.uid)}
              inputStatus={
                member.uid && replacementErrors[member.uid]
                  ? InputStatus.error
                  : undefined
              }
              errorMessage={member.uid && replacementErrors[member.uid]}
              groups={groups}
              groupByGroup
            />
          </Col>
        )
      })}
    </Col>
  )
}
