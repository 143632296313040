import {
  Anchor,
  Button,
  ButtonVariant,
  LoadingSpinner,
  SvgIcon,
  Text,
} from '@traba/react-components'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useVettingCampaigns } from 'src/hooks/useVettingCampaigns'

export type VettingCampaignSpreadsheetItemContentProps = {
  applicationItemSourceId?: string
}

export function VettingCampaignSpreadsheetItemContent({
  applicationItemSourceId,
}: VettingCampaignSpreadsheetItemContentProps) {
  const { getDateTime } = useTimezonedDates()
  const { vettingCampaigns, isLoading } = useVettingCampaigns({
    id: applicationItemSourceId || '',
  })

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!vettingCampaigns || vettingCampaigns.length === 0) {
    return (
      <>
        <Text variant="h4">Vetting Campaign</Text>
        <Text variant="body1">No vetting campaign found</Text>
      </>
    )
  }
  const vettingCampaign = vettingCampaigns[0]

  return (
    <>
      <Text variant="h4">{vettingCampaign.title}</Text>
      <Anchor
        href={`https://docs.google.com/spreadsheets/d/${vettingCampaign.googleSheetsId}`}
        openInNewTab
      >
        <Button variant={ButtonVariant.LINK}>
          <Text>Vetting Campaign </Text>
          <SvgIcon name="link" />
        </Button>
      </Anchor>
      <Text variant="body1">Companies</Text>
      <Text variant="body2" color="secondary">
        {vettingCampaign.companies
          .map((company) => company.employerName)
          .join(', ')}
      </Text>
      <Text variant="body1">Roles</Text>
      <Text variant="body2" color="secondary">
        {vettingCampaign.roles.map((role) => role.roleName).join(', ')}
      </Text>
      <Text variant="body1">Last Synced</Text>
      {vettingCampaign.lastGSheetSyncAt && (
        <Text variant="body2" color="secondary">
          {getDateTime(new Date(vettingCampaign.lastGSheetSyncAt))}
        </Text>
      )}
    </>
  )
}
