import { Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { HyperlinkLocalizedText } from '../HyperlinkLocalizedText'

interface DetailRowProps {
  label: string
  value?: string
  customResultsComponent?: React.ReactNode
}

export function DetailRow({
  label,
  value,
  customResultsComponent,
}: DetailRowProps) {
  return (
    <Row>
      <Text variant="h6" style={{ minWidth: '150px', maxWidth: '150px' }}>
        {label}
      </Text>

      {customResultsComponent ? (
        customResultsComponent
      ) : (
        <HyperlinkLocalizedText
          text={value || ''}
          variant="body1"
          linkColor={theme.colors.brand}
        />
      )}
    </Row>
  )
}
