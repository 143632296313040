import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { ShiftRequestParentWithShiftRequest } from '@traba/types'

export interface WorkerRecurringSchedulesParams {
  workerId: string
  showInactiveSchedules: boolean
}

const getShiftRequestParents = async ({
  workerId,
  showInactiveSchedules,
}: WorkerRecurringSchedulesParams) => {
  try {
    const response = await trabaApi.get(
      `/workers/${workerId}/schedules/${showInactiveSchedules}`,
    )
    return response.data
  } catch (error) {
    console.error("Error querying for company's shift request parents: ", error)
    throw error
  }
}

export const useWorkerShiftRequestParents = ({
  workerId,
  showInactiveSchedules,
}: WorkerRecurringSchedulesParams) => {
  const {
    isLoading,
    isError,
    data: workerScheduleData,
    error,
    isFetched,
    refetch,
  } = useQuery<
    { shiftRequestParents: ShiftRequestParentWithShiftRequest[] },
    Error
  >({
    queryKey: ['shift-request-parents', workerId, showInactiveSchedules],
    queryFn: () =>
      getShiftRequestParents({
        workerId,
        showInactiveSchedules,
      }),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    workerScheduleData,
    refetch,
  }
}
