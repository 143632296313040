import { Button, ButtonVariant } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Role, User, Roster, EmploymentType } from '@traba/types'
import { Dispatch, SetStateAction } from 'react'
import { Col } from 'src/components/base'
import { CreateShiftRequest } from 'src/hooks/useShiftRequests'
import { PopulatedWorker } from 'src/screens/WorkerSearchScreen/worker-search.types'
import { ShiftPostingInputContainer } from '../ShiftPostingInputContainer'
import { IndividualRoleForm } from './IndividualRoleForm'

interface Props {
  createShiftRequests: CreateShiftRequest[]
  setCreateShiftRequests: (value: SetStateAction<CreateShiftRequest[]>) => void

  roles: Role[] | undefined
  companyUsers: User[] | undefined
  minHourlyPayRate: number
  setWorkersToInvite: Dispatch<SetStateAction<PopulatedWorker[]>>
  workersToInvite: PopulatedWorker[]
  companyId: string
  rosters: Roster[] | undefined
  overrideBusinessStartTime?: Date
  isEditExistingRole?: boolean
  shiftEmploymentType: EmploymentType
}

export const RolesAndWorkersSection = ({
  createShiftRequests,
  setCreateShiftRequests,
  roles,
  companyUsers = [],
  minHourlyPayRate,
  setWorkersToInvite,
  workersToInvite,
  companyId,
  rosters,
  overrideBusinessStartTime,
  isEditExistingRole,
  shiftEmploymentType,
}: Props) => {
  return (
    <ShiftPostingInputContainer
      title="Roles & Workers"
      titleActions={
        isEditExistingRole ? null : (
          <Button
            variant={ButtonVariant.TEXT}
            style={{ color: theme.colors.Violet }}
            onClick={() => {
              setCreateShiftRequests((prev) => [
                ...prev,
                { ...createShiftRequests[0], application: undefined },
              ])
            }}
          >
            Add another role
          </Button>
        )
      }
    >
      <Col>
        {createShiftRequests.map((cr, index) => (
          <IndividualRoleForm
            key={`role-form-${index}`}
            index={index}
            createShiftRequest={cr}
            createShiftRequests={createShiftRequests}
            setCreateShiftRequests={setCreateShiftRequests}
            roles={roles}
            companyId={companyId}
            companyUsers={companyUsers}
            minHourlyPayRate={minHourlyPayRate}
            overrideBusinessStartTime={overrideBusinessStartTime}
            workersToInvite={workersToInvite}
            setWorkersToInvite={setWorkersToInvite}
            rosters={rosters}
            shiftEmploymentType={shiftEmploymentType}
            disableChangingRole={isEditExistingRole ?? false}
          />
        ))}
      </Col>
    </ShiftPostingInputContainer>
  )
}
