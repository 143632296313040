import { Badge, BadgeVariant } from '@traba/react-components'
import { WorkerApplicationStatus } from '@traba/types'

export type WorkerApplicationStatusBadgeProps = {
  applicationStatus: WorkerApplicationStatus
  style?: React.CSSProperties
}

const APPLICATION_STATUS_TO_BADGE_VARIANT: Record<
  WorkerApplicationStatus,
  BadgeVariant
> = {
  [WorkerApplicationStatus.COMPLETED_ELIGIBLE]: BadgeVariant.SUCCESS,
  [WorkerApplicationStatus.COMPLETED_INELIGIBLE]: BadgeVariant.DARK_ORANGE,
  [WorkerApplicationStatus.IN_PROGRESS]: BadgeVariant.YELLOW,
  [WorkerApplicationStatus.NOT_STARTED]: BadgeVariant.DISABLED,
  [WorkerApplicationStatus.REVIEW_REQUIRED]: BadgeVariant.DARK_RED,
  [WorkerApplicationStatus.SYSTEM_PROCESSING]: BadgeVariant.INFO,
}

export const CUSTOM_STATUS_TO_TEXT: Record<WorkerApplicationStatus, string> = {
  [WorkerApplicationStatus.COMPLETED_ELIGIBLE]: 'Eligible',
  [WorkerApplicationStatus.COMPLETED_INELIGIBLE]: 'Ineligible',
  [WorkerApplicationStatus.IN_PROGRESS]: 'In Progress',
  [WorkerApplicationStatus.NOT_STARTED]: 'To Do',
  [WorkerApplicationStatus.REVIEW_REQUIRED]: 'Needs Action',
  [WorkerApplicationStatus.SYSTEM_PROCESSING]: 'Processing',
}

export function WorkerApplicationStatusBadge({
  applicationStatus,
  ...props
}: WorkerApplicationStatusBadgeProps) {
  return (
    <Badge
      {...props}
      title={CUSTOM_STATUS_TO_TEXT[applicationStatus]}
      variant={APPLICATION_STATUS_TO_BADGE_VARIANT[applicationStatus]}
    />
  )
}
