import { Col } from '@traba/react-components'
import {
  ApplicationItemContent as ApplicationItemContentType,
  ApplicationItemType,
} from '@traba/types'
import {
  buildInitialInputFromCustomQuestionApplicationItemContent,
  buildInitialInputFromOpsManualStepApplicationItemContent,
  convertToCustomQuestionApplicationItemContent,
  convertToOpsManualStepApplicationItemContent,
} from 'src/utils/applicationUtils'
import { CustomQuestionApplicationItemContent } from './items/CustomerQuestionApplicationItemContent'
import { OpsManualStepApplicationItemContent } from './items/OpsManualStepApplicationItemContent'
import { VettingCampaignSpreadsheetItemContent } from './items/VettingCampaignSpreadsheetItemContent'
import { WorkerVettingCallApplicationItemContent } from './items/WorkerVettingCallApplicationItemContent'

interface ApplicationItemContentProps {
  applicationItemId?: string
  applicationItemType: ApplicationItemType
  applicationItemSourceId?: string
  itemContent?: ApplicationItemContentType
}

export const ApplicationItemContent = ({
  applicationItemId,
  applicationItemType,
  applicationItemSourceId,
  itemContent = {},
}: ApplicationItemContentProps) => {
  const defaultContent = <Col>Nothing to display</Col>

  switch (applicationItemType) {
    case ApplicationItemType.AI_VETTING_CALL: {
      const vettingConfig =
        'vettingConfig' in itemContent ? itemContent?.vettingConfig : undefined

      return (
        <WorkerVettingCallApplicationItemContent
          applicationItemSourceId={applicationItemSourceId}
          vettingConfig={vettingConfig}
        />
      )
    }
    case ApplicationItemType.CUSTOM_QUESTION: {
      const customQuestionItemContent =
        convertToCustomQuestionApplicationItemContent(itemContent)
      const initialValuesFromItemContent =
        buildInitialInputFromCustomQuestionApplicationItemContent(
          customQuestionItemContent,
        )

      return (
        <CustomQuestionApplicationItemContent
          applicationItemSourceId={applicationItemSourceId}
          {...initialValuesFromItemContent}
        />
      )
    }
    case ApplicationItemType.OPS_MANUAL_STEP: {
      const opsManualStepItemContent =
        convertToOpsManualStepApplicationItemContent(itemContent)
      const initialValuesFromItemContent =
        buildInitialInputFromOpsManualStepApplicationItemContent(
          opsManualStepItemContent,
        )

      return (
        <OpsManualStepApplicationItemContent
          applicationItemId={applicationItemId}
          {...initialValuesFromItemContent}
        />
      )
    }
    case ApplicationItemType.RESUME_UPLOAD: {
      return null // no extra content to show
    }
    case ApplicationItemType.VETTING_CAMPAIGN_SPREADSHEET: {
      return (
        <VettingCampaignSpreadsheetItemContent
          applicationItemSourceId={applicationItemSourceId}
        />
      )
    }
    case ApplicationItemType.GHOST_PROFILE_LINKED: {
      return null // no extra content to show
    }
    default:
      return defaultContent
  }
}
