import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { captureSentryError } from '@traba/utils'
import { ZealEmployeeType } from 'src/types/zeal'

export async function getZealAccountLink(
  workerId: string,
): Promise<string | undefined> {
  const path = `/zeal/generate-account-link/${workerId}`
  const response = await trabaApi.post(path)
  return response.data
}

async function getZealInformation(workerId: string) {
  try {
    const path = `/zeal/get-employee-info/${workerId}`
    const response = await trabaApi.get(path)
    return response.data
  } catch (error) {
    console.error(error)
    captureSentryError(error)
  }
}

export function useZeal({
  workerId,
  enabled,
}: {
  workerId: string
  enabled?: boolean
}) {
  const {
    isLoading: isZealLoading,
    isError: isZealError,
    data: zealEmployeeData,
    error: zealError,
  } = useQuery<ZealEmployeeType, Error>({
    queryKey: ['zeal', workerId],
    queryFn: () => getZealInformation(workerId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!enabled,
  })

  return {
    zealEmployeeData,
    isZealLoading,
    isZealError,
    zealError,
  }
}
