import { Col, LoadingSpinner } from '@traba/react-components'
import { theme } from '@traba/theme'
import { ShiftPostingInputContainer } from '../../ShiftPostingInputContainer'

export function ApplicationsSectionLoader() {
  return (
    <ShiftPostingInputContainer
      title="Applications (optional)"
      style={{ background: theme.colors.Grey10, borderRadius: '10px' }}
    >
      <Col fullWidth gap={theme.space.sm}>
        <LoadingSpinner />
      </Col>
    </ShiftPostingInputContainer>
  )
}
