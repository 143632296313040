import { ButtonVariant } from '@traba/react-components'
import { StyledButton } from './styles'

interface CancelButtonProps {
  onCancel: () => void
  isLoading?: boolean
}

export function CancelButton({ onCancel, isLoading }: CancelButtonProps) {
  return (
    <StyledButton
      variant={ButtonVariant.OUTLINED}
      onClick={onCancel}
      disabled={isLoading}
    >
      Cancel
    </StyledButton>
  )
}
