import { Drawer, IconButton, Tooltip } from '@mui/material'
import { useAlert, useUserContext } from '@traba/context'
import { useDeleteEligibilityConnectionMutation } from '@traba/hooks'
import { Badge, SvgIcon, BadgeVariant, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { Tag, TagAssociation } from '@traba/types'
import { useEffect, useMemo, useState, useCallback } from 'react'
import { Plus } from 'src/assets/svg/icons/Plus'
import { Trash } from 'src/assets/svg/icons/Trash'
import { Button, CopyTextIcon, Link } from 'src/components/base'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import { useModal } from 'src/components/base/Modal/Modal'
import { DataTable, TableRow } from 'src/components/base/Table/DataTable'
import { BugleActionType } from 'src/components/BugleDrawer/BugleDrawer'
import { DrawerContainer } from 'src/components/BugleDrawer/styles'
import { NewBugleScreen } from 'src/components/BugleDrawer/tabs/NewBugleScreen'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { useCompanyEligibilityConnections } from 'src/hooks/useConnections'
import { useEligibilityGroups } from 'src/hooks/useEligibilityGroups'
import { useRoles } from 'src/hooks/useRoles'
import { useTags } from 'src/hooks/useTags'
import { truncateString } from 'src/utils/stringUtils'
import { CompanyEligibilityConnectionModal } from './CompanyEligibilityConnectionModal'
import { CompanyEligibilityGroupModal } from './CompanyEligibilityGroup'
import { CompanyEligibilityGroupTable } from './CompanyEligibilityGroupTable'
import { ExpandableList } from './ExpandableList'

interface CompanyEligibilityConnectionsProps {
  companyId: string
}

const BadgeWithHoverDelete = ({
  tag,
  workerId,
  onDelete,
}: {
  tag: Tag
  workerId: string
  onDelete: (workerId: string, tagName: string) => void
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        marginRight: theme.space.xs,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Badge variant={BadgeVariant.BRAND} key={tag.id} title={tag.name} />
      {isHovered && (
        <Tooltip title={`Delete ${tag.name} group from worker`}>
          <IconButton
            size="small"
            onClick={() => onDelete(workerId, tag.name)}
            style={{
              position: 'absolute',
              right: -10,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              boxShadow: '0 0 2px rgba(0, 0, 0, 0.2)',
              padding: 2,
            }}
          >
            <Trash size={16} color={theme.colors.red} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export default function CompanyEligibilityConnections({
  companyId,
}: CompanyEligibilityConnectionsProps) {
  const { showError } = useAlert()

  const {
    state: { userProfile },
  } = useUserContext()

  const {
    eligibilityConnections = [],
    isLoading,
    isError,
  } = useCompanyEligibilityConnections(companyId)

  const { deleteEligibilityConnections } =
    useDeleteEligibilityConnectionMutation()

  const { roles = [] } = useRoles({ companyId })
  const { tags: tagsForRoles = [] } = useTags({
    associations: roles.map((role) => ({
      roleId: role.roleId,
    })),
  })

  const {
    tags: tagsForWorkers = [],
    isSearchingTags,
    isSearchingTagsError,
  } = useTags({
    associations: eligibilityConnections?.map((worker) => ({
      workerId: worker.workerId,
    })),
  })

  const { unassignWorkersFromEligibilityGroup } = useEligibilityGroups()

  const tagsByWorker = useMemo(() => {
    return tagsForWorkers
      .filter((tag) => tagsForRoles.some((t) => t.id === tag.id))
      .reduce<Record<string, Tag[]>>((acc, tag) => {
        tag.associations
          .filter(
            (a): a is TagAssociation & { workerId: string } =>
              a.workerId !== undefined,
          )
          .forEach(({ workerId }) => {
            acc[workerId] = [...(acc[workerId] || []), tag]
          })
        return acc
      }, {})
  }, [tagsForRoles, tagsForWorkers])

  const workersByTagName = useMemo(() => {
    return tagsForWorkers.reduce<Record<string, string[]>>((acc, tag) => {
      tag.associations
        .filter(
          (a): a is TagAssociation & { workerId: string } =>
            a.workerId !== undefined,
        )
        .forEach(({ workerId }) => {
          acc[tag.name] = [...(acc[tag.name] || []), workerId]
        })
      return acc
    }, {})
  }, [tagsForWorkers])

  const createCompanyEligibilityConnectionModal = useModal()
  const createEligibilityGroupModal = useModal()
  const [selectedWorkerId, setSelectedWorkerId] = useState<string>('')
  const [showSelect, setShowSelect] = useState<boolean>(false)
  const [isOpenBugleDrawer, setIsOpenBugleDrawer] = useState<boolean>(false)

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [workerToUnassign, setWorkerToUnassign] = useState<string | null>(null)
  const [tagToUnassign, setTagToUnassign] = useState<string | null>(null)
  const [unassignInProgress, setUnassignInProgress] = useState(false)

  useEffect(() => {
    if (isError || isSearchingTagsError) {
      showError(
        'There was an error fetching the eligibility connections',
        'Error fetching eligibility connections',
      )
    }
  }, [isError, isSearchingTagsError, showError])

  const handleBadgeDelete = useCallback((workerId: string, tagName: string) => {
    setWorkerToUnassign(workerId)
    setTagToUnassign(tagName)
    setIsConfirmationOpen(true)
  }, [])

  if (isLoading || isSearchingTags) {
    return <CircularProgress size="medium" />
  }

  const handleUnassign = async () => {
    if (!workerToUnassign || !tagToUnassign) {
      return
    }

    setUnassignInProgress(true)
    try {
      await unassignWorkersFromEligibilityGroup({
        companyId,
        workerIds: [workerToUnassign],
        tagName: tagToUnassign,
        initiatedBy: userProfile?.email ?? 'unknown',
      })
    } finally {
      setUnassignInProgress(false)
      setWorkerToUnassign(null)
      setTagToUnassign(null)
    }
  }

  const getWorkerName = (workerId: string | null) => {
    if (!workerId) {
      return 'Unknown Worker'
    }

    const worker = eligibilityConnections.find(
      (w) => w.workerId === workerId || w.uid === workerId,
    )

    if (!worker) {
      return 'Unknown Worker'
    }
    return `${worker.firstName} ${worker.lastName}`
  }

  const tableRows: TableRow[] = eligibilityConnections.map((worker) => {
    const { firstName, lastName, regionId } = worker

    const workerId = worker.workerId || worker.uid
    const name = `${firstName} ${lastName}`

    return {
      key: `eligibility_connections_${worker.id}`,
      cells: [
        {
          renderFn: () => (
            <Row justifyBetween>
              <Link to={`/workers/${workerId}`} target="_blank">
                <Button style={{ padding: 0 }} variant={ButtonVariant.TEXT}>
                  {name}
                </Button>
              </Link>
            </Row>
          ),
          sortKey: name,
        },
        {
          renderFn: () => (
            <>
              {truncateString(workerId, 6)}
              <CopyTextIcon textToCopy={workerId} />
            </>
          ),
          sortKey: workerId,
        },
        {
          renderFn: () => regionId,
        },
        {
          renderFn: () => (
            <ExpandableList
              title={tagsByWorker[workerId]?.map((tag) => tag.name).join(', ')}
              style={{ padding: theme.space.xs }}
            >
              {tagsByWorker[workerId]?.map((tag) => (
                <BadgeWithHoverDelete
                  key={tag.id}
                  tag={tag}
                  workerId={workerId}
                  onDelete={handleBadgeDelete}
                />
              ))}
            </ExpandableList>
          ),
        },
        {
          renderFn: () => (
            <Row justifyBetween>
              {tagsByWorker[workerId] === undefined && (
                <>
                  <SvgIcon
                    name={'edit'}
                    onClick={() => {
                      setSelectedWorkerId(workerId)
                      setShowSelect(false)
                      createCompanyEligibilityConnectionModal.open()
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                  <SvgIcon
                    name={'cancel'}
                    color={theme.colors.red}
                    onClick={() => {
                      deleteEligibilityConnections({ companyId, workerId })
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </>
              )}
            </Row>
          ),
        },
      ],
    }
  })

  return (
    <>
      <Row pb={theme.space.xs} fullWidth alignEnd>
        <Row
          ml="auto"
          gap={theme.space.xs}
          style={{ minWidth: '500px' }}
          justifyEnd
        >
          <Button
            style={{ padding: 0 }}
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={() => {
              setIsOpenBugleDrawer(true)
            }}
          >
            Assign Workers to Group
          </Button>
          <Button
            style={{ padding: 0 }}
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={() => {
              createEligibilityGroupModal.open()
            }}
          >
            Create Eligibility Group
          </Button>
          <Button
            style={{ padding: 0 }}
            leftIcon={<Plus />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={() => {
              setShowSelect(true)
              createCompanyEligibilityConnectionModal.open()
            }}
          >
            Create Eligibility Connection
          </Button>
        </Row>
      </Row>

      <CompanyEligibilityGroupTable
        companyId={companyId}
        onDelete={async (tagName) => {
          await unassignWorkersFromEligibilityGroup({
            companyId,
            workerIds: workersByTagName[tagName] || [],
            tagName,
            initiatedBy: userProfile?.email || 'unknown',
          })
        }}
      />

      <DataTable
        initialSortByColumnIndex={0} // sort by name column
        rows={tableRows}
        headers={[
          {
            key: 'name',
            label: 'Name',
            sortable: true,
          },
          {
            key: 'workerId',
            label: 'Worker ID',
            sortable: true,
          },
          {
            key: 'regionId',
            label: 'Region Id',
          },
          {
            key: 'groups',
            label: 'Groups',
          },
          {
            key: 'modifyEligibilityConnection',
            label: 'Modify Eligibility Connection',
          },
        ]}
      />
      <CompanyEligibilityConnectionModal
        companyId={companyId}
        isOpen={createCompanyEligibilityConnectionModal.isOpen}
        handleClose={createCompanyEligibilityConnectionModal.handleClose}
        selectedWorkerId={selectedWorkerId}
        setSelectedWorkerId={setSelectedWorkerId}
        showSelect={showSelect}
      />
      <CompanyEligibilityGroupModal
        companyId={companyId}
        isOpen={createEligibilityGroupModal.isOpen}
        handleClose={createEligibilityGroupModal.handleClose}
      />
      <Drawer
        anchor="right"
        open={isOpenBugleDrawer}
        onClose={() => setIsOpenBugleDrawer(false)}
      >
        <DrawerContainer style={{ padding: `0 ${theme.space.xs}px` }}>
          <NewBugleScreen
            companyId={companyId}
            defaultActionType={BugleActionType.EligibilityGroup}
          />
        </DrawerContainer>
      </Drawer>

      <ConfirmationDialog
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleUnassign}
        title="Remove Worker from Group"
        content={`Are you sure you want to remove ${getWorkerName(workerToUnassign)} from the "${tagToUnassign}" group?`}
        confirmButtonText="Remove"
        confirmLoading={unassignInProgress}
      />
    </>
  )
}
