import { Badge, Col, LoadingSpinner, Row } from '@traba/react-components'
import { theme } from '@traba/theme'
import { VettingConfig } from '@traba/types'
import { AttributeBadge } from 'src/components/AttributeBadge/AttributeBadge'
import { useAttributes } from 'src/hooks/useAttributes'
import { useWorkerVettingCampaign } from 'src/hooks/useWorkerVetting'
import { DetailRow } from '../DetailRow'

interface WorkerVettingCallApplicationItemContentProps {
  applicationItemSourceId?: string
  vettingConfig?: VettingConfig
}

export function WorkerVettingCallApplicationItemContent({
  applicationItemSourceId,
  vettingConfig,
}: WorkerVettingCallApplicationItemContentProps) {
  const { campaign: workerVettingCampaign, isLoading } =
    useWorkerVettingCampaign(applicationItemSourceId || '')

  const { attributes: roleAttributes } = useAttributes()

  if (isLoading) {
    return <LoadingSpinner />
  }

  const {
    jobTitle,
    payRatePlainText,
    locationDetails,
    scheduleDetails,
    roleDetails,
    employmentType,
    otherQuestions,
    language,
  } =
    workerVettingCampaign?.activePromptContext?.context ||
    vettingConfig?.vettingLogistics ||
    {}

  const requiredAttributes =
    workerVettingCampaign?.activePromptContext?.requiredAttributeLevels

  return (
    <Col gap={theme.space.xs}>
      <DetailRow label="Job Title(s)" value={jobTitle} />
      <DetailRow label="Pay Rate" value={payRatePlainText} />
      <DetailRow label="Location Details" value={locationDetails} />
      <DetailRow
        label="Language"
        customResultsComponent={
          <div>
            <Badge sm title={language === 'es' ? 'Spanish' : 'English'} />
          </div>
        }
      />
      <DetailRow label="Schedule details" value={scheduleDetails} />
      <DetailRow label="Role details" value={roleDetails} />
      <DetailRow label="Employment Type" value={employmentType || '-'} />
      <DetailRow label="Other Questions" value={otherQuestions?.join('\n')} />
      <DetailRow
        label="Attributes"
        customResultsComponent={
          <Row gap={theme.space.xxs} wrap>
            {requiredAttributes?.map((attributeLevel) => {
              const roleAttribute = roleAttributes?.find(
                (sa) => sa.type === attributeLevel.attribute.type,
              )
              if (!roleAttribute) {
                return null
              }
              return (
                <AttributeBadge
                  key={attributeLevel.attribute.type}
                  attributeType={attributeLevel.attribute.type}
                  attributeLevel={attributeLevel.level}
                  attributeName={roleAttribute.displayName}
                  category={roleAttribute.category}
                  levelType={roleAttribute.levelType}
                />
              )
            })}
          </Row>
        }
      />
    </Col>
  )
}
