export enum CompanyFeatureFlag {
  LOCATIONS_ASSIGNED_USER_ANNOUNCEMENT_BANNER = 'location_assigned_user_announcement_banner',
  SCHEDULES = 'schedules',
}

export enum OpsFeatureFlag {
  WORKER_PERFORMANCE = 'worker_performance',
}

export enum DynamicConfigs {
  ATS_DYNAMIC_CONFIG = 'ats_dynamic_config',
  OPERATOR_AI_CONFIG = 'operator_ai_config',
  SHIFT_MINIMUM_DURATION_BYPASS = 'shift_minimum_duration_bypass',
}
