import {
  Button,
  ButtonVariant,
  Col,
  LoadingSpinner,
  Row,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Company,
  Shift,
  ShiftRequest,
  ShiftRequestParentWithShiftRequest,
  ShiftStatus,
} from '@traba/types'
import { useState } from 'react'
import { getPayRateString } from 'src/utils/stringUtils'
import { EditScheduleOption } from './EditScheduleOption'
import { EditScheduleShiftRequestDetails } from './EditScheduleShiftRequestDetails'

interface Props {
  company: Company
  shiftRequests: ShiftRequest[]
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  shifts?: Shift[]
  onBack: () => void
  onSuccess: () => void
}
enum EDIT_SHIFT_REQUEST_STEPS {
  SELECT_SHIFT_REQUEST = 'SELECT_SHIFT_REQUEST',
  MODIFY_SHIFT_REQUEST = 'MODIFY_SHIFT_REQUEST',
}

export const EditScheduleShiftRequest = ({
  company,
  shiftRequestParent,
  shiftRequests,
  shifts,
  onBack,
  onSuccess,
}: Props) => {
  const [currentStep, setCurrentStep] = useState<EDIT_SHIFT_REQUEST_STEPS>(
    EDIT_SHIFT_REQUEST_STEPS.SELECT_SHIFT_REQUEST,
  )
  const [selectedShiftRequest, setSelectedShiftRequest] =
    useState<ShiftRequest>()

  const activeShiftRequestIds = shifts?.reduce((acc, shift) => {
    if (shift.status === ShiftStatus.ACTIVE) {
      acc.add(shift.shiftRequestId)
    }
    return acc
  }, new Set<string>())

  if (currentStep === EDIT_SHIFT_REQUEST_STEPS.SELECT_SHIFT_REQUEST) {
    return (
      <>
        <Text variant="h5" mb={theme.space.sm}>
          Select which shift request you'd like to edit
        </Text>
        <Col gap={theme.space.xs}>
          {shiftRequests
            .filter((sr) => activeShiftRequestIds?.has(sr.shiftRequestId))
            .map((sr) => (
              <EditScheduleOption
                key={sr.shiftRequestId}
                selected={
                  selectedShiftRequest?.shiftRequestId === sr.shiftRequestId
                }
                title={`${sr.shiftRole} (${sr.slotsRequested} slots - ${getPayRateString(sr.payType, sr.payRate)})`}
                onClick={() => {
                  setSelectedShiftRequest(sr)
                }}
              />
            ))}
        </Col>
        <Row justifyBetween mt={theme.space.sm}>
          <Button variant={ButtonVariant.OUTLINED} onClick={onBack}>
            Back
          </Button>
          <Button
            disabled={!selectedShiftRequest}
            onClick={() => {
              setCurrentStep(EDIT_SHIFT_REQUEST_STEPS.MODIFY_SHIFT_REQUEST)
            }}
          >
            Next
          </Button>
        </Row>
      </>
    )
  }
  if (!selectedShiftRequest) {
    return <LoadingSpinner />
  }
  return (
    <EditScheduleShiftRequestDetails
      shifts={shifts}
      selectedShiftRequest={selectedShiftRequest}
      shiftRequestParent={shiftRequestParent}
      company={company}
      onBack={() =>
        setCurrentStep(EDIT_SHIFT_REQUEST_STEPS.SELECT_SHIFT_REQUEST)
      }
      onSuccess={onSuccess}
    />
  )
}
