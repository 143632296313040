import { useQuery } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import { AxiosResponse } from 'axios'

type ZealI9StatusAndLink = {
  i9Status: string
  i9Link: string
}

export function useZealI9({
  workerId,
  enabled,
}: {
  workerId: string
  enabled?: boolean
}) {
  const { showError } = useAlert()

  async function getZealI9Link(workerId: string) {
    try {
      const path = `/zeal/i9-link/${workerId}`
      const response = await trabaApi.get(path)
      return response.data
    } catch (error: any) {
      showError(error.message || 'Error fetching Zeal I9 link')
      throw error
    }
  }

  const {
    isFetching: isZealI9Fetching,
    isError: isZealI9Error,
    data: zealI9Data,
    error: zealI9Error,
    refetch: refetchZealI9,
  } = useQuery<AxiosResponse<string, string>, Error, ZealI9StatusAndLink>({
    queryKey: ['zeal', workerId],
    queryFn: () => getZealI9Link(workerId),
    staleTime: FIVE_MINUTES_IN_MS,
    enabled: !!enabled,
  })

  return {
    zealI9Data,
    isZealI9Fetching,
    isZealI9Error,
    zealI9Error,
    refetchZealI9,
  }
}
